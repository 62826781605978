import React from "react";
import { SortAscIcon, SortDescIcon, SortIcon } from "assets/icons";
import { Typography } from "@mui/material";
import { HStack } from "libs/layouts";
import { css } from "ui/css";
import { HeaderCellProps } from "./types";

const textStyles = css({
  fontSize: "13px",
  fontWeight: 500,
  textTransform: "uppercase",
});

export function DefaultThContent(props: HeaderCellProps) {
  const direction = props?.sort?.sort_desc ? "desc" : "asc";
  const active = props?.sort?.sort_by === props.column.id;
  const hasSort = props.column.sortable;

  const Icon = active ? (direction === "asc" ? SortAscIcon : SortDescIcon) : SortIcon;

  return (
    <HStack space={2} align="center" className={props.className + " rt-th"} style={props.style}>
      <Typography noWrap component="div" color="textSecondary" className={textStyles().className}>
        {props.children}
      </Typography>
      {hasSort && (
        <Icon
          cursor="pointer"
          fontSize="small"
          onClick={props?.toggleSort}
          color={active ? "primary" : "action"}
        />
      )}
    </HStack>
  );
}
