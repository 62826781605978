import React from "react";
import { FORM_ERROR } from "final-form";
import { Dialog, DialogProps } from "@mui/material";
import { useModal } from "libs/hooks";
import { isThirdPartyEditMode } from "modules/analytics/Analytics";
import { ConfirmDialog } from "./ConfirmDialog";

const stopPropagation = (e: any) => e.stopPropagation();

const defaultErrorMap = (v: string) => ({ [FORM_ERROR]: v });
export function useFormDialog<
  P extends {
    onSubmit: (v: any) => any;
    onClose?: any;
    onDelete?: (v: any) => any;
    maxWidth?: DialogProps["maxWidth"];
    className?: DialogProps["className"];
    testId?: string;
  }
>(
  props: P,
  Component: React.FC<React.PropsWithChildren<P>> = ConfirmDialog as any,
  config: {
    mapSubmitError?: (error: string) => Record<string, string>;
  } = {}
) {
  config.mapSubmitError ??= defaultErrorMap;
  return useModal((args) => {
    const onSubmit = React.useCallback(
      async (v: any) => {
        try {
          const res = await props.onSubmit(v);
          args.onClose();
          return res;
        } catch (e: any) {
          return config?.mapSubmitError?.(e.response?.data?.error);
        }
      },
      [args.onClose, props.onSubmit]
    );
    const onClose = React.useCallback(
      (e: any) => {
        e?.stopPropagation();
        args?.onClose();
      },
      [args.onClose]
    );
    const onDelete = React.useCallback(
      async (v: any) => {
        await props?.onDelete?.(v);
        args?.onClose();
      },
      [args.onClose, props?.onDelete]
    );
    return (
      <Dialog
        {...args}
        maxWidth={props.maxWidth || "xs"}
        fullWidth
        onClick={stopPropagation}
        disableEscapeKeyDown
        disableEnforceFocus={isThirdPartyEditMode()}
        PaperProps={{ className: props.className }}
        data-testid={props.testId}
      >
        <Component
          {...props}
          onSubmit={onSubmit}
          onClose={onClose}
          onDelete={props?.onDelete ? onDelete : undefined}
        />
      </Dialog>
    );
  });
}
