import "./VarsPrism.css";
declare const Prism: any;

Prism.languages.vars = {
  var: {
    pattern: /\{\{\w*[\}]*/,
    inside: {
      ld: {
        pattern: /^(?:\{\{)/,
      },
      rd: {
        pattern: /\}\}$/,
      },
      property: /\b[a-zA-Z_]\w*\b/,
    },
  },
};

export default "";
