import axios from "axios";

export function isRbacError<T>(error: T): { result: boolean; error?: T } {
  if (axios.isAxiosError(error)) {
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if ([401, 403].includes(status) && data?.detail?.Code !== "mfa-required") {
        return {
          result: true,
          error: error,
        };
      }
    }
  }
  return {
    result: false,
  };
}
