import React from "react";
import { Box } from "@mui/system";
import { styled } from "ui/css";

const NoteContainer = styled(Box, {
  background: "#e9f6f9",
  border: "1px solid #d7eff4",
  padding: "10px",
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "-0.2px",
  width: "fit-content",
});

export interface NoteProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  hideTitle?: boolean;
}

export function Note({ children, style, title, hideTitle }: NoteProps) {
  return (
    <NoteContainer style={style}>
      <span hidden={hideTitle} style={{ fontWeight: "bold" }}>
        {title || "Note"}:{" "}
      </span>
      {children}
    </NoteContainer>
  );
}
