import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { SimpleSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { Resource } from "@opentelemetry/resources";
import { SEMRESATTRS_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";

let lastUserAction = "";

function setLastUserAction(action: string) {
  lastUserAction = action;
}
export const frontendTracingSetup = async () => {
  console.info("Setting up frontend tracing");
  const provider = new WebTracerProvider({
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: "frontend-web",
    }),
  });

  provider.addSpanProcessor(
    new SimpleSpanProcessor(
      new OTLPTraceExporter({
        url: "/otel-web/v1/traces",
        concurrencyLimit: 2,
      })
    )
  );

  provider.register({
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  });

  registerInstrumentations({
    tracerProvider: provider,

    instrumentations: [
      new XMLHttpRequestInstrumentation({
        ignoreUrls: [/localhost:3000\/sockjs-node/],
        applyCustomAttributesOnSpan(span, request) {
          const path = new URL(request.responseURL).pathname;
          span.setAttribute("http.path", path);
          span.updateName(`frontend-web#${path}`);
          if (lastUserAction) {
            span.setAttribute("app.last_user_action", lastUserAction);
          }
        },
      }),
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: /.*/,
        clearTimingResources: true,
        applyCustomAttributesOnSpan() {},
      }),
    ],
  });
  setupDomMonitoring();
};

export function setupDomMonitoring() {
  console.info("Setting up DOM monitoring");
  document.addEventListener("click", function (event) {
    let element = event.target as Element;
    // max parents to traverse up the DOM tree
    let maxParents = 5;
    // Traverse up the DOM tree to find the element with data-test-id attribute
    while (element && !element.getAttribute("data-testid") && maxParents > 0) {
      element = element.parentElement as Element;
      maxParents--;
    }

    if (element && element.getAttribute("data-testid")) {
      const testId = element.getAttribute("data-testid");
      // Perform your desired action here
      lastUserAction = `Clicked ${testId}`;
      setLastUserAction(lastUserAction);
    }
  });

  window.addEventListener("popstate", () => {
    lastUserAction = "Navigation";
    setLastUserAction(lastUserAction);
  });
}
