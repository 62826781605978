import { ResizeObserver } from "@juggle/resize-observer";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "assets/styles/index.css";
import "assets/styles/tailwind.css";
import "./app/setupExternalServices";
import "./app/setupVersionCheck";
import { overrideInsert } from "./shared/fullstory-styles-fix";

(window as any).ResizeObserver = ResizeObserver;
overrideInsert();
import(
  /* webpackChunkName: "app"  */
  /* webpackMode: "eager"*/
  "./app/main"
).then(({ main }) => main());
