import { createState } from "okdux";
import { IUser, IUserToggle } from "api/cloud-sensors/interfaces";
import {
  IGSuiteTenant,
  IO365Tenant,
  ISalesforceTenant,
  ICloudSensorTenant,
} from "api/auth/interfaces";
import { effectsFetching } from "libs/restatex";
import { o365Effects } from "./o365.effects";
import { salesforceEffects } from "./salesforce.effects";
import { gsuiteEffects } from "./gsuite.effects";

export const initialTenantState: ICloudSensorTenant = {
  id: "",
  email: "",
  name: "",
  isSandbox: false,
};

const gsuiteState = createState({
  tenant: createState<ICloudSensorTenant>(initialTenantState),
  connectedUsersMap: createState<Record<string, IUser[]>>({}),
  fetching: effectsFetching(gsuiteEffects),
  autoMonitoringEnabled: createState(false),
});

const o365State = createState({
  tenant: createState<ICloudSensorTenant>(initialTenantState),
  connectedUsers: createState<IUser[]>([]),
  fetching: effectsFetching(o365Effects),
  autoMonitoringEnabled: createState(false),
});

const salesforceState = createState({
  tenant: createState<ICloudSensorTenant>(initialTenantState),
  connectedUsers: createState<IUser[]>([]),
  fetching: effectsFetching(salesforceEffects),
  autoMonitoringEnabled: createState(false),
});

const sensorsState = createState({
  o365: o365State,
  salesforce: salesforceState,
  gsuite: gsuiteState,
});

salesforceState
  .on(salesforceEffects.import.success, importUsers)
  .on(salesforceEffects.toggle.success, toggleUser)
  .on(salesforceEffects.enableAll.success, enableAll)
  .on(salesforceEffects.fetchUsers.success, setUsers)
  .on(salesforceEffects.login.success, setSalesforceTenant)
  .on(salesforceEffects.logout.success, logout)
  .on(salesforceEffects.getUser.success, setSalesforceTenant);

o365State
  .on(o365Effects.import.success, importUsers)
  .on(o365Effects.toggle.success, toggleUser)
  .on(o365Effects.enableAll.success, enableAll)
  .on(o365Effects.fetchUsers.success, setUsers)
  .on(o365Effects.login.success, setO365Tenant)
  .on(o365Effects.logout.success, logout)
  .on(o365Effects.getUser.success, setO365Tenant);

gsuiteState
  .on(gsuiteEffects.import.success, importUsersMap)
  .on(gsuiteEffects.toggle.success, toggleUserMap)
  .on(gsuiteEffects.enableAll.success, enableAllMap)
  .on(gsuiteEffects.fetchUsers.success, setUsersMap)
  .on(gsuiteEffects.login.success, setGsuiteTenant)
  .on(gsuiteEffects.logout.success, logout)
  .on(gsuiteEffects.toggleAutoMonitoring.success, toggleAutoMonitoring)
  .on(gsuiteEffects.getUser.success, setGsuiteTenant);

function enableAll(state: any, p: Pick<IUser, "monitored">) {
  return {
    ...state,
    connectedUsers: state.connectedUsers.map((user: any) => ({
      ...user,
      monitored: p.monitored,
    })),
  };
}

function toggleAutoMonitoring(
  state: any,
  { domain, monitored }: { domain: string; monitored: boolean }
) {
  return {
    ...state,
    tenant: {
      ...state.tenant,
      domains: state.tenant.domains.map((d: any) => {
        if (d.domain !== domain) {
          return d;
        }
        return {
          ...d,
          monitor_all_users: monitored,
        };
      }),
    },
  };
}

function enableAllMap(state: any, p: Pick<IUser, "monitored"> & { domain: string }) {
  return {
    ...state,
    connectedUsersMap: {
      ...state.connectedUsersMap,
      [p.domain]: (state.connectedUsersMap[p.domain] || []).map((user: any) => ({
        ...user,
        monitored: p.monitored,
      })),
    },
  };
}

function setUsersMap(state: any, { data, domain }: any) {
  return {
    ...state,
    connectedUsersMap: {
      ...state.connectedUsersMap,
      [domain]: data.entities,
    },
  };
}

function setUsers(state: any, payload: any) {
  return {
    ...state,
    autoMonitoringEnabled: payload.force_monitor_all,
    connectedUsers: payload.entities,
  };
}

function setSalesforceTenant(state: any, payload: ISalesforceTenant) {
  const tenant: ICloudSensorTenant = {
    id: payload.organization_id,
    email: payload.user_email,
    name: payload.user_name,
    isSandbox: payload.is_sandbox,
  };
  return {
    ...state,
    tenant,
  };
}

function setO365Tenant(state: any, payload: IO365Tenant) {
  const tenant: ICloudSensorTenant = {
    id: payload.id!,
    email: payload.default_domain!,
    name: payload.display_name!,
    isSandbox: false,
  };

  return {
    ...state,
    tenant,
  };
}

function setGsuiteTenant(state: any, payload: IGSuiteTenant) {
  const tenant: ICloudSensorTenant = payload;

  return {
    ...state,
    tenant,
  };
}

function logout(state: any) {
  return {
    ...state,
    tenant: { ...initialTenantState },
  };
}

function importUsers(state: any, payload: any) {
  return payload.reduce(toggleUser, state);
}

function importUsersMap(state: any, payload: { domain: string; users: IUser[] }) {
  return payload.users.map((u) => ({ ...u, domain: payload.domain })).reduce(toggleUserMap, state);
}

function toggleUser(state: any, payload: IUserToggle) {
  return {
    ...state,
    connectedUsers: state.connectedUsers.map((user: any) => {
      if (user.id !== payload.id && user.email !== payload.email) {
        return user;
      }

      return {
        ...user,
        monitored: payload.monitored,
      };
    }),
  };
}

function toggleUsersHelper(users: IUser[], payload: IUserToggle): IUser[] {
  return users.map((user: any) => {
    if (user.id !== payload.id && user.email !== payload.email) {
      return user;
    }

    return {
      ...user,
      monitored: payload.monitored,
    };
  });
}

function toggleUserMap(state: any, payload: IUserToggle) {
  return {
    ...state,
    connectedUsersMap: {
      ...state.connectedUsersMap,
      [payload.domain!]: toggleUsersHelper(state.connectedUsersMap[payload.domain!] || [], payload),
    },
  };
}

export { sensorsState, o365State, salesforceState, gsuiteState };
