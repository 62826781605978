import React, { FunctionComponent } from "react";
import { Navigate, useLocation, useMatch } from "react-router-dom";
import { authState } from "modules/auth";
import { useSelector } from "libs/hooks";

export function redirectIfAuthenticated(
  Route: any
): FunctionComponent<React.PropsWithChildren<any>> {
  return (props) => {
    const isAuthorized = useSelector(authState.select(({ user }) => !!user.id));
    const location = useLocation();
    const state = location.state;
    if (isAuthorized) {
      return <Navigate to={state?.from || "/"} replace state={window.history.state?.usr} />;
    }
    return <Route {...props} />;
  };
}

export const RedirectWithSearch = ({
  from,
  to,
}: {
  from: string;
  to: string;
  noThrow?: boolean;
}) => {
  const { search } = useLocation();
  const match = useMatch(from + "/*");
  if (!match) {
    return null;
  }
  const rest = match?.params["*"];
  const _to = to + (rest ? `/${rest}/` : "") + search;
  return <Navigate to={_to} replace state={window.history.state?.usr} />;
};
