import { useEffect, useMemo } from "react";
import { useControlledState } from "@react-stately/utils";
import { Collection, CollectionBase, Expandable, Node, Key, MultipleSelection } from "./types";
import { useMultipleSelectionState } from "./useMultipleSelection";
import { SelectionManager, SelectionManagerOptions } from "./SelectionManager";
import { TreeCollection } from "./TreeCollection";
import { useCollection } from "./collection/useCollection";

export interface TreeProps<T> extends CollectionBase<T>, Expandable, MultipleSelection {
  isSectionSelected?: SelectionManagerOptions["isSectionSelected"];
  parentNotSelectable?: SelectionManagerOptions["parentNotSelectable"];
}
export interface TreeState<T> {
  /** A collection of items in the tree. */
  readonly collection: Collection<Node<T>>;

  /** A set of keys for items that are disabled. */
  readonly disabledKeys: Set<Key>;

  /** A set of keys for items that are expanded. */
  readonly expandedKeys: Set<Key>;

  /** Toggles the expanded state for an item by its key. */
  toggleKey(key: Key): void;

  /** A selection manager to read and update multiple selection state. */
  readonly selectionManager: SelectionManager;
}

/**
 * Provides state management for tree-like components. Handles building a collection
 * of items from props, item expanded state, and manages multiple selection state.
 */
export function useTreeState<T extends object>(props: TreeProps<T>): TreeState<T> {
  const [expandedKeys, setExpandedKeys] = (useControlledState as any)(
    props.expandedKeys ? new Set(props.expandedKeys) : undefined,
    props.defaultExpandedKeys ? new Set(props.defaultExpandedKeys) : (new Set() as Set<Key>),
    props.onExpandedChange!
  );

  const selectionState = useMultipleSelectionState(props);
  const disabledKeys = useMemo(
    () => (props.disabledKeys ? new Set(props.disabledKeys) : new Set<Key>()),
    [props.disabledKeys]
  );

  const tree = useCollection(
    props,
    (nodes: Iterable<Node<T>>) => {
      return new TreeCollection(nodes, { expandedKeys });
    },
    null,
    [expandedKeys]
  );

  // Reset focused key if that item is deleted from the collection.
  useEffect(() => {
    if (selectionState.focusedKey != null && !tree.getItem(selectionState.focusedKey)) {
      selectionState.setFocusedKey(null);
    }
  }, [tree, selectionState.focusedKey]);

  const onToggle = (key: Key) => {
    setExpandedKeys((expandedKeys: Set<Key>) => toggleKey(expandedKeys, key));
  };
  return {
    collection: tree,
    expandedKeys,
    disabledKeys,
    toggleKey: onToggle,
    selectionManager: new SelectionManager(tree, selectionState, {
      isSectionSelected: props.isSectionSelected,
      selectionMode: props.selectionMode,
      parentNotSelectable: props.parentNotSelectable ?? false,
    }),
  };
}

function toggleKey(set: Set<Key>, key: Key): Set<Key> {
  const res = new Set(set);
  if (res.has(key)) {
    res.delete(key);
  } else {
    res.add(key);
  }
  return res;
}
