import React from "react";
import { SvgIcon } from "@mui/material";
import { locations } from "modules/graph/services";
import { types } from "api";
import { types_LocationEnum } from "api/gen";
import { AppFavIcon, DomainFavIcon } from "ui";
import { locationIconMap } from "modules/graph/icons";

export interface LocationIconProps {
  item: types.ExpandableLocationItem;
  depth?: number;
  shouldShowGenericIconForNested?: boolean;
}

export function LocationIcon({
  item: { expand_path, location, location_outline },
  depth = expand_path?.length ? expand_path.length - 1 : 0,
  shouldShowGenericIconForNested = false,
}: LocationIconProps) {
  // start showing icons level before for emails and endpoint apps
  const isFavIconsLevel =
    location === types_LocationEnum.LocationMail ||
    location === types_LocationEnum.LocationMailBody ||
    location === types_LocationEnum.LocationEndpointApps
      ? depth > 0
      : depth > 1;

  if (isFavIconsLevel) {
    if (
      location === types_LocationEnum.LocationWebsite ||
      location === types_LocationEnum.LocationMail ||
      location === types_LocationEnum.LocationMailBody
    ) {
      return <DomainFavIcon size={16} url={location_outline} />;
    }

    if (location === types_LocationEnum.LocationEndpointApps) {
      return <AppFavIcon size={16} app={location_outline} color={locations.endpoint_apps.color} />;
    }
  }

  return !isFavIconsLevel || shouldShowGenericIconForNested ? (
    <SvgIcon fontSize="small">{locationIconMap[location] || locationIconMap["*"]}</SvgIcon>
  ) : null;
}
