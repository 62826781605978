import { newPromise } from "libs/async";

/* eslint-disable */
export function setupIntercom(config) {
  window.intercomSettings = config;
  const [promise, resolve, reject] = newPromise();

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + config.app_id;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
        s.addEventListener("load", (e) => {
          resolve((...args) => window.Intercom(...args));
        });
        s.addEventListener("error", () => reject("Error loading script."));
        s.addEventListener("abort", () => reject("Script loading aborted."));
      };
      l();
    }
  })();
  return promise;
}
