import React, { useCallback, useMemo } from "react";
import {
  GridFilterInputDate,
  GridFilterInputDateProps,
  GridFilterItem,
} from "@mui/x-data-grid-pro";
import { removeLocalTZOffset, shiftDateByTimezoneOffset } from "libs/timezone/timezoneUtils";

export function GridFilterDateInput(props: GridFilterInputDateProps & { timezone?: string }) {
  const { item, applyValue, timezone, ...rest } = props;

  const itemWithoutTimezone = useMemo(() => {
    const dateWithoutTimezone =
      item.value && timezone
        ? shiftDateByTimezoneOffset(item.value, timezone).toISOString()
        : item.value;

    return {
      ...item,
      value:
        props.type === "date"
          ? dateWithoutTimezone?.split("T")[0]
          : dateWithoutTimezone?.slice(0, -1),
    };
  }, [timezone, item]);

  const applyValueWithTimezone = useCallback(
    (newItem: GridFilterItem) => {
      if (newItem.value) {
        let newDate = removeLocalTZOffset(new Date(newItem.value));
        if (timezone) {
          newDate = shiftDateByTimezoneOffset(newDate, timezone, false);
        }

        newItem.value = newDate.toISOString();
      }

      applyValue(newItem);
    },
    [timezone]
  );

  return (
    <GridFilterInputDate applyValue={applyValueWithTimezone} item={itemWithoutTimezone} {...rest} />
  );
}
