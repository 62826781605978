export const messages = {
  accountSetup: "Setting up your account",
  loginError: "Incorrect username or password",
  resetAuthMethod: "If this email is registered, you will receive a one-time login link shortly",
  validation: {
    containNum: "Password must contain at least 1 number",
    lowLetter: "Password must contain at least 1 lowercase letter",
    upperLetter: "Password must contain at least 1 uppercase letter",
    specialLetter: "Password must contain at least 1 special character (#?!@$%^&*-+)",
    maxLength: "Password must be shorter than or equal to 50 characters",
    maxLengthByte: "Password is too long",
    minLength: "Password must be longer than or equal to 8 characters",
    required: "This field is required",
    nameMinLength: "Name must be longer than or equal to 3 characters",
    passEquality: "Passwords are not identical",
  },
  notifications: {
    systemClockOutOfSync: "Your system clock is inconsistent with that of the Cyberhaven server.",
    systemClockOutOfSyncDescription: "This may lead to inconsistencies in the filtering of events.",
    accountNotApproved:
      "Your account is not approved yet, please contact your administrator to obtain access",
    notInvitedAccount: "You are not invited. Please contact your administrator to obtain access",
    unrecognizedCreds: "Unrecognized user email or password",
    signUpSuccess: "Authenticated successfully.",
    invalidToken:
      "Invite token is invalid. Please contact your administrator for a new invite link",
    expiredToken:
      "Invite token expired. Please contact your administrator to obtain a new invitation link",
    twoFACodeNotValid: "Verification code is not valid",
  },
  inactivityPopup: {
    title: "Your session will expire in:",
    description:
      "Please click “Extend session” to keep working or click “Log out” to end your session now",
    stay: "Extend session",
    logout: "Log out",
  },
  formFields: {
    email: "Email",
    password: "Password",
    name: "Full Name",
    nameRule: `Username should be at least 3 characters long`,
    repeatPass: "Repeat password",
    repeatPassRule: `Passwords should have at least 8 characters and contain a mix of uppercase and lowercase letters,
    numbers, and symbols (such as #?!@$%^&*-+).`,
  },
  logIn: "Log in",
  forgotPassword: "Problems logging in?",
  resendMFALoginCode: "Resend code",
  signUp: "Sign up",
  signUpWithGoogle: "Sign up with Google",
  twoFACodeLabel: "Enter your 2FA code from Google Authenticator",
  twoFAPoneCodeLabel: "Enter your verification code sent by sms",
  enterVerificationCode: "Please enter the verification code sent to your phone.",
  authProviderPageTitle: "Providers",
  failedToUpdateProvider: "Failed to update provider",
  enabledProvider: "Enabled",
  disabledProvider: "Disabled",
  lastEnabledProvidedTooltip: "At least one provider should be enabled",
  setupMFA: "Setup SMS authentication",
};
