import React from "react";
import { VariantProps } from "@stitches/react";
import { space } from "ui/vars";
import { theme } from "ui/theme";
import { VStack } from "ui/atoms/Stack";
import { Box, CssPropType } from "ui/atoms/Box";
import { css } from "ui/css";

const CIRCLE_SIZE = 13;

export const CircleSizes = {
  DEFAULT: CIRCLE_SIZE,
  SMALL: 8,
};

export const CircleTypes = {
  NOT_FILLED: "NOT_FILLED",
  FILLED: "FILLED",
  DASHED: "DASHED",
} as const;

export type CircleType = (typeof CircleTypes)[keyof typeof CircleTypes];

const borderLeft = `1px solid ${theme.palette.primary.main}`;

const classes = {
  container: css({
    width: CIRCLE_SIZE,
    flex: "0 !important",
    marginLeft: 15,
    marginRight: 15,
    alignSelf: "stretch",
  }),

  connector: css({
    flex: 1,
    variants: {
      showBorder: {
        true: {
          borderLeft,
          marginLeft: CIRCLE_SIZE / 2 - 0.3,
        },
      },
    },
  }),
  firstConnector: css({
    marginTop: space["3"],
  }),
  border: css({
    borderLeft,
    marginLeft: CIRCLE_SIZE / 2 - 0.3,
  }),
  circle: css({
    borderRadius: "1em",
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    borderStyle: "solid",
    variants: {
      type: {
        [CircleTypes.FILLED]: {
          background: theme.palette.primary.main,
        },
        [CircleTypes.DASHED]: {
          borderStyle: "dotted",
        },
        [CircleTypes.NOT_FILLED]: {
          borderStyle: "dotted",
        },
      },
      size: {
        small: {
          width: CircleSizes.SMALL,
          height: CircleSizes.SMALL,
        },
      },
    },
  }),
};

export type CircleBulletType = VariantProps<typeof classes.circle> & {
  isFirst?: boolean;
  isLast?: boolean;
  showBorder?: boolean;
  color?: string;
  sx?: CssPropType;
  onClick?: () => void;
};

export const CircleBullet = ({
  type,
  isFirst = false,
  isLast = false,
  showBorder = true,
  size = undefined,
  color = undefined,
  sx,
  onClick,
}: CircleBulletType) => {
  return (
    <VStack onClick={onClick} className={classes.container().className} sx={sx}>
      <Box
        className={
          showBorder && isFirst
            ? classes.firstConnector().className
            : classes.connector({ showBorder }).className
        }
      ></Box>
      <Box
        style={{
          backgroundColor: type === CircleTypes.FILLED ? color : undefined,
          borderColor: color,
        }}
        className={classes.circle({ type, size }).className}
      ></Box>
      <Box className={classes.connector({ showBorder: !isLast && showBorder }).className}></Box>
    </VStack>
  );
};
