import React from "react";
import { ENV, ENV_LOGGED } from "runenv";
import { logInfo } from "shared/logger";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItem,
  Avatar,
  Typography,
  Link as MLink,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { messages as uiMessages } from "ui/messages";
import { css } from "ui/css";
import { usePopover } from "libs/hooks";
import { HStack } from "libs/layouts";

export const Terms = () => {
  return (
    <Typography>
      <MLink target="_blank" underline="hover" href="https://cyberhaven.io/legal/enterprise-terms">
        {uiMessages.copyright.enterpriseTerms}
      </MLink>
    </Typography>
  );
};

const noHover = css({
  "&:hover": { background: "white" },
});

export const ApplicationVersion = () => <div style={{ userSelect: "all" }}>{ENV.GIT_TAG}</div>;

export function useProfileMenu({ logout, user }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return usePopover(({ onOpen, ...api }) => {
    return (
      <Menu {...api}>
        <MenuItem className={noHover().className}>
          <ListItemText secondary={user.email} />
        </MenuItem>
        <MenuItem className={noHover().className}>
          <ListItemIcon>
            <BusinessIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            style={{ textTransform: "capitalize" }}
            primary={ENV_LOGGED.CUSTOMER_NAME}
          />
        </MenuItem>
        <Divider />
        <MenuItem component={Link} {...({ to: "/profile" } as any)} onClick={api.onClose}>
          <ListItemText primary={"Account settings"} />
        </MenuItem>
        <MenuItem onClick={logout}>{uiMessages.profileMenu.logout}</MenuItem>
        <MenuItem className={noHover().className}>
          <ListItemText
            primary={<Terms />}
            secondary={
              <HStack as={Typography}>
                <span>&copy; Cyberhaven</span>
                <div
                  onClick={() => {
                    logInfo(ENV);
                  }}
                >
                  <ApplicationVersion />
                </div>
              </HStack>
            }
          />
        </MenuItem>
      </Menu>
    );
  });
}

function badgeName(name: any) {
  return name
    .split(" ")
    .map((el: any) => el[0])
    .join("");
}

const avatarStyles = css({
  width: "24px",
  height: "24px",
  fontSize: "0.74rem",
  cursor: "pointer",
  marginTop: "auto",
});

export function ProfileMenuItem({ children = "", ...props }) {
  return (
    <ListItem {...props}>
      <Avatar className={avatarStyles().className} children={badgeName(children)} />
    </ListItem>
  );
}
