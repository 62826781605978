import axios from "axios";
import { print } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";

export class GraphqlError extends Error {
  constructor(
    public errors: Array<{
      message: string;
      extensions: {
        code: string;
        path: string;
      };
    }>
  ) {
    super(errors?.[0]?.message || "Graphql error");
  }
}
// reference implementation
export function createRequest(url: string) {
  return async function request<Result, Args>(
    query: TypedDocumentNode<Result, Args>,
    variables: Args
  ): Promise<Result> {
    const res = await axios.post(url, {
      query: print(query),
      variables,
    });
    if (res.data.errors) {
      throw new GraphqlError(res.data.errors);
    }
    return res.data?.data;
  };
}
