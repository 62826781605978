import React from "react";
import { FormRenderProps } from "react-final-form";
import { modalFormSelectors } from "shared/testConstants";
import { DialogProps } from "@mui/material/Dialog";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Loader } from "ui/atoms";
import { messages } from "ui/messages";
import { isThirdPartyEditMode } from "modules/analytics/Analytics";

export interface IModalFormPureProps {
  title: string;
  isOpen: boolean;
  submitText?: string;
  disableSubmit?: boolean;
  hideCancel?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  onClose(): void;
  onSubmit(a: any): void;
  children?: React.ReactNode;
}

export class ModalPure extends React.Component<IModalFormPureProps & FormRenderProps> {
  static defaultProps: Partial<IModalFormPureProps & FormRenderProps> = {
    submitText: messages.openModalButton.submitText,
    maxWidth: "md",
  };

  render() {
    const {
      title,
      isOpen,
      onClose,
      onSubmit,
      submitText,
      disableSubmit,
      hideCancel,
      children,
      maxWidth,
      form,
    } = this.props;
    const { submitting } = form.getState();
    return (
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={isOpen}
        onClose={onClose}
        disableEscapeKeyDown={submitting}
        disableEnforceFocus={isThirdPartyEditMode()}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {!hideCancel && (
            <Button onClick={onClose} disabled={submitting}>
              Cancel
            </Button>
          )}

          <Button
            type="submit"
            onClick={onSubmit}
            disabled={submitting || disableSubmit}
            data-testid={modalFormSelectors.Submit}
            color="primary"
            variant="contained"
          >
            <Loader size={20} loading={submitting}>
              {submitText}
            </Loader>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
