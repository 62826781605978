import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Link,
} from "@mui/material";
import { styled } from "ui/css";
import { LayoutLoader } from "ui";
import * as api from "api/terms";
import { messages } from "./messages";
import { useTermsStateQuery, useTermStateQueryKey } from "./state";

export const TermsDownloadLink = "https://www.cyberhaven.com/legal/enterprise-terms";

const StyledCircular = styled(CircularProgress, {
  position: "absolute",
});
const Background = styled("div", {
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  filter: "blur(6px)",
  height: "100%",
});

export const TermsAndConditions = () => {
  const { isLoading: queryLoading, isError } = useTermsStateQuery();
  const cache = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      // optimistically set value
      cache.setQueryData(useTermStateQueryKey(), () => true);
      return api.acceptTerms();
    },
    onError() {
      cache.invalidateQueries({ queryKey: useTermStateQueryKey() });
    },
  });

  if (isPending) {
    return <LayoutLoader />;
  }
  if (isError) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText>{messages.error}</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div data-testid="terms-and-conditions-page">
      <Dialog maxWidth="lg" TransitionComponent={Fade} open={true} scroll={"paper"}>
        <DialogTitle>{messages.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {messages.agreeText}{" "}
            <Link href={TermsDownloadLink} rel="noopener" target="_blank">
              {messages.termsLinkText}
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => mutate()}
            aria-label="accept-terms-button"
            variant="contained"
            color="primary"
            data-testid="accept-terms-button"
            disabled={queryLoading}
          >
            {messages.accept} {queryLoading && <StyledCircular size={20} />}
          </Button>
        </DialogActions>
      </Dialog>
      <Background />
    </div>
  );
};
