import { createState } from "okdux";

export function effectsFetching(actionsMap: any) {
  const reducerBuilder = createState({});
  Object.keys(actionsMap).forEach((el) => {
    const { success, request, failure, cancel } = actionsMap[el];
    const successType = success.getType();
    const errorType = failure.getType();
    const requestType = request.getType();

    success &&
      reducerBuilder.on(success, (state) => ({
        ...state,
        [errorType]: null,
        [requestType]: false,
        [successType]: true,
      }));
    request &&
      reducerBuilder.on(request, (state, payload) => ({
        ...state,
        [errorType]: null,
        [requestType]: payload || true,
        [successType]: false,
      }));
    failure &&
      reducerBuilder.on(failure, (state, payload) => ({
        ...state,
        [errorType]: payload,
        [requestType]: false,
        [successType]: false,
      }));
    cancel &&
      reducerBuilder.on(cancel, (state) => ({
        ...state,
        [errorType]: false,
        [requestType]: false,
        [successType]: false,
      }));
  }, {});
  return reducerBuilder;
}
