import cx from "classnames";
import { createStitches } from "@stitches/react";

const t =
  process.env.NODE_ENV === "test"
    ? ({
        root: null,
      } as any)
    : null;
const stitchesCss = createStitches({
  ...t,
  theme: {
    colors: {},
    space: {},
  },
  utils: {
    paddingX: (v: string) => ({
      paddingLeft: v,
      paddingRight: v,
    }),
    paddingY: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    marginX: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    centerContent: (should: boolean) =>
      should
        ? {
            display: "flex",
            placeContent: "center",
            placeItems: "center",
          }
        : {},
    truncate: (type: "clip" | "ellipsis") => ({
      overflow: "hidden",
      textOverflow: type,
      whiteSpace: "nowrap",
    }),
    noOfLines: (n: number) => ({
      "display": "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": n,
      "overflow": "hidden",
    }),
  },
});

const { styled, css, theme, config, getCssText, keyframes } = stitchesCss;

export { cx, styled, css, theme, config, getCssText, keyframes };
