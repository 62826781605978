import axios from "axios";
import { IO365Tenant } from "./interfaces";
import { ExternalAuth } from "./ExternalAuth";

class O365Auth extends ExternalAuth<IO365Tenant> {
  private tenant?: IO365Tenant;

  isAuthorized() {
    return !!this.tenant;
  }

  getUser = () => {
    if (this.isAuthorized()) {
      return this.tenant!;
    }

    return axios
      .get<IO365Tenant>("/o365/login/info", {
        meta: {
          ignoreUnauthorized: true,
        },
      } as any)
      .then((response) => {
        this.tenant = response.data;
        return this.tenant!;
      });
  };

  login() {
    return this.authorize("/o365/login-consent", "O365 Login Consent").then(this.getUser);
  }

  logout() {
    return axios.get<IO365Tenant>("/o365/logout").then((response) => {
      this.tenant = undefined;
      return response;
    });
  }
}

export const o365Auth = new O365Auth();
