import { useCallback, useMemo } from "react";
import { useCanArray } from "./permissions";
import { useAppEnvironment } from "./environment";
import { INavigationItem } from "./types";

export function useAvailableNavigationItems(): {
  availableItems: INavigationItem[] | null;
  availablePaths: string[] | undefined;
  homeItem: INavigationItem | null;
  isLoading: boolean;
} {
  const { navigationItems } = useAppEnvironment();

  const visibleNavigationItems = useMemo(
    () => navigationItems.filter((item) => !item.hidden),
    [navigationItems]
  );

  const availableItems = useCanArray({
    items: visibleNavigationItems,
    getItemPermission: useCallback(
      (item: INavigationItem) => item.requiredPermissions || item.requiredPermissionsAnyOf,
      []
    ),
    isDoEither: useCallback((item: any) => !!item.requiredPermissionsAnyOf, []),
  });

  const availablePaths = useMemo(() => availableItems?.map((item) => item.path), [availableItems]);

  const homeItem = useMemo(
    () => availableItems?.filter((link) => !link.isSpacer)?.[0] || null,
    [availableItems]
  );

  return {
    availableItems,
    availablePaths,
    isLoading: !!availableItems,
    homeItem,
  };
}
