import { ReactElement, useLayoutEffect } from "react";
import { useSelector } from "libs/hooks";

export const Preload = ({
  once = null,
  load = null,
  isLoaded,
  loader = null,
  children,
}: {
  load: null | Function;
  children: ReactElement | null;
  loader: ReactElement | null;
  isLoaded: (v: any, props: any) => boolean;
  once?: boolean | null;
}) => {
  const isLoadedRes = useSelector(isLoaded);
  useLayoutEffect(() => {
    if (!once || !isLoadedRes) {
      load?.();
    }
  }, []);
  return (isLoadedRes ? children : loader) || null;
};
