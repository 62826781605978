import { useRef, useState } from "react";
import { useKeyDown } from "libs/hooks";
import { useOnChange2 } from "libs/hooks/useOnChange2";

interface InlineEditFieldProps<V> {
  value: V;
  onChange: (v: V) => Promise<any> | any;
  isInitialEditing?: boolean;
  renderEditView: (props: {
    error: string;
    value: V;
    onChange: (v: V) => any;
    onBlur: () => Promise<any>;
  }) => JSX.Element;
  renderView: (props: { value: V; toggleEdit: () => any }) => JSX.Element;
}

export function InlineEditField<V>({
  value,
  onChange,
  isInitialEditing = false,
  renderEditView,
  renderView,
}: InlineEditFieldProps<V>) {
  const [isEditing, setIsEditing] = useState(isInitialEditing);
  const [innerValue, setInnerValue] = useState(value);
  const isSubmittingRef = useRef(false);
  const [error, setError] = useState(null);

  const handleChange = async () => {
    isSubmittingRef.current = true;
    setError(null);
    try {
      await onChange(innerValue);
    } catch (e: any) {
      isSubmittingRef.current = false;
      const error = e?.response?.data?.error || "Something went wrong";
      return setError(error);
    }
    isSubmittingRef.current = false;

    setIsEditing(false);
    setError(null);
  };

  useKeyDown(
    "enter",
    async () => {
      if (isEditing && !isSubmittingRef.current) {
        await handleChange();
      }
    },
    isEditing,
    innerValue
  );
  useOnChange2(value, () => setInnerValue(value));
  const edit = renderEditView({
    error: error ? String(error) : "",
    value: innerValue,
    onChange: setInnerValue,
    onBlur: handleChange,
  });
  const view = renderView({ value: innerValue, toggleEdit: () => setIsEditing((v) => !v) });

  return isEditing ? edit : view;
}
