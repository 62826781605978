import React from "react";
import { Box } from "ui/atoms/Box";
import { css } from "ui/css";

export type SensitivityBarsProps = {
  children?: React.ReactNode;
  className?: string;
  baseColor?: string;
  fillColor?: string;
  maxBars?: number;
  barHeight?: number;
  barWidth?: number;
  spaceBetween?: number;

  barsCount: number;
};

const sensitivityBarsStyles = css({
  display: "inline-flex",
});

export const SensitivityBars = ({
  className,
  maxBars = 4,
  barsCount = 0,
  baseColor = "#EAEAEA",
  fillColor = "#E03434",
  barWidth = 3,
  barHeight = 11,
  spaceBetween = 2,
}: SensitivityBarsProps) => {
  const bars = new Array(maxBars).fill(0);
  return (
    <Box data-testid="sensitivity-bars" className={sensitivityBarsStyles({ className }).className}>
      {bars.map((_, i) => {
        const active = i < barsCount;
        return (
          <div
            key={i}
            style={{
              background: active ? fillColor : baseColor,
              width: barWidth,
              marginRight: spaceBetween,
              height: barHeight,
            }}
          />
        );
      })}
    </Box>
  );
};
