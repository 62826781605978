import { TrieNode } from "./TrieNode";

export const ANY_KEY_PLACEHOLDER = "*";

export function maskValue(value: any, piiPlaceholder: string): any {
  if (value === null || value === undefined || value === "") {
    return value;
  }

  if (Array.isArray(value)) {
    return [piiPlaceholder];
  }

  return piiPlaceholder;
}

/**
 * Masks data based on the provided trie.
 */
export function maskData<T>(data: T, trie: TrieNode, piiPlaceholder: string): T {
  function maskDataRecursive(obj: any, node: TrieNode): any {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      const branchNode = node.getBranch(ANY_KEY_PLACEHOLDER);

      if (branchNode?.isLeaf) {
        return obj.map(() => piiPlaceholder);
      }

      if (branchNode) {
        return obj.map((item) => maskDataRecursive(item, branchNode));
      }

      return obj;
    }

    const maskedData: any = {};
    for (const [key, value] of Object.entries(obj)) {
      const branchNode = node.hasBranch(ANY_KEY_PLACEHOLDER)
        ? node.getBranch(ANY_KEY_PLACEHOLDER)
        : node.getBranch(key);

      if (branchNode?.isLeaf) {
        maskedData[key] = branchNode.shouldMask(obj) ? maskValue(value, piiPlaceholder) : value;
      } else if (branchNode) {
        maskedData[key] = maskDataRecursive(value, branchNode);
      } else {
        maskedData[key] = value;
      }
    }

    return maskedData;
  }

  return maskDataRecursive(data, trie);
}
