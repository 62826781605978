import React, { useEffect } from "react";
import { CHIcon4 } from "assets/icons";
import { useLocation } from "react-router";
import { Dialog, Button } from "@mui/material";
import { useModal, useStorageWithExpiration } from "libs/hooks";
import { Box, Text, VStack, space } from "ui";
import { subscribeOnVersionChange } from "./setupVersionCheck";
import { ROUTES_PATHS } from "./routesPaths";

const IGNORE_TIMEOUT = 1000 * 60 * 2;
export function NewVersionHandler() {
  // If user dismissed update, then do not bother with popup for IGNORE_TIMEOUT
  const [getIsIgnore, setIsIgnore] = useStorageWithExpiration(
    "ignore-version-updates",
    false,
    IGNORE_TIMEOUT
  );

  const [modal, modalApi] = useModal(({ extraProps: _, ...args }) => {
    const onDismiss = () => {
      setIsIgnore(true);
      args.onClose();
    };

    const onRefresh = () => {
      setIsIgnore(true);
      window.location.reload();
    };

    return (
      <Dialog
        {...args}
        PaperProps={{ style: { maxWidth: "300px" } }}
        fullWidth
        disableEscapeKeyDown
      >
        <VStack align="center" style={{ padding: `${space[8]} ${space[3]} ${space[7]}` }}>
          <Box style={{ marginBottom: space[5] }}>
            <CHIcon4 style={{ width: "1em", height: "1em", fontSize: "50px" }} />
          </Box>
          <Text variant="header6MC" style={{ textTransform: "none", marginBottom: space[2] }}>
            New version is available!
          </Text>
          <Text
            variant="body2"
            color="#737373"
            style={{ marginBottom: space[5], textAlign: "center" }}
          >
            We kindly request that you click on the Refresh button to access the latest version of
            our product and enjoy an enhanced user experience
          </Text>
          <VStack align="center" space="1" style={{ width: "200px" }}>
            <Button variant="contained" fullWidth color="primary" onClick={onRefresh}>
              Refresh
            </Button>
            <Button color="secondary" fullWidth onClick={onDismiss}>
              Dismiss
            </Button>
          </VStack>
        </VStack>
      </Dialog>
    );
  }, true);

  useEffect(
    () =>
      subscribeOnVersionChange(() => {
        if (!getIsIgnore()) {
          modalApi.handleOpen();
        }
      }),
    []
  );

  const location = useLocation();
  useEffect(() => {
    // If user was logged out due to inactivity and did not accept update, then we can safely refresh page
    if (modalApi.open && location.pathname.includes(ROUTES_PATHS.LOGIN)) {
      window.location.reload();
    }
  }, [modalApi.open, location.pathname]);

  return <>{modal}</>;
}
