export const safeJSONParse = (s?: string) => {
  if (!s) {
    return undefined;
  }

  try {
    return JSON.parse(s);
  } catch (e) {
    return undefined;
  }
};
