import React from "react";
import { VariantProps } from "@stitches/react";
import { css } from "../css";

export const DisabledStyles = css({
  $$grayscale: "none",
  variants: {
    isDisabled: {
      true: {
        "cursor": "no-drop",
        "pointerEvents": "none",
        "opacity": 0.85,
        "&>*": {
          pointerEvents: "none",
          filter: "var(--disabled-filter)",
          visibility: "var(--disabled-visibility, visible)" as any,
        },
      },
      false: {},
    },
    variant: {
      hidden: {
        "--disabled-visibility": "hidden",
      },
      grayscale: {
        "--disabled-filter": "grayscale(100%)",
      },
      blured: {
        "--disabled-filter": "grayscale(100%) blur(2px)",
      },
    },
  },
  defaultVariants: {
    variant: "grayscale",
  },
});

export function Disabled({
  isDisabled,
  variant,
  children,
}: VariantProps<typeof DisabledStyles> & {
  children: React.ReactNode;
}) {
  return (
    <div
      className={DisabledStyles({
        isDisabled,
        variant,
      })}
    >
      {children}
    </div>
  );
}
