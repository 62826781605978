import {
  Collection,
  MultipleSelectionState,
  SelectionMode,
  Selection,
  ISelection,
  Key,
} from "./types";

type SelectionNode = {
  childNodes: Iterable<SelectionNode>;
  hasChildNodes: boolean;
  key: Key;
  parentKey?: Key;
  type: string;
};

const areSetsEqual = (x: Set<any>, y: Set<any>) =>
  x.size === y.size && [...x].every((x) => y.has(x));

const setWhenSelectionIsAll = new Set(["a", "l"]);
const defaultSectionSelection =
  (collection: Collection<SelectionNode>) => (key: Key, pendingSelection: ISelection) => {
    const item = collection.getItem(key);
    const s = pendingSelection;

    if (areSetsEqual(s, setWhenSelectionIsAll)) {
      return true;
    }
    return [...item.childNodes].every((v) => {
      return s.has(v.key);
    });
  };

export interface SelectionManagerOptions {
  selectionMode?: SelectionMode;
  parentNotSelectable?: boolean;
  // determine if section is fully selected
  isSectionSelected?: (k: Key, pendingSelection: ISelection) => boolean;
}

export const selectionType = {
  item: "item",
  section: "section",
};

/**
 * An interface for reading and updating multiple selection state.
 */
export class SelectionManager {
  private _isSelectAll: boolean;
  private isSectionSelected: SelectionManagerOptions["isSectionSelected"];
  private parentNotSelectable: boolean;
  selectionMode: SelectionMode;
  constructor(
    private collection: Collection<SelectionNode>,
    private state: MultipleSelectionState,
    options: SelectionManagerOptions = {}
  ) {
    this._isSelectAll = false;
    this.parentNotSelectable = options.parentNotSelectable ?? false;
    this.selectionMode = options?.selectionMode ?? "multiple";
    this.isSectionSelected = options.isSectionSelected ?? defaultSectionSelection(collection);
  }

  /**
   * The currently selected keys in the collection.
   */
  get selectedKeys(): Set<Key> {
    return this.areAllSelected() ? new Set(this.getSelectAllKeys()) : this.state.selectedKeys;
  }

  get rawSelection(): ISelection {
    return this.state.selectedKeys;
  }

  private areAllSelected(): boolean {
    return areSetsEqual(
      this.state.selectedKeys,
      new Set([...setWhenSelectionIsAll, ...this.collection.getKeys()])
    );
  }

  hasSelectedChild(key: Key) {
    if (this.state.selectionMode === "none") {
      return false;
    }

    key = this.getKey(key)!;
    if (key === null) {
      return false;
    }

    if (this.areAllSelected()) {
      return !this.state.disabledKeys.has(key);
    }
    return [...this.selectedKeys.values()].find((el) => el.toString().startsWith(key as string));
  }

  isSelected(key: Key) {
    if (this.state.selectionMode === "none") {
      return false;
    }

    key = this.getKey(key)!;
    if (key === null) {
      return false;
    }

    if (this.areAllSelected()) {
      return !this.state.disabledKeys.has(key);
    }
    // Find a parent selectable section
    let item = this.collection.getItem(key);

    while (
      item &&
      item.type !== selectionType.section &&
      item.parentKey &&
      !this.state.selectedKeys.has(item.key)
    ) {
      item = this.collection.getItem(item.parentKey);
    }
    return this.state.selectedKeys.has(item.key);
  }

  /**
   * Whether the selection is empty.
   */
  get isEmpty(): boolean {
    return !this.areAllSelected() && this.state.selectedKeys.size === 0;
  }

  /**
   * Whether all items in the collection are selected.
   */
  get isSelectAll(): boolean {
    if (this.isEmpty) {
      return false;
    }

    if (this.areAllSelected()) {
      return true;
    }

    if (this._isSelectAll != null) {
      return this._isSelectAll;
    }

    const allKeys = this.getSelectAllKeys();
    this._isSelectAll = allKeys.every((k) => this.isSelected(k));
    return this._isSelectAll;
  }

  private getKey(key: Key) {
    const item = this.collection.getItem(key);
    if (!item) {
      // ¯\_(ツ)_/¯
      return key;
    }

    if (!item || item.type !== selectionType.item) {
      return null;
    }

    return item.key;
  }

  /**
   * Toggles whether the given key is selected.
   */
  toggleSelection(key: Key) {
    key = this.getKey(key)!;
    if (key == null) {
      return;
    }

    let keys = new Selection(
      this.areAllSelected() ? this.getSelectAllKeys() : this.state.selectedKeys
    );
    const pKeys = [...this.getAllParentKeys(key)];
    const hasSelectedParent = pKeys.find((el) => keys.has(el));
    // if we have selected parent and we click on child we have to bubble up and reset parent selection
    // but at the same time add subchildren to selection
    if (hasSelectedParent) {
      for (const p of pKeys.reverse()) {
        keys.delete(p);
        for (const k of this.getAllChildKeys(p)) {
          keys.add(k);
        }
      }
    }
    const item = this.collection.getItem(key);
    // in our case if parent is selected = all subitems are selected
    let isSelected = keys.has(key) || !!hasSelectedParent;
    if (this.selectionMode === "single") {
      keys = new Set();
      if (!keys.has(key) && hasSelectedParent) {
        isSelected = false;
      }
    }

    if (isSelected) {
      // if the item is selected then we remove its key from set and remove keys for all children
      if (item.hasChildNodes) {
        for (const k of this.getAllChildKeys(item.key)) {
          keys.delete(k);
        }
      }
      keys.delete(key);
    } else {
      // if item has children we add this node as well as all children
      if (this.parentNotSelectable) {
        if (!item.hasChildNodes) {
          keys.add(key);
        } else {
          // has children
          const childrenKeysList = this.getAllChildKeys(key);
          const isAllChildrenSelected = [...childrenKeysList].every((key) => keys.has(key));
          for (const childKey of childrenKeysList) {
            if (isAllChildrenSelected) {
              keys.delete(childKey);
            } else {
              keys.add(childKey);
            }
          }
        }
      } else {
        keys.add(key);

        // detect if we selected all child items and decide if we mark parent as selected or not
        // TODO instead of function use enum with selection strategies
        for (const p of pKeys.reverse()) {
          if (p && this.isSectionSelected?.(p, keys)) {
            keys.add(p);
            for (const k of this.getAllChildKeys(p)) {
              keys.delete(k);
            }
          } else {
            break;
          }
        }
      }
    }
    this.state.setSelectedKeys(keys);
  }

  private getAllChildKeys(key: Key): Iterable<Key> {
    const result: Key[] = [];
    const addKeys = (key: Key) => {
      const item = this.collection.getItem(key);
      if (item?.hasChildNodes) {
        for (const el of item.childNodes) {
          result.push(el.key);
          if (el.hasChildNodes) {
            addKeys(el.key);
          }
        }
      }
    };
    addKeys(key);
    return result;
  }

  /**
   * Replaces the selection with only the given key.
   */
  replaceSelection(key: Key) {
    key = this.getKey(key)!;
    if (key == null) {
      return;
    }

    this.state.setSelectedKeys(new Selection([key]));
  }

  /**
   * Replaces the selection with the given keys.
   */
  setSelectedKeys(keys: Iterable<Key>) {
    if (this.selectionMode === "none") {
      return;
    }

    const selection = new Selection();
    for (let key of keys) {
      key = this.getKey(key)!;
      if (key != null) {
        selection.add(key);
      }
    }

    this.state.setSelectedKeys(selection);
  }

  private getAllParentKeys(key: Key): Iterable<Key> {
    let item = this.collection.getItem(key);
    const result: Key[] = [];
    while (item && item.type !== selectionType.section && item.parentKey) {
      item = this.collection.getItem(item.parentKey);
      result.push(item.key);
    }
    return result;
  }

  private getSelectAllKeys() {
    const keys: Key[] = [];

    for (const key of this.collection.getKeys()) {
      if (!this.state.disabledKeys.has(key)) {
        const item = this.collection.getItem(key);
        if (item.type === selectionType.item) {
          keys.push(key);
        }
      }
    }

    return keys.filter((key: Key) => !["all", "a", "l"].includes(key as string));
  }

  /**
   * Selects all items in the collection.
   */
  selectAll() {
    if (this.selectionMode === "multiple") {
      this.state.setSelectedKeys(new Set([...setWhenSelectionIsAll, ...this.collection.getKeys()]));
    }
  }

  /**
   * Removes all keys from the selection.
   */
  clearSelection() {
    if (this.areAllSelected() || this.state.selectedKeys.size > 0) {
      this.state.setSelectedKeys(new Selection());
    }
  }

  toggleSelectAll() {
    if (this.isSelectAll) {
      this.clearSelection();
    } else {
      this.selectAll();
    }
  }

  setSelection(key: Key, value: boolean) {
    if (this.selectionMode === "none") {
      return;
    }
    const tempSet = new Set([...this.state.selectedKeys]);
    if (value) {
      return this.state.setSelectedKeys(tempSet.add(key));
    }
    tempSet.delete(key);
    return this.state.setSelectedKeys(tempSet);
  }

  select(key: Key) {
    if (this.selectionMode === "none") {
      return;
    }

    this.toggleSelection(key);
  }
}
