import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ENV } from "runenv";
import { resolveService } from "service";

// In case we get to AccessDenied or NotFound page we log current state.
export function usePageErrorLog() {
  const currentUser = useSelector((store: any) => store.auth?.user);
  const location = useLocation();

  useEffect(() => {
    const logInfo = async () => {
      console.group("Page error info");
      console.info("Current user:", currentUser);
      console.info("Current features:", ENV.FEATURES);
      console.info("Location:", location);

      const permissionsService = await resolveService("permissions");
      console.info("Permissions user id:", permissionsService.userId);
      const permissions = await permissionsService.preloadPermissions();
      console.info("Permissions:", permissions);
      console.groupEnd();
    };
    logInfo();
  }, []);
}
