import { types_SearchFieldEnum, irm } from "api/gen";
import { types } from "api";
import { Severity } from "modules/risks/dashboard-core/severity";
import { IncidentAction } from "../dashboard/utils";
import { RuleSelect } from "../search/types";

export enum UserColumnField {
  // Not a part of data model
  RiskScore = "risk_score",
  NormalizedRiskScore = "normalized_score",
  LastViolationTime = "last_violation_time",
  // Put it under FF and assume string[]
  RiskGroups = "risk_groups",
  EndpointInfo = "endpoint_info",
  // Either primary email from directory or email reported by endpoint
  Alias = "alias",

  // Either Full Name from directory or local user name
  DisplayName = "display_name",

  LocalUserName = "name",
  MachineName = "machine_name",
  Emails = "user_info.emails",
  PrimaryEmail = "user_info.primary_email",
  FirstName = "user_info.first_name",
  LastName = "user_info.last_name",
  FullName = "user_info.full_name",
  EmployeeStatus = "user_info.employee_status",
  StartDate = "user_info.start_date",
  TerminationDate = "user_info.termination_date",
  UserGroups = "user_info.user_groups",
  Title = "user_info.title",
  Department = "user_info.department",
  PrimaryAddressRegion = "user_info.primary_address_region",
  PrimaryAddressCountry = "user_info.primary_address_country",
  PrimaryAddressCity = "user_info.primary_address_city",
  PrimaryAddressZipCode = "user_info.primary_address_zip_code",
  PrimaryAddressStreetAddress = "user_info.primary_address_street_address",
  PhoneNumber = "user_info.phone_number",
  ManagerName = "user_info.manager_name",
  ManagerEmail = "user_info.manager_email",
  Remote = "user_info.remote",
  Username = "user_info.username",
  State = "user_info.state",
}

export type SearchFieldValue = (typeof types_SearchFieldEnum)[keyof typeof types_SearchFieldEnum];

export interface FlowData {
  id: string;
  risk_score?: number;
  from: {
    id?: string;
    name: string;
    count: number;
    sensitivity?: number | null;
  };
  to: {
    id?: string;
    name: string;
    incidentAction: IncidentAction;
    severity?: number | null;
  };
}

export interface UserSeveritiesCounts {
  total: number;
  bySeverity: {
    [Severity.Critical]: number;
    [Severity.High]: number;
    [Severity.Medium]: number;
    [Severity.Low]: number;
    [Severity.Informational]: number;
  };
}

export interface UserRiskPerDate {
  risk: number;
  date: string;
}

export interface UserRiskProgression {
  risks: UserRiskPerDate[];
  type: types.Period;
}

export interface UserRiskGroup {
  id?: string;
  name: string;
  type: irm.UserRiskGroupType;
  filter: string;
  rulesSelect: RuleSelect[];
  color: string;
  description: string;
  risk_multiplier: number;
  quickFilter: boolean;
  createdAt?: string;
}

export type TimeRange = { start_time: string; end_time: string; label: string };
