import React from "react";
import { Field, useField } from "react-final-form";
import { Disabled } from "ui";

export const Condition = ({ when, is, children }: any) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        const show = typeof is === "function" ? is(value) : value === is;
        return show ? children : null;
      }}
    </Field>
  );
};

export function DisableCondition({ when, is, children, ...rest }: any) {
  const { input } = useField(when, { subscription: { value: true } });
  const isDisabled = typeof is === "function" ? is(input.value) : input.value === is;
  return (
    <Disabled variant="grayscale" isDisabled={isDisabled ? true : false} {...rest}>
      {typeof children === "function" ? children(isDisabled) : children}
    </Disabled>
  );
}

export const FormSpyField = ({
  name,
  children,
}: {
  name: string;
  children: (value: any) => React.ReactNode;
}) => {
  return (
    <Field name={name} subscription={{ value: true }}>
      {({ input: { value } }) => {
        return children(value);
      }}
    </Field>
  );
};
