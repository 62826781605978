import React from "react";

export const ContentAttributeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 164 164"
    width="64px"
    height="64px"
    {...props}
  >
    <g>
      <path d="M31,2C15.009,2,2,15.009,2,31s13.009,29,29,29s29-13.009,29-29S46.991,2,31,2z M31,58C16.112,58,4,45.888,4,31   S16.112,4,31,4s27,12.112,27,27S45.888,58,31,58z" />
      <path d="M31,50c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1s1-0.448,1-1v-2C32,50.448,31.552,50,31,50z" />
      <path d="M37.013,50.077c-0.143-0.534-0.688-0.852-1.225-0.707c-0.533,0.143-0.85,0.691-0.707,1.225l0.518,1.932   c0.12,0.447,0.523,0.742,0.965,0.742c0.085,0,0.172-0.011,0.259-0.035c0.533-0.143,0.85-0.691,0.707-1.225L37.013,50.077z" />
      <path d="M21.75,47.521c-0.479-0.277-1.09-0.112-1.366,0.366l-1,1.732c-0.276,0.478-0.112,1.09,0.366,1.366   c0.157,0.091,0.33,0.134,0.499,0.134c0.346,0,0.682-0.179,0.867-0.5l1-1.732C22.392,48.41,22.229,47.798,21.75,47.521z" />
      <path d="M41.616,47.888c-0.277-0.478-0.889-0.643-1.366-0.366c-0.479,0.276-0.642,0.888-0.366,1.366l1,1.732   c0.186,0.321,0.521,0.5,0.867,0.5c0.169,0,0.342-0.043,0.499-0.134c0.479-0.276,0.642-0.888,0.366-1.366L41.616,47.888z" />
      <path d="M26.212,49.37c-0.537-0.145-1.082,0.173-1.225,0.707l-0.518,1.932c-0.143,0.534,0.174,1.082,0.707,1.225   c0.087,0.023,0.174,0.035,0.259,0.035c0.442,0,0.846-0.295,0.965-0.742l0.518-1.932C27.062,50.061,26.745,49.512,26.212,49.37z" />
      <path d="M38.351,11.322c0.712,0.267,1.419,0.576,2.099,0.919c0.145,0.073,0.298,0.107,0.45,0.107c0.366,0,0.718-0.201,0.894-0.549   c0.249-0.493,0.051-1.095-0.442-1.343c-0.746-0.376-1.52-0.715-2.3-1.007c-0.515-0.193-1.093,0.069-1.287,0.586   S37.833,11.128,38.351,11.322z" />
      <path d="M47.263,14.737c-0.661-0.661-1.365-1.286-2.093-1.856c-0.435-0.341-1.064-0.265-1.404,0.17   c-0.341,0.435-0.265,1.063,0.17,1.404c0.665,0.521,1.309,1.092,1.913,1.696C49.815,20.117,52,25.391,52,31   c0,4.215-1.234,8.24-3.531,11.663c-2.401-4.407-6.262-7.541-10.72-8.836C39.147,31.786,40,29.287,40,27v-2c0-4.962-4.038-9-9-9   s-9,4.038-9,9v2c0,2.287,0.853,4.786,2.25,6.827c-4.457,1.295-8.318,4.429-10.72,8.836C11.234,39.239,10,35.215,10,31   c0-5.609,2.185-10.883,6.151-14.849c4.748-4.749,11.544-6.949,18.187-5.886c0.553,0.089,1.059-0.284,1.146-0.829   c0.087-0.545-0.284-1.059-0.829-1.146c-7.275-1.165-14.717,1.246-19.917,6.446C10.393,19.081,8,24.856,8,31   s2.393,11.919,6.737,16.263c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414   c-0.458-0.458-0.886-0.937-1.295-1.428c0.012-0.021,0.033-0.035,0.044-0.057c2.243-4.613,6.208-7.795,10.767-8.829   C27.158,37.02,28.998,38,31,38c2.002,0,3.842-0.98,5.334-2.464c4.559,1.033,8.524,4.216,10.767,8.83   c0.011,0.022,0.028,0.039,0.04,0.06c-0.409,0.49-0.835,0.968-1.292,1.424c-0.391,0.391-0.391,1.023,0,1.414   c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293C51.607,42.919,54,37.144,54,31S51.607,19.081,47.263,14.737z M31,36   c-3.704,0-7-5.033-7-9v-2c0-3.86,3.14-7,7-7s7,3.14,7,7v2C38,30.967,34.704,36,31,36z" />
    </g>
  </svg>
);
