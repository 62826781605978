import { types } from "api";

export const EUserRole = {
  INACTIVE: "inactive",
} as const;
export type IUser = Partial<types.FullUserDTO>;

export interface IUserCredentials {
  email: string;
  password: string;
}
