import React, { CSSProperties, forwardRef } from "react";
import { ForwardRefComponent } from "@radix-ui/react-polymorphic";
import { Slot } from "@radix-ui/react-slot";
import { css } from "ui/css";
// import { SxProps, Theme } from "@mui/system";

export const BoxInternal = css({});
// Use own css prop definition in order to simplify css types
export type CssPropType = CSSProperties & {
  marginX?: string;
  marginY?: string;
  paddingX?: string;
  paddingY?: string;
  centerContent?: boolean;
  noOfLines?: number;
} & {
  [k: string]: string | boolean | CSSProperties | number;
};
export type BoxComponent<ExtraProps> = ForwardRefComponent<
  "div",
  ExtraProps & {
    sx?: any;
    // do not render extra div element and apply all classnames to direct children
    asChild?: boolean;
    disabled?: boolean;
  }
>;

export const Box: BoxComponent<{}> = forwardRef(function Box(
  { as: Comp = "div", className, sx, asChild, ...props },
  ref
) {
  const resClassName = BoxInternal({
    className,
    css: sx as any,
  }).toString();
  if (asChild) {
    Comp = Slot as any;
  }
  return <Comp ref={ref} className={resClassName} {...props} />;
});
