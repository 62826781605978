import React from "react";
import { Merge } from "ts-essentials";
import { TablePagination } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { TablePaginationProps } from "@mui/material/TablePagination";
import { css } from "ui/css";
import { HStack } from "libs/layouts";

const spacerStyles = css({
  display: "none",
});

const toolbarStyles = css({
  justifyContent: "flex-end",
});

const selectLabelStyles = css({
  color: "#757575",
});

const displayedRowsStyles = css({
  color: "#757575",
});

const selectStyles = css({
  "&.MuiSelect-select": {
    fontSize: "0.9375rem",
  },
});

const selectIconStyles = css({
  "&.MuiTablePagination-selectIcon": {
    top: "calc(50% - 12px)",
  },
});

const classes = {
  spacer: spacerStyles().className,
  select: selectStyles().className,
  toolbar: toolbarStyles().className,
  selectLabel: selectLabelStyles().className,
  selectIcon: selectIconStyles().className,
  displayedRows: displayedRowsStyles().className,
};

export function Pagination({
  onChangePage,
  isNextDisabled,
  isPrevDisabled,
  isLoading,
  count = 0,
  page = 0,
  perPage: rowsPerPage = 0,
  isLastPage,
  onChangePerPage,
  lazyCount,
  ...props
}: Merge<
  Partial<TablePaginationProps>,
  {
    // apply lazy count logic, if count is < than per page then show exact count otherwise show some many items label
    lazyCount?: boolean;
    isLastPage?: any;
    isPrevDisabled?: any;
    isNextDisabled?: any;
    onChangePage?: (n: number) => void;
    isLoading?: boolean;
    perPage?: number;
    onChangePerPage?: (v: number) => void;
  }
>) {
  if (isLoading) {
    return (
      <HStack space={2}>
        <Skeleton width="150px" height="55px" />
        <Skeleton width="200px" height="55px" />
      </HStack>
    );
  }
  return (
    <TablePagination
      component={"div" as any}
      classes={classes}
      rowsPerPageOptions={[10, 25, 50, 100]}
      nextIconButtonProps={{
        disabled: isNextDisabled || isLastPage,
      }}
      backIconButtonProps={{
        disabled: isPrevDisabled || page === 0,
      }}
      labelDisplayedRows={({ count }) => {
        const totalCount = Math.ceil(count / rowsPerPage);

        if (lazyCount && count > rowsPerPage) {
          count = Infinity;
        }
        return `${page + 1} of ${count === Infinity ? "Many" : totalCount}`;
      }}
      onPageChange={(_, n) => {
        if (onChangePage) {
          onChangePage(n);
        }
      }}
      count={count}
      page={page}
      onRowsPerPageChange={(e) => {
        onChangePerPage?.(parseInt(e.target.value, 10));
      }}
      rowsPerPage={rowsPerPage}
      {...props}
    />
  );
}
