import React, { useLayoutEffect } from "react";
import { create, formatters } from "jsondiffpatch";
import { identity, isEqual } from "lodash";
import { css } from "ui/css";

const getStyles = (hideUnchanged: boolean) =>
  css({
    ".jsondiffpatch-delta": {
      fontFamily: '"Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Courier, monospace',
      fontSize: "12px",
      margin: "0",
      display: "inline-block",
      marginLeft: "-5px",
    },
    ".jsondiffpatch-delta pre": {
      fontFamily: '"Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Courier, monospace',
      fontSize: "12px",
      margin: "0",
      padding: "0",
      display: "inline-block",
    },
    "ul.jsondiffpatch-delta": {
      listStyleType: "none",
      padding: "0 0 0 20px",
      margin: "0",
    },
    ".jsondiffpatch-delta ul": {
      listStyleType: "none",
      padding: "0 0 0 20px",
      margin: "0",
    },
    ".jsondiffpatch-added .jsondiffpatch-property-name, .jsondiffpatch-added .jsondiffpatch-value pre, .jsondiffpatch-modified .jsondiffpatch-right-value pre, .jsondiffpatch-textdiff-added":
      {
        background: "#bbffbb",
      },
    ".jsondiffpatch-deleted .jsondiffpatch-property-name, .jsondiffpatch-deleted pre, .jsondiffpatch-modified .jsondiffpatch-left-value pre, .jsondiffpatch-textdiff-deleted":
      {
        background: "#ffbbbb",
        textDecoration: "line-through",
      },
    ".jsondiffpatch-unchanged, .jsondiffpatch-movedestination": {
      color: "gray",
    },
    ".jsondiffpatch-unchanged, .jsondiffpatch-movedestination > .jsondiffpatch-value": {
      transition: "all 0.5s",
      WebkitTransition: "all 0.5s",
      overflowY: "hidden",
    },
    ".jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged, .jsondiffpatch-unchanged-showing .jsondiffpatch-movedestination > .jsondiffpatch-value":
      {
        maxHeight: "100px",
      },
    ".jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged, .jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value":
      {
        maxHeight: "0",
      },
    ".jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value, .jsondiffpatch-unchanged-hidden .jsondiffpatch-movedestination > .jsondiffpatch-value":
      {
        display: "block",
      },
    ".jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged, .jsondiffpatch-unchanged-visible .jsondiffpatch-movedestination > .jsondiffpatch-value":
      {
        maxHeight: "100px",
      },
    ".jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged, .jsondiffpatch-unchanged-hiding .jsondiffpatch-movedestination > .jsondiffpatch-value":
      {
        maxHeight: "0",
      },
    ".jsondiffpatch-unchanged-showing .jsondiffpatch-arrow, .jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow":
      {
        display: "none",
      },
    ".jsondiffpatch-value": { display: "inline-block" },
    ".jsondiffpatch-property-name": {
      display: "inline-block",
      paddingRight: "5px",
      verticalAlign: "top",
    },
    ".jsondiffpatch-property-name:after": { content: '": "' },
    ".jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name:after": {
      content: '": ["',
    },
    ".jsondiffpatch-child-node-type-array:after": { content: '"],"' },
    "div.jsondiffpatch-child-node-type-array:before": { content: '"["' },
    "div.jsondiffpatch-child-node-type-array:after": { content: '"]"' },
    ".jsondiffpatch-value pre:after": { content: '","' },
    "li:last-child > .jsondiffpatch-value pre:after, .jsondiffpatch-modified > .jsondiffpatch-left-value pre:after":
      {
        content: '""',
      },
    ".jsondiffpatch-modified .jsondiffpatch-value": { display: "inline-block" },
    ".jsondiffpatch-modified .jsondiffpatch-right-value": { marginLeft: "5px" },
    ".jsondiffpatch-moved .jsondiffpatch-value": { display: "none" },
    ".jsondiffpatch-moved .jsondiffpatch-moved-destination": {
      display: "inline-block",
      background: "#ffffbb",
      color: "#888",
    },
    ".jsondiffpatch-moved .jsondiffpatch-moved-destination:before": {
      content: '" => "',
    },
    "ul.jsondiffpatch-textdiff": { padding: "0" },
    ".jsondiffpatch-textdiff-location": {
      color: "#bbb",
      display: "inline-block",
      minWidth: "60px",
    },
    ".jsondiffpatch-textdiff-line": { display: "inline-block" },
    ".jsondiffpatch-textdiff-line-number:after": { content: '","' },
    ".jsondiffpatch-error": {
      background: "red",
      color: "white",
      fontWeight: "bold",
    },
    ...(hideUnchanged ? { ".jsondiffpatch-unchanged": { display: "none" } } : {}),
  });

const jsonDiff = create({
  arrays: {
    // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
    detectMove: true,
    // default false, the value of items moved is not included in deltas
    includeValueOnMove: true,
  },
  textDiff: {
    // default 60, minimum string length (left and right sides) to use text diff algorythm: google-diff-match-patch
    minLength: 100,
  },
  propertyFilter: function (name: any) {
    return name.slice(0, 1) !== "$" && name !== "last_modified";
  },
  cloneDiffValues: false,
});

type SmartDiffProps = {
  from: Object;
  to: Object;
  selectDiff?: (diff: any) => any;
  hideUnchanged?: boolean;
};

export function useSmartDiff({
  from,
  to,
  selectDiff = identity,
  hideUnchanged = true,
}: SmartDiffProps) {
  const [diffHtml, setDiffHtml] = React.useState<string | null>(null);
  const styles = getStyles(hideUnchanged);

  useLayoutEffect(() => {
    const delta = selectDiff(jsonDiff.diff(from, to));
    setDiffHtml(
      delta && !isEqual(delta, {}) ? formatters.html.format(delta!, selectDiff(from)) : null
    );
  }, [to, from]);

  return {
    diffHtml,
    styles,
  };
}
