import { alpha } from "@mui/system/colorManipulator";

export const gridColors = {
  searchIcon: "#323232",
  main: "rgba(0, 0, 0, 0.87)",
  darkGrey: "rgba(0, 0, 0, 0.38)",
  primary: "#1976D2",
  primaryLight: alpha("#1976D2", 0.5),
  primaryBackground: alpha("#1976D2", 0.08),
  link: "#757575",
  highlight: "#C62828",
};

export const gridSpacing = "8px";

export const cellHorizontalPadding = "24px";
