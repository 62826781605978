import { DataMasking } from "libs/data-masking";
import { types_LocationEnum } from "api/gen";

export const PII_PLACEHOLDER = "[masked]";

export enum MaskedEndpoint {
  AGGREGATIONS = "aggregations",
  EDGE_VIEW = "edge_view",
  ENDPOINTS = "endpoints",
  INCIDENTS = "incidents",
  LOCATIONS = "locations",
  VIEW_BY_ID = "view_by_id",
  USER_FIND = "user_find",
  USERS_LIST = "users_list",
}

export const FIELDS = {
  "Acting user": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.acting_username"],
  },
  "AI explanation": {
    [MaskedEndpoint.INCIDENTS]: ["*.ai_explanation", "*.ai_flags.explanation"],
  },
  "App command line": {
    [MaskedEndpoint.INCIDENTS]: ["data.app_commandline"],
    [MaskedEndpoint.VIEW_BY_ID]: [
      "nodes.*.app_commandline",
      "id_to_result.*.nodes.*.app_commandline",
    ],
  },
  "Browser page title": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.browser_page_title",
      "*.category_location.browser_page_title",
      "*.source.browser_page_title",
    ],
    [MaskedEndpoint.INCIDENTS]: ["*.data.browser_page_title", "*.source_data.browser_page_title"],
  },
  "Cloud app account": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.cloud_app_account",
      "*.dataset_location.cloud_app_account",
      "*.source.cloud_app_account",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.cloud_app_account",
      "*.dataset_location.cloud_app_account",
      "*.data.cloud_app_account",
      "*.source.cloud_app_account",
    ],
  },
  "Cloud destination account": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.cloud_shared_with",
      "*.dataset_location.cloud_shared_with",
      "*.source.cloud_shared_with",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.cloud_shared_with",
      "*.dataset_location.cloud_shared_with",
      "*.source.cloud_shared_with",
      "*.edge.destination.cloud_shared_with",
    ],
  },
  "Cloud destination groups": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.cloud_destination_groups",
      "*.dataset_location.cloud_destination_groups",
      "*.source.cloud_destination_groups",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.cloud_destination_groups",
      "*.dataset_location.cloud_destination_groups",
      "*.source.cloud_destination_groups",
    ],
  },
  "Cloud messaging groups": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.cloud_messaging_groups",
      "*.dataset_location.cloud_messaging_groups",
      "*.source.cloud_messaging_groups",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.cloud_messaging_groups",
      "*.dataset_location.cloud_messaging_groups",
      "*.source.cloud_messaging_groups",
    ],
  },
  "Cloud messaging users": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.cloud_messaging_users",
      "*.dataset_location.cloud_messaging_users",
      "*.source.cloud_messaging_users",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.cloud_messaging_users",
      "*.dataset_location.cloud_messaging_users",
      "*.source.cloud_messaging_users",
    ],
  },
  "Directory user groups": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.user_groups.*.name"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.user_groups.*.name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.user_groups.*.name"],
    [MaskedEndpoint.USER_FIND]: ["user_groups.*.name"],
  },
  "Email account": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.email_account",
      "*.category_location.email_account",
      "*.source.email_account",
      "*.acting_user.emails.*.value",
      "*.acting_user.id",
    ],
    [MaskedEndpoint.ENDPOINTS]: [
      "*.directory_user.emails.*.value",
      "*.directory_user.primary_email",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.email_account",
      "*.category_location.email_account",
      "*.source.email_account",
      "*.data.email_account",
      "*.source_data.email_account",
      "*.edge.source.email_account",
      "*.edge.destination.email_account",
      "*.endpoint_user.acting_username",
      "*.endpoint_user.id",
    ],
    [MaskedEndpoint.USER_FIND]: [
      "emails.*.value",
      "primary_email",
      "sensor_users.*.endpoint_email",
    ],
    [MaskedEndpoint.USERS_LIST]: ["*.email"],
    [MaskedEndpoint.VIEW_BY_ID]: [
      "nodes.*.email_account",
      "nodes.*.cloud_app_account",
      "id_to_result.*.nodes.*.email_account",
      "id_to_result.*.nodes.*.cloud_app_account",
    ],
  },
  "File path": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.path",
      "*.category_location.path_basename",
      "*.category_location.path_components.*",
      "*.dataset_location.path",
      "*.dataset_location.path_basename",
      "*.dataset_location.path_components.*",
      "*.source.path",
      "*.source.path_components.*",
      "*.source.path_basename",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.category_location.path",
      "*.category_location.path_basename",
      "*.category_location.path_components.*",
      "*.dataset_location.path",
      "*.dataset_location.path_basename",
      "*.dataset_location.path_components.*",
      "*.data.path",
      "*.data.path_basename",
      "*.data.path_components.*",
      "*.source_data.path",
      "*.source_data.path_components.*",
      "*.source_data.path_basename",
      "*.edge.source.path",
      "*.edge.source.path_components.*",
      "*.edge.source.path_basename",
      "*.edge.destination.path",
      "*.edge.destination.path_components.*",
      "*.edge.destination.path_basename",
    ],
    [MaskedEndpoint.VIEW_BY_ID]: [
      "nodes.*.path",
      "nodes.*.path_components.*",
      "nodes.*.path_basename",
      "nodes.*.links.*",
      "id_to_result.*.nodes.*.path",
      "id_to_result.*.nodes.*.path_components.*",
      "id_to_result.*.nodes.*.path_basename",
      "id_to_result.*.nodes.*.links.*",
    ],
  },
  "First name": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.first_name"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.first_name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.first_name"],
    [MaskedEndpoint.USER_FIND]: ["first_name"],
  },
  "Full name": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.full_name"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.full_name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.full_name"],
    [MaskedEndpoint.USER_FIND]: ["display_name", "full_name"],
    [MaskedEndpoint.USERS_LIST]: ["*.full_name"],
  },
  "Group name": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.category_location.group_name",
      "*.dataset_location.group_name",
      "*.source.group_name",
    ],
    [MaskedEndpoint.ENDPOINTS]: ["*.last_logged_user_local_groups"],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.group_name",
      "*.category_location.group_name",
      "*.source.group_name",
      "*.data.group_name",
      "*.source_data.group_name",
      "*.edge.source.group_name",
      "*.edge.destination.group_name",
      "*.endpoint_user.local_user_groups.*.name",
    ],
    [MaskedEndpoint.USER_FIND]: ["sensor_users.*.local_groups.*.name"],
  },
  "Hostname": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.hostname",
      "*.category_location.hostname",
      "*.source.hostname",
    ],
    [MaskedEndpoint.ENDPOINTS]: ["*.hostname"],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.hostname",
      "*.category_location.hostname",
      "*.source.hostname",
      "*.acting_user.hostname",
      "*.data.hostname",
      "*.source_data.hostname",
      "*.edge.source.hostname",
      "*.edge.destination.hostname",
      "*.endpoint_user.hostname",
    ],
    [MaskedEndpoint.USER_FIND]: ["sensor_users.*.hostname"],
    [MaskedEndpoint.VIEW_BY_ID]: ["nodes.*.hostname", "id_to_result.*.nodes.*.hostname"],
  },
  "Last name": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.last_name"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.last_name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.last_name"],
    [MaskedEndpoint.USER_FIND]: ["last_name"],
  },
  "Local machine name": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.local_machine_name",
      "*.category_location.local_machine_name",
      "*.source.local_machine_name",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.local_machine_name",
      "*.category_location.local_machine_name",
      "*.source.local_machine_name",
      "*.data.local_machine_name",
      "*.source_data.local_machine_name",
      "*.edge.source.local_machine_name",
      "*.edge.destination.local_machine_name",
    ],
    [MaskedEndpoint.VIEW_BY_ID]: [
      "events.*.local_machine_name",
      "id_to_result.*.events.*.local_machine_name",
    ],
  },
  "Local user groups": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.local_user_groups.*.name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.local_user_groups.*.name"],
    [MaskedEndpoint.VIEW_BY_ID]: ["events.*.group_name.*", "id_to_result.*.events.*.group_name.*"],
  },
  "Local user name": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.local_user_name",
      "*.category_location.local_user_name",
      "*.source.local_user_name",
    ],
    [MaskedEndpoint.ENDPOINTS]: [
      "*.directory_user.display_name",
      "*.directory_user.username",
      "*.last_logged_username",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.last_logged_username",
      "*.dataset_location.local_user_name",
      "*.category_location.local_user_name",
      "*.source.local_user_name",
      "*.data.local_user_name",
      "*.source_data.local_user_name",
      "*.edge.source.local_user_name",
      "*.edge.destination.local_user_name",
    ],
    [MaskedEndpoint.USER_FIND]: ["username", "sensor_users.*.local_user_name"],
  },
  "Manager email": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.manager_email"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.manager_email"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.manager_email"],
  },
  "Manager name": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.manager_name"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.manager_name"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.manager_name"],
  },
  "Phone number": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.phone_number"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.phone_number"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.phone_number"],
  },
  "Primary address city": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_address_city"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.primary_address_city"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_address_city"],
  },
  "Primary address country": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_address_country"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.primary_address_country"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_address_country"],
  },
  "Primary address region": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_address_region"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.primary_address_region"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_address_region"],
  },
  "Primary address street address": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_address_street_address"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.primary_address_street_address"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_address_street_address"],
  },
  "Primary address zip code": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_address_zip_code"],
    [MaskedEndpoint.ENDPOINTS]: ["*.directory_user.primary_address_zip_code"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_address_zip_code"],
  },
  "Primary email": {
    [MaskedEndpoint.EDGE_VIEW]: ["*.acting_user.primary_email"],
    [MaskedEndpoint.INCIDENTS]: ["*.acting_user.primary_email"],
  },
  "Printer name": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.local_user_name",
      "*.category_location.local_user_name",
      "*.source.local_user_name",
    ],
    [MaskedEndpoint.INCIDENTS]: ["data.printer_name"],
  },
  "Removable device name": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.removable_device_name",
      "*.category_location.removable_device_name",
      "*.source.removable_device_name",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.removable_device_name",
      "*.category_location.removable_device_name",
      "*.source.removable_device_name",
      "data.removable_device_name",
    ],
  },
  "Location outline": {
    [MaskedEndpoint.EDGE_VIEW]: [
      "*.dataset_location.location_outline",
      "*.category_location.location_outline",
      "*.source.location_outline",
    ],
    [MaskedEndpoint.INCIDENTS]: [
      "*.dataset_location.location_outline",
      "*.category_location.location_outline",
      "*.source.location_outline",
      "*.data.location_outline",
      "*.source_data.location_outline",
      "*.edge.source.location_outline",
      "*.edge.destination.location_outline",
    ],
    [MaskedEndpoint.LOCATIONS]: [
      {
        when: (obj: any) =>
          [
            types_LocationEnum.LocationEndpoint,
            types_LocationEnum.LocationShare,
            types_LocationEnum.LocationRemovableMedia,
            types_LocationEnum.LocationPrinter,
          ].includes(obj.location),
        path: "records.*.subitems.*.location_item.location_outline",
      },
      {
        when: (obj: any) =>
          [
            types_LocationEnum.LocationEndpoint,
            types_LocationEnum.LocationShare,
            types_LocationEnum.LocationRemovableMedia,
            types_LocationEnum.LocationPrinter,
          ].includes(obj.location),
        path: "records.*.subitems.*.location_item.path_component",
      },
      "records.*.subitems.*.subitems.*.location_item.location_outline",
      "records.*.subitems.*.subitems.*.location_item.path_component",
      "records.*.category_location.location_outline",
    ],
    [MaskedEndpoint.VIEW_BY_ID]: [
      "nodes.*.location_outline",
      "id_to_result.*.nodes.*.location_outline",
    ],
  },
  "User": {
    [MaskedEndpoint.INCIDENTS]: ["*.user"],
  },
} as Record<string, DataMasking.Field>;
