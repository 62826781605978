import React, { CSSProperties } from "react";
import { Button, ButtonProps } from "@mui/material";
import { HStack } from "ui/atoms/Stack";

export type UIButtonProps = Omit<ButtonProps, "sx"> & {
  sx?: { [k: string]: string | boolean | CSSProperties | number };
};

export const UIButton = ({ children, ...props }: UIButtonProps) => (
  <HStack
    as={Button}
    color="primary"
    align="center"
    variant="contained"
    style={{ flexGrow: 1, color: "white" }}
    onClick={props.onClick}
    {...props}
  >
    {children}
  </HStack>
);
