import React from "react";
import { css } from "ui/css";
import { useIsFocusedState } from "./FocusManager";

const fitContent = css({
  minWidth: "fit-content",
  width: '"100%"',
});

const fullWidth = css({
  width: "100%",
});

export type AutoWidthSizeProps = {
  children: any;
};

export function AutoWidthSizeWithFocusContext(props: AutoWidthSizeProps) {
  const isFocused = useIsFocusedState();

  return (
    <div className={isFocused ? fitContent().className : fullWidth().className}>
      {props.children}
    </div>
  );
}
