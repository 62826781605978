/* eslint-disable */
import React from "react";
import Editor from "react-simple-code-editor/lib";
export const styles = {
  container: {
    position: "relative",
    textAlign: "left",
    boxSizing: "border-box",
    padding: 0,
    overflow: "hidden",
  },
  textarea: {
    border: "inherit",
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    resize: "none",
    color: "inherit",
    overflow: "hidden",
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    WebkitTextFillColor: "transparent",
  },
  highlight: {
    position: "relative",
    pointerEvents: "none",
  },
  editor: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    outline: "none",
    // borderTop: 0,
    // borderRight: 0,
    // borderBottom: 0,
    // borderLeft: 0,
    background: "none",
    border: "none",
    boxSizing: "inherit",
    display: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontVariantLigatures: "inherit",
    fontWeight: "inherit",
    letterSpacing: "inherit",
    lineHeight: "inherit",
    tabSize: "inherit",
    textIndent: "inherit",
    textRendering: "inherit",
    textTransform: "inherit",
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
};
const container = "npm__react-simple-code-editor__container";

const cssText = /* CSS */ `
/**
 * Make sure that layout related properties cannot be set on the children
 * They will likely break the editor when set. The user can always use !important to force override.
 */
.${container} > pre * {
  display: inline;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
/**
 * Reset the text fill color so that placeholder is visible
 */
.${container} > textarea:empty {
  -webkit-text-fill-color: inherit !important;
}
/**
 * Hack to apply on some CSS on IE10 and IE11
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /**
    * IE doesn't support '-webkit-text-fill-color'
    * So we use 'color: transparent' to make the text transparent on IE
    * Unlike other browsers, it doesn't affect caret color in IE
    */
  .${container} > textarea {
    color: transparent !important;
  }
  .${container} > textarea::selection {
    background-color: #accef7 !important;
    color: transparent !important;
  }
}
`;

export default class SimpleCodeEditor extends Editor {
  render() {
    const {
      value,
      style,
      className,
      padding,
      highlight,
      autoFocus,
      disabled,
      form,
      maxLength,
      minLength,
      name,
      placeholder,
      readOnly,
      required,
      onClick,
      onFocus,
      onBlur,
      onKeyUp,
      /* eslint-disable no-unused-vars */
      onKeyDown,
      onValueChange,
      tabSize,
      insertSpaces,
      ignoreTabKey,
      children,
      /* eslint-enable no-unused-vars */
      ...rest
    } = this.props;

    const contentStyle = {
      paddingTop: padding,
      paddingRight: padding,
      paddingBottom: padding,
      paddingLeft: padding,
    };

    const highlighted = highlight(value);

    return (
      <div
        {...rest}
        style={{ ...styles.container, ...style }}
        className={`${container} ${className || ""}`}
      >
        {children({
          "ref": (c) => (this._input = c),
          "style": {
            ...styles.editor,
            ...styles.textarea,
            ...contentStyle,
          },
          value,
          "onChange": this._handleChange,
          "onKeyDown": this._handleKeyDown,
          onClick,
          onKeyUp,
          onFocus,
          onBlur,
          disabled,
          form,
          maxLength,
          minLength,
          name,
          placeholder,
          readOnly,
          required,
          autoFocus,
          "autoCapitalize": "off",
          "autoComplete": "off",
          "autoCorrect": "off",
          "spellCheck": false,
          "data-gramm": false,
        })}
        <pre
          aria-hidden="true"
          style={{ ...styles.editor, ...styles.highlight, ...contentStyle }}
          {...(typeof highlighted === "string"
            ? { dangerouslySetInnerHTML: { __html: highlighted + "<br />" } }
            : { children: highlighted })}
        />
        {/* eslint-disable-next-line react/no-danger */}
        <style type="text/css" dangerouslySetInnerHTML={{ __html: cssText }} />
      </div>
    );
  }
}
