import { route } from "./utils";

export declare namespace frontendconfig {
  export type GetFrontendConfigReq = {};

  export type GetFrontendConfigRes = {
    config: frontendconfig.FrontendConfig | null;
  };

  export type FrontendConfig = {
    CUSTOMER_NAME: string;
    APP_ANALYTICS_CUSTOMER_NAME: string;
  };
}

/**
 * Manually created API routes
 */
export const apiCustom = {
  GetFrontendConfig: route<
    frontendconfig.GetFrontendConfigReq,
    frontendconfig.GetFrontendConfigRes
  >("GET", "/v1/web-authenticated-config", {}, { json: ["config"] }),
};
