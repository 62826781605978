export const searchMessages = {
  rule: "Condition",
  ruleSearch: "Search",
  condition: "Condition",
  ariaCondition: (name: any) => `Condition ${name}`,
  delete: "Delete",
  fieldInsertNewLine: "Click or press ‘Enter’ to add a new line",
  subconditionAria: "subcondition",
  conditionAria: "condition",
  unsupportedConditionFieldError: (minVersion: string, entity: "Datasets" | "Policies") =>
    `Searches using this field cannot be saved to
    ${entity} until all of your sensors are upgraded to support it. 
    Field only available on sensor ${minVersion} and later.`,
};

export const searchTestIDs = {
  insertNewLine: "insert_new_line",
  nestedRuleSection: "nested_rule_section",
};
