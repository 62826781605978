import { reduce } from "lodash";
import { build, createEffects } from "okdux";
import { IUser, IUserToggle, IUpdated, salesforceSensorService } from "api/cloud-sensors";
import { ISalesforceTenant } from "api/auth/interfaces";
import { salesforceAuth } from "api/auth/SalesforceAuth";
import { apiErrorNotification, notification } from "modules/notification";
import { messages } from "./messages";

const salesforceEffects = createEffects(
  {
    fetchUsers: build.async<IUser[], boolean>(),
    login: build.async<ISalesforceTenant>(),
    logout: build.async(),
    getUser: build.async<ISalesforceTenant>(),
    toggle: build.async<IUserToggle, Partial<IUser>>(),
    import: build.async<IUser[], IUserToggle[]>(),
    enableAll: build.async<Pick<IUser, "monitored">>(),
    enableForIds: build.async<string[], string[]>(),
  },
  "salesforce"
);

export async function fetchUsers(polling = false) {
  const { success, request, failure } = salesforceEffects.fetchUsers;

  !polling && request();
  try {
    const data = await salesforceSensorService.fetchUsers();
    success(data);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function monitor(): Promise<boolean> {
  const { data } = await salesforceSensorService.monitorUsers();

  const totalChanges = reduce<IUpdated, number>(data, countChanges, 0);
  return !!totalChanges;

  function countChanges(acc: number, changes: IUpdated[keyof IUpdated]): number {
    return acc + changes.length;
  }
}

export async function logout() {
  const { success, request, failure } = salesforceEffects.logout;

  request();
  try {
    await salesforceAuth.logout();
    success();
    notification.open({ message: messages.notifications.userDisconnected });
    return;
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.logoutError, description: error.message });
    return Promise.reject(error);
  }
}

export async function importUsers(users: IUser[]) {
  const { success, request, failure } = salesforceEffects.import;
  request();
  try {
    await salesforceSensorService.batch(users);
    success(users);
    notification.open({ message: messages.notifications.successfullyImported });
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.importError, description: error.message });
  }
}

export async function enableForIds(users: string[]) {
  const { success, request, failure } = salesforceEffects.enableForIds;

  request();
  try {
    await salesforceSensorService.batch(users.map((id) => ({ id, monitored: true })));
    success(users);
    notification.open({ message: messages.notifications.usersMonitoringEnabled });
  } catch (error: any) {
    failure(error);
    notification.error({ message: error.message });
  }
}

export async function login({ isSandbox }: { isSandbox: boolean }) {
  const { success, request, failure } = salesforceEffects.login;

  request();
  try {
    const tenant = await salesforceAuth.login(undefined, isSandbox);
    success(tenant);
  } catch (error: any) {
    failure(error);
    notification.error({
      message: messages.notifications.salesForceError,
      description: error.message,
    });
  }
}

export async function getUser() {
  const { success, request, failure } = salesforceEffects.getUser;

  request();
  try {
    const tenant = await salesforceAuth.getUser();
    success(tenant);
  } catch (error) {
    failure(error);
  }
}

export async function toggle({ id, monitored }: any) {
  const { success, request, failure } = salesforceEffects.toggle;

  request();
  try {
    await salesforceSensorService.updateUser(id, { monitored });
    notification.open({ message: messages.notifications.userMonitoring(monitored) });
    success({ id, monitored });
  } catch (error: any) {
    failure({ id });
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAll(params: Pick<IUser, "monitored">) {
  const { success, request, failure } = salesforceEffects.enableAll;

  request();
  try {
    await salesforceSensorService.updateAll(params);
    notification.open({
      message: messages.notifications.allUserMonitoring(params.monitored),
    });
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAutoMonitoring(params: Pick<IUser, "monitored">) {
  try {
    await salesforceSensorService.toggleAutoMonitoring(params);
    await fetchUsers();
    notification.open({
      message: messages.notifications.autoMonitoring(params.monitored),
    });
  } catch (error: any) {
    notification.error(apiErrorNotification(error));
  }
}

export { salesforceEffects };
