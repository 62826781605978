import constate from "constate";
import { pick } from "lodash";
import { removeLocalTZOffset } from "libs/timezone/timezoneUtils";
import { getTimeRangeDates, timerangeLabels, TimeRangeEnum } from "libs/datepicker/timerangeUtils";
import { useTimezoneState } from "modules/timezone";
import { useBrowserStorageState } from "libs/hooks";
import { useFilters } from "modules/table";
import { UserRiskGroup } from "./types";

export const getTimeRange = ({ timezone }: { timezone: string }) => {
  // Later this will be separate setting configurable on UI
  const timerange = TimeRangeEnum.LAST_90_DAYS;
  const { startDate, endDate } = getTimeRangeDates(timerange, timezone);

  return {
    start_time: removeLocalTZOffset(startDate).toISOString(),
    end_time: removeLocalTZOffset(endDate).toISOString(),
    label: timerangeLabels[timerange],
  };
};

const getFiltersConfig = () => ({
  assignees: {
    queryKey: "assignees",
    defaultValue: [] as Array<string | null>,
  },
  selectedUserRiskGroups: {
    queryKey: "selectedUserRiskGroups",
    defaultValue: [] as UserRiskGroup[],
  },
});

export const [RiskyUsersFiltersProvider, useRiskyUsersFilters] = constate(() => {
  const { timezone } = useTimezoneState();
  const config = getFiltersConfig();

  const stateKey = "risky-users-filters";
  const filtersState = useBrowserStorageState({
    key: stateKey,
    useStateHook: (initialState: Record<string, any> | null) =>
      useFilters({
        queryKeyPrefix: stateKey,
        filters: config,
        initialValue: { ...initialState!, userStatus: "new" },
        clearAllFilter: (name: string) => name !== "timeRange",
      }),
    getStateToStore: (state) => pick(state, ["timeRange"]),
  });

  return {
    ...filtersState,
    timeRange: getTimeRange({ timezone }),
  };
});
