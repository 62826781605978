import {
  ActionAttachIcon,
  ActionCopyIcon,
  ActionCopypasteIcon,
  ActionCreateCloudIcon,
  ActionDownloadIcon,
  ActionEditIcon,
  ActionMoveIcon,
  ActionReceiveIcon,
  ActionUploadIcon,
  ActionViewIcon,
  ActionSentIcon,
  ActionSentToPrinterIcon,
  StartedTrackingIcon,
  ActionZipIcon,
  ActionUnzipIcon,
  DlpScannerIcon,
} from "assets/icons";
import { types_EventTypeEnum } from "api/gen";
import { EventTypes } from "./events";

export function resolveIcon(eventType: EventTypes, location: string) {
  switch (eventType) {
    case "received_email":
      return ActionReceiveIcon;
    case "copy":
    case "rename_copy":
    case "save_as":
      return ActionCopyIcon;
    case "clipboard_copypaste":
      return ActionCopypasteIcon;
    case "attachment_add":
      return ActionAttachIcon;
    case "upload":
      return ActionUploadIcon;
    case "sent_email":
    case "send":
    case "attachment_send":
      return ActionSentIcon;
    case "create_edit":
      return ActionCreateCloudIcon;
    case "create":
      return location === "cloud_storage" ? ActionCreateCloudIcon : StartedTrackingIcon;
    case "move":
    case "rename":
      return ActionMoveIcon;
    case "download":
    case "attachment_save":
      return ActionDownloadIcon;
    case "app_access":
      return ActionViewIcon;
    case "edit":
      return ActionEditIcon;
    case "sent_to_printer":
      return ActionSentToPrinterIcon;
    case "zip":
      return ActionZipIcon;
    case "unzip":
      return ActionUnzipIcon;
    case types_EventTypeEnum.EventTypeDlpScan:
      return DlpScannerIcon;
    default:
      return ActionMoveIcon;
  }
}
