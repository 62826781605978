export const groupAttr = {
  role: "group",
};

// helper selectors
export const selectors = {
  touchParent: `*:focus > &, *:focus-within > &, *:hover > &`,
  touchGroup: `[role="group"]:hover &, [role="group"]:focus &, [role="group"]:focus-within &`,
  hoverParent: `[role="group"]:hover > &`,
  hoverGroup: `[role="group"]:hover &`,
  errorGroup: `[role="group"][data-error="true"] &`,
  touch: "&:hover, &:focus, &:focus-within",
};
