import React, { useLayoutEffect, useState, useMemo } from "react";
import debounce from "lodash/debounce";
import { InputAdornment, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { grey } from "@mui/material/colors";
import { css } from "ui/css";
import { messages } from "../messages";

const INPUT_DEBOUNCE = 1000;
const IconClassname = (value: any) =>
  css({
    color: grey[600],
    opacity: value ? 1 : 0,
    cursor: "pointer",
    transition: "all 0.5s ease-in",
  });

const searchStyles = css({
  "&.Mui-focused": {
    borderColor: "#47A7EA",
    borderWidth: 2,
    background: "white",
  },
  ".MuiInputBase-input": {
    paddingTop: 12,
    paddingBottom: 12,
  },
  "&:hover": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#47A7EA",
    },
  },
  "fieldset": {
    "borderWidth": 1,
    "borderColor": "transparent",
    "&::before": {
      display: "none",
    },
  },
});

export function SearchInput(props: any) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showCancel,
    initialValue = "",
    label,
    onRequestSearch,
    onCancel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onBlur,
    className,
    placeholder = null,
    inputProps,
    ...rest
  } = props;
  const [value, updateValue] = useState(initialValue);

  const handleKeyUp = (e: any) => {
    if (e.charCode === 13 || e.key === "Enter") {
      onRequestSearch(value);
    }
  };
  const debouncedUpdate = useMemo(
    () => debounce(onRequestSearch, INPUT_DEBOUNCE),
    [onRequestSearch]
  );
  const onChange = (e: any) => {
    const v = e.target.value || "";
    updateValue(v);
    debouncedUpdate(v);
  };

  useLayoutEffect(() => {
    updateValue(initialValue);
  }, [initialValue]);

  const handleCancel = () => {
    debouncedUpdate.cancel();
    updateValue("");
    onCancel && onCancel("");
  };

  return (
    <TextField
      label={label}
      value={value}
      placeholder={placeholder}
      className={searchStyles({ className }).className}
      onChange={onChange}
      id="search"
      autoComplete={"off"}
      onKeyUp={handleKeyUp}
      fullWidth
      margin="dense"
      variant="standard"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: grey[600] }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment onClick={handleCancel} position="end">
            <ClearIcon className={IconClassname(value)().className} />
          </InputAdornment>
        ),
        ...inputProps,
      }}
      {...rest}
    />
  );
}

SearchInput.defaultProps = {
  placeholder: messages.searchInput.placeholder,
};
