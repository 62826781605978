import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { HStack } from "libs/layouts";

export function FilterSkeleton({ count = 3 }: { count: number }) {
  return Array.from({ length: count }, (_, i) => (
    <Skeleton animation="wave" variant="rectangular" key={i} height={128} width={320} />
  )) as any;
}

export function TopLevelFilters({ isLoading, children }: any) {
  return (
    <HStack data-fs="unmask" space={2} style={{ userSelect: "none", marginBottom: "15px" }}>
      {isLoading ? (
        <FilterSkeleton count={React.Children.toArray(children).length} />
      ) : (
        <>{children}</>
      )}
    </HStack>
  );
}
