import { useMemo, useCallback, useRef } from "react";
import memoize from "memoize-one";
import { shallowEqual } from "react-redux";
import { useLocation, useNavigate, Location, useSearchParams } from "react-router-dom";

export function useQueryParam<T>(name: string, initialValue: T, keepInHistory = true) {
  const [search, setSearch] = useSearchParams();
  const memoParse = useMemo(() => {
    return memoize(JSON.parse);
  }, []);

  const paramValue = useMemo<T>(() => {
    const values = search;
    return values?.has(name) ? memoParse(values.get(name)!) : initialValue;
  }, [search, name]);

  const setValue = useCallback(
    (value: T) => {
      setSearch(
        (prevSearchObject: URLSearchParams) => {
          const searchObject = new URLSearchParams(prevSearchObject);

          if (value === null) {
            searchObject.delete(name);
          } else {
            searchObject.set(name, JSON.stringify(value));
          }

          return searchObject;
        },
        { replace: !keepInHistory, state: getHistoryState() }
      );
    },
    [name, setSearch, keepInHistory]
  );
  return [paramValue, setValue] as const;
}

const getUrlState = () => new URLSearchParams(window.location.search);
// React router contains current state inside "usr" property
const getHistoryState = () => window.history.state?.usr;
export function createHistoryHook(hash: (data: string) => string) {
  return function useHistoryParam<T>(name: string, defaultValue: T, keepInHistory = true) {
    const navigate = useNavigate();
    const location = useLocation();

    const locationRef = useRef<Location | null>(null);
    locationRef.current = location;
    const paramValue = (location.state?.[name] ?? getHistoryState()?.[name] ?? defaultValue) as T;

    // TODO: Amirov Set query hash string if we have state in history but no query param

    // be careful do not make this call async
    const setValue = useCallback(
      (
        value: T,
        { openNewTab = false, newPath = undefined }: { openNewTab?: boolean; newPath?: string } = {}
      ) => {
        if (shallowEqual(value, paramValue)) {
          return;
        }
        const { pathname } = locationRef.current!;
        const newState = () => ({
          ...getHistoryState(),
          [name]: value,
        });
        const hashStr = hash(JSON.stringify(newState()));

        const searchState = getUrlState();
        searchState.set("s", hashStr);
        const nextLocationObject = {
          state: newState(),
          replace: !keepInHistory,
        };
        const url = (newPath || pathname) + "?" + searchState.toString();

        if (openNewTab) {
          window.open(url, "_blank");
        } else {
          navigate(url, nextLocationObject);
        }
      },
      [name, keepInHistory, paramValue]
    );

    return [paramValue, setValue] as const;
  };
}
