export function createRadioTransformer(keys: string[]) {
  return {
    format(current: any) {
      return keys.reduce<Record<any, any>>((acc, k) => {
        acc[k] = current === k;
        return acc;
      }, {});
    },
    parse(current: any) {
      return keys.reduce((acc, k) => {
        if (current[k]) {
          return k;
        }
        return acc;
      }, "");
    },
  };
}

export const required = (v: any) => {
  if (!v) {
    return "Required";
  }
  return;
};

export function getNextName(prefix: any, names: string[]): string {
  const regexp = new RegExp(`^\\s\\((\\d+)\\)$`);
  const maxNumber = names.reduce((acc, name) => {
    if (name.includes(prefix)) {
      const match = name.replace(prefix, "").match(regexp);

      if (match && match[1] && acc < parseInt(match[1])) {
        return parseInt(match[1]);
      }
    }

    return acc;
  }, 0);

  return `${prefix} (${maxNumber + 1})`;
}
