export function openAuthWindow(url: string, title: string, width = 500, height = 900) {
  const top = window!.top!.outerHeight / 2 + window!.top!.screenY - height / 2;
  const left = window!.top!.outerWidth / 2 + window!.top!.screenX - width / 2;

  const features = [
    "toolbar=no",
    "location=no",
    "directories=no",
    "status=no",
    "menubar=no",
    "scrollbars=yes",
    "resizable=yes",
    "copyhistory=no",
    `width=${width}`,
    `height=${height}`,
    `top=${top}`,
    `left=${left}`,
  ];

  return window.open(url, title, features.join(", "));
}
