import { FORM_ERROR } from "final-form";
import React from "react";
import { useField } from "react-final-form";
import { FormHelperText } from "ui/atoms/FormHelperText";
import { getErrorLabel } from "ui/atoms/Field/mapError";

type Props = {
  name?: string;
  isSubmitError?: boolean;
  style?: React.CSSProperties;
  className?: string;
  getErrorLabel?: (meta: any) => string;
};

export function FormErrorMessage({
  name = FORM_ERROR,
  isSubmitError,
  style,
  className,
  getErrorLabel: customGetErrorLabel,
}: Props) {
  const { meta } = useField(name);
  const labelGetter = customGetErrorLabel || getErrorLabel;
  const errorLabel = isSubmitError ? meta.submitError : labelGetter(meta);
  return errorLabel ? (
    <FormHelperText component="span" error style={style} className={className}>
      {typeof errorLabel === "string" ? <>{errorLabel}</> : <>{JSON.stringify(errorLabel)}</>}
    </FormHelperText>
  ) : null;
}
