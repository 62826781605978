import { Column } from "./types";

type ParsedColumn = Map<string, [number, number]>;

export class SizeNode {
  constructor(public id: string, public width: number) {}
}

const normalizeColumnsSizes = (columns: Column[], tableWidth: number) => {
  return columns.map((c) => {
    return {
      ...c,
      minWidth: c.minWidth || 130,
      maxWidth: c.maxWidth || tableWidth,
    };
  });
};

const parseColumnSize = (column: Column) => {
  return [column.minWidth, column.maxWidth] as [number, number];
};

export const parseMinMaxSizes = (columns: Column[], tableWidth: number): ParsedColumn => {
  const normalizedColumns = normalizeColumnsSizes(columns, tableWidth);
  const map = new Map() as ParsedColumn;

  return normalizedColumns.reduce((acc, column) => {
    if (column.resizable) {
      acc.set(column.id, parseColumnSize(column));
    }
    return acc;
  }, map);
};

export const calculateRealSizes = (columns: Column[], parent: HTMLElement = document as any) => {
  return columns.reduce((acc, c) => {
    const head = parent.querySelector(`[data-id="${c.id}"]`) as HTMLElement;

    if (head) {
      acc.set(c.id, head.getBoundingClientRect().width);
    }

    return acc;
  }, new Map<string, number>());
};

export const isResizePossible = (
  currentColumn: string,
  sizeDiff: number,
  columns: Column[],
  minMaxSizes: ParsedColumn,
  realSizes: ReturnType<typeof calculateRealSizes>
) => {
  const columnIndex = columns.findIndex((c) => c.id === currentColumn);
  const getRealSizeTotal = (arr: any) =>
    arr.reduce((acc: any, c: any) => {
      const size = realSizes.get(c.id);
      return acc + size;
    }, 0);
  const getMinSizeTotal = (arr: any) =>
    arr.reduce((acc: any, c: any) => {
      const size = minMaxSizes.get(c.id)![0];
      return acc + size;
    }, 0);
  const getMaxSizeTotal = (arr: any) =>
    arr.reduce((acc: any, c: any) => {
      const size = minMaxSizes.get(c.id)![1];
      return acc + size;
    }, 0);
  const [left, right] = splitArray(columns, columnIndex).map((arr) =>
    arr.filter((c) => c.resizable)
  );

  if (sizeDiff >= 0) {
    const maxSize = minMaxSizes.get(currentColumn)?.[1]!;
    const realSize = realSizes.get(currentColumn)!;
    if (realSize + sizeDiff > maxSize) {
      return false;
    }
  } else {
    const currColumn = columns[columnIndex];
    const leftSizeMinTotal = getMinSizeTotal([...left, currColumn]);
    const leftSizeTotal = getRealSizeTotal([...left, currColumn]);

    if (leftSizeMinTotal >= leftSizeTotal + sizeDiff) {
      return false;
    }

    const rightSizeMaxTotal = getMaxSizeTotal(right);
    const rightSizeTotal = getRealSizeTotal(right);

    if (rightSizeMaxTotal <= rightSizeTotal - sizeDiff) {
      return false;
    }
  }

  return true;
};

export const calculateCurrentSizes = (
  currentColumn: string,
  sizeDiff: number,
  columns: Column[],
  minMaxSizes: ParsedColumn,
  realSizes: ReturnType<typeof calculateRealSizes>,
  tableSize: { clientWidth: number }
) => {
  const diffLeft = Math.abs(sizeDiff);

  const currentIndex = columns.findIndex((c) => c.id === currentColumn);
  const [left, right] = splitArray(columns, currentIndex);
  const _currentColumn = columns[currentIndex];
  const totalWidth = columns.reduce((acc, c) => acc + (realSizes.get(c.id) || 0), 0) + sizeDiff;

  if (sizeDiff >= 0) {
    const mapBefore = (column: Column) => {
      const realWidth = realSizes.get(column.id);
      return new SizeNode(column.id, column.resizable ? realWidth! : (column.width as number));
    };
    const mapCurrent = (column: Column, diffLeft: number) => {
      const realWidth = realSizes.get(column.id);
      return new SizeNode(column.id, (realWidth || 0) + diffLeft);
    };
    const mapAfter = (column: Column, diffLeft: number) => {
      if (!column.resizable) {
        return [new SizeNode(column.id, column.width as number), diffLeft] as const;
      }

      const colMinWidth = minMaxSizes.get(column.id)![0];
      const realWidth = realSizes.get(column.id);
      const availableWidth = (realWidth || 0) - colMinWidth;

      if (availableWidth >= diffLeft) {
        return [new SizeNode(column.id, (realWidth || 0) - diffLeft), 0] as const;
      } else {
        return [new SizeNode(column.id, colMinWidth), diffLeft - availableWidth] as const;
      }
    };

    const leftColumns = left.map(mapBefore);
    const newCurrentColumn = mapCurrent(_currentColumn, diffLeft);
    const [rightColumns] = right.reduce(reduceWithAcc(mapAfter), [[], diffLeft]);

    return leftColumns.concat(newCurrentColumn, rightColumns);
  } else {
    // diff < 0
    const mapAfter = (c: Column, diffLeft: number) => {
      if (!c.resizable) {
        return [c as SizeNode, diffLeft] as const;
      }

      const realWidth = realSizes.get(c.id);
      const colMaxWidth = minMaxSizes.get(c.id)![1];
      const available = colMaxWidth - (realWidth || 0);

      if (available >= diffLeft) {
        return [new SizeNode(c.id, (realWidth || 0) + diffLeft), 0] as const;
      } else {
        return [new SizeNode(c.id, colMaxWidth), diffLeft - available] as const;
      }
    };
    const mapCurrent = (c: Column, diffLeft: number) => {
      const colMinWidth = minMaxSizes.get(c.id)![0];
      const realWidth = realSizes.get(c.id);
      const available = (realWidth || 0) - colMinWidth;

      if (available >= diffLeft) {
        return [new SizeNode(c.id, (realWidth || 0) - diffLeft), 0] as const;
      } else {
        return [new SizeNode(c.id, colMinWidth), diffLeft - available] as const;
      }
    };
    const mapBefore = (c: Column, diffLeft: number) => {
      const realWidth = realSizes.get(c.id);

      if (c.resizable) {
        if (diffLeft === 0) {
          return [new SizeNode(c.id, realWidth!), 0] as const;
        } else {
          return mapCurrent(c, diffLeft);
        }
      } else {
        return [new SizeNode(c.id, c.width as any), diffLeft] as const;
      }
    };

    const [rightColumns] = right.reduce(reduceWithAcc(mapAfter), [
      [],
      totalWidth > tableSize.clientWidth ? 0 : tableSize.clientWidth - totalWidth,
    ]);
    const [newCurrentColumn, leftSizeLeft] = mapCurrent(_currentColumn, diffLeft);
    const [leftColumn] = left.reduceRight(reduceWithAcc(mapBefore), [[], leftSizeLeft]);

    return leftColumn.reverse().concat(newCurrentColumn, rightColumns);
  }
};

function splitArray<T>(arr: T[], idx: number) {
  const left = arr.slice(0, idx);
  const right = arr.slice(idx + 1);
  return [left, right];
}

function reduceWithAcc<Column, Diff = number>(
  map: (c: Column, diffLeft: Diff) => readonly [SizeNode, Diff]
) {
  return (acc: [SizeNode[], Diff], c: Column) => {
    const diffLeft = acc[1];
    const [column, newDiff] = map(c, diffLeft);
    acc[0].push(column as SizeNode);
    acc[1] = newDiff;

    return acc;
  };
}

export function toGridTemplateColumns(columns: Array<{ width?: number | string }>) {
  return (
    columns
      .map(({ width }) => {
        if (typeof width === "number") {
          width = `${width}px`;
        }
        return `${width || "minmax(100px, 1fr)"}`;
      })
      .join(" ") + " [col-end]"
  );
}
