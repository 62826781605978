import { useState } from "react";
import { isEqual } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { api } from "api/gen";

export const MViewStatusKey = "m-view-status";

export interface MViewsStatus {
  isPrecomputedReady: boolean;
  staleAsOf?: string;
}

// Continuously polls for status change
export function useMViewsStatus({
  onStatusChange,
  enabled,
}: {
  onStatusChange: (status: MViewsStatus) => any;
  enabled: boolean;
}) {
  const [status, setStatus] = useState<MViewsStatus | null>(null);

  useQuery({
    queryKey: [MViewStatusKey],
    queryFn: async () => {
      const response = await api.eventsapi.Service.MViewStatus({});
      const newStatus: MViewsStatus = {
        isPrecomputedReady: !response || response?.is_precomputed_ready,
        staleAsOf: response?.stale_as_of,
      };

      if (!isEqual(status, newStatus)) {
        setStatus(newStatus);
        onStatusChange(newStatus);
      }
      return response;
    },
    refetchInterval: getRefetchInterval(status),
    refetchIntervalInBackground: true,
    enabled,
  });

  return status;
}

function getRefetchInterval(status: MViewsStatus | null): number | false {
  if (!status?.isPrecomputedReady) {
    // 1 minute interval if data is not ready, so we can quickly update if update completed
    return 1000 * 60;
  }

  // 2 minutes polling interval if data is ready
  return 1000 * 60 * 2;
}
