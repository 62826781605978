import { useRef, useLayoutEffect } from "react";
import useMountedState from "react-use/lib/useMountedState";

type AnimatedPropsInternal = {
  key: string | boolean | number;
  keyframes: Keyframe[] | PropertyIndexedKeyframes;
  options?: number | KeyframeAnimationOptions;
  animateMount?: boolean;
} & KeyframeAnimationOptions;

export function useAnimatedChange({
  key,
  keyframes,
  animateMount,
  ...options
}: AnimatedPropsInternal) {
  const ref = useRef<any>();
  const mounted = useMountedState();
  useLayoutEffect(() => {
    if (!mounted() && !animateMount) {
      return;
    }
    ref?.current?.animate(keyframes, options);
  }, [key]);
  return { ref };
}
