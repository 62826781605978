import { useEffect, useMemo } from "react";
import { usePrevious } from "react-use";
import { isEqual } from "lodash";

interface BrowserStorageStateConfig<T, P> {
  storage?: Storage;
  key: string;
  useStateHook: (initialState: T | null) => P;
  // By default, whole state is stored, but you can modify it to store partially
  getStateToStore?: (hookResult: P) => T;
  defaultState?: T;
}
export function useBrowserStorageState<T extends any, P extends any>({
  key,
  storage = sessionStorage,
  getStateToStore = (hookResult) => (Array.isArray(hookResult) ? hookResult[0] : hookResult),
  useStateHook,
  defaultState,
}: BrowserStorageStateConfig<T, P>) {
  const initialState = useMemo(() => getStateFromStorage<T>(storage, key), []);
  const hookResult = useStateHook(initialState !== null ? initialState : defaultState || null);

  const stateToStore = useMemo(() => getStateToStore(hookResult), [hookResult]);
  const previousStateToStore = usePrevious(stateToStore);
  useEffect(() => {
    if (!isEqual(previousStateToStore, stateToStore)) {
      setStateToStorage(storage, key, stateToStore);
    }
  }, [stateToStore]);

  return hookResult;
}

function getStateFromStorage<T extends any>(storage: Storage, key: string): T | null {
  const stateString = storage.getItem(key);

  try {
    return stateString ? JSON.parse(stateString) : null;
  } catch (err) {
    console.error(`Failed to load state from storage, key:${key}`);
    return null;
  }
}

function setStateToStorage<T extends any>(storage: Storage, key: string, state: T) {
  storage.setItem(key, JSON.stringify(state));
}
