const topLevelLocations = {
  user: "User",
  audience: "Organization",
  anyone: "Anyone",
} as const;

const cloudAccessLevel = {
  reader: "Viewer",
  writer: "Editor",
  commenter: "Commenter",
} as const;

export const cloudSharedTypeCategoryOptions = Object.entries(topLevelLocations).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

export const cloudAccessLevelOptions = Object.entries(cloudAccessLevel).map(([key, value]) => ({
  value: key,
  label: value,
}));
