import React from "react";
import { messages } from "../messages";
import { HelperInfo } from "./HelperInfo";

const examples = [
  {
    group: messages.luceneHelper.search,
    explanation: (
      <div>
        {messages.luceneHelper.searchExp}
        <pre style={{ whiteSpace: "pre-wrap" }}>{messages.luceneHelper.searchExample}</pre>
      </div>
    ),
  },
  {
    group: messages.luceneHelper.fuzzySearches,
    explanation: (
      <div>
        {messages.luceneHelper.fuzzySearchesExp}:
        <pre>{messages.luceneHelper.fuzzySearchesExample}</pre>
      </div>
    ),
  },

  {
    group: messages.luceneHelper.wildcardSearches,
    explanation: (
      <div>
        {messages.luceneHelper.wildcardSearchesExp1}:
        <pre>{messages.luceneHelper.wildcardSearchesExample1}</pre>
        {messages.luceneHelper.wildcardSearchesExp2}:
        <pre>{messages.luceneHelper.wildcardSearchesExample2}</pre>
        {messages.luceneHelper.wildcardSearchesExp3}
      </div>
    ),
  },
  {
    group: messages.luceneHelper.fieldGrouping,
    explanation: (
      <div>
        {messages.luceneHelper.fieldGroupingExp}:
        <pre>{messages.luceneHelper.fieldGroupingExample}</pre>
      </div>
    ),
  },

  {
    group: messages.luceneHelper.rangeSearches,
    explanation: (
      <div>
        <p>{messages.luceneHelper.rangeSearchesExp1}</p>
        <pre>{messages.luceneHelper.rangeSearchesExample1}</pre>
        <p>
          {messages.luceneHelper.rangeSearchesExp1}, {messages.luceneHelper.rangeSearchesExp2}
        </p>
        <pre>{messages.luceneHelper.rangeSearchesExample2}</pre>
      </div>
    ),
  },
  {
    group: messages.luceneHelper.not,
    explanation: (
      <div>
        {messages.luceneHelper.notExp}:<pre>{messages.luceneHelper.notExample}</pre>
        {messages.luceneHelper.notOr}
        <pre>{messages.luceneHelper.notOrExample}</pre>
      </div>
    ),
  },
  {
    group: messages.luceneHelper.escaping,
    explanation: (
      <div>
        {messages.luceneHelper.escapingExp}:<pre>{messages.luceneHelper.escapingExample}</pre>
      </div>
    ),
  },
];

const links = [
  {
    label: messages.luceneHelper.guidelineLabel,
    url: "https://lucene.apache.org/core/2_9_4/queryparsersyntax.html",
  },
  {
    label: messages.luceneHelper.searchQueryLabel,
    url: "https://docs.cyberhaven.io/v1/docs/lucene-search-syntax",
  },
];

export function LuceneHelperInfo() {
  return <HelperInfo examples={examples} links={links} label={messages.luceneHelper.title} />;
}
