import { useCallback, useMemo } from "react";
import constate from "constate";
import { useCurrentUser } from "modules/users/apiHooks";
import { getCurrentTimezone } from "libs/timezone/timezoneUtils";
import { allTimezonesList } from "libs/timezone/timezonesList";
import { useTimezoneSettings, updateTimezoneSettings } from "./timezoneApiHooks";

function useTimezoneStateInternal() {
  const user = useCurrentUser();
  const userID = user?.data?.id;
  const timezoneSetting = useTimezoneSettings({ userID });
  const isTimezoneReady = timezoneSetting.isFetchedAfterMount;
  const timezoneSettingsCode = timezoneSetting.data?.tzCode;
  const timezone = timezoneSettingsCode || getCurrentTimezone();

  const timezoneUtc = useMemo(() => {
    const tz = allTimezonesList.find((tz) => tz.tzCode === timezone);

    return `GMT ${tz!.utc}`;
  }, [timezone]);

  const updateTimezone = useCallback(
    async (updatedTimezoneCode: string) => {
      if (!allTimezonesList.find((tz) => tz.tzCode === updatedTimezoneCode)) {
        throw new Error("invalid timezone code");
      }
      await updateTimezoneSettings(userID!, updatedTimezoneCode);
      timezoneSetting.refetch();
    },
    [userID, timezoneSetting]
  );

  const removeTimezone = useCallback(async () => {
    await updateTimezoneSettings(userID!, null);
    timezoneSetting.refetch();
  }, [userID, timezoneSetting]);

  return {
    timezone,
    updateTimezone,
    removeTimezone,
    isTimezoneReady,
    timezoneUtc,
    isLocalTimezone: !timezoneSettingsCode,
  };
}

export const [TimezoneProvider, useTimezoneState] = constate(useTimezoneStateInternal);
