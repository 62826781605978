import { AxiosError } from "axios";
import { INotification } from "./state";

export const apiErrorNotification = (
  error: AxiosError,
  props?: INotification["props"]
): Partial<INotification> => {
  if (!error.response) {
    return {
      message: "Something went wrong. Please try again later",
    };
  }

  if (!error.response.data) {
    return {
      message: error.message,
    };
  }

  return {
    message: error.message,
    description: error.response.data.error,
    props: {
      autoHideDuration: 5000,
      ...props,
    },
  };
};

export const graphApiErrorNotification = (
  error: AxiosError,
  props?: INotification["props"]
): Partial<INotification> => {
  return apiErrorNotification(error, {
    autoHideDuration: 5000,
    ...props,
  });
};
