import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";
import * as api from "api/terms";

export function useTermsState() {
  return useTermsStateQuery().data;
}
export const useTermStateQueryKey = () => ["terms"];
export function useTermsStateQuery() {
  return useSuspenseQuery({
    queryKey: useTermStateQueryKey(),
    queryFn: () => api.fetchTermsAndConditions(),
  });
}

export function preloadTermsState(queryClient: QueryClient) {
  return queryClient.prefetchQuery({
    queryKey: useTermStateQueryKey(),
    queryFn: () => {
      return api.fetchTermsAndConditions();
    },
  });
}
