import React, { CSSProperties, forwardRef } from "react";
import { ForwardRefComponent } from "@radix-ui/react-polymorphic";
import { css } from "ui/css";

const ZStackStyles = css({
  "display": "grid",
  "justifyItems": "center",
  "placeItems": "center",
  "width": "fit-content",
  "> *": {
    gridArea: "1/1/1/1",
    zIndex: 1,
  },
});

type ZStackComponent = ForwardRefComponent<
  "div",
  {
    alignX?: CSSProperties["placeItems"];
    alignY?: CSSProperties["alignItems"];
    fullWidth?: boolean;
  }
>;

export const ZStack: ZStackComponent = forwardRef(function ZStack(
  { as: Comp = "div", className, alignX, alignY, fullWidth, style, ...props },
  ref
) {
  return (
    <Comp
      ref={ref}
      data-component={"ZStack"}
      style={{
        ...style,
        width: fullWidth ? "100%" : undefined,
        alignItems: alignY,
        justifyItems: alignX,
      }}
      className={[ZStackStyles, className].join(" ")}
      {...props}
    />
  );
});
