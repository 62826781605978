import { useMemo } from "react";
import { filter, reject } from "lodash";
import { usePermissions } from "shared/permissions";
import { Permission } from "api/types";
import { FIELDS_ALWAYS_IN_BEGINNING_ORDERED } from "./constants";
import { CHGridColDef, CHGridColDefOptional, ListViewTableColumn } from "./types";

/**
 * @deprecated Do not use this function. It is to be deprecated after all tables are migrated to datagrid.
 * Implementations should use CHGridColDef[] definitions instead of ListViewTableColumn[].
 */
export const mapOldGridColumnsToDataGrid = (
  columns: ListViewTableColumn[],
  options: CHGridColDefOptional = {}
): CHGridColDef[] => {
  return columns.map(
    (column: ListViewTableColumn): CHGridColDef & { defaultVisible?: boolean } => ({
      field: column.id,
      headerName: typeof column.Header === "string" ? column.Header : "",
      type: column.type || "string",
      renderHeader: typeof column.Header === "function" ? column.Header : undefined,
      getHeaderProps: column.getHeaderProps,
      defaultVisible: column.defaultVisible,
      headerClassName: column.thClassName,
      renderCell:
        typeof column.Cell === "function"
          ? (cell: any) => column.Cell?.({ original: cell.row })
          : undefined,
      filterable: column.filterable ?? false,
      sortable: column.sortable ?? false,
      resizable: column.resizable ?? true,
      minWidth: column.minWidth,
      ...options,
    })
  );
};

export function moveElementInArray<T>(arr: T[], element: T, newIndex: number) {
  const copy = [...arr];
  const currentIndex = copy.indexOf(element);
  copy.splice(newIndex, 0, copy.splice(currentIndex, 1)[0]);
  return copy;
}

interface UsePermissionFilteredColumnsProps<ColumnType> {
  columns: ColumnType[];
  fieldToPermissionMap: Record<string, Permission>;
  getFieldId?: (c: ColumnType) => string;
}

/**
 * Removes those columns that do not satisfy the given permission map.
 * The map is given in form of columnId -> permissionName.
 */
export function usePermissionFilteredColumns<ColumnType>({
  columns,
  fieldToPermissionMap,
  getFieldId = (c: any) => c.field,
}: UsePermissionFilteredColumnsProps<ColumnType>): ColumnType[] {
  const { permissionsSet } = usePermissions();

  return useMemo(
    () =>
      reject(
        columns,
        (c) =>
          fieldToPermissionMap[getFieldId(c)] &&
          !permissionsSet.has(fieldToPermissionMap[getFieldId(c)])
      ),
    [permissionsSet, columns]
  );
}

/**
 * Returns the columns that do not satisfy the given permission map.
 * The map is given in form of columnId -> permissionName.
 */
export function useColumnsUserHasNoAccessTo<ColumnType>({
  columns,
  fieldToPermissionMap,
  getFieldId = (c: any) => c.field,
}: UsePermissionFilteredColumnsProps<ColumnType>): ColumnType[] {
  const { permissionsSet } = usePermissions();

  return useMemo(
    () =>
      filter(
        columns,
        (c) =>
          fieldToPermissionMap[getFieldId(c)] &&
          !permissionsSet.has(fieldToPermissionMap[getFieldId(c)])
      ),
    [permissionsSet]
  );
}

export function columnOrderWithoutFixed(columnOrder: string[]) {
  const excludedFromSortFields = new Set(FIELDS_ALWAYS_IN_BEGINNING_ORDERED);

  return [
    ...FIELDS_ALWAYS_IN_BEGINNING_ORDERED,
    ...(columnOrder.filter((id) => !excludedFromSortFields.has(id)) ?? []),
  ];
}
