export function storeWhen(store: any, compare: any, fn: any) {
  const getState = store.getState;
  let initial = getState();
  fn(getState(), getState());
  store.subscribe(() => {
    const current = getState();
    if (compare(initial, current)) {
      fn(initial, current);
    }
    initial = current;
  });
}
