import { types } from "api/gen";

export interface UserGroup {
  id: string;
  name: string;
}

export interface DirectoryUser extends Omit<types.DirectoryUser, "emails"> {
  emails?: string[];
}

export enum DirectoryState {
  Linked = "linked",
  Unlinked = "unlinked",
  Validating = "validating",
  Deleting = "deleting",
  InvalidCredentials = "invalid_credentials",
}

export interface Directory extends Omit<types.FullDirectory, "status" | "state" | "type"> {
  created_at: string;
  domain: string;
  id: string;
  loadingStatus: "loaded" | "in_progress" | "not_started";
  name: string;
  organization_id: string;
  state: DirectoryState;
  type: string;
  updated_at: string;
  portal_link: string;
}
