export type TimeData = {
  range: "second" | "minute" | "hour" | "day" | "week" | "month" | "year";
  value: number;
};

const MINUTE_SECONDS = 60;
const HOUR_SECONDS = MINUTE_SECONDS * 60;
const DAY_SECONDS = HOUR_SECONDS * 24;
export const timeRanges: Record<TimeData["range"], TimeData> = {
  second: {
    range: "second",
    value: 1,
  },
  minute: {
    range: "minute",
    value: MINUTE_SECONDS,
  },
  hour: {
    range: "hour",
    value: HOUR_SECONDS,
  },
  day: {
    range: "day",
    value: DAY_SECONDS,
  },
  week: {
    range: "week",
    value: DAY_SECONDS * 7,
  },
  month: {
    range: "month",
    value: DAY_SECONDS * 30,
  },
  year: {
    range: "year",
    value: DAY_SECONDS * 365,
  },
};

export function fromSecondsToTimeData(
  seconds: number,
  options: { min?: TimeData["range"]; max?: TimeData["range"]; precision?: number } = {}
): TimeData {
  const { min = "minute", max = "year", precision = 0 } = options;
  const k = Math.pow(10, precision);
  const toTimeData = (range: TimeData) => ({
    range: range.range,
    value: Math.floor((seconds / range.value) * k) / k,
  });

  const availableRanges: Array<TimeData["range"]> = [
    "second",
    "minute",
    "hour",
    "day",
    "week",
    "month",
    "year",
  ];
  const ranges = availableRanges
    .slice(availableRanges.indexOf(min), availableRanges.indexOf(max) + 1)
    .reverse();

  for (const range of ranges) {
    if (seconds >= timeRanges[range].value) {
      return toTimeData(timeRanges[range]);
    }
  }

  return toTimeData(timeRanges[min]);
}

export function fromTimeDataToSeconds(time: TimeData): number {
  const timeRange = timeRanges[time.range];

  return time.value * timeRange.value;
}
