import { apiauth } from "api/gen";

export enum ScopeEntity {
  Datasets = "datasets",
  UserGroups = "user_groups",
}

export enum AccessType {
  Include = "include",
  Exclude = "exclude",
  FullAccess = "full",
}

export type ScopeDefinition = Record<
  ScopeEntity,
  {
    ids?: string[];
    access_type: AccessType;
  }
>;

export type ApiKey = apiauth.ApiKeyDto & {
  status: ApiKeyStatus;
};

export enum ApiKeyStatus {
  Active = "Active",
  Expired = "Expired",
}

export enum UserKind {
  User = "user",
  ApiKey = "api_key",
}
