import React from "react";
import { ErrorPageTemplate } from "../templates/ErrorPageTemplate";
import { messages } from "../messages";
import { usePageErrorLog } from "./usePageErrorLog";

export const AccessDeniedPage = () => {
  usePageErrorLog();
  return (
    <ErrorPageTemplate
      title="403"
      subTitle={
        <>
          {messages.accessDenied} <br />
          {messages.accessDeniedPageSubTitle}
        </>
      }
    />
  );
};
