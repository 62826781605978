import React, { ReactNode } from "react";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

export interface ConditionsDndProviderProps {
  children: ReactNode;
  conditionsIds: string[];
}

export const ConditionsDndProvider = ({ children, conditionsIds }: ConditionsDndProviderProps) => {
  return (
    <SortableContext items={conditionsIds} strategy={verticalListSortingStrategy}>
      {children}
    </SortableContext>
  );
};
