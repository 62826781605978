import MuiAccordion from "@mui/material/Accordion";
import { styled } from "ui/css";

export const Accordion = styled(MuiAccordion, {
  "margin": "0 0 2px 0",
  "boxShadow": "none",
  "&::before": {
    display: "none",
  },
  "&.Mui-expanded": {
    marginTop: 0,
  },
});
