import { useEffect, useRef } from "react";

const useInterval = (callback: any, delay: number) => {
  const id = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    if (id.current) {
      clearInterval(id.current);
      id.current = null;
    }
    if (delay !== null) {
      id.current = setInterval(callback, delay);
    }

    return () => {
      id.current && clearInterval(id.current);
    };
  }, [callback, delay]);
};

export default useInterval;
