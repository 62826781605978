import { useMemo, useRef } from "react";
import constate from "constate";
import { Column } from "./types";
import { useResize } from "./useResize";
import { toGridTemplateColumns } from "./resizeServices";

const emptyFn = () => {};

export const [TableStateProvider, useTableState, useResizeHandlers] = constate(
  ({ columns }: { columns: Column[] }) => {
    const tableRef = useRef<HTMLTableElement | null>(null);
    const isResizable = useMemo(() => columns.some((c) => c.resizable), [columns]);
    const { sizes, onMouseDown, isResizeActive } = isResizable
      ? useResize(columns, tableRef)
      : { sizes: null, onMouseDown: emptyFn, isResizeActive: false };

    const gridTemplateColumns = useMemo(() => {
      return sizes ? toGridTemplateColumns(sizes) : toGridTemplateColumns(columns);
    }, [sizes]);

    return { gridTemplateColumns, onMouseDown, tableRef, isResizeActive };
  },
  ({ gridTemplateColumns, tableRef, isResizeActive }) => {
    return { gridTemplateColumns, tableRef, isResizeActive };
  },
  ({ onMouseDown }) => {
    return onMouseDown;
  }
);
