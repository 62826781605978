import React, { useLayoutEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Box, Button, Dialog, FormHelperText, Link, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Close } from "@mui/icons-material";
import { notification } from "modules/notification";
import { useModal } from "libs/hooks";
import { HStack, Stack } from "libs/layouts";
import { CardActions, LoadingButton } from "ui";
import { useAuthService, useCaptcha } from "modules/auth/loginHooks";
import { messages } from "../messages";

function SingleFieldForm({ onSubmit, children }: any) {
  return (
    <HStack
      as="form"
      space={2}
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmit({ form: e.target });
      }}
    >
      {children}
      <div id="recaptcha" />
    </HStack>
  );
}
function ErrorMessage({ error }: any) {
  return error && <FormHelperText error>{(error as any)?.message}</FormHelperText>;
}

export interface SetupPhoneMFAProps {
  onDone: () => void;
  onClose?: () => void;
}

export function SetupPhoneMFA(props: SetupPhoneMFAProps) {
  const [verificationId, setVerificationId] = useState<string | null>("");
  const [phone, setPhone] = useState<string | null>(null);
  const { service, error } = useAuthService();
  const [captchaNode, verifierInstance] = useCaptcha([verificationId]);

  const { mutate: resendCode, error: resendSubmitError } = useMutation({
    mutationFn: async () => {
      const res = await service!.setupMFASendCode(phone!, verifierInstance.current);
      setVerificationId(res!);
    },
  });

  const {
    mutate: sendCode,
    error: submitError,
    isPending,
  } = useMutation({
    mutationFn: async ({ form }: { id: string; form: HTMLFormElement }) => {
      const phone = (form.elements as Record<string, any>)["phone"][0].value;
      const res = await service!.setupMFASendCode(phone, verifierInstance.current);
      setPhone(phone);
      setVerificationId(res!);
    },
  });

  const {
    mutate: verifyCode,
    error: verificationError,
    isPending: isVerifyLoading,
  } = useMutation({
    mutationFn: async ({ form }: { form: HTMLFormElement }) => {
      const code = (form.elements as Record<string, any>)["verificationCode"].value;
      await service!.setupMFAVerify(verificationId, code);
      props.onDone();
    },
  });

  if (verificationId) {
    return (
      <SingleFieldForm onSubmit={verifyCode} key="verification">
        <Stack space={1} style={{ width: "100%" }}>
          <Typography variant="body2" color="textSecondary">
            {messages.setupSmsConfirm(phone)}
            <Link onClick={() => setVerificationId(null)}>{messages.setupChangePhoneNumber}</Link>
          </Typography>
          <TextField
            variant="standard"
            name="verificationCode"
            required
            type="text"
            label="Enter your verification code"
          />
          {captchaNode}
          <ErrorMessage error={error || verificationError || resendSubmitError} />
          <CardActions
            secondary={
              <Button id="phone" color="primary" onClick={() => resendCode()}>
                Send again
              </Button>
            }
          >
            {props.onClose && (
              <Button color="primary" onClick={props.onClose}>
                Cancel
              </Button>
            )}
            <LoadingButton
              isLoading={isVerifyLoading}
              color="primary"
              variant="contained"
              type="submit"
            >
              Submit code
            </LoadingButton>
          </CardActions>
        </Stack>
      </SingleFieldForm>
    );
  }
  return (
    <SingleFieldForm onSubmit={sendCode} key="setup">
      <Stack space={1}>
        <Typography variant="body2" color="textSecondary">
          {messages.setupSms}
        </Typography>
        <TextField
          variant="standard"
          style={{ width: 300 }}
          placeholder="+1(123) 456 7890"
          required
          name="phone"
          type="tel"
          label="Enter your phone"
        />
        <ErrorMessage error={error || submitError} />
        {captchaNode}
        <CardActions>
          {props.onClose && (
            <Button color="primary" onClick={props.onClose}>
              Cancel
            </Button>
          )}
          <LoadingButton
            isLoading={isPending}
            color="primary"
            variant="contained"
            id="phone"
            type="submit"
          >
            Send code
          </LoadingButton>
        </CardActions>
      </Stack>
    </SingleFieldForm>
  );
}

export function useSetupPhoneMFA({ onDone }: any) {
  const [hasMFA, setMFA] = useState(false);
  const { service } = useAuthService();
  useLayoutEffect(() => {
    if (service) {
      return service.onUserChange((_, user) => {
        if (user) {
          setMFA(service.hasMFA(user));
        }
      });
    }
  }, [service]);

  function disableMFA() {
    return service!
      .disableMFA()!
      .then(() => {
        setMFA(false);
        notification.warning({
          message: "MFA has been disabled.",
        });
      })
      .catch((e) => {
        notification.error({
          message: e?.message,
        });
        throw e;
      });
  }
  const modal = useModal((props) => (
    <Dialog {...props} style={{ margin: 20 }}>
      <Stack as={Box} padding={3} space={1} style={{ width: 600 }}>
        <HStack>
          <Typography variant="h6" style={{ textTransform: "capitalize" }}>
            {messages.setupSmsAuth}
          </Typography>
          <HStack.Spacer />
          <Close onClick={props.onClose} />
        </HStack>
        <SetupPhoneMFA
          onClose={props.onClose}
          onDone={() => {
            props.onClose();
            onDone();
            setMFA(true);
          }}
        />
      </Stack>
    </Dialog>
  ));
  return [modal[0], { ...modal[1], hasMFA, isComputing: !service, disableMFA }] as const;
}
