import { useState, useMemo, useEffect } from "react";

export interface IPaginatorHandlers {
  handlePageSize: (perPage: number, page: number) => void;
  handlePage: (page: number) => void;
  reset: () => void;
}
export interface IPaginatorState {
  perPage: number;
  page: number;
}
export type IPaginatorResult = IPaginatorState & IPaginatorHandlers;

export interface IPaginatorProps {
  page?: number;
  perPage?: number;
  render?: (options: IPaginatorState & IPaginatorHandlers) => JSX.Element;
}

export function usePagination({ page = 0, perPage = 50 }: IPaginatorProps): IPaginatorResult {
  const [pageValue, updatePage] = useState(page);
  const [pageSizeValue, updatePageSize] = useState(perPage);
  useEffect(() => {
    updatePageSize(perPage);
  }, [perPage]);
  const handlers = useMemo(
    () => ({
      handlePageSize(perPage: number, page: number) {
        updatePage(page);
        updatePageSize(perPage);
      },
      handlePage: updatePage,
      reset() {
        updatePage(0);
      },
    }),
    [perPage]
  );
  return { ...handlers, perPage: pageSizeValue, page: pageValue };
}
