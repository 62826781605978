import { useEffect, useState } from "react";
import { throttle } from "lodash";

export function useIsScrolled(el: HTMLElement | null, throttleMs = 100): boolean {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (!el) {
      return;
    }

    let prevIsScrolled = isScrolled;
    const checkScrollPosition = throttle(() => {
      const currentIsScrolled = el.scrollTop > 0;
      if (currentIsScrolled !== prevIsScrolled) {
        prevIsScrolled = currentIsScrolled;
        setIsScrolled(currentIsScrolled);
      }
    }, throttleMs);

    checkScrollPosition();
    el.addEventListener("scroll", checkScrollPosition);

    return () => {
      el.removeEventListener("scroll", checkScrollPosition);
    };
  }, [el]);

  return isScrolled;
}
