import { createTypeEnum } from "shared/dashboard";

export const widgetItemsCount = 1000;
export const AllDatasets = "risky_all_datasets_count";
export const AllCategories = "all_categories";
export const AllUserGroups = "all_user_groups";

export const AllCategoriesLabel = "All policies";
export const AllDatasetsLabel = "All datasets";
export const AllUsersLabel = "All";
export const AllStatusesLabel = "All";

export const predefinedDatasetsTitle = {
  [AllDatasets]: "All datasets",
};

export const chartHeaderHeight = 60;
export const predefinedDatasets = {
  [AllDatasets]: {
    id: AllDatasets,
    name: predefinedDatasetsTitle[AllDatasets],
  },
};

export const uncategorizedCategory = "Uncategorized";

/**
 * checks if id is some internal marker for states like edit or new
 * @param id - id candidate to check
 */
export function isInternalId(id: string): boolean {
  return id === createTypeEnum.new || id === createTypeEnum.search || id === uncategorizedCategory;
}
