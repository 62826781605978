import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "ui/css";
import { colors, space } from "ui/vars";

export const AccordionDetails = styled(MuiAccordionDetails, {
  "background": colors.light100,
  "padding": `${space[2]} ${space[3]}`,
  "borderRadius": 3,
  "marginTop": 2,
  "&:hover": {
    background: colors.light200,
    cursor: "pointer",
  },
});
