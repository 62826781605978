import React, { useState, useLayoutEffect, useRef, useMemo } from "react";
import { listViewSelectors } from "shared/testConstants";
import classnames from "classnames";
import { get } from "lodash";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { VariantProps } from "@stitches/react";
import { Typography } from "@mui/material";
import { css, styled } from "ui/css";
import { theme, Loader } from "ui";
import { CopyTextToClipboard } from "ui/atoms/CopyTextToClipboard";
import { Column, TableBodyProps } from "./types";
import { TableStateProvider, useTableState, useResizeHandlers } from "./state";
import { DefaultThContent } from "./ThContent";
import { TableLoader } from "./TableLoader";

const expandColumnPadding = 24;

export const CELL_BLANK = undefined; // render Td
export const CELL_EMPTY = Symbol("CELL_EMPTY"); // Dont render Td (useful when cell is merged vertically or horizontally, refer to getColSpan/getRawSpan)

const loadingClassName = css({
  td: { opacity: 0.6, filter: "blur(1px)", userSelect: "none" },
});

// to fix selection headers during resizing in safari
const resizeActiveClassName = css({
  "th,td": { userSelect: "none" },
});

const resizerClassName = css({
  "position": "absolute",
  "left": "0px",
  "top": "0",
  "bottom": "0",
  "userSelect": "none",
  "cursor": "col-resize",
  "width": "5px",
  "&:hover": { borderLeft: `2px solid ${theme.palette.divider} !important` },
});

const stripedClassName = css({
  "& > td": {
    background: theme.secondaryBackground,
  },
});

const stripedCellClassName = css({
  background: theme.secondaryBackground,
});

const borderBottomClassName = css({
  borderBottom: `1px solid ${theme.palette.divider}`,
});

const highlightOnHoverClassName = css({
  "&:hover": {
    background: "#e5e5e5",
  },
});
const highlightClassName = css({
  background: "#e5e5e5",
});

const borderNone = css({
  borderLeft: "none",
});

export const TableInner = styled("table", {
  "display": "grid",
  "width": "100%",
  "flexGrow": 1,
  "overflowY": "auto",
  "overflowX": "auto",
  "gridAutoRows": "min-content",
  '&[data-borderless="true"]': { borderTop: "none" },
  "> tbody": {
    "display": "contents",
    "gridTemplateColumns": "inherit",
    "> tr": {
      "display": "contents",
      "&.row": {
        "&:hover > td": { background: "#e5e5e5" },
        "&:last-child td": { borderBottom: `1px solid ${theme.palette.divider}` },
      },
    },
  },
  '[data-type="checkbox"]': { borderRight: "none" },
  "> thead": {
    "display": "contents",
    "borderLeft": `1px solid ${theme.palette.divider}`,
    "> tr": { display: "contents" },
  },
  "variants": {
    borders: {
      full: {
        border: `1px solid ${theme.palette.divider}`,
      },
      vertical: {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      none: { borderBottom: "none" },
    },
  },
  "defaultVariants": {
    borders: "full",
  },
});

const Th = styled("th", {
  "position": "sticky",
  "top": "0",
  "background": "white",
  "zIndex": 1,
  "whiteSpace": "nowrap",
  "padding": "15px",
  "lineHeight": "normal",
  "letterSpacing": "normal",
  "textAlign": "left",
  "display": "flex",
  "alignItems": "center",
  "borderLeft": `1px solid ${theme.palette.divider}`,
  "borderBottom": `1px solid ${theme.palette.divider}`,
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "&:last-child": { paddingRight: "12px" },
  "&:first-child": {
    paddingLeft: "20px",
    borderLeft: "none",
    [`.${resizerClassName().className}`]: { display: "none" },
  },
  ":hover": {
    [`.${resizerClassName().className}`]: {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
  },
});

export const Td = styled("td", {
  "whiteSpace": "nowrap",
  "position": "relative",
  "padding": "5px 15px 5px 15px",
  "letterSpacing": "normal",
  "display": "flex",
  "alignItems": "center",
  "minHeight": "48px",
  "&:first-child": { paddingLeft: "20px", borderRight: "none", borderLeft: "none" },
  "&:last-child": { borderRight: "none" },
  "borderLeft": `1px solid ${theme.palette.divider}`,
  "borderBottom": `1px solid ${theme.secondaryBackground}`,
});

const FullCell = styled("td", {
  gridColumn: "1 / col-end",
  padding: "0",
});

const LoaderContainer = styled("div", {
  position: "absolute",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
  zIndex: 5,
});

const Wrapper = styled("div", {
  position: "relative",
  display: "flex",
  overflow: "hidden",
  flexGrow: 1,
});

const Resizer = ({ onMouseDown }: any) => {
  return <div onMouseDown={onMouseDown} className={resizerClassName().className} />;
};

const HeaderCell = ({
  children,
  column,
  resizable,
  ...props
}: {
  column: Column;
  children: any;
  resizable: any;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const onMouseDown = useResizeHandlers();

  return (
    <Th {...props} data-id={column.id} className={classnames(props.className)}>
      {children} {resizable && <Resizer onMouseDown={onMouseDown} />}
    </Th>
  );
};

function CellRenderer<RenderProps extends object>({
  children,
  CellComponent = React.Fragment as React.ComponentType<React.PropsWithChildren<RenderProps>>,
  props,
  index,
}: {
  children: any;
  CellComponent?: React.ComponentType<React.PropsWithChildren<RenderProps>>;
  props?: RenderProps;
  index: number;
}) {
  return (
    <CopyTextToClipboard
      sx={{
        width: "100%",
      }}
    >
      {typeof children === "function" ? (
        children(props as RenderProps, index)
      ) : (
        <CellComponent {...(props as RenderProps)}>{children}</CellComponent>
      )}
    </CopyTextToClipboard>
  );
}

export function FullRow({ children, ...props }: any) {
  return (
    <tr className="full-row">
      <FullCell {...props}>{children}</FullCell>
    </tr>
  );
}
function ExpandRow({ expanded, ...props }: any) {
  return (
    <KeyboardArrowRight
      {...props}
      fontSize="default"
      cursor="pointer"
      data-testid={expanded ? listViewSelectors.CollapseBtn : listViewSelectors.ExpandBtn}
      style={{
        position: "absolute",
        left: 1,
        transition: "transform .2s ease-in",
        transform: expanded ? "translateY(-50%) rotate(90deg)" : "translateY(-50%)",
        top: "50%",
      }}
    />
  );
}

function TableContainer({ children, className, bordered, borders }: any) {
  const { gridTemplateColumns, tableRef, isResizeActive } = useTableState();
  return (
    <TableInner
      data-borderless={bordered ? "false" : "true"}
      className={classnames(className, isResizeActive && resizeActiveClassName().className)}
      style={{ gridTemplateColumns }}
      ref={tableRef}
      borders={borders as VariantProps<typeof TableInner>["borders"]}
    >
      {children}
    </TableInner>
  );
}

export function TableBody<D>({
  columns,
  renderSubComponent,
  expand = {},
  expandPosIndex = 0,
  data,
  children,
  loading,
  getId,
  striped,
  onSortChange,
  sort,
  theadProps,
  classes = {},
  ThComponent = DefaultThContent,
  noData,
  infiniteScrollRef,
  infiniteScrollMargin,
  loader = <Loader loading />,
  bordered = true,
  borderBottomCell = false,
  highlightCell = null,
  borders = "full",
}: TableBodyProps<D>) {
  const isExpandable = !!renderSubComponent;
  const RowComponent = isExpandable ? ExpandableRow : Row;
  const dataRef = useRef(data);
  const itemWithRefIndex = useMemo(() => {
    if (!dataRef.current?.length || !infiniteScrollMargin) {
      return false;
    }
    return Math.round(dataRef.current?.length * (1 - infiniteScrollMargin));
  }, [dataRef.current?.length]);

  if (!loading) {
    dataRef.current = data;
  }

  if (!columns) {
    return null;
  }

  const hasDataLength = !!dataRef.current?.length;

  return (
    <TableStateProvider columns={columns}>
      <Wrapper>
        {!loading && Array.isArray(data) && !hasDataLength && noData}
        {loading && hasDataLength && <LoaderContainer>{loader}</LoaderContainer>}
        {loading && !hasDataLength && (
          <LoaderContainer style={{ top: 55 }}>
            <TableLoader />
          </LoaderContainer>
        )}
        <TableContainer bordered={bordered} borders={borders} className={classes.table}>
          <thead {...(theadProps || {})}>
            <tr>
              {columns.map((column, i) => {
                const headerProps = column.getHeaderProps?.(column);
                const toggleSort = () => {
                  onSortChange?.({
                    sort_by: column.id,
                    sort_desc: sort?.sort_by === column.id ? !sort?.sort_desc : false,
                  });
                };
                return (
                  <HeaderCell
                    key={column.id + column.Header || i}
                    style={{
                      ...column.thStyle,
                      ...(expandPosIndex === i && isExpandable
                        ? { paddingLeft: expandColumnPadding }
                        : {}),
                    }}
                    resizable={columns[i - 1]?.resizable && column.resizable}
                    className={classnames(column.thClassName, classes.th)}
                    column={column}
                    data-type={column.type ?? "cell"}
                  >
                    <CellRenderer
                      CellComponent={ThComponent}
                      index={i}
                      props={{
                        column,
                        sort,
                        toggleSort,
                        ...headerProps,
                      }}
                    >
                      {column.Header}
                    </CellRenderer>
                  </HeaderCell>
                );
              })}
            </tr>
          </thead>
          <tbody className={classnames(loading && loadingClassName().className)}>
            {!!dataRef.current?.length &&
              dataRef.current.map((item, i) => {
                return (
                  <RowComponent
                    infiniteScrollRef={itemWithRefIndex === i ? infiniteScrollRef : undefined}
                    index={i}
                    className={striped && i % 2 === 0 ? stripedClassName().className : undefined}
                    borderBottom={borderBottomCell}
                    tdClassName={classes.td!}
                    hidden={loading!}
                    columns={columns}
                    key={getId?.(item) || i}
                    item={item}
                    expanded={expand[i]}
                    subContent={isExpandable ? renderSubComponent(item, i) : undefined}
                    expandPosIndex={expandPosIndex}
                    highlightCell={highlightCell!}
                  />
                );
              })}
          </tbody>
          <tbody>
            {React.Children.map(children, (el) => {
              return <FullRow>{el}</FullRow>;
            })}
          </tbody>
        </TableContainer>
      </Wrapper>
    </TableStateProvider>
  );
}

type Row<Item> = {
  item: Item;
  className?: string;
  columns: Array<Column<Item>>;
  tdClassName: string;
  index: number;
  borderBottom: boolean;
  highlightCell: string;
};

export function Row<Item>({
  className,
  columns,
  item,
  index,
  tdClassName,
  borderBottom,
  highlightCell,
  infiniteScrollRef,
}: Row<Item> & { infiniteScrollRef?: (item: any) => void }) {
  return (
    <tr className={className}>
      {columns.map((column, k) => {
        const value = column?.accessor ? column.accessor(item) : item[column.id as keyof Item];
        const ref = (index: number) =>
          index === Math.floor(columns?.length / 2) ? infiniteScrollRef : undefined;
        if (value === CELL_EMPTY) {
          return null;
        }
        if (value === CELL_BLANK && !column.supportEmptyValue) {
          return (
            <Td
              // ref is placed on the 5th column. Next task is to reimplement the table to MUI X.
              ref={ref(k)}
              key={k}
              data-column={column.id}
              data-type={column.type}
              className={classnames(borderNone().className, column.className)}
            />
          );
        }

        return (
          <Td
            key={column.id || k}
            // ref is placed on the 5th column. Next task is to reimplement the table to MUI X.
            ref={ref(k)}
            data-id={column.id}
            data-type={column.type}
            className={classnames(
              column.className,
              tdClassName,
              (column.borderBottom || borderBottom) && borderBottomClassName().className,
              column.striped && index % 2 === 0 ? stripedCellClassName().className : null,
              column.highlightOnHover && highlightOnHoverClassName().className,
              column.getItemId?.(value) === highlightCell && highlightClassName().className
            )}
            style={{
              ...(typeof column.tdStyle === "function" ? column.tdStyle(item) : column.tdStyle),
              gridRow: `span ${column.getRowSpan?.(item)}`,
              gridColumn: `span ${column.getColSpan?.(item)}`,
            }}
          >
            {column.Cell ? (
              <CellRenderer index={k} props={{ original: item, value: value }}>
                {column.Cell}
              </CellRenderer>
            ) : (
              <Typography
                title={column.truncateText ? value : undefined}
                noWrap={column.truncateText}
              >
                {value}
              </Typography>
            )}
          </Td>
        );
      })}
    </tr>
  );
}

function ExpandableRow<Item>({
  subContent,
  expanded,
  columns,
  item,
  hidden,
  className,
  expandPosIndex,
  tdClassName,
  infiniteScrollRef,
}: Row<Item> & {
  hidden: boolean;
  expandPosIndex: number;
  expanded: boolean;
  infiniteScrollRef?: (item: any) => void;
  subContent: JSX.Element | undefined;
}) {
  const [isExpanded, update] = useState(expanded);
  const expandedContent = isExpanded && subContent;
  const styles = hidden ? { display: "none" } : {};
  function toggle() {
    update((s) => !s);
  }
  useLayoutEffect(() => {
    update(expanded);
  }, [expanded]);
  return (
    <React.Fragment key={(item as any).id}>
      <tr className={className + " row"}>
        {columns.map((column, k) => {
          const value = column?.accessor ? column.accessor(item) : get(item, column.id);
          return (
            <Td
              // ref is placed on the 5th column. Next task is to reimplement the table to MUI X.
              ref={k === 5 ? infiniteScrollRef : undefined}
              data-type={column.type}
              data-column={column.id}
              key={k}
              className={classnames(tdClassName, column.className)}
              style={{
                ...(typeof column.tdStyle === "function" ? column.tdStyle(item) : column.tdStyle),
                ...(expandPosIndex === k ? { paddingLeft: expandColumnPadding } : {}),
              }}
            >
              {k === expandPosIndex && <ExpandRow expanded={isExpanded} onClick={toggle} />}
              {column.Cell ? (
                <CellRenderer index={k} props={{ original: item, value: value }}>
                  {column.Cell}
                </CellRenderer>
              ) : (
                <CellRenderer index={k} CellComponent={Td}>
                  {value}
                </CellRenderer>
              )}
            </Td>
          );
        })}
      </tr>
      {isExpanded && (
        <FullRow key={"expand-content"} style={styles}>
          {expandedContent}
        </FullRow>
      )}
    </React.Fragment>
  );
}
