import { messages } from "./messages";

export const validatePasswordComplexity = (password: string = "") => {
  const errors: string[] = [];
  if (!/\d/.test(password)) {
    errors.push(messages.validation.containNum);
  }
  if (!/\p{Ll}/u.test(password)) {
    errors.push(messages.validation.lowLetter);
  }
  if (!/\p{Lu}/u.test(password)) {
    errors.push(messages.validation.upperLetter);
  }
  if (!/[#?!@$%^&*\-+]/.test(password)) {
    errors.push(messages.validation.specialLetter);
  }
  if (password.length > 50) {
    errors.push(messages.validation.maxLength);
  } else if (new Blob([password]).size > 50) {
    errors.push(messages.validation.maxLengthByte);
  }
  if (password.length < 8) {
    errors.push(messages.validation.minLength);
  }

  return errors.length > 0 ? errors.join(". ") + "." : null;
};
