import React from "react";
import { ErrorState } from "ui/atoms/StateScreens";
import { ErrorBoundary } from "ui/atoms/ErrorBoundary";

type Props = {
  children: React.ReactChild | React.ReactChild[];
  networkErrorFallback?: (onRefresh: (() => void) | undefined) => React.ReactElement;
  errorBoundaryFallback?: () => React.ReactElement;
  isNetworkError?: boolean;
  onRefresh?: () => void;
};

export const ErrorWrapper = ({
  children,
  onRefresh,
  isNetworkError = false,
  networkErrorFallback = (onRefresh) => <ErrorState text="Network Error" onRefresh={onRefresh} />,
  errorBoundaryFallback = () => <ErrorState />,
}: Props) => {
  return isNetworkError ? (
    networkErrorFallback(onRefresh)
  ) : (
    <ErrorBoundary fallback={errorBoundaryFallback()}>{children}</ErrorBoundary>
  );
};
