import { types_OperatorEnum } from "api/gen";
import { FullOperatorEnum } from "api/types";
import { makeSelectors } from "libs/testing/makeSelectors";

const MATCHING_LISTS_KEY = "matchingLists";
const MATCHING_LISTS_ALL_KEY = "matchingListsAll";
export const MAX_LIST_NAME_LENGTH = 70;
export const getAllMatchingListsKey = () => [MATCHING_LISTS_ALL_KEY, MATCHING_LISTS_KEY];
export const getMatchingLitsDetailsKey = ({ id }: { id: string }) => [MATCHING_LISTS_KEY, id];

export const NEW_COL_ID = "new";

export const messages = {
  matchingLists: "Matching Lists",
  pageTitle: "LISTS",
  item: "List",
  itemPlural: "Lists",
  save: "Save",
  cancel: "Cancel",
  navigateToPolicy: "Go to Policy",
  navigateToDataset: "Go to Dataset",
  destimationMatcherTooltip: "Destination matchers can be leveraged in Policy definitions.",
  sourceMatcherTooltip: "Source matchers can be leveraged in Policy definitions.",
  destinationTitle: (n: number) => `Destination Matchers (${n})`,
  sourceTitle: (n: number) => `Source Matchers (${n})`,
  addNewList: "+ Add new list",
  addNewMatcher: "+ Add matcher",
  addMatcher: "Add matcher",
  deleteMatcherMessage: (field?: string, operator?: string) =>
    `Are you sure you want to delete ${field} ${operator} matcher?`,
  deleteListMessage: (name?: string) => `Are you sure you want to delete ${name} list ?`,
  defaultListName: "",
  defaultListDescription: "",
  cancelEditConfirmMessage: (name: string) =>
    `Are you sure you want to close ${name}? All unsaved changes will be deleted`,
  emptyTextareaLabel: "Add list items",
  cannotDeleteList:
    "Lists that are in use in Datasets, Policies or Cyberhaven managed lists cannot be deleted",
  noListsTitle: "No lists to display",
  noListsDescription: "Add your first list first",
  listExitsError: "A list with this name already exists",
  listContentIsTooBig: "The list content cannot be displayed because it exceeds the limit. ",
  exportSuccess: "List successfully downloaded",
  updatedItemsSuccess: "List successfully updated",
  exportCofirm: "Are you sure you want to replace existing list? This action cannot be undone",
  cannotEditList:
    "Name and description of this list cannot be edited as it is managed by Cyberhaven. However, you can edit the content of the list",
  cannotEditLisAndItems: "This list cannot be edited because it is managed by Cyberhaven",
};

export const listsSelectors = makeSelectors("lists-matching", {
  addNewButton: "",
  pageTitle: "",
  name: "",
  description: "",
  saveButton: "",
  cancelButton: "",
  importButton: "",
  importButtonTrigger: "",
  itemsTextarea: "",
  itemsError: "",
  navigateItem: "",
  deleteList: "",
  selectList: "",
  grid: "",
  newSidepanel: "",
  editSidepanel: "",
  sidepanelEmptyState: "",
  openListActions: "",
  toggleLists: "",
  tooManyItemsToDisplay: "",
  duplicateList: "",
  usedIn: "",
});

export const listsMatchingOperators = [
  {
    key: types_OperatorEnum.OperatorListIs,
    negate: false,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListStartsWith,
    negate: false,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListEndsWith,
    negate: false,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListContains,
    negate: false,
    selector: "match_any",
  },
  {
    key: types_OperatorEnum.OperatorListIs,
    negate: true,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListStartsWith,
    negate: true,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListEndsWith,
    negate: true,
    selector: "match_any",
  },
  {
    key: FullOperatorEnum.OperatorListContains,
    negate: true,
    selector: "match_any",
  },
];

export const MAX_LIST_ITEMS_COUNT = 1_000_000;
export const MAX_LIST_ITEMS_SIZE_MB = 30;
export const MAX_UI_LIST_ITEMS_COUNT = 1000;
