import { PermissionsProvider } from "shared/permissions";
import React from "react";
import { useAvailableNavigationItems } from "shared/useAvailableNavigationItems";
import { HOME_PAGE_FALLBACK_PATH } from "app/routesPaths";
import { LayoutLoader } from "ui";
import { RedirectWithSearch } from "../helpers";
import { RouteUserCheck } from "../molecules";

function RedirectToHome() {
  const { homeItem, isLoading } = useAvailableNavigationItems();

  if (!isLoading) {
    return <LayoutLoader />;
  }

  return <RedirectWithSearch noThrow from="/" to={homeItem?.path || HOME_PAGE_FALLBACK_PATH} />;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function HomePage() {
  return (
    <PermissionsProvider>
      <RouteUserCheck>
        <RedirectToHome />
      </RouteUserCheck>
    </PermissionsProvider>
  );
}
