import React from "react";
import { WarningTriangleIcon } from "assets/icons";
import { Alert } from "@mui/material";

export function ConditionError({
  children,
  maxWidth = "100%",
}: {
  children: React.ReactNode;
  maxWidth?: string | number;
}) {
  return (
    <Alert icon={<WarningTriangleIcon fontSize="inherit" />} style={{ maxWidth }} severity="error">
      {children}
    </Alert>
  );
}
