import { IconType, LocationEndpointAppsIcon, LocationWebsiteIcon } from "assets/icons";
import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

function getDomainSafe(str: string) {
  try {
    if (str.match(/^(\d+\.?)+$/)) {
      throw new Error("Cannot extract domain from ip address");
    }

    const hasProto = str.includes("http");
    const domain = hasProto ? new URL(str)?.hostname : new URL("https://" + str).hostname;
    const domainParts = domain.split(".");
    if (domainParts.length < 2) {
      throw new Error("url should contain at least two domain levels");
    }
    // strip subdomain
    const twoLevelDomain = [domainParts.at(-2), domainParts.at(-1)].join(".");

    return [twoLevelDomain, domain];
  } catch (e) {
    return [null, null];
  }
}

const formatAppName = (app: string): string => {
  const appName = String(app).toLowerCase();

  if (appName.includes(".")) {
    return appName.split(".").slice(0, -1).join(".");
  }

  return appName;
};

const fetchImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(src);
    };
    img.onerror = reject;
    img.src = src;
  });
};

const fetchImageWithFallback = (src: string, fallbackSrc?: string) => {
  return fetchImage(src).catch((error) => {
    if (fallbackSrc && fallbackSrc !== src) {
      return fetchImage(fallbackSrc);
    }

    throw error;
  });
};

function FavIcon({
  url,
  fallbackUrl,
  id,
  fallbackIcon: FallbackIcon,
  size = 18,
  color,
}: {
  url: string;
  fallbackIcon: IconType;
  id: string | null;
  fallbackUrl?: string;
  color?: string;
  size?: number;
}) {
  const { data, isInitialLoading, isError } = useQuery({
    queryKey: ["images", { id }],
    queryFn: () => fetchImageWithFallback(url, fallbackUrl),
    refetchOnMount: false,
    enabled: !!id,
  });

  if (isInitialLoading || isError || !id) {
    return (
      <FallbackIcon
        color={color ? undefined : "action"}
        htmlColor={color}
        style={{ width: size, height: size }}
      />
    );
  }

  return (
    <img
      src={data as any}
      width={size}
      height={size}
      alt="favicon"
      style={{
        borderRadius: 2,
      }}
    />
  );
}

export function DomainFavIcon({ url, size = 18 }: { url: string; size?: number }) {
  const [domain, fallback] = useMemo(() => getDomainSafe(url), [url]);
  const getIconUrl = (domain: string | null) => `/v1/favicon?size=64&domain=${domain}`;

  return (
    <FavIcon
      url={getIconUrl(domain)}
      fallbackUrl={getIconUrl(fallback)}
      id={domain}
      size={size}
      fallbackIcon={LocationWebsiteIcon}
    />
  );
}

export function AppFavIcon({
  app,
  size = 18,
  color,
}: {
  app: string;
  size?: number;
  color?: string;
}) {
  const appName = useMemo(() => formatAppName(app), [app]);

  return (
    <FavIcon
      url={`/v1/icons?app=${appName}`}
      id={appName}
      size={size}
      fallbackIcon={LocationEndpointAppsIcon}
      color={color}
    />
  );
}
