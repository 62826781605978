import { min, max } from "lodash";

export const getTitleArray = (title: any, highlightSubstring: any, textLimit: any) => {
  const textSubstrIndex = title.toString().toLowerCase().indexOf(highlightSubstring.toLowerCase());
  if (textSubstrIndex !== -1) {
    let start = 0;
    let end = title.length;
    if (title.length > textLimit) {
      const extraSpace = (textLimit - highlightSubstring.length) / 1.5; // < 2 shift highlighted text to the left, > 2 - to the right
      const endIndex = min([
        textSubstrIndex + highlightSubstring.length + extraSpace,
        title.length,
      ]);
      const startIndex = endIndex - textLimit;
      start = max([startIndex, 0])!;
      end = endIndex;
    }

    highlightSubstring = title.substr(textSubstrIndex, highlightSubstring.length);
    return {
      textArray: [
        (start > 0 ? "..." : "") + title.slice(start, textSubstrIndex),
        title.slice(textSubstrIndex + highlightSubstring.length, end) +
          (end < title.length ? "..." : ""),
      ],
      highlightText: highlightSubstring,
    };
  } else {
    return { textArray: [title], highlightText: "" };
  }
};
