/**
 * Checks whether the given value is null or undefined
 *
 * @param value The value to check
 * @returns A boolean stating whether the value is `null` or `undefined`
 */
export function isNullOrUndefined<ValueType>(
  value: ValueType | null | undefined
): value is null | undefined {
  return value === null || value === undefined;
}
