import { useField } from "react-final-form";

type FieldSwitchProps<T extends string | "default"> = {
  name: string;
  cases: Record<Exclude<T, "default">, string[]>;
  children: Record<T, JSX.Element>;
};
export function FieldSwitch<T extends string | "default">({
  name,
  cases,
  children,
}: FieldSwitchProps<T>) {
  const { input } = useField(name, { subscription: { value: true } });
  const inverted = Object.fromEntries(
    Object.entries(cases).flatMap(([key, values]: any) => {
      return values.map((v: any) => [v, key]);
    })
  );
  const matched = inverted[input.value] || "default";
  return (children as any)[matched];
}
