import React, { ComponentProps } from "react";
import { lighten } from "@mui/system";

export enum Severity {
  Informational = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export const NULL_SEVERITY = -1;

export type SeverityItemData = {
  label: string;
  color: string;
  severity: Severity;
  unselectedColor: string;
  isNullItem?: boolean;
};

export const severityMap: Record<Severity | "null_value", SeverityItemData> = {
  [Severity.Informational]: {
    label: "Informational",
    color: "#60CAFA",
    severity: Severity.Informational,
    unselectedColor: lighten("#60CAFA", 0.6),
  },
  [Severity.Low]: {
    label: "Low",
    color: "#F1C21B",
    severity: Severity.Low,
    unselectedColor: lighten("#F1C21B", 0.6),
  },
  [Severity.Medium]: {
    label: "Medium",
    color: "#FF832B",
    severity: Severity.Medium,
    unselectedColor: lighten("#FF832B", 0.6),
  },
  [Severity.High]: {
    label: "High",
    color: "#DA1E28",
    severity: Severity.High,
    unselectedColor: lighten("#DA1E28", 0.6),
  },
  [Severity.Critical]: {
    label: "Critical",
    color: "#570000",
    severity: Severity.Critical,
    unselectedColor: lighten("#570000", 0.6),
  },
  null_value: {
    label: "Unmatched",
    color: "#7C7C7C",

    unselectedColor: lighten("#570000", 0.6),
    severity: NULL_SEVERITY as Severity,
    isNullItem: true,
  },
};

export const severityListWithNull = Object.values(severityMap)
  .map(({ label, color, severity, ...rest }) => {
    return { severity, label, color, ...rest };
  })
  .sort((a, b) => b.severity - a.severity);

export const severityList = severityListWithNull.filter(({ isNullItem }) => !isNullItem);
export const isNullSeverityAllowed = (severities: Severity[] | null) => {
  if (!severities) {
    return false;
  }
  return severities.includes(NULL_SEVERITY as Severity);
};

export const isAllSeveritiesSelected = (severities?: Severity[] | null) => {
  if (!severities) {
    return false;
  }
  if (severities.length === 0) {
    return true;
  }
  const severitiesSet = new Set(severities);
  return severityList.every((v) => severitiesSet.has(v.severity));
};

export type SeverityItemProps = {
  containerProps?: ComponentProps<"div">;
  severity: Severity;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  showLabel?: boolean;
  nullSeverity?: boolean;
};

export const SeverityItem = ({
  containerProps,
  severity,
  onClick,
  showLabel = true,
  nullSeverity = false,
}: SeverityItemProps) => {
  if (!severityMap[severity] && !nullSeverity) {
    return null;
  }

  const { style, ...restContainerProps } = containerProps || {};
  const { color, label } = formatSeverity(severity);
  const marginRight = showLabel ? 10 : 0;

  return (
    <div
      onClick={onClick}
      style={{ display: "flex", alignItems: "center", padding: "3px 0", ...style }}
      {...restContainerProps}
    >
      <div style={{ background: color, width: 16, height: 16, marginRight, flexShrink: 0 }} />
      {showLabel && label}
    </div>
  );
};

export function formatSeverity(severity?: Severity): { color: string; label: string } {
  return typeof severity === "number"
    ? severityMap[severity] || severityMap.null_value
    : severityMap.null_value;
}
