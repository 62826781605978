import React from "react";
import { Spacing } from "ui/vars";
import { Spacer } from "libs/layouts";
import { selectors } from "libs/css";
import { HStack } from "./Stack";
import { Box } from "./Box";

export type ShowOnHoverProps = {
  node: React.ReactNode;
  children?: React.ReactNode;
  space?: Spacing;
  sx?: React.CSSProperties;
  spacer?: boolean;
  disableOnHover?: boolean;
  className?: string;
  directChildOnly?: boolean;
};

/**
 * Abstraction to show component on children hover.
 * @param node to be shown once user hovers on the "children" of ShowOnHover.
 * @param spacer to add spacer between children and component to be shown
 */

export const ShowOnHover = React.forwardRef<HTMLDivElement, ShowOnHoverProps>(
  (
    {
      children,
      space,
      sx,
      className,
      node,
      disableOnHover,
      spacer = false,
      directChildOnly = false,
    }: ShowOnHoverProps,
    ref
  ) => {
    return (
      <HStack
        role="group"
        ref={ref}
        space={space}
        sx={{
          ...sx,
          position: "relative",
        }}
        className={className}
      >
        {children}
        {spacer && <Spacer />}
        {node && (
          <Box
            sx={
              !disableOnHover
                ? {
                    visibility: "hidden",
                    [directChildOnly ? selectors.hoverParent : selectors.hoverGroup]: {
                      visibility: "visible",
                    },
                  }
                : {}
            }
          >
            {node}
          </Box>
        )}
      </HStack>
    );
  }
);
