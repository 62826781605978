import React from "react";
import { Typography } from "@mui/material";
import { SetupPhoneMFA } from "modules/users/organisms/SetupPhoneMFA";
import { VStack } from "ui";
import { messages } from "../messages";

export interface SetupMFAProps {
  onDone: () => void;
}

export function SetupMFA({ onDone }: SetupMFAProps) {
  return (
    <>
      <VStack>
        <Typography variant="h6" style={{ textTransform: "capitalize" }}>
          {messages.setupMFA}
        </Typography>
        <SetupPhoneMFA onDone={onDone} />
      </VStack>
    </>
  );
}
