export function newPromise<T>() {
  let res: (arg: T) => void;
  let err: (arg: any) => void;
  return [
    new Promise<T>((r, k) => {
      res = r;
      err = k;
    }),
    res!,
    err!,
  ] as const;
}

export function delay(time = 1000) {
  return new Promise((res) => {
    setTimeout(res, time);
  });
}

export function idleDelay(time = 1000) {
  const [promise, resolve] = newPromise<any>();
  setTimeout(() => {
    requestAnimationFrame(resolve);
  }, time);
  return promise;
}

export * from "./lazyService";
