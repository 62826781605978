import bowser from "bowser/bowser";

export const browsers = {
  FF: "firefox",
  Chrome: "chrome",
  IE: "msie",
  safari: "safari",
  edge: "msedge",
};

export const IS_FF = bowser[browsers.FF];
export const IS_CHROME = bowser[browsers.Chrome];
export const IS_IE = bowser[browsers.IE];
export const IS_SAFARI = bowser[browsers.safari];
export const IS_EDGE = bowser[browsers.edge];
