import Color from "color";

export const saturation55 = (color: string): string => {
  return setSaturation(color, 55);
};

export const lighten40 = (color: string): string => {
  return addLightness(color, 40);
};

export const setSaturation = (color: string, saturation: number): string => {
  if (!Number.isInteger(saturation)) {
    throw new Error("Saturation must be an integer.");
  }
  if (saturation < 0 || saturation > 100) {
    throw new Error("Saturation must be in [0, 100]");
  }

  const newColor = Color(color).hsl();
  // @ts-ignore
  newColor.color[2] = saturation;
  return newColor.hex();
};

export const addLightness = (color: string, lightness: number): string => {
  const newColor = Color(color).hsl();
  // @ts-ignore
  newColor.color[2] += lightness;
  return newColor.hex();
};
