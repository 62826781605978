import { useContext, useMemo } from "react";
import { Active, Over } from "@dnd-kit/core";
import { DndInfoContext } from "./dndInfoContext";
import { DndEventData } from "./types";

export const useDropIndicatorPosition = (
  currentId: string,
  active: Active | null,
  over: Over | null,
  isLastCondition: boolean
): "before" | "after" | undefined => {
  const { isBelowCenterOfOverRect } = useContext(DndInfoContext);

  return useMemo(() => {
    if (!active || !over || over.id !== currentId || currentId === active.id) {
      return undefined;
    }

    const sourceData = active.data.current as DndEventData;
    const destData = over.data.current as DndEventData;

    // If over other rule show indicator before unless draggable below last item
    if (sourceData.ruleIndex !== destData.ruleIndex) {
      return isLastCondition && isBelowCenterOfOverRect ? "after" : "before";
    }

    return destData.conditionIndex > sourceData.conditionIndex ? "after" : "before";
  }, [currentId, active, over, isBelowCenterOfOverRect]);
};
