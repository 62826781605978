import React from "react";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarProps,
} from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import { HStack } from "ui";
import { styled } from "ui/css";
import { GridToolbarSearch } from "../molecules";
import { gridColors } from "../theme";
import { GridRowSelectionModel } from "../types";

const Divider = styled("div", {
  width: "1px",
  height: "1rem",
  backgroundColor: gridColors.darkGrey,
});

const Title = styled(Typography, {
  flexShrink: 0,
  fontWeight: 400,
  fontSize: "20px",
  letterSpacing: "-0.5px",
  textTransform: "capitalize",
});

export interface CustomToolbarProps extends GridToolbarProps {
  totalRowsCount: number;
  showTotalRowsCount?: boolean;
  selectionModel: GridRowSelectionModel;
  renderToolbarActions?: (selection: GridRowSelectionModel) => React.ReactNode;
  messages?: { item?: string; itemPlural?: string; searchPlaceholder?: string };
  searchEnabled?: boolean;
}
export function GridToolbar({
  totalRowsCount,
  showTotalRowsCount = true,
  selectionModel,
  renderToolbarActions,
  messages = {},
  searchEnabled = false,
}: CustomToolbarProps) {
  const toolbarContent =
    selectionModel?.selected?.length || selectionModel?.all ? (
      <Title variant="h6" style={{ marginRight: "auto" }}>
        {selectionModel.all ? totalRowsCount : selectionModel?.selected?.length} Selected
      </Title>
    ) : (
      <>
        <Title variant="h6">
          {showTotalRowsCount && totalRowsCount >= 0 && `${totalRowsCount} `}
          {totalRowsCount > 1 || !totalRowsCount
            ? messages.itemPlural || "items"
            : messages.item || "item"}
        </Title>
        {searchEnabled && (
          <>
            <Divider />
            <GridToolbarSearch placeholder={messages.searchPlaceholder} />
          </>
        )}
      </>
    );

  const toolbarActions = renderToolbarActions ? (
    renderToolbarActions(selectionModel)
  ) : (
    <>
      <GridToolbarFilterButton />
    </>
  );

  return (
    <GridToolbarContainer data-testid="datagrid-toolbar" sx={{ height: 50, alignItems: "center" }}>
      <HStack fullWidth align="center" space="2">
        {toolbarContent}
        <HStack sx={{ flexShrink: 0, marginLeft: "auto" }} space="1">
          {toolbarActions}
        </HStack>
      </HStack>
    </GridToolbarContainer>
  );
}
