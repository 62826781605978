import React from "react";
import { messages } from "../messages";
import { HelperInfo } from "./HelperInfo";

const examples = [
  {
    group: messages.scriptHelper.search,
    explanation: (
      <div>
        {messages.scriptHelper.searchExp}
        <pre style={{ whiteSpace: "pre-wrap" }}>{messages.scriptHelper.searchExample}</pre>
      </div>
    ),
  },
];

const links = [
  {
    label: messages.scriptHelper.guidelineLabel,
    url: "https://www.elastic.co/guide/en/elasticsearch/painless/master/painless-guide.html",
  },
];

export function ScriptHelperInfo() {
  return <HelperInfo examples={examples} links={links} label={messages.scriptHelper.title} />;
}
