import React from "react";
import { Merge } from "ts-essentials";
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import { radii } from "ui/vars";
import { css } from "ui/css";

const rootStyles = css({
  fieldset: {
    "borderWidth": 1,
    "borderColor": "transparent",
    "&::before": {
      display: "none",
    },
  },
  input: {
    paddingLeft: 0,
  },
});

export const SearchInputV2 = React.forwardRef(function SearchInputV2(
  {
    size,
    onChange,
    onCancel,
    ...props
  }: Merge<
    Partial<OutlinedInputProps>,
    {
      size?: "small" | "inherit" | "medium" | "large";
      onCancel?: (reason: "cancel" | "escape") => void;
      onChange: (v: string) => void;
    }
  >,
  ref
) {
  return (
    <OutlinedInput
      ref={ref}
      {...props}
      fullWidth
      autoFocus
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          onCancel?.("escape");
        }
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize={size} color="action" />
        </InputAdornment>
      }
      endAdornment={
        onCancel ? (
          <InputAdornment
            style={{ cursor: "pointer" }}
            onClick={() => onCancel("cancel")}
            position="end"
          >
            <ClearIcon fontSize={size} color="action" />
          </InputAdornment>
        ) : null
      }
      classes={{
        root: rootStyles().className,
        ...props.classes,
      }}
      style={{
        borderRadius: radii.md,
        ...props.style,
      }}
      onChange={(el) => {
        const value = el.target.value;
        onChange(value);
      }}
    />
  );
});
