import React, { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Switch } from "@mui/material";
import { css } from "ui/css";
import { Box } from "ui/atoms/Box";
import { space } from "ui/vars";
import { selectors } from "libs/css";
import { HStack, Stack } from "libs/layouts";
import { listsSelectors } from "modules/matching-lists/listsConstants";
import { theme } from "../theme";
import { searchMessages, searchTestIDs } from "../messages";
import { getFieldLabel } from "../fieldSpec";
import { dragHandleDisabledAttribute, dragHandleEnabledAttribute } from "../constants";
import { ConditionDragOverlay, ConditionError, DragHandle } from "../atoms";
import { DraggableConditionContext } from "../draggableConditionContext";

function RightIndicator() {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: 3,
        background: theme.blue1,
        zIndex: 1,
      }}
    />
  );
}
export function ConditionTemplate({
  children,
  actions,
  isActive = false,
  style = {},
  onFocus,
  onBlur,
  tabIndex,
  divider,
}: any) {
  const { position = "relative", ...restStyles } = style;
  return (
    <Box
      style={{
        position,
        ...restStyles,
      }}
      sx={{
        background: isActive ? theme.gray2 : "none",
        [selectors.touch]: {
          background: theme.gray2,
        },
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
    >
      <RightIndicator />
      <Stack space={1} as={Box}>
        {children}
      </Stack>
      <Box
        sx={{
          [selectors.touchParent]: {
            height: "auto",
            visibility: "visible",
          },
          visibility: isActive ? "visible" : "hidden",
          height: isActive ? "auto" : "0",
          paddingX: space[3],
          paddingBottom: space[1],
        }}
        style={{
          position: "absolute",
          zIndex: 1,
          bottom: 0,
          transform: "translateY(calc(100% - 1px))",
          background: theme.gray2,
          width: "100%",
          right: 0,
        }}
      >
        <RightIndicator />
        {actions}
        <Box
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            transform: "translateY(7px)",
          }}
        >
          {divider}
        </Box>
      </Box>
    </Box>
  );
}

const conditionStyles = css({
  "*:first-child > &": {
    ".and-operator": { marginLeft: "-25px", color: "transparent" },
  },
  "variants": {
    isDragging: {
      true: {
        "& .and-operator": { marginLeft: "-25px", color: "transparent" },
      },
    },
  },
});

export const getFieldNameErrorMessage = (error: any): string => {
  if (error.type === "endpoint_field_not_supported") {
    return searchMessages.unsupportedConditionFieldError(
      error.minRequiredVersion,
      error.entityType === "dataset" ? "Datasets" : "Policies"
    );
  }

  return error;
};

export function FieldTemplate({
  children,
  onRemoveRule,
  caption,
  label = "",
  noBg = false,
  error = null,
  onListSwitchChange,
  isList,
  showListSwitch,
  ...props
}: any) {
  const { isDragging, isOverlay } = useContext(DraggableConditionContext);

  return (
    <Box
      {...props}
      aria-label={searchMessages.ariaCondition(getFieldLabel(label as any))}
      className={conditionStyles({ isDragging }).className}
      data-testid={searchTestIDs.nestedRuleSection}
      data-drag-handle={dragHandleEnabledAttribute}
      data-error={!!error}
      as={Stack}
      sx={{
        position: "relative",
        paddingX: space[3],
        paddingY: space[1],
        [selectors.touch]: {
          background: theme.gray1,
        },
        background: isDragging ? theme.gray1 : "none",
        cursor: isDragging ? "grabbing" : "grab",
      }}
      role="group"
    >
      <DragHandle isDragging={!!isDragging} />
      <HStack
        sx={{ marginX: space[-3], paddingX: space[3], alignItems: "center" }}
        as={Box}
        align="center"
        role="group"
      >
        {isOverlay && <ConditionDragOverlay />}
        <Box style={{ width: "100%", overflow: "hidden" }}>
          <HStack>
            <span className="and-operator">and</span>
            {caption}
          </HStack>
        </Box>
        <HStack.Spacer />

        {showListSwitch && (
          <label
            style={{
              display: "inline-flex",
              alignItems: "center",
              fontSize: "13px",
              flexShrink: 0,
              marginRight: "-10px",
            }}
          >
            Lists
            <Switch
              data-testid={listsSelectors.toggleLists}
              data-list-on={String(isList)}
              id={isList ? "toggle-lists-mode-on" : "toggle-lists-mode-off"}
              checked={isList}
              onChange={() => onListSwitchChange(!isList)}
            />
          </label>
        )}
        <Box
          onClick={onRemoveRule}
          sx={{
            visibility: isDragging ? "visible" : "hidden",
            centerContent: true,
            [selectors.touchGroup]: {
              visibility: "visible",
            },
          }}
          style={{ cursor: "pointer" }}
          data-drag-handle={dragHandleDisabledAttribute}
        >
          <DeleteIcon fontSize="small" color="action" />
        </Box>
      </HStack>
      <Box
        sx={{ background: noBg ? "" : theme.gray3 }}
        data-drag-handle={dragHandleDisabledAttribute}
      >
        {children}
      </Box>
      {error && <ConditionError>{getFieldNameErrorMessage(error)}</ConditionError>}
    </Box>
  );
}
