import { types } from "api/gen";

export enum UserMappingColumnField {
  EndpointUserId = "endpoint_user_id",
  EndpointEmail = "endpoint_email",
  Hostname = "hostname",
  LocalUserName = "local_user_name",
  SensorType = "sensor_type",
  SensorVersion = "sensor_version",
  Directories = "directories",
  Status = "status",
  Details = "details",
}

export enum UserMappingStatus {
  Mapped = "Mapped",
  Unmapped = "Unmapped",
}

export type MappedUser = types.EndpointUserMappingStatus;
