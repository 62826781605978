import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorState } from "./StateScreens";

export const ErrorBoundary = ({
  children,
  fallback = <ErrorState />,
}: {
  children: React.ReactChild | React.ReactChild[];
  fallback?: React.ReactElement;
}) => {
  return <ReactErrorBoundary fallback={fallback}>{children}</ReactErrorBoundary>;
};
