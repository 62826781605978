import { LocationCategoryValues } from "api/gen";
import { ExpandableLocation } from "../types";

export function makeInitialStateForExpansions() {
  function makeLocationExpansion(location: string) {
    return {
      location: location as any,
      location_outline: location,
      path_component: location,
      query: {
        rules: [
          {
            conditions: [
              {
                field_name: "location",
                operator: "is",
                selector: "match_any",
                values: [
                  {
                    value: location,
                  },
                ],
                case_sensitive: false,
                match_path_delimiters: false,
                negated: false,
              },
            ],
          },
        ],
        cel: "",
        list_references: null,
      },
      expand_path: [location],
      subitems_size: 20,
    } as ExpandableLocation;
  }
  return [
    LocationCategoryValues.LocationCategoryMail,
    LocationCategoryValues.LocationCategoryWebsite,
    LocationCategoryValues.LocationCategoryEndpointApps,
    LocationCategoryValues.LocationCategoryEndpoint,
    LocationCategoryValues.LocationCategoryCloudApps,
  ].map((el) => makeLocationExpansion(el));
}
