import { openAuthWindow } from "./utils";
import { IAuthorizationService } from "./interfaces";

function onWindowClose(window: Window, onClose: any) {
  const timerId = setInterval(() => {
    if (window.closed) {
      clear();
      onClose();
    }
  }, 300);

  function clear() {
    clearInterval(timerId);
  }

  return clear;
}

export interface IExternalAuthResponse {
  type: string;
  payload: string;
}

export abstract class ExternalAuth<T> implements IAuthorizationService {
  private static readonly AUTH_SUCCESS_TYPE = "@auth/success";
  private static readonly AUTH_ERROR_TYPE = "@auth/error";

  abstract login(token?: string, sandbox?: boolean): Promise<T>;

  abstract logout(params?: any): Promise<any> | any;

  abstract isAuthorized(): boolean;
  authWindow?: Window;

  authorize(url: any, title: any): Promise<IExternalAuthResponse> {
    if (this.authWindow) {
      this.authWindow.close();
    }
    return new Promise((resolve, reject) => {
      const startLoadTime = new Date().getTime();
      const handleMessageBind = handleMessage.bind(this);

      this.authWindow = openAuthWindow(url, title)!;

      window.addEventListener("message", handleMessageBind);

      const removeCloseListener = onWindowClose(this.authWindow!, handleClose);

      function handleClose() {
        // prevent rejecting because of broken SSL cetr message
        const unloadTime = new Date().getTime();
        if (unloadTime - startLoadTime < 300) {
          return null;
        }

        window.removeEventListener("message", handleMessageBind);
        reject(new Error("Canceled By User"));
        return;
      }

      function handleMessage(this: any, { data: DTO }: { data: any }): void {
        if (typeof DTO !== "string") {
          return;
        }

        try {
          const data = JSON.parse(DTO) as IExternalAuthResponse;

          switch (data.type) {
            case ExternalAuth.AUTH_SUCCESS_TYPE:
              removeCloseListener();
              window.removeEventListener("message", handleMessageBind);
              this.authWindow?.close();
              resolve(data);
              break;

            case ExternalAuth.AUTH_ERROR_TYPE:
              removeCloseListener();
              window.removeEventListener("message", handleMessageBind);
              this.authWindow.close();
              // TODO backend returns broken json, fix that and handle this properly
              try {
                reject(JSON.parse(data.payload));
              } catch (e) {
                console.error(e);
                reject(new Error("Failed to authenticate"));
              }
              break;
          }
        } catch (error) {
          reject(error);
        }
      }
    });
  }
}
