import React, { useEffect, useState } from "react";
import { ENV } from "runenv";
import { Can } from "shared/permissions";
import { GenericTable } from "modules/table";
import { HStack, TableTemplate, Typography } from "ui";
import { Switch } from "modules/graph-search-panel/atoms";
import { notification } from "modules/notification";
import {
  useSAMLProviderUpdate,
  useDefaultProviderAvailabilityUpdate,
  usePasswordProviderMFAUpdate,
} from "../apiHooks";
import { DeleteSAMLProvider, EditSAMLProvider } from "./SAMLProviderActions";
import { messages } from "../messages";
import {
  AuthProvider,
  ProviderType,
  useAuthProviders,
  useEnabledProvidersCount,
} from "../useAuthProviders";

function AuthProviderStatusCell({
  provider,
  enabled,
}: {
  provider: AuthProvider;
  enabled: boolean;
}) {
  const { mutateAsync: updateSAMLProvider } = useSAMLProviderUpdate({});
  const { mutateAsync: updateDefaultProviderAvailability } = useDefaultProviderAvailabilityUpdate();

  const enabledProvidersCount = useEnabledProvidersCount();
  const isLastEnabledProvider = enabledProvidersCount === 1 && enabled;

  const [checked, setChecked] = useState(enabled);
  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  const updateProviderAvailability = (newChecked: boolean) => {
    // optimistic update
    setChecked(newChecked);

    const updateFn =
      provider.type === ProviderType.SAML
        ? () => updateSAMLProvider({ ...provider.data, enabled: newChecked })
        : () =>
            updateDefaultProviderAvailability({
              name: provider.data.name,
              enabled: newChecked,
            });

    updateFn().catch(() => {
      notification.error({ message: messages.failedToUpdateProvider });
      setChecked(checked);
    });
  };

  return (
    <Can do={"admin_saml__modify"}>
      {({ canDo }) => (
        <HStack space="1" title={isLastEnabledProvider ? messages.lastEnabledProvidedTooltip : ""}>
          <Switch
            checked={checked}
            onChange={(event) => updateProviderAvailability(event.target.checked)}
            disabled={!canDo || isLastEnabledProvider}
          />
          <Typography>{checked ? messages.enabledProvider : messages.disabledProvider}</Typography>
        </HStack>
      )}
    </Can>
  );
}

function AuthProviderEnforceMFACell({
  provider,
  enabled,
}: {
  provider: AuthProvider;
  enabled: boolean;
}) {
  const { mutateAsync: updatePasswordProviderMFA } = usePasswordProviderMFAUpdate();
  const [checked, setChecked] = useState(enabled);
  useEffect(() => {
    setChecked(enabled);
  }, [enabled]);

  const updateProviderMFA = (newChecked: boolean) => {
    // optimistic update
    setChecked(newChecked);

    updatePasswordProviderMFA({ enforce: newChecked }).catch(() => {
      notification.error({ message: messages.failedToUpdateProvider });
      setChecked(checked);
    });
  };

  if (provider.id !== "password") {
    return null;
  }

  return (
    <Can do={"admin_saml__modify"}>
      {({ canDo }) => (
        <HStack space="1">
          <Switch
            checked={checked}
            onChange={(event) => updateProviderMFA(event.target.checked)}
            disabled={!canDo}
          />
          <Typography>{checked ? messages.enabledProvider : messages.disabledProvider}</Typography>
        </HStack>
      )}
    </Can>
  );
}

function AuthProviderActionsCell({ provider }: { provider: AuthProvider }) {
  if (provider.type !== ProviderType.SAML) {
    return null;
  }

  return (
    <Can do={"admin_saml__modify"}>
      <HStack space="1" fullWidth sx={{ justifyContent: "center" }}>
        <EditSAMLProvider provider={provider.data} />
        <DeleteSAMLProvider provider={provider.data} />
      </HStack>
    </Can>
  );
}

const columns = [
  {
    Header: "Name",
    id: "name",
    resizable: true,
    width: "1fr",
    Cell: ({ value }: { value: string }) => {
      return (
        <Typography noWrap title={value}>
          {value}
        </Typography>
      );
    },
  },
  {
    Header: "Status",
    id: "enabled",
    resizable: true,
    width: "1fr",
    Cell: ({ original, value }: { value: boolean; original: AuthProvider }) => (
      <AuthProviderStatusCell provider={original} enabled={value} />
    ),
  },
  {
    Header: "Enforce MFA",
    id: "enforce_mfa",
    resizable: true,
    width: "1fr",
    Cell: ({ original, value }: { value: boolean; original: AuthProvider }) => (
      <AuthProviderEnforceMFACell provider={original} enabled={value} />
    ),
  },
  {
    Header: "Actions",
    id: "id",
    width: 200,
    Cell: ({ original }: { original: AuthProvider }) => (
      <AuthProviderActionsCell provider={original} />
    ),
  },
].filter((el) => {
  if (el.id === "enforce_mfa") {
    return ENV.FEATURES.ENFORCE_2FA_UI;
  }
  return true;
});

export function AuthProviderTable() {
  const { isLoading, data } = useAuthProviders();

  return (
    <TableTemplate>
      <GenericTable isLoading={isLoading} data={data} columns={columns} />
    </TableTemplate>
  );
}
