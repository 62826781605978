import { types } from "api/gen";

type SideSelectorOption = { label: string; value?: string };
type SideSelectorOptions = Record<types.SideSelector, SideSelectorOption>;

const SideSelectorOptionsMap: SideSelectorOptions = mapFields({
  "category": {
    label: "Destinations",
  },
  "dataset": {
    label: "Sources",
  },
  "": {
    label: "All",
  },
});

// Derived state
export const SideSelectorOptions = [
  SideSelectorOptionsMap[""],
  SideSelectorOptionsMap["dataset"],
  SideSelectorOptionsMap["category"],
];

function mapFields(data: SideSelectorOptions): SideSelectorOptions {
  for (const k in data) {
    data[k as keyof SideSelectorOptions].value = k;
  }
  return data;
}

export function getSideSelectorLabel(key: types.SideSelector) {
  return SideSelectorOptionsMap[key]?.label;
}
