import React from "react";
import { ReactComponent as CHLogo4 } from "assets/images/cyberhaven-logo4.svg";
import { Paper } from "@mui/material";
import { Stack } from "libs/layouts";
import { Box } from "ui/atoms/Box";
import { space } from "ui/vars";

export function PageTemplate({ children }: any) {
  const logoSx = { width: "100%", centerContent: true };
  const sizeLogoSx = { height: 43, paddingX: 80 };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        centerContent: true,
      }}
    >
      <Stack space={5} style={{ flexBasis: "400px 1 1", maxWidth: 400 }} fullWidth>
        <Box sx={{ ...logoSx, ...sizeLogoSx }}>
          <CHLogo4 />
        </Box>
        <Box as={Paper} sx={{ paddingX: space[10], paddingY: space[8] }} elevation={3} square>
          {children}
        </Box>
      </Stack>
    </Box>
  );
}
