import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { css } from "ui/css";
import { messages } from "../messages";

const textColor = "#DDDDDD";

const styles = css({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

const textStyles = css({
  fontSize: "25vh",
  lineHeight: "1em",
});

export const ErrorPageTemplate = ({
  title,
  subTitle,
  className = "",
}: {
  title: string;
  subTitle: string | JSX.Element;
  className?: string;
}) => (
  <div className={styles({ className }).className}>
    <div style={{ textAlign: "center" }}>
      <Typography variant="h5" className={textStyles({ css: { color: textColor } }).className}>
        {title}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {subTitle}
      </Typography>
      <div style={{ marginTop: "3em" }}>
        <Button
          color="primary"
          variant="contained"
          component={(props: any) => <Link to="/" {...props} />}
        >
          {messages.backBtn}
        </Button>
      </div>
    </div>
  </div>
);
