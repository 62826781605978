import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import formatLib from "date-fns/format";
import { DATE_FORMAT } from "shared";
import { types } from "api";

const START_TIME = "T00:00:00Z";
const END_TIME = "T23:59:59Z";
export const DATE_FORMAT_ISO = "yyyy-MM-dd'T'HH:mm:ss.'000Z'";

export type DateType = Date | number | string | undefined | null;

export const format = (
  date: DateType,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) => {
  if (!date) {
    return null;
  }

  if (typeof date === "string") {
    const dateFromString = new Date(date);
    if (!Number(dateFromString)) {
      return null;
    }
    return formatLib(dateFromString, format, options);
  }
  return formatLib(date, format, options);
};

export type Duration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export function toInteger(dirtyNumber: unknown) {
  if (dirtyNumber === null || dirtyNumber === true || dirtyNumber === false) {
    return NaN;
  }

  const number = Number(dirtyNumber);

  if (isNaN(number)) {
    return number;
  }

  return number < 0 ? Math.ceil(number) : Math.floor(number);
}

/**
 * @name add
 * @category Common Helpers
 * @summary Add the specified years, months, weeks, days, hours, minutes and seconds to the given date.
 *
 * @description
 * Add the specified years, months, weeks, days, hours, minutes and seconds to the given date.
 *
 * @param {Date|Number} dirtyDate - the date to be changed
 * @param {Duration} duration - the object with years, months, weeks, days, hours, minutes and seconds to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 *
 * | Key            | Description                        |
 * |----------------|------------------------------------|
 * | years          | Amount of years to be added        |
 * | months         | Amount of months to be added       |
 * | weeks          | Amount of weeks to be added       |
 * | days           | Amount of days to be added         |
 * | hours          | Amount of hours to be added        |
 * | minutes        | Amount of minutes to be added      |
 * | seconds        | Amount of seconds to be added      |
 *
 * All values default to 0
 *
 * @returns {Date} the new date with the seconds added
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Add the following duration to 1 September 2014, 10:19:50
 * const result = add(new Date(2014, 8, 1, 10, 19, 50), {
 *   years: 2,
 *   months: 9,
 *   weeks: 1,
 *   days: 7,
 *   hours: 5,
 *   minutes: 9,
 *   seconds: 30,
 * })
 * //=> Thu Jun 15 2017 15:29:20
 */
export function add(dirtyDate: Date | number, duration: Duration): Date {
  if (!duration || typeof duration !== "object") {
    return new Date(NaN);
  }
  const years = "years" in duration ? toInteger(duration.years) : 0;
  const months = "months" in duration ? toInteger(duration.months) : 0;
  const weeks = "weeks" in duration ? toInteger(duration.weeks) : 0;
  const days = "days" in duration ? toInteger(duration.days) : 0;
  const hours = "hours" in duration ? toInteger(duration.hours) : 0;
  const minutes = "minutes" in duration ? toInteger(duration.minutes) : 0;
  const seconds = "seconds" in duration ? toInteger(duration.seconds) : 0;

  // Add years and months
  const date = new Date(dirtyDate);
  const dateWithMonths = months || years ? addMonths(date, months + years * 12) : date;

  // Add weeks and days
  const dateWithDays = days || weeks ? addDays(dateWithMonths, days + weeks * 7) : dateWithMonths;

  // Add days, hours, minutes and seconds
  const minutesToAdd = minutes + hours * 60;
  const secondsToAdd = seconds + minutesToAdd * 60;
  const msToAdd = secondsToAdd * 1000;
  const finalDate = new Date(dateWithDays.getTime() + msToAdd);

  return finalDate;
}

export function sub(dirtyDate: Date | number, duration: Duration) {
  if (!duration || typeof duration !== "object") {
    return new Date(NaN);
  }

  const years = "years" in duration ? toInteger(duration.years) : 0;
  const months = "months" in duration ? toInteger(duration.months) : 0;
  const weeks = "weeks" in duration ? toInteger(duration.weeks) : 0;
  const days = "days" in duration ? toInteger(duration.days) : 0;
  const hours = "hours" in duration ? toInteger(duration.hours) : 0;
  const minutes = "minutes" in duration ? toInteger(duration.minutes) : 0;
  const seconds = "seconds" in duration ? toInteger(duration.seconds) : 0;

  // Subtract years and months
  const dateWithoutMonths = subMonths(new Date(dirtyDate), months + years * 12);

  // Subtract weeks and days
  const dateWithoutDays = subDays(dateWithoutMonths, days + weeks * 7);

  // Subtract hours, minutes and seconds
  const minutestoSub = minutes + hours * 60;
  const secondstoSub = seconds + minutestoSub * 60;
  const mstoSub = secondstoSub * 1000;
  const finalDate = new Date(dateWithoutDays.getTime() - mstoSub);

  return finalDate;
}

export const getOffset = (timeZone = "UTC", date = new Date()) => {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export type RelativeDate = {
  duration: Duration;
  offset?: Duration;
};

function convertRelativeTime(relative: RelativeDate, timezone?: string) {
  const now = new Date();
  let endDate = timezone ? new Date(now.toLocaleString("en-US", { timeZone: timezone })) : now;
  if (relative.offset) {
    endDate = sub(endDate, relative.offset);
  }
  const startDate = sub(endDate, relative.duration);

  return {
    start_time: startOfDate(format(startDate, DATE_FORMAT)!),
    end_time: endOfDate(format(endDate, DATE_FORMAT)!),
  };
}

export function convertTime(
  dateFilters: types.TimesFilter & { relative?: RelativeDate | null },
  timezone?: string
) {
  if (dateFilters.relative) {
    return convertRelativeTime(dateFilters.relative, timezone);
  }
  return {
    start_time: dateFilters.start_time,
    end_time: dateFilters.end_time,
  };
}

export function toDate(date: string) {
  if (!date) {
    return null;
  }
  return date.split("T")[0];
}

// We need this method for backward compatibility
export function startOfDate(t: string): string {
  if ((t || "").includes("T")) {
    return t;
  }
  return addTimePrefix(t, START_TIME);
}

// We need this method for backward compatibility
export function endOfDate(t: string): string {
  if ((t || "").includes("T")) {
    return t;
  }
  return addTimePrefix(t, END_TIME);
}

function addTimePrefix(t: string, prefix: string): string {
  if (!t) {
    return t;
  }
  if (t && t.indexOf(prefix) !== -1) {
    return t;
  }
  return String(t) + prefix;
}
