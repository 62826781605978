import React from "react";
import { Merge } from "ts-essentials";
import { css } from "ui/css";
import { ToggleButtonGroupInternal, ToggleButtonGroupProps } from "./ToggleButtonGroupInternal";

type ToggleButtonProps = Merge<
  ToggleButtonGroupProps,
  {
    onChange: (v: string) => void;
  }
>;

const styles = css({});

export function ToggleButtonGroup({
  children,
  value,
  onChange,
  onBlur,
  onFocus,
  style,
  className,
  ...rest
}: ToggleButtonProps) {
  return (
    <ToggleButtonGroupInternal
      value={value}
      onFocus={onFocus}
      exclusive
      onChange={(_, v) => {
        if (v !== null) {
          onChange(v);
          setTimeout(() => {
            onBlur?.(_ as any);
          });
        }
      }}
      {...rest}
      style={{
        ...style,
        display: "grid",
        gridTemplateColumns: `repeat(${React.Children.count(children)}, 1fr)`,
      }}
      className={
        styles({
          className,
          css: {
            ">button": {
              padding: `0 15px`,
            },
          },
        }).className
      }
    >
      {children}
    </ToggleButtonGroupInternal>
  );
}

export { default as ToggleButton } from "./ToggleButton";
