import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const LayoutLoader = ({
  classes = {},
  innerRef = null,
}: {
  classes?: { progress?: string; loaderWrapper?: string };
  innerRef?: React.RefObject<HTMLDivElement> | null;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        height: "100%",
        placeContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        zIndex: 200,
      }}
      ref={innerRef}
    >
      <CircularProgress className={classes?.progress} size={50} />
      Loading...
    </div>
  );
};
