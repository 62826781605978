import constate from "constate";
import type { INavigationItem } from "./types";

export type AppEnvironment = {
  navigationItems?: INavigationItem[];
  routes?: Record<string, { path: string }>;
};

// Environment provider serves generic purpose of storing app scoped data like services
// navigation icons and similar stuff
export const [AppEnvironmentProvider, useAppEnvironment] = constate(
  ({ navigationItems, routes }: AppEnvironment) => {
    return {
      navigationItems: navigationItems ?? [],
      routes,
    };
  }
);
