import { JSXElementConstructor, Key as RKey, ReactElement, ReactNode } from "react";
export type Key = RKey;

export type FocusStrategy = "first" | "last";
export type SelectionMode = "none" | "single" | "multiple";
export interface MultipleSelection {
  /** The type of selection that is allowed in the collection. */
  selectionMode?: SelectionMode;
  /** Whether the collection allows empty selection. */
  disallowEmptySelection?: boolean;
  /** The currently selected keys in the collection (controlled). */
  selectedKeys?: "all" | Iterable<Key>;
  /** The initial selected keys in the collection (uncontrolled). */
  defaultSelectedKeys?: "all" | Iterable<Key>;
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (keys: Selection) => any;
  /** The currently disabled keys in the collection (controlled). */
  disabledKeys?: Iterable<Key>;
}
export interface SingleSelection {
  /** Whether the collection allows empty selection. */
  disallowEmptySelection?: boolean;
  /** The currently selected key in the collection (controlled). */
  selectedKey?: Key;
  /** The initial selected key in the collection (uncontrolled). */
  defaultSelectedKey?: Key;
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (key: Key) => any;
}

export interface FocusState {
  /** Whether the collection is currently focused. */
  readonly isFocused: boolean;
  /** Sets whether the collection is focused. */
  setFocused(isFocused: boolean): void;
  /** The current focused key in the collection. */
  readonly focusedKey: Key;
  readonly childFocusStrategy: FocusStrategy;
  /** Sets the focused key, and optionally, whether the first or last child of that key should receive focus. */
  setFocusedKey(key: Key | null, child?: FocusStrategy): void;
}

export interface MultipleSelectionState extends FocusState {
  /** The type of selection that is allowed in the collection. */
  readonly selectionMode: SelectionMode;
  /** Whether the collection allows empty selection. */
  readonly disallowEmptySelection: boolean;
  /** The currently selected keys in the collection. */
  readonly selectedKeys: ISelection;
  /** Sets the selected keys in the collection. */
  setSelectedKeys(keys: ISelection | ((v: Selection) => Selection)): void;
  /** The currently disabled keys in the collection. */
  readonly disabledKeys: Set<Key>;
}
export type ISelection = Set<Key>;

export class Selection extends Set<Key> {}

// internal representation of node
export interface Node<T> {
  /** The type of item this node represents. */
  "type": string;
  /** A unique key for the node. */
  "key": Key;
  /** The object value the node was created from. */
  "value": T;
  /** The level of depth this node is at in the hierarchy. */
  "level": number;
  /** Whether this item has children, even if not loaded yet. */
  "hasChildNodes": boolean;
  /** The loaded children of this node. */
  "childNodes": Iterable<Node<T>>;
  /** The rendered contents of this node (e.g. JSX). */
  "rendered": ReactNode;
  /** A string value for this node, used for features like typeahead. */
  "textValue": string;
  /** An accessibility label for this node. */
  "aria-label"?: string;
  /** The index of this node within its parent. */
  "index"?: number;
  /** A function that should be called to wrap the rendered node. */
  "wrapper"?: (element: ReactElement) => ReactElement;
  /** The key of the parent node. */
  "parentKey"?: Key;
  /** Additional properties specific to a particular node type. */
  "props"?: any;
  /** @private */
  "shouldInvalidate"?: (context: unknown) => boolean;
}

export interface ItemProps<T> {
  /** Rendered contents of the item or child items. */
  "children": ReactNode;
  /** Rendered contents of the item if `children` contains child items. */
  "title"?: ReactNode; // label?? contents?
  /** A string representation of the item's contents, used for features like typeahead. */
  "textValue"?: string;
  /** An accessibility label for this item. */
  "aria-label"?: string;
  /** A list of child item objects. Used for dynamic collections. */
  "childItems"?: Iterable<T>;
  /** Whether this item has children, even if not loaded yet. */
  "hasChildItems"?: boolean;
  /** element wrapper */
  "as"?: string | JSXElementConstructor<any>;
}

export type ItemElement<T> = ReactElement<ItemProps<T>>;
export type SectionElement<T> = ReactElement<SectionProps<T>>;
export type ItemRenderer<T> = (props: ItemProps<T>) => ItemElement<T>;

export interface SectionProps<T> {
  /** Rendered contents of the section, e.g. a header. */
  "title"?: ReactNode;
  /** An accessibility label for the section. */
  "aria-label"?: string;
  /** Static child items or a function to render children. */
  "children": ItemElement<T> | Array<ItemElement<T>>;
  /** Item objects in the section. */
  "items"?: Iterable<T>;
  /** element wrapper */
  "as"?: string | JSXElementConstructor<any>;
}

export type CollectionElement<T> = SectionElement<T> | ItemElement<T>;
export type CollectionChildren<T> =
  | CollectionElement<T>
  | Array<CollectionElement<T>>
  | ((item: T) => CollectionElement<T>);
export interface CollectionBase<T> {
  /** The contents of the collection. */
  children: CollectionChildren<T>;
  /** Item objects in the collection. */
  items?: Iterable<T>;
  /** The item keys that are disabled. These items cannot be selected, focused, or otherwise interacted with. */
  disabledKeys?: Iterable<Key>;
}
export interface Collection<T> extends Iterable<T> {
  getItem(k: Key): T;
  getKeys(): Iterable<Key>;
}

export interface Expandable {
  /** The currently expanded keys in the collection (controlled). */
  expandedKeys?: Iterable<Key>;
  /** The initial expanded keys in the collection (uncontrolled). */
  defaultExpandedKeys?: Iterable<Key>;
  /** Handler that is called when items are expanded or collapsed. */
  onExpandedChange?: (keys: Set<Key>) => any;
}

export interface Sortable {
  /** The current sorted column and direction. */
  sortDescriptor?: SortDescriptor;
  /** Handler that is called when the sorted column or direction changes. */
  onSortChange?: (descriptor: SortDescriptor) => any;
}

export type SortDirection = "ascending" | "descending";

export interface SortDescriptor {
  /** The key of the column to sort by. */
  column?: Key;
  /** The direction to sort by. */
  direction?: SortDirection;
}

export interface PartialNode<T> {
  "type"?: string;
  "key"?: Key;
  "value"?: T;
  "element"?: ReactElement;
  "wrapper"?: (element: ReactElement) => ReactElement;
  "rendered"?: ReactNode;
  "textValue"?: string;
  "aria-label"?: string;
  "index"?: number;
  "renderer"?: ItemRenderer<T>;
  "hasChildNodes"?: boolean;
  "childNodes"?: () => IterableIterator<PartialNode<T>>;
  "props"?: any;
  "shouldInvalidate"?: (context: unknown) => boolean;
}
