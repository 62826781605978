import { useMemo, useRef } from "react";
import { Collection, CollectionBase, Node } from "../types";
import { CollectionBuilder } from "./CollectionBuilder";

type CollectionFactory<T, C extends Collection<Node<T>>> = (
  node: Iterable<Node<T>>,
  prev: C | null
) => C;

export function useCollection<
  T extends object,
  C extends Collection<Node<T>> = Collection<Node<T>>
>(
  props: CollectionBase<T>,
  factory: CollectionFactory<T, C>,
  context?: unknown,
  invalidators: any[] = []
): C {
  const builder = useMemo(() => new CollectionBuilder<T>(), []);

  const prev = useRef<C>(null);
  return useMemo(() => {
    const nodes = builder.build(props, context);
    // @ts-ignore
    prev.current = factory(nodes, prev.current);
    return prev.current;
    // Don't invalidate when any prop changes, just the two we care about.
  }, [builder, props.children, props.items, context, ...invalidators]);
}
