import React, { ReactNode, useMemo } from "react";
import { useSortableCondition } from "../useSortableCondition";
import { useDropIndicatorPosition } from "../useDropIndicatorPosition";
import { DraggableConditionContext } from "../draggableConditionContext";
import { Draggable } from "../atoms";

export interface DraggableConditionProps {
  children: ReactNode;
  id: string;
  conditionIndex: number;
  ruleIndex: number;
}

export const DraggableCondition = ({
  children,
  id,
  conditionIndex,
  ruleIndex,
}: DraggableConditionProps) => {
  const { attributes, listeners, setNodeRef, active, over, items } = useSortableCondition(
    id,
    conditionIndex,
    ruleIndex
  );

  const dropIndicatorPosition = useDropIndicatorPosition(
    id,
    active,
    over,
    conditionIndex === items.length - 1
  );

  const draggableConditionContext = useMemo(
    () => ({ isOverlay: active?.id === id }),
    [active?.id, id]
  );

  return (
    <Draggable
      ref={setNodeRef}
      dropIndicatorPosition={dropIndicatorPosition}
      {...attributes}
      {...listeners}
    >
      <DraggableConditionContext.Provider value={draggableConditionContext}>
        {children}
      </DraggableConditionContext.Provider>
    </Draggable>
  );
};
