import React from "react";
import { Portal } from "@mui/material";
import { styled } from "ui/css";

const Layer = styled("div", {
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  zIndex: 10000,
});

// This component helps block pointer events (hovers) on elements while dragging
export const DragOverlayLayer = () => (
  <Portal container={document.body}>
    <Layer />
  </Portal>
);
