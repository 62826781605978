import React from "react";
import { TextField } from "@mui/material";
import { mapProps } from "./mapError";

export class TextFieldInput extends React.PureComponent {
  render() {
    return <TextField variant="standard" {...mapProps(this.props)} />;
  }
}

TextFieldInput.defaultProps = {
  blacklist: [],
};
