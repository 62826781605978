import React from "react";

export function InfiniteScroll({
  onLoadMore,
  canFetchMore,
  rootMargin = 250,
}: {
  onLoadMore: () => void;
  canFetchMore: boolean;
  rootMargin?: number;
}) {
  const triggerRef = React.useRef(null);

  React.useEffect(() => {
    const options = {
      root: null,
      rootMargin: `${rootMargin}px`,
    };

    const handleIntersection = (entries: any) => {
      const entry = entries[0];
      if (entry.isIntersecting && canFetchMore) {
        onLoadMore();
      }
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    observer.observe(triggerRef.current as any);

    if (!canFetchMore) {
      observer.disconnect();
    }

    return () => observer.disconnect();
  }, [onLoadMore, canFetchMore]);

  return <div ref={triggerRef} style={{ padding: "1px" }} data-testid="infinite-scroll-trigger" />;
}
