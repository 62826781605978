import { QueryClient, useQuery } from "@tanstack/react-query";
import { logEvent, eventsConstants } from "modules/analytics/Analytics";
import { sendGQLRequest } from "api/graphql/network";
import { GetSettingsDocument, UpdateSettingsDocument } from "api/graphql";
import { notification } from "../notification";

export function preloadTimezoneSettings(queryClient: QueryClient, userID: string) {
  return queryClient.prefetchQuery({
    queryKey: ["timezone", userID],
    queryFn: () => fetchTimezoneSettings(userID),
  });
}

function fetchTimezoneSettings(userID: string) {
  return sendGQLRequest(GetSettingsDocument, { user_id: userID }).then(
    (el) => el?.settings_by_pk?.data?.timezone
  );
}

export function useTimezoneSettings({ userID }: { userID?: string }) {
  return {
    ...useQuery({
      queryKey: ["timezone", userID],
      queryFn: () => fetchTimezoneSettings(userID!),
      refetchOnWindowFocus: true,
      placeholderData: {},
      staleTime: 10 * 1000,
      enabled: !!userID,
    }),
  };
}

export const updateTimezoneSettings = async (userID: string, tzCode: string | null) => {
  try {
    await sendGQLRequest(UpdateSettingsDocument, {
      user_id: userID,
      data: {
        timezone: {
          tzCode,
        },
      },
    });

    logEvent(eventsConstants.preferences.timezone + ": " + tzCode, { tzCode });
  } catch (error: any) {
    notification.error({ message: error?.message });
  }
};
