import React from "react";
import { useDroppable, UniqueIdentifier } from "@dnd-kit/core";
import { verticalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { SortableItem, RenderItem } from "./SotableItem";

const PLACEHOLDER_ID = "placeholder";

export type DroppableProps<T> = {
  renderItem: RenderItem;
  items: T[];
  id: string;
  activeId: UniqueIdentifier | null;
  dndEnabled?: boolean;
  isDroppable?: boolean;
  height?: string | number;
  footerExtraContent?: React.ReactNode;
};

const Droppable = <T extends { id: string }>({
  id,
  items,
  renderItem,
  activeId,
  dndEnabled,
  isDroppable,
  height = 350,
  footerExtraContent = null,
}: DroppableProps<T>) => {
  const { setNodeRef } = useDroppable({ id, disabled: !isDroppable });

  return (
    <SortableContext
      id={id}
      items={items.map((item) => item.id).concat(PLACEHOLDER_ID)}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={{ position: "relative", overflowY: "auto", height }}>
        {items.map((item, index) => (
          <SortableItem
            active={item.id === activeId}
            index={index}
            renderItem={renderItem}
            item={item}
            key={item.id}
            dndEnabled={dndEnabled}
          />
        ))}
        {footerExtraContent}
      </div>
    </SortableContext>
  );
};

export default Droppable;
