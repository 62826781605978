import React, { ReactNode } from "react";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import addMinutes from "date-fns/addMinutes";
import { DEFAULT_DATE_FORMAT } from "shared";
import { types } from "api";
import { toUTC } from "libs/time-utc";
import { DATE_FORMAT_ISO, DateType, format, getOffset, convertTime, RelativeDate } from "libs/time";
import { removeDiacriticsInString } from "libs/utils/removeDiacriticsInString";
import { TimeZone, TimezoneRegions } from "./timezoneTypes";
import { allTimezonesList, timezonesList } from "./timezonesList";

const MINUTES_IN_HOUR = 60;
// example +10:30, -2:50, 5:00
const HOURS_MINUTES_REGEX = /[-+]?(\d+):?(\d+)?/;

const timezonesMap = keyBy(allTimezonesList, "tzCode");

const getTimezoneOffsetMinutes = (timezone: string, date: Date) => {
  let sumInMinutes;

  try {
    sumInMinutes = getOffset(timezone, date);
  } catch {
    const { utc } = timezonesMap[timezone];
    const sign = utc[0];
    const [hours, minutes] = utc.slice(1).split(":");
    sumInMinutes = Number(hours) * MINUTES_IN_HOUR + Number(minutes);
    sumInMinutes = sign === "-" ? -sumInMinutes : sumInMinutes;
  }

  return sumInMinutes;
};

export function getCurrentTimezoneOffset(date?: string | Date | number) {
  return (date ? new Date(date) : new Date()).getTimezoneOffset();
}

export function removeLocalTZOffset(date: string | Date | number) {
  return new Date(+new Date(date) - getCurrentTimezoneOffset(date) * 1000 * 60);
}

export function addLocalTZOffset(date: string | Date | number) {
  return new Date(+new Date(date) + getCurrentTimezoneOffset(date) * 1000 * 60);
}

export function getCurrentTimezone() {
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!findTimezone(userTz, allTimezonesList)) {
    // in case the user timezone is not in the list, return the default GMT+00:00, normally this should not happen
    return "Europe/Dublin";
  }

  return userTz;
}

export function mapUtcToZonedTime(date: Date | string | number, timezone: string) {
  const utcDate = toUTC(new Date(date));
  return addMinutes(utcDate, -getTimezoneOffsetMinutes(timezone, utcDate));
}

export function removeTimezoneOffset(date: Date | string | number, timezone: string) {
  const utcDate = toUTC(new Date(date));
  return addMinutes(utcDate, getTimezoneOffsetMinutes(timezone, utcDate));
}

export function shiftDateByTimezoneOffset(
  date: Date | string | number,
  timezone: string,
  add = true
): Date {
  const dateObject = new Date(date);
  const offsetInMinutes = getTimezoneOffsetMinutes(timezone, dateObject);

  return addMinutes(dateObject, add ? offsetInMinutes : -offsetInMinutes);
}

export function shiftHoursByTimezoneOffset(
  date: Date,
  hours: number,
  timezone: string,
  add = true
) {
  const offsetInHours = getTimezoneOffsetMinutes(timezone, date) / 60;
  const newHours = hours + (add ? offsetInHours : -offsetInHours);

  if (newHours < 0 || newHours > 24) {
    return Math.abs(Math.abs(newHours) - 24);
  }

  return newHours;
}

export function formatDateTimeZoned(
  date: DateType,
  timezone: string,
  dateFormat = DEFAULT_DATE_FORMAT
) {
  if (!date) {
    return null;
  }

  return format(removeTimezoneOffset(date, timezone), dateFormat);
}

export function formatDateShort(date: Date, timezone?: string) {
  const dateFormat = "MMM d" + (date.getFullYear() == new Date().getFullYear() ? "" : ", yyyy");

  return timezone ? formatDateTimeZoned(date, timezone, dateFormat) : format(date, dateFormat);
}

export const convertTimeFilterToZonedApiFormat = (
  timeFilter: types.TimesFilter & { relative?: RelativeDate },
  timezone: string
) => {
  const { start_time, end_time } = convertTime(timeFilter, timezone);
  return {
    start_time: format(mapUtcToZonedTime(start_time!, timezone), DATE_FORMAT_ISO),
    end_time: format(mapUtcToZonedTime(end_time!, timezone), DATE_FORMAT_ISO),
  };
};

const getGroupedTimezonesForParticularRegion = (
  groupedTimezones: Record<string, TimeZone[]>,
  selectedRegion: TimezoneRegions
) => {
  return Object.entries(groupedTimezones).reduce(
    (acc: Record<string, TimeZone[]>, [utc, timezones]) => {
      const regionTimezones = timezones.filter(
        ({ tzCode }) => getTimezoneRegionFromCode(tzCode) === selectedRegion
      );
      if (regionTimezones.length) {
        acc[utc] = regionTimezones;
      }

      return acc;
    },
    {}
  );
};

function matchTimezone(tzName: string, search: string) {
  const name = removeDiacriticsInString(tzName.slice(12)).toLocaleLowerCase();
  const matchIndex = name.indexOf(removeDiacriticsInString(search.trim().toLowerCase()));
  return {
    match: matchIndex > -1,
    matchIndex,
  };
}

function matchByTimezoneOffset(search: string, utc: string) {
  const utcSign = utc[0];
  const [utcHours, utcMinutes] = utc.slice(1).split(":");
  let searchMatch = search.match(HOURS_MINUTES_REGEX)?.[0];

  if (!searchMatch) {
    return false;
  }
  let searchSign = searchMatch[0];
  if (searchSign === "-" || searchSign === "+") {
    searchMatch = searchMatch.slice(1);
  } else {
    searchSign = "";
  }

  if (searchSign && searchSign !== utcSign) {
    return false;
  }
  const [searchHours, searchMinutes] = searchMatch.split(":");

  return (
    (Number(utcHours) === Number(searchHours) || utcHours.startsWith(searchHours)) &&
    utcMinutes.startsWith(searchMinutes || "0")
  );
}

export const getLocalTimeTimezones = (timezonesList: TimeZone[], tzCode: string) => {
  const timezone: TimeZone | undefined = findTimezone(tzCode, timezonesList);

  if (!timezone) {
    return {};
  }

  return {
    [timezone.utc]: timezonesList.filter((tz) => {
      return tz.utc === timezone.utc && timezone.tzCode.split("/")[0] === tz.tzCode.split("/")[0];
    }),
  };
};

export const groupTimezonesByUtcAndFilterBySearch = (
  timezonesList: TimeZone[],
  search: string,
  selectedRegion?: TimezoneRegions
): Record<string, TimeZone[]> => {
  const trimmed = search.trim().toLocaleLowerCase();
  const grouped = groupBy(timezonesList, "utc");

  if (!trimmed) {
    return selectedRegion
      ? getGroupedTimezonesForParticularRegion(grouped, selectedRegion)
      : grouped;
  }
  return Object.entries(grouped).reduce((acc: Record<string, TimeZone[]>, [utc, timezones]) => {
    const regionTimezones = selectedRegion
      ? timezones.filter(({ tzCode }) => getTimezoneRegionFromCode(tzCode) === selectedRegion)
      : timezones;
    if (
      (matchByTimezoneOffset(search, utc) && regionTimezones.length) ||
      regionTimezones.some((tz) => matchTimezone(tz.name, trimmed).match)
    ) {
      acc[utc] = regionTimezones;
    }

    return acc;
  }, {});
};

export const TIMEZONE_NAME_OFFSET_LENGTH = 12;

export const getTimezonesCities = (timezones: TimeZone[], search?: string) => {
  return timezones.reduce((acc: Array<{ id: string; content: ReactNode }>, tz, index) => {
    const cityLabel = removeDiacriticsInString(
      tz.name.slice(TIMEZONE_NAME_OFFSET_LENGTH) + (index + 1 < timezones.length ? ", " : "")
    );

    if (search) {
      const { match, matchIndex } = matchTimezone(tz.name, search);
      if (!match) {
        acc.push({ id: tz.tzCode, content: cityLabel });
        return acc;
      }
      const searchTrimmed = search.trim().toLowerCase();

      acc.push({
        content: (
          <>
            {cityLabel.slice(0, matchIndex)}
            <b>{cityLabel.slice(matchIndex, matchIndex + searchTrimmed.length)}</b>
            {cityLabel.slice(matchIndex + searchTrimmed.length)}
          </>
        ),
        id: tz.tzCode,
      });
    } else {
      acc.push({ id: tz.tzCode, content: cityLabel });
    }

    return acc;
  }, []);
};

export const findTimezone = (tzCode: string, timezones: TimeZone[] = timezonesList) =>
  timezones.find((tz) => tz.tzCode === tzCode);

export const getCurrentRegion = (
  tzCode: string,
  timezones: TimeZone[],
  isLocalTimezone: boolean
): TimezoneRegions => {
  if (isLocalTimezone) {
    return TimezoneRegions.LocalTime;
  }
  const timezone = findTimezone(tzCode, timezones);
  return timezone ? getTimezoneRegionFromCode(timezone.tzCode) : TimezoneRegions.Other;
};

export const isTimezoneGroupActive = (timezones: TimeZone[], currentTimezone: string) => {
  return timezones.some((tz) =>
    tz.tzCode.toLocaleLowerCase().includes(currentTimezone.toLocaleLowerCase())
  );
};

export const getTimezoneRegionFromCode = (tzCode: string): TimezoneRegions => {
  const region = tzCode.split("/")[0];
  switch (region) {
    case TimezoneRegions.Asia:
    case TimezoneRegions.Europe:
    case TimezoneRegions.Australia:
    case TimezoneRegions.America:
    case TimezoneRegions.Africa:
      return region;
    default:
      return TimezoneRegions.Other;
  }
};

export const isSameDayUTC = (dateA: string | Date | null, dateB: string | Date | null) => {
  if (!dateA || !dateB) {
    return false;
  }

  return new Date(dateA).getUTCDate() === new Date(dateB).getUTCDate();
};
