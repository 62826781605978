import React, { HtmlHTMLAttributes } from "react";
import { PolymorphicComponentProps } from "react-polymorphic-box";
import { HStack, Stack } from "libs/layouts";
import { space as spaceConstants } from "ui/vars";

type CardPropsBase = HtmlHTMLAttributes<HTMLDivElement> & { space?: number };
export type CardProps<E extends React.ElementType = "div"> = PolymorphicComponentProps<
  E,
  CardPropsBase
>;

type SpaceKey = keyof typeof spaceConstants;
export function Card<E extends React.ElementType = "div">({
  space = 3,
  children,
  ...rest
}: CardProps<E>) {
  return (
    // @ts-ignore
    <Stack
      sx={{
        paddingX: spaceConstants[(space + 1) as unknown as SpaceKey],
        paddingY: spaceConstants[space as unknown as SpaceKey],
      }}
      as="div"
      space={space}
      {...rest}
    >
      {children}
    </Stack>
  );
}

export function CardActions({
  space = 3,
  children,
  secondary,
}: {
  space?: number;
  children: React.ReactNode;
  secondary?: JSX.Element;
}) {
  return (
    <HStack space={space}>
      {secondary}
      <HStack.Spacer />
      {children}
    </HStack>
  );
}
