import axios from "axios";
import { install } from "api/auth/interceptors/auth.interceptor";
import { IUpdated } from "./interfaces/User";

class O365SensorService {
  client = axios.create({
    baseURL: "/v1/config/o365",
  });

  constructor() {
    install(this.client);
  }

  monitorUsers() {
    return this.client.get<IUpdated>("/users/updated");
  }

  fetchUsers() {
    return this.client.get("/users").then((response) => response.data);
  }

  updateUser(id: any, updateData: any) {
    return this.client.patch(`/users/${id}`, updateData).then((el) => el.data);
  }

  batch(data: any) {
    return this.client.patch("/users/batch", data);
  }

  updateAll(params = { monitored: true }) {
    return this.client.patch("/users/all", params);
  }

  toggleAutoMonitoring(params = { monitored: true }) {
    return this.client.patch("/users/force_all", params);
  }
}

const o365SensorService = new O365SensorService();
export { O365SensorService, o365SensorService };
