import { default as localFormat } from "date-fns/format";

const MILLISECONDS_IN_MINUTE = 60000;

export function toUTC(date: Date) {
  if (!date) {
    return date;
  }
  const offset = date.getTimezoneOffset();
  return addUTCMinutes(new Date(date), offset);
}

export function revertToUTC(date: Date) {
  if (!date) {
    return date;
  }
  const offset = date.getTimezoneOffset();
  return addUTCMinutes(new Date(date), -offset);
}

export function formatUTC(date: Date, specifier: string) {
  return localFormat(toUTC(date), specifier);
}
export const formatDate = localFormat;

export function isSameUTCDay(dirtyDateLeft: Date, dirtyDateRight: Date) {
  const dateLeftStartOfDay = startOfUTCDay(dirtyDateLeft);
  const dateRightStartOfDay = startOfUTCDay(dirtyDateRight);

  return dateLeftStartOfDay.getTime() === dateRightStartOfDay.getTime();
}
export function toInteger(number: number) {
  if (isNaN(number)) {
    return number;
  }

  return number < 0 ? Math.ceil(number) : Math.floor(number);
}

export function endOfUTCToday() {
  return endOfUTCDay(new Date());
}
export function startOfUTCToday() {
  return startOfUTCDay(new Date());
}

export function addUTCDays(dirtyDate: Date, dirtyAmount: number) {
  const date = new Date(dirtyDate);
  const amount = toInteger(dirtyAmount);
  date.setUTCDate(date.getUTCDate() + amount);
  return date;
}

export function subDays(dirtyDate: Date, dirtyAmount: number) {
  return addUTCDays(dirtyDate, -dirtyAmount);
}

export function isToday(dirtyDate: Date) {
  return isSameUTCDay(dirtyDate, new Date());
}

export function addUTCMilliseconds(dirtyDate: Date, dirtyAmount: number) {
  var timestamp = new Date(dirtyDate).getTime();
  var amount = toInteger(dirtyAmount);
  return new Date(timestamp + amount);
}
export function addUTCMinutes(dirtyDate: Date, dirtyAmount: number) {
  const amount = toInteger(dirtyAmount);
  return addUTCMilliseconds(dirtyDate, amount * MILLISECONDS_IN_MINUTE);
}
export function subUTCMinutes(dirtyDate: Date, dirtyAmount: number) {
  return addUTCMinutes(dirtyDate, -dirtyAmount);
}

export function startOfUTCDay(oldDate: Date) {
  const date = new Date(oldDate);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

export function endOfUTCDay(oldDate: Date) {
  const date = new Date(oldDate);
  date.setUTCHours(23, 59, 59, 999);
  return date;
}

export function startOfUTCMonth(oldDate: Date) {
  const date = new Date(oldDate);
  date.setUTCDate(1);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}
export function startOfUTCYear(dirtyDate: any) {
  var cleanDate = new Date(dirtyDate);
  var date = new Date(0);
  date.setUTCFullYear(cleanDate.getUTCFullYear(), 0, 1);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

export function startOfUTCWeek(dirtyDate: any, options: { weekStartsOn?: number } = {}) {
  var weekStartsOn = options.weekStartsOn || 0;

  // Test if weekStartsOn is between 0 and 6 _and_ is not NaN
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  }

  var date = new Date(dirtyDate);
  var day = date.getUTCDay();
  var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
  date.setUTCDate(date.getUTCDate() - diff);
  date.setUTCHours(0, 0, 0, 0);
  return date;
}

// converts seconds to milliseconds
export function toMils(t: number) {
  return t * 1000;
}
