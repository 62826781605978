import React from "react";
import { Google } from "assets/icons/internal";
import { Button } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { HStack } from "libs/layouts";

const icons = {
  google: {
    Icon: Google,
  },
  saml: {
    Icon: BusinessIcon,
  },
  password: {
    Icon: PermIdentityIcon,
  },
};

export interface ProviderButtonProps {
  type: keyof typeof icons;
  label: string;
  onClick: () => void;
}

export function ProviderButton(props: ProviderButtonProps) {
  const { Icon } = icons[props.type];
  return (
    <Button
      size="large"
      variant="outlined"
      onClick={props.onClick}
      style={{
        textTransform: "none",
      }}
    >
      <HStack
        space={2}
        align="center"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Icon style={{ fontSize: 20, width: 20, height: 20, minWidth: 20 }} />
        <span>{props.label}</span>
      </HStack>
    </Button>
  );
}
