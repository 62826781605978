import React from "react";
import MTab, { TabProps } from "@mui/material/Tab";
import { css } from "ui/css";

const styles = {
  tab: css({
    zIndex: 1,
    textTransform: "uppercase !important",
  }),
};

export const Tab = function Tab(props: TabProps) {
  return <MTab {...props} className={styles.tab({ className: props.className }).className} />;
} as typeof MTab;

export { default as Tabs } from "@mui/material/Tabs";
