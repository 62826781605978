import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { GridPinnedColumns } from "@mui/x-data-grid-pro/hooks/features/columnPinning/gridColumnPinningInterface";
import { CHGridColDef, GridStateFields } from "./types";
import { columnOrderWithoutFixed } from "./utils";

/**
 * @param {CHGridColDef[]} columns If columns are a result of a function, you need to wrap it into useMemo.
 * @param {Partial<GridStateFields>} overrides Overrides for the default state.
 * @returns {GridStateFields} Default state for the grid.
 */
export function getDefaultState(
  columns: CHGridColDef[],
  overrides?: Partial<GridStateFields>
): GridStateFields {
  const shouldShowColumn = (col: CHGridColDef) =>
    !!col.defaultVisible || col.hideable === false || col.field === "actions";

  const columnVisibilityModel = [...columns].reduce(
    (map, col) => ({
      ...map,
      [col.field]: shouldShowColumn(col),
    }),
    {}
  ) as GridColumnVisibilityModel;
  return {
    columnVisibilityModel,
    search: "",
    sortModel: overrides?.sortModel || [],
    pinnedColumns: { left: [], right: [] } as GridPinnedColumns,
    columnsOrder: columnOrderWithoutFixed(columns.map((column) => column.field)),
    columnsWidths: {},
    density: "standard",
    pickedRow: undefined,
    filters: {},
  };
}
