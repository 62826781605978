import { IReducerBuilder, Unpacked } from "okdux";
import { useSelector as useInternal } from "react-redux";

export function useSelector<RootState, Res>(
  fn: (r: RootState, props: any) => Res,
  props?: any
): Res;
export function useSelector<T>(store: IReducerBuilder<Unpacked<T>>, props?: any): Unpacked<T>;

export function useSelector(selector: any) {
  const select = selector.select ? selector.select : selector;
  return useInternal(select);
}
