import React from "react";
import Bg from "assets/images/404.svg";
import { css } from "ui/css";
import { ErrorPageTemplate } from "../templates/ErrorPageTemplate";
import { messages } from "../messages";
import { RouteUserCheck } from "../molecules";
import { usePageErrorLog } from "./usePageErrorLog";

const bgClassName = css({
  backgroundImage: `url(${Bg})`,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NotFound = () => {
  usePageErrorLog();
  return (
    <RouteUserCheck>
      <ErrorPageTemplate
        title="404"
        className={bgClassName().className}
        subTitle={messages.notFoundPageSubTitle}
      />
    </RouteUserCheck>
  );
};
