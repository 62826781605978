import { useState, useEffect, useRef, useCallback } from "react";

type AsyncState<T> =
  | {
      loading: boolean;
      error?: undefined;
      value?: undefined;
    }
  | {
      loading: false;
      error: Error;
      value?: undefined;
    }
  | {
      loading: false;
      error?: undefined;
      value: T;
    };

/**
 * @deprecated use react query instead
 */
export function useAsyncSimple<Result = any>(
  fn?: () => Promise<Result>,
  initialState: AsyncState<Result> = { loading: true }
) {
  return useAsyncSimple2(fn, initialState).state;
}

/**
 * @deprecated use react query instead
 */
export function useQuery<Result = any>(
  fn?: () => Promise<Result>,
  initialState: AsyncState<Result> = { loading: true }
) {
  const res = useAsyncSimple2(fn, initialState);
  return [res.state, res.set] as const;
}

/**
 * @deprecated use react query instead
 */
export function useAsyncSimple2<Result = any>(
  fn?: () => Promise<Result>,
  initialState: AsyncState<Result> = { loading: true }
) {
  const [state, set] = useState(initialState);
  const [refetchCounter, setRefetchCounter] = useState(0);

  useEffect(() => {
    let setState = set;
    let call = (fn: any) => fn();
    setState((state) => {
      return { ...state, loading: true } as any;
    });
    Promise.resolve()
      .then(() => call(fn))
      .then(
        (value) => {
          setState({ value, loading: false });
          return value;
        },
        (error) => {
          setState({ error, loading: false });

          return error;
        }
      );
    return () => {
      setState = () => null;
      call = () => Promise.resolve();
    };
  }, [fn, refetchCounter]);

  return { state, set, refetch: () => setRefetchCounter((s) => s + 1) };
}

/**
 * @deprecated use react query instead
 */
export function useAsyncCallback<A, R>(
  fn: (...arg: A[]) => Promise<R>,
  cb?: (args: R) => void,
  errorCb?: (error: Error) => void
) {
  const [req, setReq] = useState<undefined | Function>(undefined);
  const reqestFn = useCallback(() => {
    if (req === undefined) {
      return Promise.resolve(null);
    }
    return req();
  }, [req]);
  const data = useAsyncSimple(reqestFn);
  const cbRef = useRef(null as any);
  cbRef.current = cb;

  const errorCbRef = useRef(null as any);
  errorCbRef.current = errorCb;
  useEffect(() => {
    if (data.value && !data.error && !data.loading) {
      cbRef.current && cbRef.current(data.value);
    } else if (data.error && !data.loading) {
      errorCbRef.current && errorCbRef.current(data.error);
    }
  }, [data.value, data.error, data.loading]);
  const call = (...data: A[]) => setReq(() => () => fn(...data));

  return [
    call,
    {
      ...data,
      loading: req ? data.loading : false,
    } as AsyncState<R>,
  ] as const;
}
