import React from "react";
import { Merge } from "ts-essentials";
import { Popover as MuiPopover } from "@mui/material";
import { PopoverProps as MuiPopoverProps } from "@mui/material/Popover";
import { usePopoverDisclosure } from "libs/hooks";

export type PopoverProps = Merge<
  Omit<MuiPopoverProps, "open">,
  {
    children: React.ReactElement;
    open?: boolean;
    onOpenClose?: (isOpen: boolean) => void;
    target: (args: {
      onClick: (event: React.MouseEvent<any>) => void;
      onOpen: ($el: HTMLElement) => void;
      isOpen: boolean;
    }) => React.ReactElement;
  }
>;

export function Popover({ children, target, open, onOpenClose, ...rest }: PopoverProps) {
  const { onOpen, ...props } = usePopoverDisclosure();
  const isOpen = open ?? props.open;
  const handleClose = () => {
    props.onClose();
    onOpenClose?.(false);
  };

  return (
    <>
      <MuiPopover {...props} {...rest} onClose={handleClose} open={isOpen}>
        {children}
      </MuiPopover>
      {target({
        onOpen,
        isOpen: !isOpen,
        onClick: (v) => {
          onOpen(v.target);
          onOpenClose?.(!isOpen);
        },
      })}
    </>
  );
}
