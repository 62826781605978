import { DataMasking } from "./types";

export class TrieNode {
  public isLeaf: boolean = false;
  private condition: DataMasking.PathCondition | null = null;
  private branches: Record<string, TrieNode> = {};

  addBranch(segment: string) {
    if (!this.hasBranch(segment)) {
      this.branches[segment] = new TrieNode();
    }

    return this.branches[segment];
  }

  hasBranch(segment: string) {
    return !!this.branches[segment];
  }

  getBranch(segment: string) {
    return this.branches[segment];
  }

  makeLeaf(): void {
    this.isLeaf = true;
  }

  addCondition(condition: DataMasking.PathCondition) {
    if (!this.isLeaf) {
      throw new Error("Condition can only be set on leaf nodes");
    }

    this.condition = condition;
  }

  getCondition(): DataMasking.PathCondition | null {
    return this.condition;
  }

  shouldMask(obj: any): boolean {
    return this.condition ? this.condition(obj) : true;
  }

  toArray(): any[] {
    const arr = [];

    for (const [key, value] of Object.entries(this.branches)) {
      if (value.isLeaf) {
        arr.push(key);
      } else {
        arr.push([key, value.toArray()]);
      }
    }

    return arr;
  }
}
