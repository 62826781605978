import React, { FunctionComponent } from "react";
import { CircularProgress } from "@mui/material";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { styled } from "ui/css";

const Wrapper = styled("div", { textAlign: "center", display: "flex", height: "100%" });

interface ILoaderProps extends CircularProgressProps {
  loading: boolean;
}

export const Loader: FunctionComponent<React.PropsWithChildren<ILoaderProps>> = ({
  loading,
  children,
  ...props
}) =>
  loading ? (
    <Wrapper aria-label="Loading">
      <CircularProgress style={{ margin: "auto" }} {...props} />
    </Wrapper>
  ) : (
    <>{children}</>
  );
