import { PointerEvent, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { dragHandleDisabledAttribute } from "./constants";

export const useSortableCondition = (id: string, conditionIndex: number, ruleIndex: number) => {
  const { listeners, ...rest } = useSortable({
    id,
    data: {
      conditionIndex,
      ruleIndex,
    },
  });

  const patchedListeners = useMemo(() => {
    return {
      onPointerDown: (event: PointerEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;
        const current = event.currentTarget as HTMLElement;
        // Disable start dragging by inputs, buttons, etc
        if (
          current.contains(target) &&
          !target.closest(
            `input, a, button, textarea, [data-drag-handle="${dragHandleDisabledAttribute}"]`
          )
        ) {
          listeners?.onPointerDown(event);
        }
      },
    };
  }, [listeners]);

  return {
    ...rest,
    listeners: patchedListeners,
  };
};
