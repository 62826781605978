import { useCurrentUser } from "shared/auth-hooks";
import { ENV } from "runenv";
import { useQuery, QueryClient } from "@tanstack/react-query";
import { GlobalSettingsDocument, GlobalSettingsQuery } from "api/graphql";
import { sendGQLRequest } from "api/graphql/network";

export type Feature = "gsuite" | "CONTENT_REPORT";

export type IBetaState = {
  [key: string]: boolean;
};

export type BetasType = Record<Feature, boolean>;

export const initialBetaState: BetasType = {
  gsuite: ENV.IS_BETA_ENV,
  CONTENT_REPORT: Boolean(ENV.FEATURES.ENABLE_EXTERNAL_CONTENT),
};

const ONLY_CYBERHAVEN_USERS: Feature[] = [];

export function preloadBetas(queryClient: QueryClient) {
  queryClient.prefetchQuery({
    queryKey: ["beta"],
    queryFn: () => {
      return sendGQLRequest(GlobalSettingsDocument, {}).then((el) => getBeta(el));
    },
  });
}
function getBeta(el: GlobalSettingsQuery): any {
  return el?.global_settings?.[0]?.data?.beta;
}

export function useBetas(): BetasType {
  const { data } = useCurrentUser();
  const isCyberHavenUser = data?.email?.endsWith("cyberhaven.com") || false;

  for (const feature of ONLY_CYBERHAVEN_USERS) {
    initialBetaState[feature] = initialBetaState[feature] && isCyberHavenUser;
  }

  return {
    ...initialBetaState,
    ...useQuery({
      queryKey: ["beta"],

      queryFn: () =>
        sendGQLRequest(GlobalSettingsDocument, {}).then((el) =>
          getBeta(el)
            ? {
                ...(getBeta(el) as IBetaState),
                CONTENT_REPORT: Boolean(initialBetaState["CONTENT_REPORT"]),
              }
            : initialBetaState
        ),

      placeholderData: initialBetaState,
      staleTime: 10 * 10,
    }).data,
  };
}
