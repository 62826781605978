import formatDate from "date-fns/format";
import { toUTC, formatUTC } from "libs/time-utc";

export const DATE_FORMAT_WITH_TIME = "yyyy-MM-dd HH:mm";
export const DATE_FORMAT_WITH_TIME_SEC = "yyyy-MM-dd HH:mm:ss.SSS";
export const DATE_FORMAT_EXPORT = "yyyy-MM-dd_HH-mm-ss";
export const DEFAULT_DATE_FORMAT = DATE_FORMAT_WITH_TIME_SEC;

export const DATE_FORMAT = "yyyy-MM-dd";

export function formatDateUtc(date: Date, format = DATE_FORMAT) {
  return formatUTC(date, format);
}

export function formatDateTime(date: string | Date, format = DEFAULT_DATE_FORMAT) {
  if (!date) {
    return null;
  }
  return formatDateTimeUtc(new Date(date), format);
}

export function formatDateTimeLocal(date: Date, format = DEFAULT_DATE_FORMAT) {
  return formatDate(date ? new Date(date) : new Date(), format);
}

export function formatDateTimeUtc(date: Date, format = DEFAULT_DATE_FORMAT) {
  return formatUTC(date, format);
}

export function formatDateForExport(date: Date) {
  return formatUTC(date, DATE_FORMAT_EXPORT);
}

export { toUTC };
