import React from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "ui/atoms/Box";
import { UIButton, UIButtonProps } from "ui/atoms/UIButton";

export type LoadingButtonProps = UIButtonProps & {
  loaderColor?: "inherit" | "primary" | "secondary";
  loader?: JSX.Element;
  loadingIndicator?: string;
  isLoading?: boolean;
};

export function LoadingButton({
  onClick,
  children,
  loaderColor = "inherit",
  loader = <CircularProgress size={15} color={loaderColor} />,
  isLoading,
  loadingIndicator,
  ...props
}: LoadingButtonProps) {
  return (
    <UIButton style={{ whiteSpace: "nowrap" }} disabled={isLoading} onClick={onClick} {...props}>
      {isLoading &&
        (loadingIndicator ? (
          loadingIndicator
        ) : (
          <Box sx={{ centerContent: true, marginRight: 8 }}>{loader}</Box>
        ))}
      {(!loadingIndicator || !isLoading) && <span>{children}</span>}
    </UIButton>
  );
}
