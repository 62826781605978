import React from "react";
import { FieldRenderProps } from "react-final-form";
import { InputBase, MenuItem, Select } from "@mui/material";
import { types, types_SizeUnitEnum } from "api/gen";
import { HStack } from "libs/layouts";
import { css } from "ui/css";
import { theme } from "ui/theme";
import { localTheme } from "modules/risks/shared";
import { sizeEnumOrdered } from "../search.schema";

const rootStyles = css({
  "position": "relative",
  "border": `1px solid ${theme.palette.grey[300]}`,
  "fontSize": "14px",
  "lineHeight": "1.5em",
  "paddingLeft": "10px",
  "paddingRight": "10px",
  "&:focus-within, &:hover": { border: `1px solid ${localTheme.blue2}` },
  "minHeight": "43px",
});

const selectStyles = css({
  "&::after,  &::before": { display: "none" },
});

const inputStyles = css({
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  'input[type="number"]': { MozAppearance: "textfield" },
  "flexGrow": 1,
});

type InputProps = FieldRenderProps<any>["input"];
export interface NumericInputProps extends Omit<InputProps, "name"> {
  modifier: types.SizeUnit | boolean;
  onModifierChange(v: any): any;
  style?: any;
}

export function NumericInput({ modifier, onModifierChange, ...props }: NumericInputProps) {
  const selectValue = typeof modifier === "boolean" ? types_SizeUnitEnum.SizeUnitByte : modifier;
  return (
    <HStack className={rootStyles().className} style={props.style}>
      <InputBase
        inputProps={{
          "data-type": "auto-expand",
        }}
        {...props}
        className={inputStyles().className}
        inputMode="numeric"
      />
      {modifier && (
        <Select
          value={selectValue}
          onChange={(e) => {
            onModifierChange(e.target.value);
          }}
          className={selectStyles().className}
          variant="standard"
        >
          {sizeEnumOrdered.map((el, i) => (
            <MenuItem value={el} key={i}>
              {el}
            </MenuItem>
          ))}
        </Select>
      )}
    </HStack>
  );
}
