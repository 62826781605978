import { createAction, AsyncActions } from "okdux";

interface IAsyncMixin<Params, Result> {
  (param?: Params, ...args: any): Result;
  use: (...args: any[]) => any;
}

export type AsyncAction<
  Success,
  Params,
  Loading = any,
  TR = Promise<Success>,
  Failure = any
> = IAsyncMixin<Params, TR> & AsyncActions<Loading, Success, Failure>;

/**@deprecated */
export function asyncAct<
  Success,
  Params = any,
  Loading = any,
  TR = Promise<Success>,
  Failure = any
>() {
  return (name: string): AsyncAction<Success, Params, Loading, TR, Failure> => {
    let thunk: any;
    const actions = {
      request: createAction(name + "_REQUEST"),
      success: createAction(name + "_SUCCESS"),
      failure: createAction(name + "_FAILURE"),
      cancel: createAction(name + "_CANCEL"),
      use: (t: any) => {
        thunk = t && t(actions);
      },
    };
    const call = (...args: any[]) => {
      const dispatch = (actions.request as any).getDispatch();
      if (thunk && dispatch) {
        return dispatch(thunk(...args));
      } else {
        throw new Error(`called a not implemented action ${name}`);
      }
    };

    return Object.assign(call, actions) as any;
  };
}
