import React, { useEffect } from "react";
import { trim } from "lodash";
import { parse } from "query-string";
import { resolveService } from "service";
import { logInfo } from "shared/logger";
import { useLocation } from "react-router-dom";
import { RegistrationForm } from "../organisms";
import { PageTemplate } from "./PageTemplate";

export const RegistrationPage = () => {
  const { search } = useLocation();
  const params = parse(search);
  const email = trim(params.email as any, '"');
  const isPasswordReset = !!params.reset;
  useEffect(() => {
    // on invite accept logout user
    async function run() {
      const auth = await resolveService("authService");
      auth.logout();
    }
    run().catch((err) => {
      logInfo(err);
    });
  }, []);
  return (
    <PageTemplate>
      <RegistrationForm email={email} isPasswordReset={isPasswordReset} />
    </PageTemplate>
  );
};

export default RegistrationPage;
