import { useState, useMemo, useRef, useLayoutEffect, useCallback } from "react";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { useControlledSet, usePagination } from "libs/hooks";

export type Sort = {
  sort_by: string;
  sort_desc: boolean;
};

export function useSorting(initialSort?: Sort) {
  const [sort, setSort] = useState<Sort | undefined>(initialSort);

  return { sort, setSort };
}

function useTableSelection<T>({
  records,
  getKeys = (d: any) => d?.map((el: any) => el.id),
}: {
  records: T;
  getKeys?: (v: T) => string[];
}) {
  const [isAllOnAllPageSelected, setIsAllOnAllPageSelected] = useState(false);
  const [selected, selectedActions] = useControlledSet<string>();
  const recordKeys = useMemo(() => getKeys(records) || [], [records]);
  const isAllSelected = useMemo(
    () => new Set(recordKeys).size === selected.size,
    [recordKeys, selected.size]
  );
  const resetAll = () => {
    selectedActions.reset(new Set());
    setIsAllOnAllPageSelected(false);
  };
  const isSelected = (id: string) => isAllOnAllPageSelected || selectedActions.has(id);
  const toggle = (id: string) => {
    if (isAllOnAllPageSelected) {
      const newSelected = new Set(recordKeys);
      newSelected.delete(id);
      selectedActions.reset(newSelected);
      setIsAllOnAllPageSelected(false);
    } else {
      selectedActions.toggle(id);
    }
  };
  const toggleAll = () => {
    const wasAllSelected = isAllOnAllPageSelected;
    if (isAllOnAllPageSelected) {
      setIsAllOnAllPageSelected(false);
    }
    selectedActions.reset(new Set(isAllSelected || wasAllSelected ? [] : recordKeys));
  };
  const selectAll = () => {
    if (isAllOnAllPageSelected) {
      setIsAllOnAllPageSelected(false);
    }
    selectedActions.reset(new Set(recordKeys));
  };
  const selectOnAllPages = () => {
    setIsAllOnAllPageSelected(true);
    selectedActions.reset(new Set([]));
  };
  const removeItems = (ids: string[]) => {
    selectedActions.removeMany(ids);
  };
  const resetMissing = () => {
    const selected = recordKeys?.filter(isSelected) || [];
    selectedActions.reset(new Set(selected));
  };

  return {
    resetMissing,
    removeItems,
    selectedSet: selected,
    selectedSize: selected.size,
    isAllSelected,
    isSelected,
    toggle,
    toggleAll,
    selectAll,
    selectOnAllPages,
    toggleAllOnAllPage: () => {
      setIsAllOnAllPageSelected((s) => !s);
      if (!isAllOnAllPageSelected) {
        selectedActions.reset(new Set());
      }
    },
    isAllOnAllPageSelected,
    resetAll,
    isAllIntermediate:
      !isAllOnAllPageSelected && recordKeys.length < selected.size && selected.size > 0,
  };
}

type HookResponse<TResult, TError> = UseInfiniteQueryResult<InfiniteData<TResult>, TError>;

type Params<TResult, RecordsResult> = {
  getPageRecords?: (data: TResult) => RecordsResult;
  getRecordsCount?: (count: RecordsResult) => number;
  getTotalCount?: (data: TResult) => number;
  getExportId?: (data: TResult) => string;
  getSelectionKeys?: (item: any) => string[];
  initialSort?: Sort;
  initialPerPage?: number;
  initialPage?: number;
};

export function useCollection<TResult, TError, RecordsResult extends any>(
  useFetchData: (args: Sort & { page: number; perPage: number }) => HookResponse<TResult, TError>,
  {
    getPageRecords = (d: any) => d?.records,
    getRecordsCount = (rec: any) => rec?.length,
    getTotalCount = (d: any) => d?.total,
    getSelectionKeys,
    initialSort,
    initialPerPage = 25,
    initialPage = 0,
  }: Params<TResult, RecordsResult>
) {
  const { page, perPage, handlePage, handlePageSize } = usePagination({
    page: initialPage,
    perPage: initialPerPage,
  });
  const { sort, setSort } = useSorting(initialSort);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, error, ...restQuery } =
    useFetchData({
      sort_by: sort?.sort_by!,
      sort_desc: sort?.sort_desc!,
      page: page!,
      perPage: perPage!,
    });

  const currentPage = data?.pages[page!];
  const records = getPageRecords(currentPage!);
  const recordsCount = getRecordsCount(records);
  const totalCount = getTotalCount(data?.pages[0]!);
  const fetchedPagesCount = data?.pages.length ?? 0;
  const isEmpty = recordsCount === 0 && !isLoading && !isFetching;
  const { resetAll, resetMissing, isAllOnAllPageSelected, ...restSelection } = useTableSelection({
    records,
    getKeys: getSelectionKeys,
  });
  const prevRecordsRef = useRef(false);

  useLayoutEffect(() => {
    if ((data?.pages.length || 0) <= 1 && page !== 0) {
      handlePage(0);
    }
  }, [data?.pages.length]);

  useLayoutEffect(() => {
    const newData = isFetching || isLoading;
    if (newData === false && prevRecordsRef.current === true && !isAllOnAllPageSelected) {
      resetMissing();
    }
    prevRecordsRef.current = newData;
  });

  const getPaginationProps = () => {
    return {
      isLoading: recordsCount === 0 && isLoading,
      isNextDisabled: isLoading || (!hasNextPage && page === recordsCount - 1) || isFetching,
      perPage: perPage,
      isLastPage: !hasNextPage && fetchedPagesCount - 1 === page,
      page,
      count: totalCount,
      onChangePerPage: (newPerPage: number) => {
        handlePageSize(newPerPage, 0);
      },
      onChangePage: (newPage: any) => {
        if (newPage >= fetchedPagesCount) {
          fetchNextPage();
        }
        handlePage(newPage);
      },
    };
  };
  const getTableProps = () => {
    return {
      isLoading: isLoading || isFetching,
      sort,
      onSortChange: setSort,
      data: records,
    };
  };
  const getSelectionProps = () => {
    return {
      hasSelection: restSelection.selectedSet.size !== 0 || isAllOnAllPageSelected,
      totalCount: isAllOnAllPageSelected ? totalCount : restSelection.selectedSet.size,
      selectedKeys: isAllOnAllPageSelected ? ["all"] : [...restSelection.selectedSet.keys()],
      isAllOnAllPageSelected: isAllOnAllPageSelected,
    };
  };
  const selection = {
    resetAll,
    isAllOnAllPageSelected,
    ...restSelection,
  };
  const getProviderProps = () => ({
    selection,
    sort,
  });

  return {
    error,
    getPaginationProps,
    getTableProps,
    getSelectionProps,
    records,
    totalCount,
    isEmpty,
    isFetching,
    isLoading,
    sort,
    perPage,
    setSort,
    ...restQuery,
    selection,
    getProviderProps,
  };
}

export function useInfiniteScrollCollection<TResult, TError, RecordsResult extends any>(
  useFetchData: (args: Sort & { page: number; perPage: number }) => HookResponse<TResult, TError>,
  {
    getPageRecords = (d: any) => d?.records,
    getTotalCount = (d: any) => d?.total,
    getExportId = (d: any) => d?.export_id,
    getSelectionKeys,
    initialSort,
    initialPage = 0,
    initialPerPage = 50,
  }: Params<TResult, RecordsResult>
) {
  const { sort, setSort } = useSorting(initialSort);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, error, ...restQuery } =
    useFetchData({
      sort_by: sort?.sort_by!,
      sort_desc: sort?.sort_desc!,
      page: initialPage,
      perPage: initialPerPage,
    });

  const records = data?.pages.reduce((acc, curr) => acc.concat(getPageRecords(curr)), [] as any[]);
  const totalCount = getTotalCount(data?.pages[0]!);
  const isEmpty = records?.length === 0 && !isLoading && !isFetching;
  const { resetAll, resetMissing, isAllOnAllPageSelected, ...restSelection } = useTableSelection({
    records,
    getKeys: getSelectionKeys,
  });
  const prevRecordsRef = useRef(false);

  useLayoutEffect(() => {
    const newData = isFetching || isLoading;
    if (newData === false && prevRecordsRef.current === true && !isAllOnAllPageSelected) {
      resetMissing();
    }
    prevRecordsRef.current = newData;
  });

  const getTableProps = () => {
    return {
      sort,
      isLoading: isLoading || isFetching,
      onSortChange: setSort,
      data: records!,
    };
  };
  const getSelectionProps = () => {
    return {
      hasSelection: restSelection.selectedSet.size !== 0 || isAllOnAllPageSelected,
      totalCount: isAllOnAllPageSelected ? totalCount : restSelection.selectedSet.size,
      selectedKeys: isAllOnAllPageSelected ? ["all"] : [...restSelection.selectedSet.keys()],
      isAllOnAllPageSelected: isAllOnAllPageSelected,
    };
  };
  const selection = {
    resetAll,
    isAllOnAllPageSelected,
    ...restSelection,
  };
  const getProviderProps = () => ({
    selection,
    sort,
  });
  const intersectionObserver = useRef<IntersectionObserver>();

  const infiniteScrollRef = useCallback(
    (item: any) => {
      if (isLoading || isFetching) {
        return;
      }
      if (intersectionObserver.current) {
        intersectionObserver.current?.disconnect();
      }

      intersectionObserver.current = new IntersectionObserver((items) => {
        if (items[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (item) {
        intersectionObserver.current.observe(item);
      }
    },
    [isFetching, isLoading, fetchNextPage, hasNextPage]
  );

  const showInfinityLoader = isFetching || (isLoading && records?.length === 0);

  const exportId = getExportId(data?.pages[0]!);

  return {
    error,
    records,
    getTableProps,
    getSelectionProps,
    fetchMore: fetchNextPage,
    infiniteScrollRef,
    totalCount,
    isEmpty,
    isFetching,
    isLoading,
    sort,
    setSort,
    showInfinityLoader,
    exportId,
    ...restQuery,
    selection,
    getProviderProps,
  };
}
