import React from "react";
import { Merge } from "ts-essentials";
import { PartialBy } from "shared";
import { TablePagination } from "@mui/material";
import { TablePaginationProps } from "@mui/material/TablePagination";
import { css } from "ui/css";

const spacerStyles = css({
  display: "none",
});

const toolbarStyles = css({
  justifyContent: "flex-end",
});

const classes = {
  spacer: spacerStyles().className,
  toolbar: toolbarStyles().className,
};

export function Pagination({
  onChangePage,
  disabled,
  ...props
}: Merge<
  PartialBy<TablePaginationProps, "onPageChange">,
  { disabled: { next?: boolean; prev?: boolean }; onChangePage: (n: number) => void }
>) {
  return (
    <TablePagination
      component={"div" as any}
      classes={classes}
      rowsPerPageOptions={[25]}
      nextIconButtonProps={{
        disabled: disabled.next,
      }}
      backIconButtonProps={{
        disabled: disabled.prev || props.page === 0,
      }}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count === Infinity ? "Many" : count}`
      }
      onPageChange={(_, n) => {
        onChangePage(n);
      }}
      {...props}
    />
  );
}
