/* eslint-disable prettier/prettier */
import { newPromise } from "libs/async";

export function setupPendo({ apiKey }) {
  const url = `https://content.analytics.cyberhaven.io/agent/static/${apiKey}/pendo.js`;
  const [promise, resolve, reject] = newPromise();
  (function (p, e, n, d, o) {
    if (!url) {
      return;
    }
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ["initialize", "identify", "updateOptions", "pageLoad"];
    for (w = 0, x = v.length; w < x; ++w) {
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    }
    y = e.createElement(n);
    y.async = !0;
    y.src = url;
    y.onerror = reject;
    y.onabort = reject;
    y.onload = () => resolve(window.pendo);
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, "script", "pendo");
  return promise;
}
