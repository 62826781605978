import { useMemo, useRef } from "react";
import { isEqual, upperFirst } from "lodash";

type State = Record<string, any>;
type Setter<T> = (v: T) => void;
export type StateWithSetters<S extends State> = {
  [key in keyof S & string as `set${Capitalize<key>}`]: Setter<S[key]>;
} & {
  [key in keyof S & string as `reset${Capitalize<key>}`]: () => void;
} & {
  [key in keyof S]: S[key];
} & {
  resetAll: () => void;
};

// Extends state with setters for each field and reset method
export function useStateWithSetters<S extends State>({
  state,
  setState,
  defaultState,
}: {
  state: S;
  setState: Setter<S>;
  defaultState: S;
}): StateWithSetters<S> {
  const latestState = useRef(state);
  latestState.current = state;

  return useMemo(() => {
    const stateWithSetters: any = {
      resetAll: () => {
        setState(defaultState);
      },
    };
    for (const field of Object.keys(defaultState)) {
      stateWithSetters[field] = latestState.current[field];
      stateWithSetters[`set${upperFirst(field)}`] = (value: any) => {
        if (isEqual(latestState.current?.[field], value)) {
          return;
        }
        latestState.current = { ...latestState.current, [field]: value };
        setState(latestState.current);
      };
      stateWithSetters[`reset${upperFirst(field)}`] = () => {
        latestState.current = { ...latestState.current, [field]: defaultState[field] };
        setState(latestState.current);
      };
    }

    return stateWithSetters;
  }, [latestState.current]);
}
