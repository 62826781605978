import { useMemo, useRef, useState } from "react";
import { useControlledState } from "@react-stately/utils";
import {
  Selection,
  Key,
  MultipleSelectionState,
  MultipleSelection,
  SelectionMode,
  ISelection,
  FocusStrategy,
} from "./types";

/**
 * Manages state for multiple selection and focus in a collection.
 */
export function useMultipleSelectionState(props: MultipleSelection): MultipleSelectionState {
  const { selectionMode = "none" as SelectionMode, disallowEmptySelection } = props;

  // We want synchronous updates to `isFocused` and `focusedKey` after their setters are called.
  // But we also need to trigger a React re-render.
  // So, we have both a ref (sync) and state (async).
  const isFocusedRef = useRef(false);
  const [, setFocused] = useState(false);
  const focusedKeyRef = useRef<Key | null>(null);
  const childFocusStrategyRef = useRef<FocusStrategy | null>(null);
  const [, setFocusedKey] = useState<Key | null>(null);
  const selectedKeysProp = useMemo(
    () => convertSelection(props.selectedKeys!),
    [props.selectedKeys]
  );
  const defaultSelectedKeys = useMemo(
    () => convertSelection(props.defaultSelectedKeys!, new Selection()),
    [props.defaultSelectedKeys]
  );
  const [selectedKeys, setSelectedKeys] = useControlledState(
    selectedKeysProp as Set<Key>,
    defaultSelectedKeys as Set<Key>,
    props.onSelectionChange!
  );

  const disabledKeysProp = useMemo(
    () => (props.disabledKeys ? new Set(props.disabledKeys) : new Set<Key>()),
    [props.disabledKeys]
  );

  return {
    selectionMode,
    disallowEmptySelection: disallowEmptySelection!,
    get isFocused() {
      return isFocusedRef.current;
    },
    setFocused(f) {
      isFocusedRef.current = f;
      setFocused(f);
    },
    get focusedKey() {
      return focusedKeyRef.current!;
    },
    get childFocusStrategy() {
      return childFocusStrategyRef.current!;
    },
    setFocusedKey(k, childFocusStrategy = "first") {
      focusedKeyRef.current = k;
      childFocusStrategyRef.current = childFocusStrategy;
      setFocusedKey(k);
    },
    selectedKeys,
    setSelectedKeys,
    disabledKeys: disabledKeysProp,
  };
}

function convertSelection(selection: Iterable<Key>, defaultValue?: Selection): ISelection {
  if (!selection) {
    return defaultValue!;
  }

  return new Selection(selection);
}
