import { Collection, Node, Key } from "./types";
import { selectionType } from "./SelectionManager";

export class TreeCollection<T> implements Collection<Node<T>> {
  private keyMap: Map<Key, Node<T>> = new Map();
  private iterable: Iterable<Node<T>>;

  constructor(nodes: Iterable<Node<T>>, { expandedKeys }: { expandedKeys?: Set<Key> } = {}) {
    this.iterable = nodes;
    expandedKeys = expandedKeys || new Set();

    const visit = (node: Node<T>) => {
      this.keyMap.set(node.key, node);
      if (
        node.childNodes &&
        (node.type === selectionType.section || expandedKeys!.has(node.key) || node.hasChildNodes)
      ) {
        for (const child of node.childNodes) {
          visit(child);
        }
      }
    };

    for (const node of nodes) {
      visit(node);
    }

    let index = 0;
    for (const [, node] of this.keyMap) {
      if (node.type === "item") {
        node.index = index++;
      }
    }
  }

  *[Symbol.iterator]() {
    yield* this.iterable;
  }

  get size() {
    return this.keyMap.size;
  }

  getKeys() {
    return this.keyMap.keys();
  }

  getItem(key: Key) {
    return this.keyMap.get(key)!;
  }
}
