import { useMemo } from "react";
import { QueryKey } from "@tanstack/query-core";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { GetFilterSuggestionsFn } from "./types";

export interface GridCollectionOptions {
  key: QueryKey;
  fetchSuggestions: (nextPage: number) => ReturnType<GetFilterSuggestionsFn>;
  search?: string;
  enabled?: boolean;
  pageSize?: number;
}

type Unwrap<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : T;

export function useGridFilterSuggestions({
  key,
  fetchSuggestions,
  enabled,
}: GridCollectionOptions) {
  const query = useInfiniteQuery<Unwrap<ReturnType<GetFilterSuggestionsFn>>>({
    queryKey: key,
    queryFn: async ({ pageParam = 0 }) => fetchSuggestions(pageParam as number),
    getNextPageParam: (lastPage) => lastPage.nextPage || undefined,
    placeholderData: keepPreviousData,
    initialPageParam: 0,
    enabled: enabled,
    staleTime: 5000,
  });

  const data = useMemo(() => {
    if (!query.data) {
      return null;
    }

    return query.data.pages.flatMap((page) => page.options);
  }, [query.data]);

  return { ...query, data };
}
