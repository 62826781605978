import { isObject } from "lodash";

export function mergeObjects(params: Array<{ key: string; data: any }>, currentState: any = {}) {
  const newStateForKeys = params.reduce(
    (acc, { key, data }) => {
      const currentStateForKey = currentState && currentState[key];
      let mergedState = data;
      if (isObject(currentStateForKey) && isObject(data)) {
        mergedState = { ...currentStateForKey, ...data };
      }
      acc[key] = mergedState;
      return acc;
    },
    { ...currentState }
  );
  return newStateForKeys;
}

export const getStateId = () => {
  // search in current url first
  const urlState = new URLSearchParams(window.location.search).get("s");
  if (urlState) {
    return urlState;
  }
  const from = window.history.state?.from as string;
  if (!from) {
    return undefined;
  }
  // check if we have state in from
  return new URLSearchParams(from.substring(from.indexOf("?"))).get("s");
};
