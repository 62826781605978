import { reduce } from "lodash";
import { build, createEffects } from "okdux";
import { IUser, IUserToggle, IUpdated, o365SensorService } from "api/cloud-sensors";
import { o365Auth } from "api/auth/O365Auth";
import { IO365Tenant } from "api/auth/interfaces";
import { apiErrorNotification, notification } from "modules/notification";
import { asyncAct } from "libs/restatex";
import { messages } from "./messages";

export const o365Effects = createEffects(
  {
    fetchUsers: build.async<IUser[], boolean>(),
    login: asyncAct<IO365Tenant>(),
    logout: build.async(),
    getUser: asyncAct<IO365Tenant>(),
    toggle: asyncAct<IUserToggle, Partial<IUser>>(),
    import: asyncAct<IUser[], IUserToggle[]>(),
    enableAll: asyncAct<any, Pick<IUser, "monitored">>(),
    enableForIds: asyncAct<string[], string[]>(),
  },
  "o365"
);

export async function fetchUsers() {
  const { success, request, failure } = o365Effects.fetchUsers;

  request();
  try {
    const data = await o365SensorService.fetchUsers();
    success(data);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function monitor(): Promise<boolean> {
  const { data } = await o365SensorService.monitorUsers();

  const totalChanges = reduce<IUpdated, number>(data, countChanges, 0);
  return !!totalChanges;

  function countChanges(acc: number, changes: IUpdated[keyof IUpdated]): number {
    return acc + changes.length;
  }
}

export async function logout() {
  const { success, request, failure } = o365Effects.logout;

  request();
  try {
    await o365Auth.logout();
    success();
    notification.open({ message: messages.notifications.userDisconnected });
    return;
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.logoutError, description: error.message });
    return Promise.reject(error);
  }
}

export async function importUsers(users: IUser[]) {
  const { success, request, failure } = o365Effects.import;

  request();
  try {
    await o365SensorService.batch(users);
    success(users);
    notification.open({ message: messages.notifications.successfullyImported });
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.importError, description: error.message });
  }
}

export async function enableForIds(users: string[]) {
  const { success, request, failure } = o365Effects.enableForIds;

  request();
  try {
    await o365SensorService.batch(users.map((id) => ({ id, monitored: true })));
    success(users);
    notification.open({ message: messages.notifications.usersMonitoringEnabled });
  } catch (error: any) {
    failure(error);
    notification.error({ message: error.message });
  }
}

export async function login() {
  const { success, request, failure } = o365Effects.login;
  request();
  try {
    const tenant = await o365Auth.login();
    success(tenant);
  } catch (error: any) {
    failure(error);
    notification.error({
      message: messages.notifications.o365LoginError,
      description: error.message,
    });
  }
}

export async function getUser() {
  const { success, request, failure } = o365Effects.getUser;
  request();
  try {
    const tenant = await o365Auth.getUser();
    success(tenant);
  } catch (error) {
    failure(error);
  }
}

export async function toggle({ id, monitored }: any) {
  const { success, request, failure } = o365Effects.toggle;
  request();
  try {
    await o365SensorService.updateUser(id, { monitored });
    notification.open({ message: messages.notifications.userMonitoring(monitored) });
    success({ id, monitored });
  } catch (error: any) {
    failure({ id });
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAll(params: Pick<IUser, "monitored">) {
  const { success, request, failure } = o365Effects.enableAll;
  request();
  try {
    await o365SensorService.updateAll(params);
    notification.open({
      message: messages.notifications.allUserMonitoring(params.monitored),
    });
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAutoMonitoring(params: Pick<IUser, "monitored">) {
  try {
    await o365SensorService.toggleAutoMonitoring(params);
    await fetchUsers();
    notification.open({
      message: messages.notifications.autoMonitoring(params.monitored),
    });
  } catch (error: any) {
    notification.error(apiErrorNotification(error));
  }
}
