export const messages = {
  none: "None",
  keywordsField: {
    label: (fields: string) => "Keywords search for: " + fields,
    placeholder: "Space separated values",
  },
  keywordsSearch: {
    locationWithNoMatch: "Locations with no matches",
    searchIn: (search: string | Element, location: string) => `Search for ${search} in ${location}`,
    searchFor: "Search for",
    in: "in",
    searchByKeywordsPlaceholder: "Search",
    keywordsSearchNotFound: "No matching results",
    keywordsScrolledContent: "Keywords scrolled content",
    keywordsSearchPopover: "Keywords search popover",
    keywordsSearchBackdrop: "Keywords search backdrop",
  },
  newSearchName: "New Search",
  validation: {
    required: "Field is required",
    searchNameUnique: "Search name must be unique",
  },
  categories: {
    endpointHosts: "Endpoints",
    endpointGroups: "Endpoint groups",
    endpointFiles: "Endpoint files",
    mailAccounts: "Mail accounts",
    mailGroups: "Mail groups",
    mailFiles: "Mail files",
    website: "Websites",
    shareHosts: "Shares",
    shareGroups: "Share groups",
    shareFiles: "Share files",
    cloudProviders: "Cloud providers",
    cloudFiles: "Cloud files",
    cloudApps: "Cloud apps",
    cloudAppsFiles: "Cloud apps files",
    cloudAppsAccount: "Cloud apps accounts",
    endpointApps: "Endpoint apps",
    printers: "Printers",
    removableFiles: "Files on removable media",
    removableMedia: "Removable media",
  },
  categoryTypes: {
    webMail: "Web Mail",
    socialMedia: "Social Media",
    consumerInstantMessaging: "Consumer Instant Messaging",
    corporateMessagingAndConferencing: "Corporate Messaging and Conferencing",
    cloudStorage: "Cloud Storage",
    corporateFinancial: "Corporate Financial",
    HRAndPayroll: "HR and Payroll",
    salesAndCRM: "Sales and CRM",
    banking: "Banking",
    cryptoCurrencies: "Crypto Currencies",
    softwareDownloads: "Software Downloads",
    malwareAndHacking: "Malware and Hacking",
    piracy: "Piracy",
    phishing: "Phishing",
    sourceCodeManagement: "Source Code Management",
    researchAndDevelopment: "Research and Development",
    graphicsDesignAndCAD: "Graphics, Design and CAD",
    jobBoardsAndRecruiting: "Job Boards and Recruiting",
    adult: "Adult",
    VPNAndProxies: "VPN and Proxies",
    illegalDrugs: "Illegal Drugs",
    gambling: "Gambling",
    musicAndVideo: "Music and Video",
    shopping: "Shopping",
    gaming: "Gaming",
    other: "Other",
  },
  appTypes: {
    salesforce: "Salesforce",
    onedrive: "OneDrive",
    gdrive: "Google Drive",
    box: "Box",
  },
  zoneTypes: {
    all: "All",
    internal: "Internal",
    external: "External",
  },
  mediaCategoryTypes: {
    all: "All",
    cdDvd: "CD/DVD",
    usb: "USB Devices",
  },
  exclude: "Exclude current",
  include: "Include another",
  templateSelect: {
    edit: "Edit template name",
    deleteMessage: "Do you want to delete this template?",
    noResult: "No results",
  },
  search: "Search",
  queryPanel: {
    from: "Source",
    to: "Destination",
    content: "Content",
    contentPlaceholder: "Content attributes included in search",
    people: "People",
    flowId: "Flow Id Search",
    advancedSearch: "Advanced Search",
    disableMonitoringTitle: "In order to set up monitoring, you should save the current search",
  },
  locationSelection: {
    zonesTitle: "Zones",
    zonesDeprecatedTooltip:
      "Zones field will be removed in the next release, please, use all zones",
    typeOfLocation: "Type of location",
    addNewLocation: "Add new location (OR)",
    notAllZone: (zone: string) => `All ${zone} locations`,
    allZone: (zone: string) => `All ${zone}`,
    sources: "Sources",
    destinations: "Destinations",
    all: "All",
  },
  extensionSelect: {
    fileType: "File type",
    failedToAddExtension: "Failed to add custom extension",
    failedToRemoveExtension: "Failed to remove custom extension",
    noResults: "No Results",
    addNewExtension: (extension: string) => `Add '.${extension}' as a new extension`,
    tagsLimitReached: "Tags limit reached",
    customExtLimitReached: "Custom extensions limit reached",
    removeExtension: "Remove extension",
    placeholder: "Type to search or to add custom extension",
  },
  saveAsBtn: {
    btnLabel: "Save ...",
    modalTitle: "Save search",
    searchNamePlaceholder: "Search name",
    saveAsDuplicate: "Save as new search (duplicate)",
  },
  peopleSearch: {
    group: "Local user group (or SID)",
    user: "Local user name (or SID)",
  },

  flowSearch: {
    flowId: "Flow ID",
  },
  contentSearch: {
    md5: "MD5 hashes",
    sha256: "SHA256 hashes",
    fileName: "Filename",
  },
  clear: "Clear",
  scriptHelper: {
    title: "Painless Scripting Language",
    guidelineLabel: "Full Painless Guide",
    search: "Search",
    searchExp: "Cross domain sharing:",
    searchExample: `doc["source.domain"].size() != 0 &&doc["destination.domain"].size() != 0 && doc["source.domain"].value != doc["destination.domain"].value`,
  },
  luceneHelper: {
    title: "Lucene Syntax",
    guidelineLabel: "Lucene Guide",
    searchQueryLabel: "Search query documentation",
    search: "Search",
    searchExp: "To search for endpoints or mail, but not endpoint apps, use a query like this",
    searchExample: `(source.location: endpoint OR source.location:mail OR destination.location:endpoint OR destination.location:mail) NOT destination.location: endpoint_apps`,
    fuzzySearches: "Fuzzy Searches:",
    fuzzySearchesExp: `To do a fuzzy search use the tilde, "~", symbol at the end of a Single word Term. For
      example to search for a term similar in spelling to "padf-pc" use the fuzzy search`,
    fuzzySearchesExample: "padf-pc~",
    wildcardSearches: "Wildcard Searches:",
    wildcardSearchesExp1: `To search for "text" or "test" you can use the query`,
    wildcardSearchesExample1: `te?t`,
    wildcardSearchesExp2: `Multiple character wildcard search looks for 0 or more characters. For example, to search
    for test, tests or tester, you can use the query`,
    wildcardSearchesExample2: `test*`,
    wildcardSearchesExp3: `Note: You cannot use a * or ? symbol as the first character of a search.`,
    fieldGrouping: "Field Grouping:",
    fieldGroupingExp: `To search for a title that contains both the word "return" and the phrase "pink panther" use
    the query`,
    fieldGroupingExample: `title:(+return +"pink panther")`,
    rangeSearches: "Range Searches:",
    rangeSearchesExp1: "This will find all documents whose titles are between Aida and Carmen",
    rangeSearchesExample1: "title:[Aida TO Carmen]",
    rangeSearchesExp2: "but not including Aida and Carmen.",
    rangeSearchesExample2: "title:{Aida TO Carmen}",
    not: "NOT:",
    notExp: `To search for documents that contain "jakarta apache" but not "Apache Lucene" use the query:`,
    notExample: `"jakarta apache" -"Apache Lucene"`,
    notOr: "or",
    notOrExample: '"jakarta apache" NOT "Apache Lucene"',
    escaping: "Escaping:",
    // eslint-disable-next-line no-useless-escape
    escapingExp: `To escape characters (+ - && || ! ( ) {} [ ] ^ " ~ * ? : \) use the \ before the character.
    For example to search for (1+1):2 use the query`,
    // eslint-disable-next-line no-useless-escape
    escapingExample: `\(1\+1\)\:2`,
  },
  advancedSearch: {
    searchType: "Search type",
    advancedSearchTitle: "Advanced",
    scriptedSearchTitle: "Scripted",
    advancedSearchPlaceholder: "Enter Lucene query...",
    scriptedSearchPlaceholder: "Enter script...",
  },
  monitoring: {
    notifyLabel: "Notify people or groups (via email)",
    blockingPlaceholder: "Warning message",
    warnLabel: "Warn users as they are about to send sensitive data using Outlook",
    blockLabel: "Block potentially harmful users actions",
    warnTypeLabel: "Email app notifications:",
    logLabel: "Log incident",
    aria: {
      openMonitoring: "open monitoring dialog",
    },
    applyLabel: "Save",
    cancelLabel: "Cancel",
    title: "Monitoring",
    subtitle: "When a new flow matches this search, perform the following actions:",
  },
};
