import { LinearProgress as L } from "@mui/material";
import { styled } from "ui/css";
import { theme } from "ui/theme";

export const PageProgress = styled(L, {
  position: "fixed",
  top: "0",
  left: "0",
  height: "4px !important",
  zIndex: theme.zIndex.modal + 2,
  width: "100%",
});
