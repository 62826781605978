import { useMemo, useRef } from "react";

export function useRafCall() {
  const r = useRef<number | null>(null);
  function call(fn: any) {
    cancelAnimationFrame(r.current!);
    r.current = requestAnimationFrame(() => fn());
  }
  function cancel() {
    cancelAnimationFrame(r.current!);
  }
  return useMemo(() => [call, cancel], []);
}
