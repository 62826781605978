import { useMemo, useReducer } from "react";

type Handler<S, P> = (p: P) => S;
type HandlerEmpty<S> = () => S;
type Event<P> = (arg: P) => void;
type EventEmpty = () => void;

type ValueOf<T> = T[keyof T];

type EventTypeMap<T extends { [key: string]: Handler<any, any> }> = {
  [K in keyof T]: T[K] extends HandlerEmpty<any>
    ? EventEmpty
    : T[K] extends Handler<any, infer Payload>
    ? Event<Payload>
    : void;
};

type ActionTypeMap<T extends { [key: string]: Handler<any, any> }> = {
  [K in keyof T]: T[K] extends HandlerEmpty<any>
    ? { type: K }
    : T[K] extends Handler<any, infer Payload>
    ? { type: K; payload: Payload }
    : void;
};

type Methods<S> = (s: S) => { [k: string]: Handler<S, any> };
type Actions<A extends Methods<any>> = ValueOf<ActionTypeMap<ReturnType<A>>>;
export type ExtractActions<A extends Methods<any>> = EventTypeMap<ReturnType<A>>;

function makeReducer<S>(methods: Methods<S>) {
  return (state: S, action: Actions<Methods<S>>) => {
    const actions = methods(state);
    if (actions[action.type]) {
      return (actions[action.type] as any)((action as any).payload);
    }
    throw new Error("unknown action");
  };
}
/**
 * Example
 * const counterActions = (state: number)=>({
 *  increment(n:number) {
 *     return state+=1;
 *  },
 *  decrement(n:number) {
 *     return state-=1;
 *  }
 * })
 *
 * // in component
 * const [state, action] = useActions(counterActions, 0)
 */
export function useActions<S, M extends Methods<S>, Args = any>(
  methods: M,
  initialState: S | Args,
  initialize?: (ars: Args) => S
): [S, ExtractActions<M>] {
  const reducer = useMemo(() => makeReducer(methods), [methods]);
  const actionTypes = useMemo(() => Object.keys(methods(null!)), [methods]);
  const [state, dispatch] = useReducer(reducer, initialState, initialize!);
  const actions = useMemo(
    () =>
      actionTypes.reduce((acc: Record<string, Function>, el) => {
        acc[el] = (payload: any) => dispatch({ type: el, payload });
        return acc;
      }, {}),
    actionTypes
  );
  return [state, actions] as any;
}
