import axios from "axios";
import { IGSuiteTenant, IMultiTenantItem } from "./interfaces";
import { ExternalAuth } from "./ExternalAuth";

class GSuiteAuth extends ExternalAuth<IGSuiteTenant> {
  private tenant?: IGSuiteTenant;

  isAuthorized() {
    return !!this.tenant;
  }

  getUser = async (forceUpdate = false) => {
    if (this.isAuthorized() && !forceUpdate) {
      return this.tenant!;
    }

    const domainsRes = await axios
      .get<{ domains: string[] }>("/v1/config/gsuite/domain/list", {
        meta: {
          ignoreUnauthorized: true,
        },
      } as any)
      .then((response) => {
        return response.data!;
      });

    const domains = await Promise.all(
      domainsRes.domains.map((domain) => {
        return axios.get<IMultiTenantItem>(`/v1/config/gsuite/domain/${domain}`);
      })
    ).then((el) => el.map((el) => el.data));

    this.tenant = {
      domains: domains || [],
    };

    return this.tenant;
  };

  login() {
    return this.authorize("/gsuite/auth/login", "GSuite Login Consent").then(
      this.getUser as any
    ) as any;
  }

  logout(domain?: string) {
    return axios
      .post<IGSuiteTenant>(`/v1/config/gsuite/domain/${domain}/set_enabled`, {
        enabled: false,
        domain,
      })
      .then((response) => {
        if (this.tenant?.domains) {
          this.tenant = {
            ...this.tenant!,
            domains: this.tenant.domains.filter((el) => el.domain !== domain),
          };
        }

        return response;
      });
  }
}

export const gsuiteAuth = new GSuiteAuth();
