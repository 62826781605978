import { mapValues } from "lodash";
import { types } from "api/gen";

type CloudAppMetadataType = Record<
  Exclude<types.CloudApp | "office_365", "">,
  CloudAppMetadataValue
>;
type CloudAppMetadataValue = { label: string; key?: string };

export const cloudAppsMetadata: CloudAppMetadataType = mapFields({
  box: {
    label: "Box",
  },
  onedrive: {
    label: "OneDrive",
  },
  gdrive: {
    label: "Google Drive",
  },
  salesforce: {
    label: "Salesforce",
  },
  dropbox: {
    label: "Dropbox",
  },
  sharepoint: {
    label: "Sharepoint",
  },
  slack: {
    label: "Slack",
  },
  chatgpt: {
    label: "ChatGPT",
  },
  iclouddrive: {
    label: "ICloud Drive",
  },
  office_365: {
    label: "Office 365",
  },
  bing_chat: {
    label: "Bing Chat",
  },
});

export const CLOUD_APP_TYPES = Object.values(cloudAppsMetadata).map((el) => ({
  value: el.key,
  label: el.label,
}));
function mapFields(records: CloudAppMetadataType): CloudAppMetadataType {
  return mapValues(records, (value, key) => ({ ...value, key }));
}
