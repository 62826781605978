import React from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { styled } from "ui/css";
import { theme } from "../theme";

const DragHandleWrapper = styled("div", {
  "position": "absolute",
  "top": "0",
  "left": "0",
  "width": `13.5px`,
  "height": "100%",
  "paddingLeft": "3px",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "visibility": "hidden",
  "background": theme.gray4,
  "pointerEvents": "none",
  "*:hover > &": {
    visibility: "visible",
  },
  "variants": {
    isDragging: {
      true: {
        visibility: "visible",
      },
    },
  },
});

export interface DragHandleProps {
  isDragging: boolean;
}

export const DragHandle = ({ isDragging }: DragHandleProps) => (
  <DragHandleWrapper isDragging={isDragging}>
    <DragHandleIcon fontSize="small" style={{ color: "white", fontSize: 13 }} />
  </DragHandleWrapper>
);
