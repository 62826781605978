import { styled } from "ui/css";
import { space, theme } from "ui";
import { dragHandleDisabledAttribute, dragHandleEnabledAttribute } from "../constants";

export const Draggable = styled("div", {
  "position": "relative",
  [`& [data-drag-handle="${dragHandleEnabledAttribute}"]`]: {
    cursor: "grab",
  },
  [`& [data-drag-handle="${dragHandleDisabledAttribute}"]`]: {
    cursor: "auto",
  },
  "&:before, &:after": {
    content: "",
    position: "absolute",
    height: "4px",
    background: theme.palette.primary.main,
    left: space[3],
    right: space[3],
    visibility: "hidden",
    zIndex: 10,
  },
  "variants": {
    dropIndicatorPosition: {
      after: {
        "&:after": {
          visibility: "visible",
          bottom: "-4px",
        },
      },
      before: {
        "&:before": {
          visibility: "visible",
          top: 0,
        },
      },
    },
  },
});
