import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { NetworkActivityIndicator } from "shared/NetworkActivityIndicator";
import { AnyAction, Store } from "redux";
import { BrowserRouter } from "react-router-dom";
import { useCurrentUser, useFrontendConfig } from "shared/auth-hooks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { NotificationCenter } from "modules/notification";
import { LayoutLoader } from "ui";
import { UserInactivityHandler } from "modules/auth/organisms";
import { Router } from "./Router";
import { StylesWrapper } from "../shared/StylesWrapper";
import { AppEnvironmentProvider } from "../shared/environment";
import { getNavigationIcons } from "./navigation";
import { routes } from "./routes";
import { NewVersionHandler } from "./NewVersionHandler";
import { handlePendoIdentityUpdate } from "./setupExternalServices";

const AppConfigLoader = ({ children }: { children: React.ReactNode }) => {
  const { data: currentUser } = useCurrentUser();
  useFrontendConfig(currentUser, (config) => {
    handlePendoIdentityUpdate(currentUser, config.CUSTOMER_NAME);
  });

  return children;
};

const icons = getNavigationIcons();
// DO NOT ADD ANYTHING THAT REQUIRES AUTH HERE
export function App({
  store,
  queryClient,
}: {
  store: Store<any, AnyAction>;
  queryClient: QueryClient;
}) {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppEnvironmentProvider navigationItems={icons} routes={routes}>
            <StylesWrapper>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NetworkActivityIndicator />
                <NotificationCenter />
                <UserInactivityHandler />
                <NewVersionHandler />
                <Suspense fallback={<LayoutLoader />}>
                  <AppConfigLoader>
                    <Router />
                  </AppConfigLoader>
                </Suspense>
              </LocalizationProvider>
            </StylesWrapper>
          </AppEnvironmentProvider>

          {process.env.NODE_ENV === "development" && (
            <div style={{ position: "fixed", zIndex: 10 }}>
              <ReactQueryDevtools initialIsOpen={false} />
            </div>
          )}
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  );
}
