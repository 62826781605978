import React from "react";
import { theme } from "ui/theme";
import { css } from "ui/css";

const containerStyles = css({
  "display": "flex",
  "flexBasis": "100%",
  "alignItems": "center",
  "color": theme.palette.grey[500],
  "&:before, &:after": {
    content: '""',
    flexGrow: 1,
    background: theme.palette.grey[300],
    height: "1px",
    fontSize: "0px",
    lineHeight: "0px",
  },
});

export function DividerWithText({ children }: any) {
  return (
    <div className={containerStyles().className}>
      <div style={{ padding: "0 8px" }}>{children}</div>
    </div>
  );
}
