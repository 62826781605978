import React from "react";
import type { INavigationItem } from "shared/types";
import { NavLink } from "react-router-dom";
import { ListItem, Tooltip } from "@mui/material";
import { theme } from "ui/theme";
import { styled } from "ui/css";

const CustomListItem = styled(ListItem, {
  "background": "white !important",
  '&[data-selected="true"], :hover, :focus': {
    "::before": {
      display: "block",
      content: '""',
      background: theme.palette.primary.main,
      width: "3px",
      height: ["90%", "30px"],
      position: "absolute",
      left: "0",
    },
    "svg": {
      color: theme.palette.primary.main,
    },
  },
});

export const NavigationItem = ({
  disabled,
  path,
  label,
  "icon": Icon,
  "data-testid": testId,
}: INavigationItem) => {
  return (
    <Tooltip title={label} placement="right">
      <NavLink to={path} data-testid={testId}>
        {({ isActive }) => (
          <CustomListItem disabled={disabled} data-selected={isActive}>
            <Icon color={isActive ? "primary" : "action"} />
          </CustomListItem>
        )}
      </NavLink>
    </Tooltip>
  );
};
