import { createState } from "okdux";
import { applyMiddleware, compose, createStore } from "redux";
import { authState } from "modules/auth/state";
import { contentRulesState } from "modules/content-rules/state";
import { notificationState } from "modules/notification/state";
import { sensorsState } from "modules/sensors/state";

const thunk = () => (next: any) => (action: any) => {
  if (typeof action === "function") {
    return action(store.dispatch, store.getState);
  }
  return next(action);
};

export const rootReducer = createState({
  auth: authState,
  notifications: notificationState,
  sensors: sensorsState,
  contentRules: contentRulesState,
});

export const enhancer = compose(applyMiddleware(thunk));

export function makeStore(userId: string | undefined = undefined): any {
  return createStore(
    (rootReducer as any).reducer,
    {
      auth: { user: { roles: [], id: userId } } as any,
    },
    enhancer
  );
}

export const store = makeStore();
(rootReducer as any).use(store.dispatch, store.getState);
