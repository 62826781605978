import { merge } from "lodash";
import { createState } from "okdux";
import { IUser } from "api/auth/interfaces";
import { effectsFetching } from "libs/restatex";
import { authEffects } from "./effects";

export const initialState: IUser = {
  id: "",
  email: "",
  name: "",
  roles: [],
  sign_in_provider: undefined,
};

const mergeNew = (a: IUser, b: IUser) => merge({}, a, b);

const authState = createState({
  user: createState<IUser>(initialState)
    .on(authEffects.login.success, mergeNew)
    .on(authEffects.signup.success, mergeNew)
    .on(authEffects.refreshToken.success, mergeNew)
    .on(authEffects.logout.success, () => initialState),
  fetching: effectsFetching(authEffects),
});

export { authState };
