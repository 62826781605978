import React, { useEffect, useRef } from "react";
import constate from "constate";
import { ResizeObserver } from "@juggle/resize-observer";

const defaultPadding = {
  top: 0,
  bottom: 0,
};

export const [ScrollProvider, useScrollState] = constate(
  ({
    containerRef,
    padding = defaultPadding,
  }: {
    containerRef: React.RefObject<HTMLElement>;
    padding?: {
      top: number;
      bottom: number;
    };
  }) => {
    return { scrollContainer: containerRef?.current, padding };
  }
);

export function AutoScroll({ children, isActive }: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { padding, scrollContainer } = useScrollState();

  useEffect(() => {
    const $el = ref.current;
    if (!$el || !isActive || !scrollContainer) {
      return;
    }

    const scrollIntoView = () => {
      const containerBox = scrollContainer.getBoundingClientRect();
      const elBox = $el.getBoundingClientRect();
      const paddingBottom = padding.bottom || containerBox.height / 3;
      const isVisible =
        containerBox.top + padding.top < elBox.top &&
        elBox.bottom < containerBox.bottom - paddingBottom;

      if (isVisible) {
        return;
      }
      const topScroll = Math.floor(
        scrollContainer.scrollTop + elBox.bottom - containerBox.bottom + paddingBottom
      );
      scrollContainer.scrollTop = topScroll;
    };

    const observer = new ResizeObserver(scrollIntoView);

    observer.observe($el);
    return () => {
      observer.disconnect();
    };
  }, [ref.current, isActive, scrollContainer, padding]);

  return <div ref={ref}>{children}</div>;
}
