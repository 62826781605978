export * from "./locationsConfigurationInfo";
import contentAnalyzerOptions from "assets/content-attributes/PredefinedContentControlLists.json";
import { nucleuzName, isNucleuz, isEdm, edmName } from "modules/graph/ciHelpers";
import * as IState from "../state/state.h";

const contentOptionsKv: {
  [k: string]: IState.IContent;
} = contentAnalyzerOptions.predefinedContentCondition.reduce<Record<string, any>>((acc, el) => {
  acc[el.name] = { value: el.name, label: el.title + " " + (el.countryTitle || "") };
  return acc;
}, {});

function makeDefault(key: any, idsToNameMap?: Record<string, string>) {
  let label = key ? key.split(":")[0] : "";
  let nucleuz = false;

  if (isNucleuz(label)) {
    if (idsToNameMap?.[label]) {
      label = idsToNameMap[label];
    } else {
      label = nucleuzName(label);
    }

    nucleuz = true;
  }

  if (isEdm(label)) {
    label = edmName(label);
  }

  return { value: key, label, attributes: [], nucleuz };
}

// TODO refactor format with 1:1:1 to something more reasonable
export const getContentAttribute = (
  key: string,
  values?: any,
  idsToNameMap?: Record<string, string>
): IState.IContent & { nucleuz: boolean } => {
  const [valueKey, minWeight, minCount] = key.split(":");
  const kvObj = contentOptionsKv[valueKey] || makeDefault(key, idsToNameMap);
  return { ...kvObj, minWeight: Number(minWeight), minCount: Number(minCount), ...values };
};
