import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCheckbox = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "padding": "0 5px 0px 8px",
});

export function CheckBox({ label = "", checked, onChange, indeterminate = false, ...rest }: any) {
  return (
    <FormControlLabel
      label={label}
      onChange={onChange}
      control={
        <StyledCheckbox
          checked={checked}
          indeterminate={indeterminate}
          disableTouchRipple
          disableRipple
          disableFocusRipple
        />
      }
      {...rest}
    />
  );
}
