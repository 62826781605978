import React from "react";
import { CHIcon4 } from "assets/icons";
import { ENV } from "runenv";
import { navigationSelectors } from "shared/testConstants";
import { useAvailableNavigationItems } from "shared/useAvailableNavigationItems";
import { HOME_PAGE_FALLBACK_PATH } from "app/routesPaths";
import { Link } from "react-router-dom";
import { Stack } from "libs/layouts";
import { logout } from "modules/auth";
import { IUser } from "api/auth/interfaces";
import { theme, Box, space } from "ui";
import { css } from "ui/css";
import { BetaLabel } from "../atoms";
import { messages } from "../messages";
import { useProfileMenu, ProfileMenuItem, NavigationItem } from "../molecules";

const boxStyles = css({
  display: "flex",
  placeSelf: "center",
  position: "relative",
});

function SidebarTemplate({ homePath, children }: { homePath: string; children: React.ReactNode }) {
  return (
    <Stack style={{ flexGrow: 1, borderRight: `1px solid ${theme.palette.divider}` }}>
      <Box
        data-testid={navigationSelectors.home}
        as={Link}
        to={homePath}
        className={boxStyles().className}
        sx={{
          paddingTop: space[3],
        }}
      >
        <CHIcon4 />
        {ENV.IS_BETA_ENV && <BetaLabel data-fs="unmask">{messages.betaLabel}</BetaLabel>}
      </Box>
      <Stack style={{ flexGrow: 1 }} space={0}>
        {children}
      </Stack>
      <div style={{ height: 50 }} />
    </Stack>
  );
}

export const Sidebar = React.memo(function Sidebar({ user }: { user: IUser }) {
  const { availableItems: links, homeItem } = useAvailableNavigationItems();
  const [modal, api] = useProfileMenu({
    logout: logout,
    user: user,
  });

  return (
    <SidebarTemplate homePath={homeItem?.path || HOME_PAGE_FALLBACK_PATH}>
      {modal}
      {links?.map((item, i) => (
        <React.Fragment key={i}>
          {!item.isSpacer ? <NavigationItem {...item} /> : <div style={{ flexGrow: 1 }} />}
        </React.Fragment>
      ))}
      <ProfileMenuItem
        onClick={(e: any) => api.onOpen(e.target)}
        data-testid={navigationSelectors.profile}
      >
        {user.email}
      </ProfileMenuItem>
    </SidebarTemplate>
  );
});
