import { types_OperatorEnum, types_SearchFieldEnum } from "./gen";
import { permissions } from "./permissions";
export type Permission = (typeof permissions)[number];
export type PermissionActions = "read" | "modify" | "delete" | "create" | "update";

export type PermissionResources = Permission extends `${infer Resource}__${PermissionActions}`
  ? Resource
  : never;

export const FullOperatorEnum = {
  ...types_OperatorEnum,
  OperatorExists: "exists",
  OperatorDateIs: "date_is",
  OperatorDateBefore: "date_before",
  OperatorDateOnOrBefore: "date_on_or_before",
  OperatorDateAfter: "date_after",
  OperatorDateOnOrAfter: "date_on_or_after",
} as const;

export const AllFieldNamesEnum = {
  ...types_SearchFieldEnum,
  Dataset: "dataset_id",
  DatasetName: "dataset_name",
  DatasetSensitivity: "dataset_sensitivity",
  Policy: "policy_id",
  PolicyName: "policy_name",
  PolicySeverity: "policy_severity",
} as const;

export type AllFieldNames = (typeof AllFieldNamesEnum)[keyof typeof AllFieldNamesEnum];
