import React from "react";
import { ScreenshotIcon } from "assets/icons";
import {
  ErrorOutline,
  HighlightOff as ErrorIcon,
  Autorenew as RefreshIcon,
} from "@mui/icons-material";
import { Typography, Button, CircularProgress } from "@mui/material";
import { styled, css } from "ui/css";

const Wrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  width: "100%",
  height: "100%",
});

export function NoDataState({
  title = null,
  icon,
  children,
}: {
  title?: string | null;
  icon?: JSX.Element;
  children?: React.ReactNode;
}) {
  return (
    <Wrapper data-fs="unmask" aria-label="no-data-component">
      <Wrapper style={{ height: "auto", margin: "auto" }}>
        {icon ?? <ErrorOutline style={{ fontSize: 80, width: "100%" }} color="action" />}
        <Typography
          color="textSecondary"
          align="center"
          variant="subtitle1"
          style={{ width: "100%" }}
        >
          {title}
        </Typography>
        {children}
      </Wrapper>
    </Wrapper>
  );
}

export const ScreenshotContentLoadingState = () => {
  return (
    <Wrapper data-fs="unmask" aria-label="screenshot-loading-component">
      <Wrapper
        style={{
          height: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <div />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
            width: "600px",
            backgroundColor: "#ECECEC",
          }}
        >
          <ScreenshotIcon fontSize="large" style={{ color: "#B4B4B4" }} />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}
        >
          <CircularProgress />
          <Typography
            color="textSecondary"
            align="center"
            variant="body2"
            style={{ width: "100%" }}
          >
            {"Screenshot content loading... "}
          </Typography>
        </div>
      </Wrapper>
    </Wrapper>
  );
};

export function AlertState({
  title,
  icon = <ErrorOutline />,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { icon: React.ReactElement; title: string }) {
  return (
    <Wrapper data-fs="unmask" {...rest}>
      <Wrapper style={{ height: "auto", margin: "auto" }}>
        {React.cloneElement(icon, { style: { fontSize: 80, width: "100%" }, color: "action" })}
        <Typography
          color="textSecondary"
          align="center"
          variant="subtitle1"
          style={{ width: "100%" }}
        >
          {title}
        </Typography>
      </Wrapper>
    </Wrapper>
  );
}

export function ErrorState({
  text = "Something went wrong",
  onRefresh,
}: {
  text?: string;
  onRefresh?: (p: any) => void;
}) {
  return (
    <Wrapper data-fs="unmask" aria-label="error-component">
      <Wrapper style={{ height: "auto", margin: "auto" }}>
        <ErrorIcon color="action" style={{ fontSize: 80, width: "100%" }} />
        <Typography
          color="textSecondary"
          align="center"
          variant="subtitle1"
          style={{ width: "100%" }}
        >
          {text}
        </Typography>
        {onRefresh && (
          <Button onClick={onRefresh}>
            <RefreshIcon />
            <Typography color="textSecondary" align="center" variant="body2">
              Retry
            </Typography>
          </Button>
        )}
      </Wrapper>
    </Wrapper>
  );
}

const loadingStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
});

export function LoadingState({ className = "", ...props } = { className: "" }) {
  return (
    <div data-fs="unmask" className={loadingStyles({ className }).className} {...props}>
      <CircularProgress />
      <div>Loading...</div>
    </div>
  );
}
