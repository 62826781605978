import { useRef, useEffect } from "react";

// this one doesn't fire onChange on initial render
export const useOnChange2 = (v: any, cb: any) => {
  const ref = useRef(v);

  useEffect(() => {
    if (ref.current !== v) {
      ref.current = v;
      cb();
    }
  });
};
