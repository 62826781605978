import constate from "constate";
import { Sort, useCollection } from "./useCollection";

function useTableStateI({
  selection,
  sort,
  settings,
}: {
  selection: ReturnType<typeof useCollection>["selection"];
  sort?: Sort;
  settings?: { hiddenColumnsExport: string[] };
}) {
  return { selection, sort, settings };
}

export const [TableProvider, useTableState] = constate(useTableStateI);
