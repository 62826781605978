import { useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import { useFocusChange } from "libs/hooks/useFocused";
import { setNativeValue } from "./dom-helpers";

export function shouldPreventBlur(e: FocusEvent) {
  return e.relatedTarget && (e.relatedTarget as any).dataset.name === "suggestionItem";
}

export function useSuggest({ textareaRef, onInputValueChange, items, replaceValue, onBlur }: any) {
  const [visible, updateVisible] = useState(false);
  const activeRef = useRef(false);
  const focusedIndexRef = useRef(-1);
  const itemsSizeLengthRef = useRef(items.length);
  const changeRef = useRef(onInputValueChange);

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isFocused, setFocused] = useState(false);

  // handle focus and blur changes
  useFocusChange(textareaRef, (event) => {
    // do not blur if we click on item from select
    if (event.type === "blur" && shouldPreventBlur(event)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      return false;
    } else if (event.type === "blur") {
      // now it's fine to blur
      onBlur(event);
      setFocused(false);
      updateVisible(false);
      return;
    }
    if (event.type === "focus") {
      setFocused(false);
      return;
    }
    return;
  });
  activeRef.current = isFocused;
  changeRef.current = onInputValueChange;
  focusedIndexRef.current = focusedIndex;
  itemsSizeLengthRef.current = items.length;
  const suggestValues = useCallback(() => {
    if (!activeRef.current) {
      return;
    }

    setTimeout(() => {
      // do not suggest if we out of focus
      if (!activeRef.current) {
        return;
      }
      changeRef.current(textareaRef);
    });
  }, [activeRef, textareaRef]);

  useLayoutEffect(() => {
    updateVisible(true);
    setFocusedIndex(-1);
  }, [items]);

  function set(w: any) {
    const el = textareaRef.current;
    const { newValue, retriggerSuggest, insertIndex } = replaceValue({
      value: el.value,
      word: w,
      selectionStart: el.selectionStart,
    });

    setNativeValue(el, newValue);
    el.dispatchEvent(new Event("change", { bubbles: true }));
    el.focus();
    updateVisible(false);
    el.selectionEnd = el.selectionStart = insertIndex || el.value.length;
    retriggerSuggest && suggestValues();
  }
  const setRef = useRef<Function | null>(null);
  setRef.current = set;

  useEffect(() => {
    const el: HTMLTextAreaElement = textareaRef.current;
    function onChange(e: KeyboardEvent) {
      if (e.key === "Escape") {
        updateVisible(false);
        setFocused(false);
        setFocusedIndex(-1);
        return;
      }
      if (e.key === "Enter" && focusedIndex !== -1) {
        e.stopPropagation();
        e.preventDefault();
        setFocusedIndex(-1);
        setRef.current?.(items[focusedIndex].value);
      } else if (e.key === "Enter" || e.key === "Tab") {
        return;
      } else if (e.key === "ArrowDown" && visible && isFocused && items.length > 0) {
        e.stopPropagation();
        e.preventDefault();
        updateVisible(true);
        setFocused(true);
        setFocusedIndex((i) => Math.min(i + 1, items.length));
      } else if (e.key === "ArrowUp" && focusedIndex !== -1 && visible) {
        setFocusedIndex((i) => Math.max(i - 1, 0));
      } else if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
        updateVisible(true);
        setFocused(true);
        suggestValues();
      }
    }

    el?.addEventListener("keydown", onChange);

    return () => {
      el?.removeEventListener("keydown", onChange);
    };
  }, [suggestValues, focusedIndex, items, visible, textareaRef, isFocused]);

  return {
    onItemClick(e: any, el: any) {
      e.preventDefault();
      e.stopPropagation();
      set(el.value);
    },
    visible: visible && isFocused,
    focusedIndex,
  };
}
