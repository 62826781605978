import { createHistoryHook } from "libs/hooks/useQueryParam";
import { sha256 } from "libs/data-utils";
import { storeString } from "api/short-url";

export const historyParamHash = (data: string): string => {
  // do not wait for hash result, we rely that it will always store hash
  storeString(data);
  return sha256(data);
};

export const useHistoryParam = createHistoryHook(historyParamHash);
