// @ts-nocheck
export function overrideInsert() {
  const appendChildOriginal = Element.prototype.appendChild;
  Element.prototype.appendChild = function () {
    const res = appendChildOriginal.apply(this, arguments);
    if (res.tagName === "STYLE") {
      res.classList.add("fs-css-in-js");
    }
    return res;
  };

  const insertBeforeOriginal = Element.prototype.insertBefore;
  Element.prototype.insertBefore = function () {
    const res = insertBeforeOriginal.apply(this, arguments);
    if (res.tagName === "STYLE") {
      res.classList.add("fs-css-in-js");
    }
    return res;
  };
}
