import React, { ReactNode } from "react";
import { useDroppable } from "@dnd-kit/core";
import { styled } from "ui/css";
import { theme } from "ui/theme";

export interface DroppableContainerProps {
  id: string;
  children: ReactNode;
}

const Droppable = styled("div", {
  position: "relative",
  variants: {
    over: {
      true: {
        "&:before": {
          content: "",
          position: "absolute",
          height: "4px",
          background: theme.palette.primary.main,
          left: 0,
          right: 0,
          top: 0,
        },
      },
    },
  },
});

export const DroppableContainer = ({ id, children }: DroppableContainerProps) => {
  const { setNodeRef, isOver } = useDroppable({ id });

  return (
    <Droppable ref={setNodeRef} over={isOver}>
      {children}
    </Droppable>
  );
};
