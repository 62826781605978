import { styled } from "ui/css";
import { theme } from "../theme";

export const ConditionDragOverlay = styled("div", {
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  zIndex: 2,
  background: theme.gray5,
  border: "1px dashed rgba(0, 0, 0, 0.12)",
});
