import React from "react";
import { useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export type RenderItemProps<T = any> = {
  item: T;
  props: React.ComponentProps<"div"> & {
    "data-index"?: string;
    "data-id"?: string;
    "dndEnabled"?: boolean;
  };
  dndEnabled?: boolean;
  active: boolean;
  index: number;
};

export type RenderItem = <T>(settings: RenderItemProps<T>) => React.ReactChild;

export type SortableItemProps<T extends { id: string }> = {
  item: T;
  renderItem: RenderItem;
  index: number;
  active: boolean;
  dndEnabled?: boolean;
};

export const SortableItem = <T extends { id: string }>({
  item,
  renderItem,
  index,
  active,
  dndEnabled,
}: SortableItemProps<T>) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
    strategy: verticalListSortingStrategy,
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: active ? 10 : undefined,
    position: "relative",
  } as const;

  return (
    <div style={itemStyle}>
      {item
        ? renderItem({
            item,
            dndEnabled,
            props: {
              "data-index": String(index),
              "data-id": String(item.id),
              "ref": setNodeRef,
              ...attributes,
              ...(dndEnabled ? listeners : null),
            },
            index,
            active,
          })
        : null}
    </div>
  );
};
