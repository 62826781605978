import React from "react";
import { Text, VStack } from "ui";
import { styled } from "ui/css";
import { gridColors } from "../theme";

interface CustomNoRowsProps {
  hasActiveFilters: boolean;
  title?: string;
  description?: string;
}

const Wrapper = styled(VStack, {
  alignItems: "center",
  marginTop: "10%",
});

export function GridNoRowsOverlay({
  hasActiveFilters,
  title: titleProps,
  description: descriptionProps,
}: CustomNoRowsProps) {
  const title = hasActiveFilters ? "No one found" : "There is no data yet";
  const description = hasActiveFilters ? "Try removing filters or expanding the time window." : "";

  return (
    <Wrapper space="2">
      <Text variant="header3" color={gridColors.primaryLight}>
        {titleProps || title}
      </Text>
      <Text variant="subtitle3" color={gridColors.primaryLight}>
        {descriptionProps || description}
      </Text>
    </Wrapper>
  );
}
