const oldToNewCategoriesLabels = {
  "Cloud Storage": "Cloud Storage and Documents",
  "HR and Payroll": "HR, Payroll and Expenses",
  "Graphics, Design and CAD": "Graphics and Design",
  "Sales and CRM": "CRM, Sales and Marketing Tools",
  "Source Code Management": "Source Code and Developer Tools",
  "Web Mail": "Web Mail and Calendar",
  "File converters": "Document Converters",
  "Phishing": "Phishing and Fraud (legacy)",
  "Banking": "Banking and Personal Finances",
  "Research and Development": "Science and Education",
  "Consumer Instant Messaging": "Consumer Messaging and Video",
  "Generative AI": "AI and GenAI Tools",
  "Crypto Currencies": "Crypto Currencies (legacy)",
  "Gambling": "Gambling (legacy)",
  "Gaming": "Gaming (legacy)",
  "Illegal Drugs": "Illegal Drugs (legacy)",
  "Malware and Hacking": "Malware and Hacking (legacy)",
  "Music and Video": "Music and Video (legacy)",
  "Piracy": "Piracy (legacy)",
} as Record<string, string>;
// Remap old categories to new ones
export function remapCategories(key: string) {
  return oldToNewCategoriesLabels[key] ?? key;
}
