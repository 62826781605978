import { styled } from "ui/css";
import { theme } from "ui/theme";

export const BetaLabel = styled("div", {
  padding: "0px 4px",
  background: "#43a7ea",
  color: "white",
  fontSize: "9px",
  borderRadius: "2px",
  position: "absolute",
  top: "8px",
  left: "25px",
  textTransform: "uppercase",
  zIndex: theme.zIndex.modal + 2,
});

export const FullscreenContent = styled("div", {
  flex: "1 1 100%",
  height: "100%",
  boxSizing: "border-box",
  flexDirection: "column",
  display: "flex",
  position: "relative",
  overflow: "hidden",
});
