import { build, createActions, createEffects, createState } from "okdux";
import { IRule } from "api";
import { effectsFetching } from "libs/restatex";
import { DOMAIN_NAME } from "./constants";

const contentRulesEffects = createEffects(
  {
    list: build.async<IRule[]>(),
    create: build.async<IRule, IRule>(),
    update: build.async<IRule, IRule>(),
    remove: build.async<any, IRule>(),
  },
  DOMAIN_NAME
);

const actions = createActions(
  {
    expand: build.action<string | null>(),
  },
  DOMAIN_NAME
);

const rules = createState<IRule[]>([]);
const expanded = createState("");
const isInitialLoaded = createState(false);

const contentRulesState = createState({
  rules,
  fetching: effectsFetching(contentRulesEffects),
  expanded,
  isInitialLoaded,
});

rules.on(contentRulesEffects.list.success, (_, p) => p);
rules.on(contentRulesEffects.remove.success, (s, p) => s.filter((rule) => rule.id !== p.id));
rules.on(contentRulesEffects.create.success, (s, p) => s.concat(p));
rules.on(contentRulesEffects.update.success, (s, p) =>
  s.map((rule) => (rule.id !== p.id ? rule : p))
);

expanded.on(actions.expand, (_, p) => p!);
expanded.on(contentRulesEffects.create.success, () => "");
expanded.on(contentRulesEffects.remove.success, () => "");
expanded.on(contentRulesEffects.update.success, () => "");

isInitialLoaded.on(contentRulesEffects.list.success, () => true);

export { contentRulesState, actions, contentRulesEffects };
