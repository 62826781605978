import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridTruncateCellItems } from "../molecules/GridTruncateCellItems";

export function GridListCell(props: GridRenderCellParams) {
  const values: string[] = props.value || [];

  return (
    <GridTruncateCellItems
      title={props.formattedValue}
      items={values}
      renderItem={(value, { shrink, last, popover }) => (
        <span
          style={{
            display: "inline-block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            flexShrink: shrink ? 1 : 0,
          }}
          key={value}
        >
          {value}
          {!last && !popover && ","}
        </span>
      )}
    />
  );
}
