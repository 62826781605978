import React, { CSSProperties, forwardRef } from "react";
import { css } from "ui/css";
import { BoxComponent } from "./Box";

const ZStackStyles = css({
  "display": "grid",
  "justifyItems": "center",
  "placeItems": "center",
  "width": "fit-content",
  "& > *": {
    gridArea: "1/1/1/1",
    zIndex: 1,
  },
});

export const ZStack: BoxComponent<{
  alignX?: CSSProperties["placeItems"];
  alignY?: CSSProperties["alignItems"];
  fullWidth?: boolean;
}> = forwardRef(function ZStack(
  { as: Comp = "div", className, alignX, sx, alignY, fullWidth, ...props },
  ref
) {
  return (
    <Comp
      ref={ref}
      data-component={"ZStack"}
      className={ZStackStyles({
        className,
        css: {
          ...sx,
          width: fullWidth ? "100%" : undefined,
          alignItems: alignY,
          justifyItems: alignX,
        },
      }).toString()}
      {...props}
    />
  );
});
