import { toArray } from "lodash";
import { locations } from "modules/graph/services";
import { messages } from "./messages";

export const LOCATION_OPTIONS = toArray(locations);

export const APPS_TYPES = [
  {
    label: messages.appTypes.salesforce,
    value: "salesforce",
  },
  {
    label: messages.appTypes.onedrive,
    value: "onedrive",
  },
  {
    label: messages.appTypes.gdrive,
    value: "gdrive",
  },
  {
    label: messages.appTypes.box,
    value: "box",
  },
];

export const CATEGORY_TYPES = [
  {
    label: "All",
    value: "*",
  },
].concat(Object.values(messages.categoryTypes).map((v) => ({ label: v, value: v })));

export const ZONE_TYPES = [
  {
    label: messages.zoneTypes.all,
    value: "*",
  },
  {
    label: messages.zoneTypes.internal,
    value: "internal",
  },
  {
    label: messages.zoneTypes.external,
    value: "external",
  },
];

export const MEDIA_CATEGORY_TYPES = [
  {
    label: messages.mediaCategoryTypes.all,
    value: "*",
  },
  {
    label: messages.mediaCategoryTypes.cdDvd,
    value: "optical",
  },
  {
    label: messages.mediaCategoryTypes.usb,
    value: "usb",
  },
];

export const INTERNAL_KEYS = {
  locationType: "location",
};

export const categories = {
  endpoint_hosts: {
    label: messages.categories.endpointHosts,
  },
  endpoint_groups: {
    label: messages.categories.endpointGroups,
  },
  endpoint_files: {
    label: messages.categories.endpointFiles,
  },
  mail_accounts: {
    label: messages.categories.mailAccounts,
  },
  mail_groups: {
    label: messages.categories.mailGroups,
  },
  mail_files: {
    label: messages.categories.mailFiles,
  },
  website: {
    label: messages.categories.website,
  },
  share_hosts: {
    label: messages.categories.shareHosts,
  },
  share_groups: {
    label: messages.categories.shareGroups,
  },
  share_files: {
    label: messages.categories.shareFiles,
  },
  cloud_providers: {
    label: messages.categories.cloudProviders,
  },
  cloud_files: {
    label: messages.categories.cloudFiles,
  },
  cloud_apps: {
    label: messages.categories.cloudApps,
  },
  cloud_apps_files: {
    label: messages.categories.cloudAppsFiles,
  },
  cloud_apps_account: {
    label: messages.categories.cloudAppsAccount,
  },
  endpoint_apps: {
    label: messages.categories.endpointApps,
  },
  printers: {
    label: messages.categories.printers,
  },
  removable_files: {
    label: messages.categories.removableFiles,
  },
  removable_media: {
    label: messages.categories.removableMedia,
  },
};

export const FLOW_FIELD_TYPES = {
  internal_rules: "definition_of_internal",
};
