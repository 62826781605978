import { useEffect } from "react";
import keycode from "keycode";

export function useKeyDown(
  key: string,
  onKeyDownHandler: (event: KeyboardEvent) => void,
  ...params: any[]
) {
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);

    function onKeyDown(event: KeyboardEvent) {
      if (keycode(event) === key) {
        onKeyDownHandler(event);
      }
    }
  }, params);
}
