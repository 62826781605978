import { useMemo } from "react";
import { ENV } from "runenv";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { api as suggestApi } from "api/suggest";
import { api, auth } from "./gen";

export const UserManagementKey = "usm";
export const EndpointMinVersionKey = "endpoints-min-version";
export const ScopeUseListKey = "scope-use-list";

export function useShortUrl<TData>(data: TData, enabled: boolean = true) {
  return useQuery({
    queryKey: ["Shorturl", data],
    enabled,
    queryFn: () => {
      const res = JSON.stringify(data);
      return api.shorturlapi.Service.Create({ data: res }).then((el) => el.id);
    },
  });
}

export function useShortUrlGet(id: string) {
  return useQuery({
    queryKey: ["Shorturl", id],

    queryFn: () => {
      return api.shorturlapi.Service.Get({ id: id }).then((el) => el.data);
    },
  });
}

/**
 * Get export data url
 */
export function useCsvExportUrl(
  url: string,
  id = "",
  hiddenColumns?: string[],
  columnsOrder?: string[]
) {
  return useMemo(() => {
    const exportUrl = new URL(url, window.location.origin);
    exportUrl.searchParams.set("id", id);

    if (hiddenColumns && hiddenColumns.length > 0) {
      exportUrl.searchParams.set("hidden_columns", hiddenColumns.join(","));
    }

    if (columnsOrder?.length) {
      exportUrl.searchParams.set("columns_order", columnsOrder.join(","));
    }

    const default_url = exportUrl.toString();
    exportUrl.searchParams.set("include_description", "true");

    const with_description = exportUrl.toString();

    return [default_url, with_description];
  }, [url, id, hiddenColumns]);
}

export function preloadFileExtensions(queryClient: QueryClient) {
  return queryClient.fetchQuery({
    queryKey: ["file-types"],
    queryFn: () => suggestApi.fetchFileExtensions(),
  });
}

export function useUsersList() {
  const usersData = useQuery({
    queryKey: [UserManagementKey],

    queryFn: () => api.auth.AuthService.UsersList({}).then((el) => el.users),
  });

  const usersAsMap = useMemo(() => new Map(usersData.data?.map((el) => [el.id, el])), [usersData]);

  return {
    ...usersData,
    usersAsMap,
  };
}

const endpointVersionRegexp = /^(\d+)\.(\d+)/;
export function useEndpointMinVersion() {
  return useQuery({
    queryKey: [EndpointMinVersionKey],

    queryFn: async () => {
      const { values } = await api.endpointsstatusapi.ServiceInterface.Suggest({
        filters: { deleted: false },
        column: "lightbeam_version",
        query: "**",
        size: 1,
        sort_ascending: true,
      });

      // Min version would be first record if we sort ascending
      const minFullVersion = values?.[0]?.value as string | undefined;
      // Extract major and minor version only, like 23.05
      const parts = minFullVersion?.match(endpointVersionRegexp);

      return parts?.[0];
    },

    enabled: !!ENV.FEATURES.CONSOLE_SUPPORTED_ENDPOINT_FIELDS?.automatic,
    staleTime: 1000 * 60 * 5,
  });
}

/**
 * Gets list of entities that are used in some abac scope
 *
 * @returns ids of datasets or user groups
 */
export function useScopeUseList(entity: keyof auth.ScopeUseResponse) {
  return useQuery({
    queryKey: [ScopeUseListKey, entity],

    queryFn: async () => {
      const response = await api.auth.AuthService.ScopeUseList({});

      return response[entity] || [];
    },

    enabled: !!ENV.FEATURES.CONTENT_BASED_RBAC,
  });
}
