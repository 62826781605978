type Entry = {
  value: any;
  timestamp: number;
};
export function useStorageWithExpiration<T>(key: string, defaultValue: T, ttl: number) {
  const get = () => {
    const item = localStorage.getItem(key);
    if (!item) {
      return defaultValue;
    }

    try {
      const entry: Entry = JSON.parse(item);
      if (Date.now() > entry.timestamp + ttl) {
        throw new Error("item expired");
      }
      return entry.value;
    } catch {
      remove();
    }

    return defaultValue;
  };

  const set = (value: T) => {
    const entry: Entry = { value, timestamp: Date.now() };
    localStorage.setItem(key, JSON.stringify(entry));
  };

  const remove = () => {
    localStorage.removeItem(key);
  };

  return [get, set, remove] as [typeof get, typeof set, typeof remove];
}
