import { useRef, useEffect } from "react";

export function usePrevious(value: any, defaultValue = undefined) {
  const ref = useRef<any>(defaultValue);

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
