import axios from "axios";
import { install } from "api/auth/interceptors/auth.interceptor";
import { IUpdated } from "./interfaces/User";

class GsuiteSensorService {
  client = axios.create({
    baseURL: "/v1/config/gsuite",
  });

  constructor() {
    install(this.client);
  }

  monitorUsers(domain: string) {
    return this.client.get<IUpdated>(`/domain/${domain}/users/updated`);
  }

  fetchUsers(domain: string) {
    return this.client.get(`/domain/${domain}/users`).then((response) => response.data);
  }

  deleteDomain(domain: string) {
    return this.client.delete<{ domain_id: string }>(`/domain/${domain}`);
  }

  updateUser(id: any, domain: string, updateData: any) {
    return this.client.patch(`/domain/${domain}/users/${id}`, updateData).then((el) => el.data);
  }

  batch(users: any, domain: string) {
    return this.client.patch(`/domain/${domain}/users/batch`, { users });
  }

  setMonitoring(params = { monitored: true, domain: "" }) {
    return this.client.post(`/domain/${params.domain}/set_monitoring`, {
      monitored: params.monitored,
      domain_id: params.domain,
    });
  }

  setEnabled(params = { enabled: true, domain: "" }) {
    return this.client.post(`/domain/${params.domain}/set_enabled`, {
      enabled: params.enabled,
      domain_id: params.domain,
    });
  }

  toggleAutoMonitoring(params = { monitored: true, domain: "" }) {
    return this.setMonitoring(params);
  }

  createDomain = (email: string) => {
    return this.client.post(`/domain/`, { email });
  };
}

const gsuiteSensorService = new GsuiteSensorService();
export { GsuiteSensorService, gsuiteSensorService };
