import { useEffect, MutableRefObject, useRef } from "react";

interface FocusChangeEvent extends FocusEvent {
  type: "blur" | "focus";
}
export function useFocusChange(
  ref: MutableRefObject<HTMLTextAreaElement>,
  onChange: (event: FocusChangeEvent) => void
) {
  const onChangeRef = useRef<Function | null>(null);
  onChangeRef.current = onChange;
  useEffect(() => {
    function onBlur(ev: FocusEvent): void {
      onChangeRef.current!(ev);
    }
    function onFocus(ev: FocusEvent): void {
      onChangeRef.current!(ev);
    }
    if (!ref.current) {
      return;
    }
    ref.current.addEventListener("focus", onFocus, true);
    ref.current.addEventListener("blur", onBlur, true);
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("focus", onFocus);
        ref.current.removeEventListener("blur", onBlur);
      }
    };
  }, [ref, onChangeRef]);
}
