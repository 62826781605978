import { IUserManagementAPIError, IUserManagementAPIPureError } from "./interfaces";

export function formatAPIError(errors: IUserManagementAPIError[] | IUserManagementAPIPureError) {
  if (!Array.isArray(errors)) {
    return errors?.message || errors?.error;
  }

  return errors.reduce((acc, error) => {
    return {
      ...acc,
      [error.property]: Object.values(error.constraints),
    };
  }, {});
}
