import React, { HTMLAttributes } from "react";
import { alpha } from "@mui/system";
import { theme } from "ui/theme";
import { css } from "ui/css";

export type ChipProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactChild;
  color?: string;
  background?: string;
  disabled?: boolean;
};

const chipStyles = css({
  borderRadius: "3px",
  textAlign: "center",
  display: "inline-flex",
  justifyContent: "center",
  minWidth: "22px",
  fontWeight: 500,
  fontSize: "12px !important",
  minHeight: "22px",
  alignItems: "center",
  lineHeight: "14px",
});

export function Chip({
  children,
  color,
  background = theme.grey.listViewAttributes,
  className,
  disabled,
  ...rest
}: ChipProps) {
  const disabledBackground = alpha(background, 0.3);

  return (
    <div
      {...rest}
      className={
        chipStyles({
          css: {
            background: disabled ? disabledBackground : background,
            color: color ?? theme.palette.getContrastText(background),
          },
          className,
        }).className
      }
      aria-disabled={disabled}
    >
      {children}
    </div>
  );
}
