import React from "react";
import { useCanArray } from "shared/permissions";
import { TableBody } from "libs/css-grid-table";
import { containerStyles, Loader, NoItemsFullpage } from "ui";
import { css } from "ui/css";
import { Column, TableBodyProps } from "libs/css-grid-table/types";
import { ThWithFilter } from "./TableFilters";

containerStyles();

const noItemsStyles = css({
  position: "absolute",
  height: "calc(100% - 100px)",
  top: "50px",
});

const HEADER_ACTIONS_ID = "header-actions";

const headerCellStyles = css({
  [`&[data-column=${HEADER_ACTIONS_ID}]`]: {
    borderLeft: "none",
  },
});

const thHeaderStyles = css({
  [`&[data-id=${HEADER_ACTIONS_ID}]`]: {
    borderLeft: "none",
  },
});

export function GenericTable<D>(
  props: TableBodyProps<D> & {
    autoExpandIfLessThan?: number;
    getId?: (v: D) => any;
    isLoading?: boolean;
    headerActions?: React.ReactChild;
    infiniteScrollRef?: (item: any) => void;
    infiniteScrollMargin?: number;
  }
) {
  let expand = props.expand;
  if (props.autoExpandIfLessThan && props.autoExpandIfLessThan === props.data?.length) {
    expand = Array.from(new Array(props.autoExpandIfLessThan)).reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {});
  }

  const rawColumns = React.useMemo(() => {
    const columns = [...(props.columns || [])];

    if (props.headerActions) {
      columns.push({
        id: HEADER_ACTIONS_ID,
        width: 60,
        Header: () => <span>{props.headerActions}</span>,
        className: headerCellStyles().className,
        thClassName: thHeaderStyles().className,
      });
    }
    return columns;
  }, [props.columns, props.headerActions]);

  const columns = useCanArray<Column>({
    items: rawColumns,
    getItemPermission: (field) => field.requiredPermissions || field.requiredPermissionsAnyOf,
    isDoEither: (field) => !!field.requiredPermissionsAnyOf,
  });

  return (
    <TableBody
      infiniteScrollRef={props?.infiniteScrollRef}
      getId={(v: any) => v["id"]}
      ThComponent={ThWithFilter}
      expandPosIndex={1}
      loader={<Loader loading />}
      noData={<NoItemsFullpage className={noItemsStyles().className} />}
      striped
      expand={expand}
      theadProps={{ "data-fs": "unmask" }}
      loading={props.isLoading || !columns}
      {...props}
      columns={columns}
    />
  );
}
