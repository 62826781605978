import { types } from "api";

export function getCategoryStatusLabel(t: types.CategoryStatus) {
  return (
    {
      risky: "High",
      risky_or_low: "Risks",
      low_risky: "Low",
      approved: "Trusted",
      needs_review: "Uncategorized",
      any: "Any",
    } as const
  )[t];
}
