import { CELConditionOperator, celCombine } from "libs/cel-query";

export const EMPTY_USER_NAME = "unknown";
export const EMPTY_USER_SERVER = "";

export function getUserNameCel(search: string) {
  if (!search) {
    return "true";
  }
  const conditions = [`local_user_name.existsContainsCI('${search.toLocaleLowerCase().trim()}')`];
  if (EMPTY_USER_NAME.includes((search || "").toLocaleLowerCase().trim())) {
    conditions.push("local_user_name == ''");
  }
  return celCombine(CELConditionOperator.OR, ...conditions);
}

export function getDisplayUsername(username: string) {
  if (username === EMPTY_USER_SERVER) {
    return EMPTY_USER_NAME;
  }

  return username;
}
