import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSelect = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "background": "inherit",
    "borderRadius": 0,
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&.Mui-focused fieldset": {
      borderWidth: 1,
    },
  },
  "&.MuiOutlinedInput-input": {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
  },
}));
