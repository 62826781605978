import React from "react";
import { getTitleArray } from "./highlightHelper";

const TEXT_LIMIT = 60;

export const HighlightedTextComponent = ({
  title,
  highlightSubstring,
  textLimit = TEXT_LIMIT,
  postFormat = (str: any) => str,
}: any) => {
  const { textArray, highlightText } = getTitleArray(title, highlightSubstring, textLimit);

  return (
    <React.Fragment>
      {textArray.map((text: any, i: any) => (
        <React.Fragment key={i}>
          {postFormat(text.toString())}
          {i < textArray.length - 1 && <b>{postFormat(highlightText)}</b>}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
