export const messages = {
  searchInput: {
    placeholder: "Search",
  },
  openModalButton: {
    submitText: "Save",
  },
  fileUpload: {
    warningMessage: (type: string) => `Only ${type} files supported!`,
  },
  copyright: {
    enterpriseTerms: "Enterprise terms",
  },
  profileMenu: {
    myAccount: "My account",
    logout: "Logout",
  },
  copyTooltip: {
    copiedText: "Copied to clipboard",
    copy: "Copy",
  },
  noItemsFound: {
    title: "No data to show.",
    subtitle: "Try to refine your search criteria.",
  },
};
