import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "ui/css";
import { colors } from "ui/vars";

export const AccordionSummary = styled(MuiAccordionSummary, {
  "backgroundColor": colors.light100,
  "borderRadius": 3,
  "&.Mui-expanded": {
    minHeight: 48,
    backgroundColor: colors.light200,
  },
  "&.Mui-expanded .MuiAccordionSummary-content": {
    margin: "12px 0",
  },
});
