import { FORM_ERROR } from "final-form";
import { set } from "lodash";
import { z } from "zod";

export function schemaToFinalFormError<T>(error: z.ZodError<T>) {
  const errors = error.errors;
  const res = {};
  for (const err of errors.reverse()) {
    if (err.code === "custom" && err.params) {
      set(res, err.path, { ...err.params, message: err.message });
    } else {
      set(res, err.path, err.message);
    }
  }
  return {
    ...res,
    [FORM_ERROR]: error.formErrors.formErrors[0],
  } as Record<string, any>;
}
