import { setupIntercom as internalIntercomSetup } from "./intercom";
import { setupFullStory as internalFullstorySetup } from "./fullstory";
import { setupPendo as internalSetupPendo } from "./pendo";
import { IntercomSettings, IntercomStatic, PendoApi, FullstoryApi } from "./externalTypes";

// see https://developers.intercom.com/installing-intercom/docs/intercom-for-web
export function setupIntercom(config: IntercomSettings): Promise<IntercomStatic> {
  return internalIntercomSetup(config);
}

export function setupFullstory(config: {
  debug?: boolean;
  host?: string;
  orgId: String;
}): Promise<FullstoryApi> {
  return internalFullstorySetup(config);
}

export function setupPendo(config: { apiKey: string }): Promise<PendoApi> {
  return internalSetupPendo(config);
}
