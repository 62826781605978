import React from "react";
export function jsxJoin(
  children: React.ReactNode,
  joinItem: (el: React.ReactNode, i: number) => React.ReactNode
) {
  const res: React.ReactNode[] = [];
  const childrenArray = React.Children.toArray(children);
  childrenArray.forEach((el, i) => {
    res.push(el);
    if (i !== childrenArray.length - 1) {
      res.push(<React.Fragment key={i + "divider"}>{joinItem(el, i)}</React.Fragment>);
    }
  });
  return res;
}
