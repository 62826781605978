import React, { forwardRef } from "react";
import { notificationsSelectors } from "shared/testConstants";
import { SnackbarContent } from "@mui/material";
import { amber } from "@mui/material/colors";
import { css } from "ui/css";
import { theme } from "ui/theme";
import { INotificationType } from "./state";

const styles = css({
  variants: {
    type: {
      default: {},
      success: {},
      info: {},

      error: {
        color: theme.palette.error.light,
      },

      warning: {
        color: amber[700],
      },
    },
  },
});

export interface INotificationProps {
  type: INotificationType;
  message: React.ReactNode;
  description?: string;
  action?: React.ReactNode;
}

export const Notification = forwardRef(
  ({ message, description, type, action }: INotificationProps, ref: any) => {
    return (
      <SnackbarContent
        style={{ justifyContent: "center" }}
        data-testid={notificationsSelectors.Message}
        className={styles({ type }).className}
        action={action}
        ref={ref}
        message={
          <>
            <span>{message}</span>
            <br />
            <span>{description}</span>
          </>
        }
      />
    );
  }
);
