import { resolveService, setServices } from "service";
import { ENV, ENV_LOGGED } from "runenv";
import cookies from "js-cookie";
import { mockErrorHandler } from "shared/mocks";
import { storeWhen } from "shared/store-when";
import { logInfo } from "shared/logger";
import { Services } from "app/externalServicesTypes";
import { PermissionsServiceImp } from "shared/permissions";
import { QueryClient } from "@tanstack/react-query";
import { LicenseInfo } from "@mui/x-license-pro";
import { PendoApi } from "modules/external/externalTypes";
import { setupIntercom, setupFullstory, setupPendo } from "modules/external";
import type { IUser } from "api/auth/interfaces";
import { getConfig, setupLogger } from "modules/analytics/Analytics";
import { idleDelay, LazyService } from "libs/async";
import { getString } from "api/short-url";
import { getStateId } from "modules/query/services";
import { handleTokenRefresh } from "modules/auth/effects";

const identity: any = () => {};

const domain = ENV.TOPLEVEL_DOMAIN || "cyberhaven.io";
const deploymentId = window.location.host.endsWith("." + domain)
  ? window.location.host.slice(0, -domain.length - 1)
  : "";
const stackdriverApiKey = ENV.STACKDRIVER_ERROR_REPORTER_API_KEY;
const analyticsApiKey = ENV.ANALYTICS_API_KEY;
const getErrorReporterCustomerName = (prefix: string) => prefix + ENV.CUSTOMER_CODE;
const intercomApiKey = ENV.INTERCOM_API_KEY;
const fullstoryApiKey = ENV.FULLSTORY_API_KEY;

function setMuiXLicense() {
  LicenseInfo.setLicenseKey(ENV.MUI_X_LICENSE_KEY);
}
// Key should be set once before first component render (https://mui.com/x/introduction/licensing/)
setMuiXLicense();

async function setupReporter(config: import("./setupErrorReporter").ErrorReporterConfig) {
  return import("./setupErrorReporter").then((el) => el.setupErrorReporter(config));
}

async function createServices(queryClient: QueryClient) {
  let errorReporter: Promise<Services["errorReporter"]> = Promise.resolve(mockErrorHandler as any);
  console.group("init services"); // eslint-disable-line no-console

  if (stackdriverApiKey) {
    errorReporter = setupReporter({
      key: stackdriverApiKey,
      projectId: ENV.STACKDRIVER_PROJECT_ID!,
      service: "frontend",
      version: ENV.CUSTOMER_CODE + "__" + ENV.VERSION + (ENV.GIT_COMMIT_DIRTY ? "__dirty" : ""),
    });
    logInfo("error reporter");
  }
  let intercom = identity;
  if (intercomApiKey) {
    // inject intercom a bit later, maybe we should use more nicer metric here but I guess this one is fine
    intercom = idleDelay(500).then(() =>
      setupIntercom({
        app_id: intercomApiKey,
        alignment: "left",
      })
    );
    logInfo("intercom");
  }

  let fullstory;
  if (fullstoryApiKey) {
    fullstory = setupFullstory({
      orgId: fullstoryApiKey,
    });
    logInfo("fullstory");
  } else {
    fullstory = Promise.resolve({
      identify: identity,
      restart: identity,
      shutdown: identity,
      event: identity,
      log: identity,
      setUserVars: identity,
    });
  }

  let pendo: Promise<PendoApi> = Promise.resolve({
    track: identity,
    initialize: identity,
    identify: identity,
  });
  if (analyticsApiKey && ENV.FEATURES.ENABLED_THIRD_PARTY_SERVICES?.pendo) {
    pendo = setupPendo({
      apiKey: analyticsApiKey,
    });
    logInfo("pendo");
  }
  const services = {
    errorReporter: errorReporter,
    pendo: pendo,
    intercom: intercom,
    fullstory: fullstory,
    permissions: new PermissionsServiceImp(queryClient),
  };
  console.groupEnd(); // eslint-disable-line no-console
  const authService = LazyService(() => {
    return import("modules/auth/firebase").then(
      (el) =>
        new el.AuthService({
          apiKey: ENV.FIREBASE_API_KEY,
          authDomain: ENV.FIREBASE_AUTH_DOMAIN,
        })
    );
  });
  return {
    ...services,
    authService,
    logger: setupLogger(
      services.pendo,
      services.fullstory,
      services.intercom,
      services.errorReporter
    ),
  };
}

async function handleRedirectCookieUpdate(user?: IUser) {
  const key = "DAT_DEPLOYMENTS";

  if (!user || !user?.id || !deploymentId) {
    return;
  }

  let info;
  try {
    info = JSON.parse(atob(cookies.get(key)!));
  } catch {
    logInfo("no cookie");
  }

  if (!Array.isArray(info)) {
    info = [];
  }

  const now = Math.floor(new Date().getTime() / 1000);
  info = info.filter(
    (obj) => obj?.id !== deploymentId && obj?.last_active >= now - 60 * 60 * 24 * 30
  );
  info.unshift({ id: deploymentId, last_active: now });

  // the following cookie is used by redirect.cyberhaven.io
  // to redirect the user to their deployment
  cookies.set(key, btoa(JSON.stringify(info)), {
    path: "/",
    expires: 3650,
    domain: domain,
    secure: true,
    sameSite: "lax",
  });
}

// this function is called whenever user changes or logs out, so we need to disable some services
async function handleUserUpdate(user?: IUser) {
  const errorHandler = await resolveService("errorReporter");
  errorHandler.setUser(getErrorReporterCustomerName(user?.id ?? "anonymous"));
  const permissions = await resolveService("permissions");
  permissions.setCurrentUser(user?.id);
  if (user?.id) {
    await permissions.preloadPermissions();
  }
}

export function deployment_info(
  format_date: (arg: Date) => any,
  date_suffix: string = "",
  bool_suffix: string = ""
): any {
  const info = {
    deployment_id: deploymentId,
    deployment_commit_id: ENV.GIT_COMMIT_DIRTY,
    deployment_master_hash: ENV.GIT_COMMIT_MASTER_HASH,
    deployment_version: ENV.GIT_TAG,
  };
  (info as any)["deployment_commit_at" + date_suffix] = format_date(new Date(ENV.GIT_COMMIT_DATE));
  (info as any)["deployment_master_at" + date_suffix] = format_date(
    new Date(ENV.GIT_COMMIT_MASTER_DATE)
  );
  (info as any)["deployment_commit_dirty" + bool_suffix] = !!ENV.GIT_COMMIT_DIRTY;
  return info;
}

function getAnonUser() {
  return "anonymus_" + ENV.CUSTOMER_CODE;
}

async function handlePendoUserUpdate(user?: IUser, customerName?: string) {
  const pendo = await resolveService("pendo");
  if (!user || !user?.id) {
    // we do not need to stop tracking after logout
    return;
  }
  const id = customerName || ENV_LOGGED.CUSTOMER_NAME;
  const customerProps = {
    id,
    ...deployment_info((d) => (isNaN(d.getTime()) ? "" : d.toISOString())),
  };
  const config = getConfig(user, customerProps);
  pendo.initialize(config); // this completely re-initializes pendo
}

export async function handlePendoIdentityUpdate(user?: IUser, customerName?: string) {
  const pendo = await resolveService("pendo");
  const errorHandler = await resolveService("errorReporter");
  if (!user || !user?.id) {
    // we do not need to stop tracking after logout
    return;
  }
  const id = customerName || ENV_LOGGED.CUSTOMER_NAME;
  const customerProps = {
    id,
    ...deployment_info((d) => (isNaN(d.getTime()) ? "" : d.toISOString())),
  };

  if (!id) {
    errorHandler.report(
      `pendo identify error: customer name is empty, env: ${ENV_LOGGED.CUSTOMER_NAME} param: ${customerName}`
    );
    console.error("pendo identify error: customer name is empty");
  }
  const config = getConfig(user, customerProps);
  pendo.identify({ visitor: config.visitor, account: config.account });
}

async function handleIntercomUserUpdate(user?: IUser) {
  const intercom = await resolveService("intercom");

  if (!user || !user?.id) {
    // we need to shutdown intercom on login because it also logs out the user from
    // the intercom messenger — so someone else on the same machine cannot read messages
    // after the user logged out
    intercom("shutdown");
    return;
  }
  intercom("boot", {
    name: user.name,
    user_id: user.id,
    email: user.email,
    user_hash: user.userHash,
    horizontal_padding: 0,
    company: {
      id: ENV.CUSTOMER_CODE,
      name: ENV.CUSTOMER_CODE,
      ...deployment_info((d) => Math.floor(d.getTime() / 1000)),
    },
  });
}

// make fullstory change handler separate to prevent issue when some service failed and other won't be initialized
async function fullstoryHandleUserChange(user?: IUser) {
  const fullstory = await resolveService("fullstory");
  if (!user || !user?.id) {
    // we do not need to stop tracking after logout
    return;
  }
  // Do not remove it if you are not aware of consiquences.
  // this fix avoids creation of unknown users
  // if we initialize fullstory later it will introduce double initialization.
  if (!user || !user?.id) {
    fullstory.identify(getAnonUser(), {
      email: "anonymous@cyberhaven.com",
      displayName: `Anonymus(${ENV.CUSTOMER_CODE})`,
    });
    return;
  }
  fullstory.identify(user.id, {
    displayName: user.name,
    email: user.email,
    customer: ENV.CUSTOMER_CODE,
    ...deployment_info((d) => d, "_date", "_bool"),
  });
}

async function handleStateLoad(user: any) {
  if (!user) {
    return;
  }
  const stateId = getStateId();
  if (!stateId) {
    return;
  }
  // wait for cookie to be set
  getString(stateId)
    .then((state) => {
      const newState = {
        ...window.history.state,
        usr: { ...window.history.state?.usr, ...JSON.parse(state) },
      };
      window.history.pushState(newState, "", window.location.toString());
    })
    .catch((e) => {
      console.error("failed to preload state", e);
    });
}

export async function initExternalServices(queryClient: QueryClient) {
  const services = createServices(queryClient);
  setServices(services as any);
  (window as any).s = services;
}

export function watchUserChanges(store: any, customerName: string) {
  async function handleError(e: any) {
    const errorHandler = await resolveService("errorReporter");
    errorHandler.report(e);
    console.error(e);
  }

  const refresher = handleTokenRefresh();
  storeWhen(
    store,
    (a: any, b: any) => a.auth.user?.id !== b.auth?.user.id,
    async (_: any, b: any) => {
      const user = b.auth.user;
      handleRedirectCookieUpdate(user).catch(handleError);
      handleIntercomUserUpdate(user).catch(handleError);
      fullstoryHandleUserChange(user).catch(handleError);
      handlePendoUserUpdate(user, customerName).catch(handleError);
      handleUserUpdate(user).catch(handleError);
      handleStateLoad(user?.id).catch(handleError);
      refresher.run(user).catch(handleError);
    }
  );
}
