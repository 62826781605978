import React from "react";
import { LoginForm } from "../organisms";
import { PageTemplate } from "./PageTemplate";

export const LoginPage = () => {
  return (
    <PageTemplate>
      <LoginForm />
    </PageTemplate>
  );
};
