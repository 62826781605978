import { DataMasking } from "./types";
import { TrieNode } from "./TrieNode";

export function buildTrieFromPaths(maskingPaths: DataMasking.Path[]): TrieNode {
  const trie = new TrieNode();

  maskingPaths.forEach((maskingPath) => {
    const path: string = typeof maskingPath === "string" ? maskingPath : maskingPath.path;
    const pathComponents = path.split(".");
    let node = trie;

    pathComponents.forEach((segment: string) => {
      node = node.addBranch(segment);
    });

    node.makeLeaf();

    if (typeof maskingPath !== "string") {
      node.addCondition(maskingPath.when);
    }
  });

  return trie;
}

export function collectPaths(
  endpoint: string,
  fieldsConfig: Record<string, DataMasking.Field>
): DataMasking.Path[] {
  return Object.values(fieldsConfig).reduce((acc, maskedField) => {
    if (!maskedField[endpoint]) {
      return acc;
    }

    return [...acc, ...maskedField[endpoint]];
  }, [] as DataMasking.Path[]);
}

/**
 * It expects config in the following format:
 * {
 *  "field1": {
 *   "endpoint1": ["path1", { when: (obj: any) => boolean, path: "path2" }],
 *  "endpoint2": ["path3"]
 * },
 */
export function buildTriesFromConfig(
  endpoints: string[],
  fieldsConfig: Record<string, DataMasking.Field>
): Record<string, TrieNode> {
  return endpoints.reduce((acc, endpoint) => {
    acc[endpoint] = buildTrieFromPaths(collectPaths(endpoint, fieldsConfig));

    return acc;
  }, {} as Record<string, TrieNode>);
}
