import { makeSelectors } from "libs/testing/makeSelectors";

export const UNASSIGNED_ID = "-1";

export const userCardSelectors = makeSelectors("userCard", {
  summary: "",
  directory: "",
  sensors: "",
  actions: {
    copy: "",
    search: "",
    addToList: "",
  },
});
