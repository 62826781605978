import "./LucenePrism.css";
declare const Prism: any;

Prism.languages.lucene = {
  string: {
    pattern: /(?:[rub]|rb|br)?(")(?:\\.|(?!\1)[^\\\r\n])*\1/i,
    greedy: true,
  },
  function: {
    pattern: /((?:^|\s)def[ \t]+)[a-zA-Z_]\w*(?=\s*\()/g,
    lookbehind: true,
  },
  keyword: /\b(?:AND|OR|NOT|TO)\b/,
  builtin: /\b(?:source|destination|personal_info_search)\b/,
  operator: /&&|\||!|\*|~|\^|-|\+/,
  punctuation: /[:]/,
  whitespace: /[^\S\r\n]/,
  bracket: /\(|\)|\[|\]/,
};

export default "";
