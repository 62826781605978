export interface TimeZone {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
}

export enum TimezoneRegions {
  America = "America",
  Africa = "Africa",
  Europe = "Europe",
  Asia = "Asia",
  Australia = "Australia",
  Other = "Other",
  LocalTime = "LocalTime",
}
