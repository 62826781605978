import { QueryKey } from "@tanstack/react-query";
import { makeSelectors } from "libs/testing/makeSelectors";

export const ALL_EDM_DB_KEY = "edm-db-all";
export const ALL_EDM_KEY = "edm-all";
export const DETAILS_EDM_KEY = "edm-details";

export const getAllEdmKey = (): QueryKey => {
  return [ALL_EDM_KEY];
};

export const getAllEdmDBKey = (): QueryKey => {
  return [ALL_EDM_DB_KEY];
};

export const getEdmDetailsKey = (id: string) => {
  return [DETAILS_EDM_KEY, id];
};

export const getEdmEntitiesKeys = (): QueryKey[] => {
  return [getAllEdmDBKey(), getAllEdmKey()];
};

export interface EdmDB {
  id: string;
  name: string;
  fields: string[];
  activeRules: number;
  inactiveRules: number;
  updatedAt: string;
}

export interface EdmRule {
  id: string;
  name: string;
  database?: string | string;
  created_at: string;
  updated_at: string;
}

export enum RuleFields {
  Name = "name",
  Status = "status",
  Database = "database",
  UserIn = "user_in",
  Added = "added",
  LastEdited = "last_edited",
  CreatedAt = "created_at",
  Version = "version",
}

export const edmSelectors = makeSelectors("edm", {
  addNewButton: "",
  pageTitle: "",
  name: "",
  numberOfMatchingRows: "",
  secondaryMatchFields: "",
  description: "",
  primaryMatchField: "",
  saveButton: "",
  cancelButton: "",
  deleteButton: "",
  itemsError: "",
  navigateItem: "",
  duplicateRule: "",
  secondaryMatches: "",
  deleteRule: "",
  selectRule: "",
  grid: "",
  newSidepanel: "",
  editSidepanel: "",
  sidepanelEmptyState: "",
  openActions: "",
  database: "",
  confirmDelete: "",
  confirmCancel: "",
});

export const NEW_COL_ID = "new";
export const MAX_EDM_NAME_LENGTH = 35;
