import { FullOperatorEnum } from "api/types";
import { types } from "api/gen";

type FilteredOperators = Exclude<
  (typeof FullOperatorEnum)[keyof typeof FullOperatorEnum],
  "is_less_than" | "matches_query" | "has_phrase"
>;

export const operatorsThatDoNotRequireValues = ["exists"];

export const keyToString: Record<
  FilteredOperators,
  ReadonlyArray<{
    case: Partial<types.QueryCondition>;
    labels: {
      default: string;
      plural?: string;
    };
  }>
> = {
  // matching lists operators
  list_is: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "is",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "is none of",
      },
    },
  ],
  list_contains: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "contains",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "doesn't contain",
      },
    },
  ],
  list_starts_with: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "starts with",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "doesn't start with",
      },
    },
  ],
  list_ends_with: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "ends with",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "doesn't end with",
      },
    },
  ],
  is_between: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "is between",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "is not between",
      },
    },
  ],
  is_equal_to: [
    {
      case: { selector: "match_any", negated: false },
      labels: {
        default: "is equal to",
      },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: {
        default: "is not equal to",
      },
    },
  ],
  is_greater_than: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is greater than" } },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "is less than or equals" },
    },
  ],
  is: [
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "is any of", plural: "include any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "is none of", plural: "include none of" },
    },
    {
      case: { selector: "match_all", negated: false },
      labels: { default: "is all of", plural: "include all of" },
    },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "is not all of", plural: "include not all of" },
    },
  ],
  equals_or_is_subdomain: [
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "is (sub)domain of any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "isn't (sub)domain of any of" },
    },
  ],
  equals_or_is_subpath: [
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "is (sub)path of any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "isn't (sub)path of any of" },
    },
  ],
  starts_with: [
    { case: { selector: "match_any", negated: false }, labels: { default: "starts with any of" } },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "doesn't start with any of" },
    },
  ],
  ends_with: [
    { case: { selector: "match_any", negated: false }, labels: { default: "ends with any of " } },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "doesn't end with any of " },
    },
  ],
  contains: [
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "contains any of", plural: "contain any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "doesn't contain any of", plural: "don't contain any of" },
    },
    {
      case: { selector: "match_all", negated: false },
      labels: { default: "contains all of", plural: "contain all of" },
    },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "doesn't contain all of", plural: "don't contain all of" },
    },
  ],
  matches_regexp: [
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "matches any of regexp" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "doesn't match any of regexp" },
    },
    {
      case: { selector: "match_all", negated: false },
      labels: { default: "matches all of regexp" },
    },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "doesn't match all of regexp" },
    },
  ],
  has_multitag: [
    {
      case: { selector: "match_all", negated: false },
      labels: { default: "has all of", plural: "contain all of" },
    },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "doesn't have all of", plural: "don't have all of" },
    },
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "has any of", plural: "contain any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "has none of", plural: "contain none of" },
    },
  ],
  has_tag: [
    {
      case: { selector: "match_all", negated: false },
      labels: { default: "has all of", plural: "contain all of" },
    },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "doesn't have all of", plural: "don't have all of" },
    },
    {
      case: { selector: "match_any", negated: false },
      labels: { default: "has any of", plural: "contain any of" },
    },
    {
      case: { selector: "match_any", negated: true },
      labels: { default: "has none of", plural: "contain none of" },
    },
  ],
  has_text: [
    { case: { selector: "match_all", negated: false }, labels: { default: "has all of words" } },
    {
      case: { selector: "match_all", negated: true },
      labels: { default: "doesn't have all of words" },
    },
    { case: { selector: "match_any", negated: false }, labels: { default: "has any of words" } },
    { case: { selector: "match_any", negated: true }, labels: { default: "has none of words" } },
  ],
  exists: [
    { case: { selector: "match_any", negated: false }, labels: { default: "exists" } },
    { case: { selector: "match_any", negated: true }, labels: { default: "doesn't exist" } },
  ],
  date_is: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is" } },
    { case: { selector: "match_any", negated: true }, labels: { default: "is not" } },
  ],
  date_on_or_after: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is on or after" } },
  ],
  date_on_or_before: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is on or before" } },
  ],
  date_after: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is after" } },
  ],
  date_before: [
    { case: { selector: "match_any", negated: false }, labels: { default: "is before" } },
  ],
} as const;

const pluralOperators: types.SearchField[] = [
  "group_name",
  "content_tags",
  "personal_info_counts",
  "personal_info_attributes",
];

export function getHumanReadableOperator(
  op: Pick<types.QueryCondition, "selector" | "field_name" | "negated"> & {
    operator: (typeof FullOperatorEnum)[keyof typeof FullOperatorEnum];
  }
) {
  if (!keyToString[op.operator as FilteredOperators]) {
    throw new Error(`unknown operator "${op.operator}"`);
  }
  const str = keyToString[op.operator as keyof typeof keyToString];
  const res = str.find(
    (el) => el.case.selector === op.selector && el.case.negated === Boolean(op.negated)
  );
  if (!res) {
    throw new Error(`unknown operator "${JSON.stringify(op)}"`);
  }

  const isPlural = pluralOperators.includes(op.field_name);
  let operatorStr = res.labels.default;
  if (isPlural && res.labels.plural) {
    operatorStr = res.labels.plural;
  }
  return operatorStr;
}
