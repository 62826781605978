export * from "./useObserver";
export * from "./useScrollHeight";

export function subscribeEvent(target: any, event: any, cb: any) {
  target.addEventListener(event, cb);
  return () => {
    target.removeEventListener(event, cb);
  };
}

export function waitForElementToExist(
  element: HTMLElement = document.body,
  selector: string
): Promise<Element | null> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(element, {
      subtree: true,
      childList: true,
    });
  });
}
