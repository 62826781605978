import React, { CSSProperties, forwardRef } from "react";
import { css } from "ui/css";
import { space, Spacing } from "ui/vars";
import { BoxComponent } from "./Box";

const HStackStyles = css({
  display: "flex",
  flex: "0 1 auto",
  flexDirection: "row",
});

export type HStackProps = BoxComponent<{
  align?: CSSProperties["alignItems"];
  fullWidth?: boolean;
  space?: Spacing;
  children?: React.ReactNode | React.ReactNode[];
}>;

export const HStack: HStackProps = forwardRef(function HStack(
  { as: Comp = "div", className, sx, space: spaceValue, align = "center", fullWidth, ...props },
  ref
) {
  return (
    <Comp
      ref={ref}
      data-component={"HStack"}
      className={HStackStyles({
        className,
        css: {
          gap: space[spaceValue || 0],
          alignItems: align,
          width: fullWidth ? "100%" : undefined,
          ...sx,
        },
      }).toString()}
      {...props}
    />
  );
});

const VStackStyles = css({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
});

export const VStack: BoxComponent<{
  align?: CSSProperties["alignItems"];
  fullWidth?: boolean;
  space?: Spacing;
  children?: React.ReactNode | React.ReactNode[];
}> = forwardRef(function HStack(
  { as: Comp = "div", className, sx, space: spaceValue, align, fullWidth, ...props },
  ref
) {
  return (
    <Comp
      ref={ref}
      data-component={"HStack"}
      className={VStackStyles({
        className,
        css: {
          gap: space[spaceValue || 0],
          alignItems: align,
          width: fullWidth ? "100%" : undefined,
          ...sx,
        },
      }).toString()}
      {...props}
    />
  );
});

export function StackSpacer() {
  return <div style={{ marginRight: "auto", marginBottom: "auto" }} />;
}
