export const messages = {
  alertsNotificationText: (action: string) => `Template ${action}`,
  created: "created",
  updated: "updated",
  removed: "removed",
  noComponentsToExpand: "No more components to expand by",
  datePickerToday: "Today",
  datePickerDate: (startDate: string, endDate: string) => `${startDate} to ${endDate}`,
  sophosShowMoreAttr: (count: number) => `${count} more attributes`,
  contentAttributes: "Content attributes",
  none: "None",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  fitToScreen: "Fit map to screen",
  searchFrom: "Search flows from here",
  searchTo: "Search flows to here",
  expandBy: "Expand By",
  nothingExpand: "Nothing to expand",
  collapse: "Collapse",

  edgeInfoPanel: {
    tabTitle: (label: string) => `${label} insights`,
  },
  graphTabTitle: "Map",
  listTabTitle: "List",
  insightshTabTitle: "Insights",
  zones: {
    internal: "Internal",
    external: "External",
    undefined: "Undefined",
  },
  mediaCategory: {
    usb: "USB Devices",
    optical: "CD/DVD",
  },
  locations: {
    all: "All",
    mail: "Email",
    mailPlural: "Email",
    mailDomain: "Email domain",
    mailAccount: "Address",
    cloudStorage: "Cloud Storage",
    cloudStoragePlural: "Cloud Storage",
    cloudProvider: "Provider",
    cloudStoragePath: "Path",
    cloudApps: "Cloud App",
    cloudAppsPlural: "Cloud Apps",
    cloudApp: "App",
    cloudAppsAccountName: "Account name",
    website: "Website",
    websitePlural: "Websites",
    websiteDomain: "Domain",
    websiteUrl: "URL",
    websiteCategory: "Website category",
    share: "Shared Folder",
    sharePlural: "Shared Folders",
    shareHostname: "Hostname",
    shareGroupName: "Group name",
    sharePath: "Path",
    endpoint: "Endpoint",
    endpointPlural: "Endpoints",
    endpointHostname: "Hostname",
    endpointGroupName: "Group name",
    endpointLocalUserName: "Local user name",
    endpointPath: "Path",
    endpointApps: "Endpoint App",
    endpointAppsPlural: "Endpoint Apps",
    endpointAppsCommandline: "Command line",
    endpointAppsGroupName: "Group name",
    endpointAppsAppName: "Application name",
    endpointAppsHostname: "Hostname",
    endpointAppsLocalUserName: "Local user name",
    endpointAppsPath: "Path",
    removableMedia: "Removable Media",
    removableMediaPlural: "Removable Media",
    mediaCategory: "Category",
    removableMediaLocalUserName: "Local user name",
    removableMediaDeviceName: "Device name",
    removableMediaSerialNumber: "Serial number",
    removableMediaPath: "Path",
    printer: "Printer",
    printerPlural: "Printers",
    printerName: "Printer name",
  },
  notSupportedTitle: "Portrait mode currently is not supported",
  notSupportedSubTitle: "Please rotate device into landscape mode to view data flows",
  notifications: {
    badRequest: "Bad request. Please try modifying your query",
  },
};
