import React, { useState } from "react";
import { updatedSinceSelectors } from "shared/testConstants";
import { RefreshOutlined } from "@mui/icons-material";
import { css, keyframes, styled } from "ui/css";
import { UpdateTimeLabel, IUpdateTimeLabelProps } from "./UpdateTimeLabel";
import { PageHeaderPill } from "../PageHeaderPill";

const spinAnimation = keyframes({
  "0%": { transform: "rotate(90deg)" },
  "100%": { transform: "rotate(450deg)" },
});

const StyledRefreshIcon = styled(RefreshOutlined, {
  transition: `transform .3s ease-out`,
  fontSize: "21px",
  color: "#757575",
  animation: `${spinAnimation} 1s ease-out`,
});

const containerStyles = css({
  cursor: "pointer !important",
  ["&:hover"]: {
    [`${StyledRefreshIcon}`]: {
      transform: "rotate(90deg)",
    },
  },
});

export function UpdateSince({
  time,
  refreshAction,
  formatLabel,
}: { refreshAction: () => any } & IUpdateTimeLabelProps) {
  const [count, setCount] = useState(0);
  return (
    <PageHeaderPill
      key={count}
      className={containerStyles().className}
      data-fs="unmask"
      onClick={() => {
        refreshAction();
        setCount((c) => c + 1);
      }}
      label={<UpdateTimeLabel time={time} formatLabel={formatLabel} />}
      icon={<StyledRefreshIcon data-testid={updatedSinceSelectors.renew} />}
    />
  );
}
