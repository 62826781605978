import decode from "jwt-decode";
import cookie from "js-cookie";
import { toMils } from "libs/time-utc";
import { ITokenPayload } from "./interfaces";
export const KEY = "DAT_AUTH";
export const SUPERSET_KEY = "SUPERSET_AUTH";
export const FIREBASE_DB_NAME = "firebaseLocalStorageDb";
export const FIREBASE_OBJECT_STORE_NAME = "firebaseLocalStorage";

export async function removeAuthTokens() {
  // Remove the data from the Firebase's IndexedDB
  const open = indexedDB.open(FIREBASE_DB_NAME);
  open.onsuccess = () => {
    const db = open.result;
    if (db.objectStoreNames.contains(FIREBASE_OBJECT_STORE_NAME)) {
      db.transaction([FIREBASE_OBJECT_STORE_NAME], "readwrite")
        .objectStore(FIREBASE_OBJECT_STORE_NAME)
        .clear();
    }
  };

  cookie.remove(KEY);
  cookie.remove(SUPERSET_KEY);
}

export async function getToken() {
  return cookie.get(KEY) ?? "";
}

// returns token expiration time in mils
export function getTokenExpirationTime() {
  return getToken().then((el) => {
    return el ? toMils(parsePayload(el)!.exp) : null;
  });
}

export function parsePayload(token: string): ITokenPayload | null {
  if (!token) {
    return null;
  }
  return decode(token);
}
