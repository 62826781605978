import React, { useState, useCallback } from "react";

export type ModalArgs<T extends any = {}> = {
  open: boolean;
  onClose: (event?: any, reason?: string) => void;
  extraProps?: T;
};

export type ModalAPI = {
  open: boolean;
  handleClose: () => void;
  handleOpen: (args?: any) => void;
};

export function useModal(
  render: <T extends any>(args: ModalArgs<T>) => React.ReactNode,
  disableBackdropClick?: boolean
) {
  const [state, setState] = React.useState({ open: false, extraProps: {} });
  const onOpen = useCallback((extraProps = {}) => setState({ open: true, extraProps }), []);
  const onClose = useCallback((_: any, reason: any) => {
    if (!disableBackdropClick || reason !== "backdropClick") {
      setState({ open: false, extraProps: {} });
    }
  }, []);
  return [
    render({ onClose, ...state }),
    { open: state.open, handleClose: onClose, handleOpen: onOpen },
  ] as [React.ReactNode, ModalAPI];
}

export type PopoverActions = {
  onClose: () => void;
  open: boolean;
  onOpen: (el: EventTarget) => void;
};

export function usePopover(
  fn: (api: any) => JSX.Element
): readonly [JSX.Element, PopoverActions & { anchorEl: EventTarget | null }] {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  function handleOpen($el: React.MouseEvent<HTMLSpanElement, MouseEvent>["target"]) {
    setAnchorEl($el);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const api = { onClose: handleClose, open: !!anchorEl, anchorEl };
  return [fn(api), { ...api, onOpen: handleOpen }] as const;
}

export function usePopoverDisclosure() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onOpen = useCallback(
    ($el: React.MouseEvent<Element, MouseEvent>["target"]) => setAnchorEl($el as Element),
    []
  );
  const onClose = useCallback(() => setAnchorEl(null), []);
  return { onClose, open: !!anchorEl, anchorEl, onOpen };
}

export function useDisclosure() {
  const [isOpen, setAnchorEl] = useState<boolean | undefined | null>(null);

  const onOpen = useCallback(($el?: boolean) => setAnchorEl($el), []);
  const onClose = useCallback(() => setAnchorEl(null), []);
  return { onClose, open: !!isOpen, onOpen };
}
