import { Method } from "axios";

export function cancelable<T>(p: T, source: any): T & { cancel: () => void } {
  const promiseAny = p as any;
  promiseAny.cancel = () => source.cancel("Request was canceled");
  const resolve = promiseAny.then.bind(p);
  promiseAny.then = (res: any, rej: any) => cancelable(resolve(res, rej), source);
  return promiseAny;
}

export type RequestMapping = Record<string, string[]>;
export type ResponseMapping = Record<string, string[]>;
export type Route<Req, Res> = {
  (data: Req): Promise<Res>;
  method: Method;
  url: string;
  __req?: Req;
  __res?: Res;
};
