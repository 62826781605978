// this is a quick hack, everything should be in global theme
export const localTheme = {
  grey: "#CACACA",
  gray2: "#FBFBFB",
  backgroundGrey: "#f2f3f4",
  green: "#5DCB6F",
  red: "#BF3E3E",
  blue1: "#C7E5F9",
  blue2: "#43A7EA",
  blue3: "#F8F9FB",
  dividerBlue2: "1px solid #43A7EA",
  white: "#ffffff",
  barHover: "#007dd1",
  menuItemHover: "#ECEEF1",
  yellow: "#F8A458",
  errorBorder: "#FF1212",
  rulesBackground: "#f5f5f5",
  backgroundDark: "#525252",
  rulesHeader: "#5b6064",
  greyBg: "#616161",
  selectedColor: "#E6EAEE",
  sidePanelBackground: "#f8f9fb",
  sidePanelBorder: "1px solid #eeeff3",
  selectedResponseFilter: "rgba(143, 154, 165, 0.44)",
};

export const riskColorsMap = {
  low_risky: localTheme.yellow,
  risky: localTheme.red,
  approved: localTheme.green,
  other: localTheme.gray2,
};
