import { resolveService } from "service";

export async function reportError(text: string | Error | object) {
  if (typeof text === "object") {
    text = JSON.stringify(text);
  }

  const logger = await resolveService("errorReporter");
  logger.report(text);
}
