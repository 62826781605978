import { useState, useMemo, useCallback } from "react";

export interface StableActions<K> {
  add: (key: K) => void;
  remove: (key: K) => void;
  toggle: (key: K) => void;
  reset: (v?: Set<K>) => void;
}

export interface Actions<K> extends StableActions<K> {
  has: (key: K) => boolean;
  removeMany: (keys: K[]) => void;
}

export const useControlledSet = <K,>(initialSet = new Set<K>()): [Set<K>, Actions<K>] => {
  const [set, setSet] = useState(initialSet);

  const stableActions = useMemo<StableActions<K>>(() => {
    const add = (item: K) => setSet((prevSet) => new Set([...Array.from(prevSet), item]));
    const remove = (item: K) =>
      setSet((prevSet) => new Set(Array.from(prevSet).filter((i) => i !== item)));
    const toggle = (item: K) =>
      setSet((prevSet) =>
        prevSet.has(item)
          ? new Set(Array.from(prevSet).filter((i) => i !== item))
          : new Set([...Array.from(prevSet), item])
      );

    return { add, remove, toggle, reset: (v = initialSet) => setSet(v) };
  }, [setSet]);

  const removeMany = (items: K[]) => {
    const newSet = new Set(Array.from(set));
    for (const item of items) {
      newSet.delete(item);
    }
    setSet(newSet);
  };

  const utils = {
    has: useCallback((item: any) => set.has(item), [set]),
    removeMany,
    ...stableActions,
  } as Actions<K>;

  return [set, utils];
};

export const useSet = <K,>(set: Set<K>, setSet: (a: Set<K>) => void) => {
  const stableActions = useMemo<StableActions<K>>(() => {
    const add = (item: K) => {
      const res = new Set([...Array.from(set), item]);
      return setSet(res);
    };
    const remove = (item: K) => setSet(new Set(Array.from(set).filter((i) => i !== item)));
    const toggle = (item: K) =>
      setSet(
        set.has(item)
          ? new Set(Array.from(set).filter((i) => i !== item))
          : new Set([...Array.from(set), item])
      );

    return { add, remove, toggle, reset: (v = set) => setSet(v) };
  }, [setSet]);

  const utils = {
    has: useCallback((item: any) => set.has(item), [set]),
    ...stableActions,
  } as Actions<K>;

  return [set, utils] as const;
};
