import React from "react";
import { Snackbar, Portal } from "@mui/material";
import { useSelector } from "libs/hooks";
import { notificationState, actions } from "./state";
import { Notification } from "./Notification";

export const NotificationCenter = () => {
  const {
    open,
    message,
    description,
    type,
    action,
    props,
    autoHideDuration = 2000,
  } = useSelector(notificationState);
  return (
    <Portal container={document.body}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={actions.close}
        {...props}
      >
        <Notification type={type} message={message} description={description} action={action} />
      </Snackbar>
    </Portal>
  );
};
