import axios, { Canceler } from "axios";
import { isBase64JSON } from "./utils";

export function storeString(data: string) {
  return axios.post("/v1/short-url", { data });
}

export function getString(id: string) {
  if (isBase64JSON(id)) {
    return Promise.resolve(atob(id));
  }
  return axios.get("/v1/short-url", { params: { id } }).then((el) => el.data.data);
}
class QueryApi {
  url = "/v1/short-url";

  cancelToken: null | Canceler = null;

  post = async (queryState: any): Promise<string> => {
    this.cancel();
    const { data } = await axios.post(
      this.url,
      { data: JSON.stringify(queryState) },
      {
        cancelToken: new axios.CancelToken((c) => (this.cancelToken = c)),
      }
    );

    return data.id;
  };

  cancel = () => {
    if (this.cancelToken) {
      this.cancelToken();
    }

    this.cancelToken = null;
  };

  get = (id: string): Promise<any> | null => {
    if (!id) {
      return null;
    }
    return axios
      .get(this.url, {
        params: {
          id,
        },
      })
      .then((el) => {
        return JSON.parse(el.data.data);
      })
      .catch(() => null);
  };
}

export const api = new QueryApi();
export { QueryApi };
