import React from "react";
import { Form, FormProps } from "react-final-form";
import { FORM_ERROR, FormApi } from "final-form";
import { FormHelperText } from "@mui/material";
import { formatAPIError } from "api/user-management";
import { GraphqlError } from "libs/api/fetch-gql";
import { ModalPure, IModalFormPureProps } from "./Modal";

const onSubmit = (onSubmitFn: any) => async (values: any, formApi: FormApi) => {
  try {
    const errors = await onSubmitFn(values, formApi);
    return errors;
  } catch (error: any) {
    if (error instanceof GraphqlError) {
      return { [FORM_ERROR]: error.errors[0].message };
    }

    return formatAPIError(error.response?.data);
  }
};

export type IModalFormProps = Partial<FormProps> & Partial<IModalFormPureProps>;

export const ModalForm = ({ submitText, ...props }: IModalFormProps) => (
  <Form
    {...props}
    onSubmit={onSubmit(props.onSubmit)}
    render={(formProps) => {
      const { submitErrors } = formProps.form.getState();

      return (
        <ModalPure
          {...formProps}
          onSubmit={formProps.form.submit}
          onClose={() => {
            formProps.form.reset();
            props.onClose?.();
          }}
          submitText={submitText}
        >
          <form onSubmit={formProps.handleSubmit}>
            {typeof submitErrors === "string" && (
              <FormHelperText error>{submitErrors}</FormHelperText>
            )}
            {props.children}
            <input type="submit" hidden />
          </form>
        </ModalPure>
      );
    }}
  />
);
