import { types } from "api";

export function getResolutionStatusLabel(
  status: types.ResolutionStatus | types.ResolutionStatus[]
): string | string[] {
  if (Array.isArray(status)) {
    return status.map(getResolutionStatusSingleLabel);
  }

  return getResolutionStatusSingleLabel(status);
}

function getResolutionStatusSingleLabel(status: types.ResolutionStatus): string {
  return {
    ignored: "Not an incident",
    in_progress: "Assigned",
    resolved: "Valid incident",
    unresolved: "Open",
  }[status];
}
