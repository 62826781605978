export const messages = {
  pageTitle: "Insider risk",
  userItem: "user",
  userItemPlural: "users",
  userRowMenu: {
    addToGroup: "Add/Remove: Manual user risk groups",
    failedToUpdateUserGroups: "Failed to update user groups. Please try again.",
    clearRiskScore: "Clear risk score",
  },
  clearRiskScoreModal: {
    error: "Failed to clear risk score",
    title: "Clear risk score",
    description:
      "You are clearing all aggregated risk score. Unaggregreated score since last night 12:00 UTC will remain.",
    riskScoreToBeCleared: "Aggregated raw scores to be cleared: ",
    riskScoreToRemain: "Aggregated raw scores to remain: ",
    commentInputLabel: "Leave a comment",
    note: "Note: Clearing your risk score will not resolve any open incidents associated with an employee. Any new risky events will still contribute to the risk score.",
  },
  navigation: {
    alerts: "See Incidents",
    dataflows: "Investigate Dataflows",
    events: "See events",
    userReport: "See user risk report",
  },
  userDetailsPanel: {
    noData: "Select a user to see details",
    missingDirectoryInfoWarning:
      "To enrich user details you must integrate Cyberhaven with your directory.",
    userActionsButton: "Actions",
    userNameUsedByOthers: "This username is also used by ",
  },
  chart: {
    noData: {
      title: "No metrics for this chart.",
      description: "Try adjusting filters.",
    },
  },
  riskChart: {
    tooltip: `This trendline plots the raw user risk score by adding up the risk score of daily events. Use this to quickly spot spikes in risky behavior`,
  },
  incidentsChart: {
    newIncidents: (plural: boolean) => (plural ? "Policy matches" : "Policy match"),
  },
  filters: {
    assignedToAnyone: "Assigned to anyone",
    unassigned: "Unassigned",
    assignedToMe: "Assigned to me",
    assignToMe: "Assign to me",
  },
  dataStatus: {
    optimizing: "Edits are being optimized",
    optimizingDescription: "You may experience longer load times until the process is complete.",
    optimized: "Optimized",
  },
  history: {
    clearedOn: "Risk score cleared on",
    empty: "Nothing to show",
  },
};
