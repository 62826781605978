import React from "react";
import {
  LocationCloudIcon,
  LocationEndpointAppsIcon,
  LocationEndpointIcon,
  LocationMailIcon,
  LocationMailBodyIcon,
  LocationShareIcon,
  LocationWebsiteIcon,
  LocationRemovableMediaIcon,
  LocationPrinterIcon,
} from "assets/icons";
import AllIcon from "@mui/icons-material/SelectAll";
import { locations } from "../risks/shared/locations";

export const locationIconMap = {
  [locations.all.value]: <AllIcon />,
  [locations.endpoint.value]: <LocationEndpointIcon htmlColor={locations.endpoint.color} />,
  [locations.mail.value]: <LocationMailIcon htmlColor={locations.mail.color} />,
  [locations.email_body.value]: <LocationMailBodyIcon htmlColor={locations.email_body.color} />,
  [locations.share.value]: <LocationShareIcon htmlColor={locations.share.color} />,
  [locations.cloud_storage.value]: <LocationCloudIcon htmlColor={locations.cloud_storage.color} />,
  [locations.cloud_apps.value]: <LocationEndpointAppsIcon htmlColor={locations.cloud_apps.color} />,
  [locations.website.value]: <LocationWebsiteIcon htmlColor={locations.website.color} />,
  [locations.printer.value]: <LocationPrinterIcon htmlColor={locations.printer.color} />,
  [locations.endpoint_apps.value]: (
    <LocationEndpointAppsIcon htmlColor={locations.endpoint_apps.color} />
  ),
  [locations.removable_media.value]: (
    <LocationRemovableMediaIcon htmlColor={locations.removable_media.color} />
  ),
};

export function getIcon(type: keyof typeof locationIconMap | string) {
  return locationIconMap[type];
}
