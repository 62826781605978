import { throttle } from "lodash";
import { useCallback, useState, useLayoutEffect } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

function getSize(el: any) {
  if (!el) {
    return {
      width: 0,
      height: 0,
      clientWidth: 0,
      clientHeight: 0,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
    clientWidth: el.clientWidth,
    clientHeight: el.clientHeight,
  };
}

export function useSize(ref: any, throttleTime: number = 1) {
  const [ComponentSize, setComponentSize] = useState<{
    width: number;
    height: number;
    clientWidth: number;
    clientHeight: number;
  }>(getSize(ref ? ref.current : {}));

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current));
      }
    },
    [ref]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    handleResize();
    const resizer = throttle(handleResize, throttleTime);
    let resizeObserver: ResizeObserver | null = new ResizeObserver(() => resizer());
    resizeObserver.observe(ref.current);

    return () => {
      resizer.cancel();
      resizeObserver?.disconnect();
      resizeObserver = null;
    };
  }, [ref.current]);

  return ComponentSize;
}
