import constate from "constate";
import { debounce } from "lodash";
import React, { useCallback, useState } from "react";

type Matcher = (e: React.FocusEvent) => boolean;

export const useIsFocused = ({
  matcher = () => true,
}: {
  matcher?: Matcher;
} = {}) => {
  const [isFocused, setFocused] = useState(false);
  const onBlur = useCallback(
    debounce(() => {
      setFocused(false);
    }, 50),
    []
  );
  const onFocus = useCallback(
    (e: any) => {
      if (matcher(e)) {
        onBlur.cancel();
        setFocused(true);
      }
    },
    [onBlur]
  );

  return [
    isFocused,
    {
      onFocus,
      onBlur,
      style: {
        outline: "none",
      },
      tabIndex: -1,
    },
  ] as const;
};

export const [FocusManagerProvider, useIsFocusedState] = constate(function useFocusedState({
  isFocused,
}: {
  isFocused: boolean;
}) {
  return isFocused;
});

export function FocusManager({
  children,
  matcher,
}: {
  children: ({
    isFocused,
    focusProps,
  }: {
    isFocused: boolean;
    focusProps: ReturnType<typeof useIsFocused>[1];
  }) => JSX.Element;
  matcher?: Matcher;
}) {
  const [isFocused, focusProps] = useIsFocused({ matcher });
  return (
    <FocusManagerProvider isFocused={isFocused}>
      {children({ isFocused, focusProps })}
    </FocusManagerProvider>
  );
}
