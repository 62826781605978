import axios from "axios";

const api = {
  terms: "/v1/terms?version=1",
};

export function fetchTermsAndConditions(): Promise<boolean> {
  return axios.get(api.terms).then((el) => el.data.consented);
}

export function acceptTerms() {
  return axios.post(api.terms, null);
}
