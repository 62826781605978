import { FunctionComponent, lazy } from "react";

/**
 * @deprecated
 * use lazyImport instead
 */
export function namedLazy<T extends Record<string, any>>(fn: () => Promise<T>, name: keyof T) {
  return lazy(() => fn().then((el) => ({ default: el[name] })));
}
/**
 * lazyImport(()=>import("./module").then(el => el.Component))
 */
export function lazyImport<T extends FunctionComponent<React.PropsWithChildren<any>>>(
  fn: () => Promise<T>
) {
  return lazy(() => fn().then((el) => ({ default: el })));
}
