import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
  UniqueIdentifier,
  DragStartEvent,
  MouseSensor,
  defaultDropAnimationSideEffects,
  DragOverlay,
  DropAnimation,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Droppable from "./Droppable";
import { RenderItem } from "./SotableItem";
import { arrayMove } from "./sortableUtils";

export type SortableListProps<T extends { id: string }> = {
  droppableId?: string;
  renderItem: RenderItem;
  items: T[];
  onReorder?: (items: T[]) => void;
  useDragOverlay?: boolean;
  dndEnabled?: boolean;
  // Defines whether list container is droppable. Useful when you have dnd between columns.
  isDroppable?: boolean;
  height?: string | number;
  footerExtraContent?: React.ReactNode;
};

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export const SortableList = <T extends { id: string }>({
  droppableId = "sortable-list-droppable",
  items: itemsProps,
  onReorder,
  renderItem,
  useDragOverlay = false,
  isDroppable = false,
  dndEnabled,
  height,
  footerExtraContent,
}: SortableListProps<T>) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [items, setItems] = useState<T[]>(itemsProps);
  const getIndex = (id: UniqueIdentifier) => items.findIndex((item) => item.id === id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  useEffect(() => {
    setItems(itemsProps);
  }, [itemsProps]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragOver = ({ over }: any) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const overContainer = over.data.current?.sortable.containerId;

    if (!overContainer) {
      return;
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    document.body.style.cursor = "grab";
    if (!active) {
      return;
    }

    setActiveId(active.id);
  };

  const handleDragEnd = ({ active, over }: any) => {
    document.body.style.cursor = "";
    setActiveId(null);
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current?.sortable.index || 0;

      const newItems = arrayMove(items, activeIndex, overIndex);
      setItems(newItems);
      onReorder?.(newItems);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      autoScroll
    >
      <Droppable<T>
        dndEnabled={dndEnabled}
        isDroppable={isDroppable}
        activeId={activeId}
        renderItem={renderItem}
        id={droppableId}
        items={items}
        height={height}
        footerExtraContent={footerExtraContent}
      />

      {useDragOverlay
        ? createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig}>
              <div>
                {activeId &&
                  renderItem({
                    item: items[activeIndex],
                    props: {},
                    active: false,
                    index: activeIndex,
                  })}
              </div>
            </DragOverlay>,
            document.body
          )
        : null}
    </DndContext>
  );
};
