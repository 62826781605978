import { throttle } from "lodash";
import { useEffect, useRef, useState, useCallback } from "react";

export function useResizeListener() {
  const [pageXDiff, setPageXDiff] = useState<number | null>(null);
  const [curCol, setCurCol] = useState<string | null>(null);
  const pageXRef = useRef<number | null>(null);

  const onMouseDown = useCallback(
    function onMouseDown(e: MouseEvent) {
      const current = (e.target as HTMLElement).parentElement as HTMLElement;
      const col = (current.previousSibling as HTMLElement).dataset["id"];

      pageXRef.current = e.pageX;
      setCurCol(col!);
    },
    [setCurCol, pageXRef]
  );

  useEffect(() => {
    const onMouseMove = throttle(function onMouseMove(e) {
      if (!pageXRef.current) {
        return;
      }
      const prevPageX = pageXRef.current;

      setPageXDiff(e.pageX - prevPageX);
    }, 20);

    function onMouseUp() {
      pageXRef.current = null;
      setCurCol(null);
      setPageXDiff(0);
    }

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return { onMouseDown, pageXDiff, curCol };
}
