import { FieldRenderProps } from "react-final-form";

export type IMapProps = {
  hasHelperText?: boolean;
  [key: string]: any;
} & FieldRenderProps;

type IMapResultProps = {
  helperText?: any;
  error?: boolean;
} & FieldRenderProps["input"];

export const getErrorLabel = (meta: any) => {
  const errorText = meta.error || meta.submitError;
  if (meta.touched && errorText) {
    return errorText;
  }
};

export const mapErrorOnly = ({ hasHelperText = true, meta }: any) => {
  const errorText = meta.error || meta.submitError;
  const errorProps =
    meta.touched && errorText
      ? {
          error: Boolean(errorText),
          helperText: hasHelperText ? errorText : "",
        }
      : {};

  return errorProps;
};

export const mapError: (props: IMapProps) => IMapResultProps = ({
  hasHelperText = true,
  meta: { touched, error, submitError } = {
    touched: null,
    error: null,
    submitError: false,
  },
  input,
  ...props
}) => {
  const errorProps: IMapResultProps =
    touched && (error || submitError)
      ? {
          ...props,
          ...input,
          error: Boolean(error || submitError),
        }
      : { ...input, ...props };

  if (touched && hasHelperText && (error || submitError)) {
    errorProps.helperText = error || submitError;
  }

  return errorProps;
};

export const mapProps = (props: IMapProps): IMapResultProps => {
  const { input, meta, is, ...rest } = props;
  if (rest.type === "checkbox" && !rest.value) {
    input.value = "";
  }
  return { ...input, ...rest, ...mapError(props) };
};
