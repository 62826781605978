export interface Selectors {
  [k: string]: Selectors | string | ((...args: string[]) => string);
}

function mapSelectorsFromObjectRec(obj: Selectors, prefix: string) {
  for (const k in obj) {
    const item = obj[k] as any;
    if (typeof item === "object") {
      mapSelectorsFromObjectRec(item, prefix + "/" + k);
    } else if (typeof item === "string") {
      obj[k] = (prefix + "/" + k) as any;
    } else if (typeof item === "function") {
      obj[k] = (...args) => prefix + "/" + k + "/" + (item(args) as string);
    }
  }
  return obj;
}

export function makeSelectors<T>(prefix: string, obj: T): T {
  return mapSelectorsFromObjectRec(obj as any, prefix) as any;
}

export function testifySelector(selector: any) {
  return `[data-testid="${selector}"]`;
}
