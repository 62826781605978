import React, { ElementType, useState } from "react";
import { range } from "lodash";
import CheckIcon from "@mui/icons-material/Check";
import { ButtonBase, Divider as MDivider } from "@mui/material";
import { Key, Node } from "libs/selection/types";
import { TreeState } from "libs/selection/useTreeState";
import { StackInner } from "libs/layouts";
import { Box } from "ui/atoms/Box";
import { colors, radii, space } from "ui/vars";
import { ExpandIcon } from "ui/atoms";
import { HStack } from "ui/atoms/Stack";
import { DivProps } from "ui/types";
import { css } from "ui/css";
import { selectors } from "libs/css";
import { Text } from "../../atoms/Text";

type State<T> = TreeState<T>;

function SelectableSection<T>({
  item,
  state,
  hideCheckedState,
  showExpandIcon = true,
  clickable = true,
}: {
  clickable?: boolean;
  hideCheckedState?: boolean;
  item: Node<T>;
  state: State<T>;
  showExpandIcon?: boolean;
}) {
  const { rendered, key, hasChildNodes, childNodes } = item;

  const childNodesArray = [...childNodes];

  const childNodesLen = childNodesArray.filter((el) =>
    state.selectionManager.isSelected(el.key)
  ).length;
  const isSelected =
    state.selectionManager.isSelected(key) ||
    (childNodesArray.length && childNodesLen === childNodesArray.length);
  const isExpanded = state.expandedKeys.has(key);
  const contents = typeof rendered === "string" ? <Text>{rendered}</Text> : rendered;
  const childCounter = childNodesLen === childNodesArray.length ? "all" : childNodesLen.toString();
  return (
    <HStack
      align="center"
      space="1"
      className={itemCss({
        clickable,
        level: item.level ?? 0,
      })}
      onClick={() => {
        state.selectionManager.select(key);
      }}
    >
      {contents}
      {childNodesLen > 0 ? <span>({childCounter})</span> : null}
      {hasChildNodes && showExpandIcon && (
        <Box
          sx={{
            borderRadius: radii.sm,
            cursor: "pointer",
            [selectors.touch]: {
              background: colors.light300,
            },
            centerContent: true,
          }}
          onClick={(e) => {
            e.stopPropagation();
            state.toggleKey(key);
          }}
        >
          <ExpandIcon expanded={isExpanded} fontSize="small" />
        </Box>
      )}
      {!hideCheckedState ? (
        <Box
          as={CheckIcon}
          visibility={isSelected ? "visible" : "hidden"}
          sx={{ marginLeft: "auto" }}
          fontSize="small"
        />
      ) : null}
    </HStack>
  );
}

function Item<T>({
  item,
  state,
  hideCheckedState,
  clickable = true,
  ItemContentComponent,
}: {
  clickable?: boolean;
  hideCheckedState?: boolean;
  item: Node<T>;
  state: TreeState<T>;
  ItemContentComponent?: ElementType<{ item: Node<T> }>;
}) {
  const { rendered, key } = item;

  const isSelected = state.selectionManager.isSelected(key);
  let contents = typeof rendered === "string" ? <Text>{rendered}</Text> : rendered;

  if (ItemContentComponent) {
    contents = <ItemContentComponent item={item} />;
  }
  return (
    <HStack
      align="center"
      space="1"
      className={itemCss({
        clickable: clickable,
        level: item.level ?? 0,
      })}
      onClick={() => {
        if (key !== "all") {
          return state.selectionManager.select(key);
        }
        return state.selectionManager.toggleSelectAll();
      }}
    >
      {contents}
      {!hideCheckedState ? (
        <Box
          as={CheckIcon}
          sx={{
            visibility: isSelected ? "visible" : "hidden",
            marginLeft: "auto",
          }}
          fontSize="small"
        />
      ) : null}
    </HStack>
  );
}
function ShowMoreButton({ level, onClick, children }: any) {
  return (
    <ButtonBase
      onClick={onClick}
      className={showMoreButton({
        level: level + 1,
      }).toString()}
    >
      <Text color={colors.blue100} variant="buttonM">
        {children}
      </Text>
    </ButtonBase>
  );
}

function useShowMoreState(initialShowMore?: Record<string, number>) {
  const [state, setState] = useState(new Map<Key, number>(Object.entries(initialShowMore ?? {})));

  return {
    getShowMoreForKey(key: Key) {
      return state.get(key) ?? 5;
    },
    showMoreForKey(key: Key) {
      setState(new Map(state.set(key, 999)));
    },
  };
}

type ListBoxProps = {
  hideCheckedState?: boolean;
};

export function ListBox<T extends object>({
  state,
  hideCheckedState = false,
  ItemContentComponent,
}: ListBoxProps & { state: TreeState<T>; ItemContentComponent?: ElementType<{ item: Node<T> }> }) {
  const spacing = 3;
  const { showMoreForKey, getShowMoreForKey } = useShowMoreState();
  function renderChildren(item: Node<T>) {
    if (item.type === "divider") {
      return (
        <Box
          key={item.key}
          sx={{
            // todo fix insertion order for stitches
            padding: "0!important",
            margin: "0!important",
          }}
          asChild
        >
          <MDivider />
        </Box>
      );
    } else if (item.hasChildNodes) {
      const isExpanded = state.expandedKeys.has(item.key);
      const showOnlyCount = isExpanded ? getShowMoreForKey(item.key) : 0;
      const children = isExpanded ? [...item.childNodes].map(renderChildren).filter(Boolean) : [];
      const itemsHidden = children.length - showOnlyCount;
      const hasShowMore = isExpanded && showOnlyCount < children.length;
      return (
        <React.Fragment key={item.key}>
          <SelectableSection
            key={item.key}
            item={item}
            state={state}
            hideCheckedState={hideCheckedState}
          />
          {children.slice(0, showOnlyCount)}
          {hasShowMore && (
            <ShowMoreButton level={item.level} onClick={() => showMoreForKey(item.key)}>
              Show more {itemsHidden > 0 ? `(+${itemsHidden})` : ""}
            </ShowMoreButton>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <Item
          ItemContentComponent={ItemContentComponent}
          key={item.key}
          item={item}
          state={state}
          hideCheckedState={hideCheckedState}
        />
      );
    }
  }
  return <StackInner space={spacing}>{[...state.collection].map(renderChildren)}</StackInner>;
}

type PreviewListBoxProps<T> = DivProps & {
  filter: (arg: Node<T>) => boolean;
  style?: React.CSSProperties;
};

// same as list box but shows only items that match filter
export function PreviewListBox<T extends object>({
  state,
  filter = () => true,
  hasRootShowMore,
  ItemContentComponent,
  ...rest
}: PreviewListBoxProps<T> & {
  ItemContentComponent?: ElementType<{ item: Node<T> }>;
  state: TreeState<T>;
  hasRootShowMore?: boolean | number;
}) {
  const spacing = 3;
  const { showMoreForKey, getShowMoreForKey } = useShowMoreState({
    root: typeof hasRootShowMore === "boolean" ? 5 : Number(hasRootShowMore),
  });

  function renderChildren(item: Node<T>) {
    if (item.type === "divider") {
      return (
        <Box
          key={item.key}
          sx={{
            // todo fix insertion order for stitches
            padding: "0!important",
            margin: "0!important",
          }}
          asChild
        >
          <MDivider />
        </Box>
      );
    } else if (item.hasChildNodes) {
      // TODO how to handle rendering selected only in generic way
      const childNodesArray = [...item.childNodes];
      let children = childNodesArray.filter(filter).map(renderChildren).filter(Boolean);
      // if all are selected do not expand
      if (children.length === childNodesArray.length) {
        children = [];
      } else if (children.length === 0 && childNodesArray.length !== 0) {
        return null;
      }
      const showOnlyCount = getShowMoreForKey(item.key);
      const itemsHidden = children.length - showOnlyCount;
      const hasShowMore = showOnlyCount < children.length;

      return (
        <React.Fragment key={item.key}>
          <SelectableSection
            hideCheckedState
            clickable={false}
            showExpandIcon={false}
            key={item.key}
            item={item}
            state={state}
          />
          {children.slice(0, showOnlyCount)}
          {hasShowMore && (
            <ShowMoreButton
              level={item.level}
              onClick={(e: any) => {
                e.stopPropagation();
                showMoreForKey(item.key);
              }}
            >
              Show more {itemsHidden > 0 ? `(+${itemsHidden})` : ""}
            </ShowMoreButton>
          )}
        </React.Fragment>
      );
    } else if (filter(item)) {
      return (
        <Item
          ItemContentComponent={ItemContentComponent}
          clickable={false}
          hideCheckedState
          key={item.key}
          item={item}
          state={state}
        />
      );
    }
    return;
  }
  const collection = [...state.collection].filter(filter);
  const showOnlyCount = hasRootShowMore ? getShowMoreForKey("root") : collection.length;
  const itemsHidden = collection.length - showOnlyCount;
  const hasShowMore = showOnlyCount < collection.length;

  return (
    <StackInner nested {...rest} space={spacing}>
      {collection.slice(0, showOnlyCount).map(renderChildren)}
      {hasShowMore && (
        <ShowMoreButton
          level={-1}
          onClick={(e: any) => {
            e.stopPropagation();
            showMoreForKey("root");
          }}
        >
          Show more {itemsHidden > 0 ? `(+${itemsHidden})` : ""}
        </ShowMoreButton>
      )}
    </StackInner>
  );
}

const itemCss = css({
  minHeight: "35px",
  paddingX: space[2],
  minWidth: 0,
  cursor: "pointer",
  [selectors.touch]: {
    background: colors.light180,
  },
  variants: {
    clickable: {
      false: {
        pointerEvents: "none",
      },
      true: {
        pointerEvents: "initial",
      },
    },
    level: Object.fromEntries(
      range(0, 10).map((el) => [el, { paddingLeft: 10 + 10 * el }])
    ) as Record<number, any>,
  },
});

const showMoreButton = css({
  "minHeight": "35px",
  "height": "35px",
  "paddingX": space[2],
  "cursor": "pointer",
  "justifyContent": "flex-start",
  "&:hover": {
    background: colors.light180,
  },
  "variants": {
    level: Object.fromEntries(
      range(0, 10).map((el) => [el, { paddingLeft: 10 + 10 * el }])
    ) as Record<number, any>,
  },
});
