/* eslint-disable */
// prettier-disable
// prettier-ignore
// Code generated by api2. DO NOT EDIT.
import axios, { AxiosRequestConfig } from "axios"
import { ResponseMapping, RequestMapping, Route, cancelable } from "libs/api";
import { QueryClient, QueryKey } from "@tanstack/react-query";

export function route<Req, Res>(
  method: AxiosRequestConfig<any>["method"],
  url: string,
  // @ts-ignore
  requestMapping: RequestMapping,
  // @ts-ignore
  responseMapping: ResponseMapping
): Route<Req, Res> {
  return Object.assign(
    (data: Req | null, override: { url?: string } = {}) => {
      let params: Req | null = null;
      if (method === "GET") {
        params = data;
        data = null;
      }
      const c = axios.CancelToken.source();

      return cancelable(
        axios
          .request<Res>({
            method: method!,
            url: url,
            data,
            cancelToken: c.token,
            params: params,
            ...override,
          })
          .then((el) => el.data),
        c
      );
    },
    {
      method: method!,
      url,
    }
  );
}

export function invalidateMultipleQueries(queryClient: QueryClient, keys: QueryKey[]) {
  return Promise.all(keys.map((key) => queryClient.invalidateQueries({ queryKey: key })));
}
