import { ENV } from "runenv";
import { types_SearchFieldEnum, SearchFieldToMinimumVersion, types } from "api/gen";

const alwaysAllowedFields: types.SearchField[] = [
  types_SearchFieldEnum.SearchFieldAppMainWindowTitle,
  types_SearchFieldEnum.SearchFieldBrowserPageTitle,
  types_SearchFieldEnum.SearchFieldCloudApp,
  types_SearchFieldEnum.SearchFieldCloudAppAccount,
  types_SearchFieldEnum.SearchFieldCloudProvider,
  types_SearchFieldEnum.SearchFieldContentAttributes,
  types_SearchFieldEnum.SearchFieldDataSize,
  types_SearchFieldEnum.SearchFieldDocumentTags,
  ...(ENV.FEATURES.EDM_RULES_ENABLED ? [types_SearchFieldEnum.SearchFieldEDMAttributes] : []),
  types_SearchFieldEnum.SearchFieldDomain,
  types_SearchFieldEnum.SearchFieldEmailAddress,
  types_SearchFieldEnum.SearchFieldEmailSubject,
  types_SearchFieldEnum.SearchFieldEndpointAppCommandLine,
  types_SearchFieldEnum.SearchFieldEndpointApplication,
  types_SearchFieldEnum.SearchFieldEventType,
  types_SearchFieldEnum.SearchFieldFileName,
  types_SearchFieldEnum.SearchFieldFilePath,
  types_SearchFieldEnum.SearchFieldFileSize,
  types_SearchFieldEnum.SearchFieldFileType,
  types_SearchFieldEnum.SearchFieldHostname,
  types_SearchFieldEnum.SearchFieldKeywordInMetadata,
  types_SearchFieldEnum.SearchFieldLocalUserGroups,
  types_SearchFieldEnum.SearchFieldLocalUserName,
  types_SearchFieldEnum.SearchFieldLocationCategory,
  types_SearchFieldEnum.SearchFieldMD5Hash,
  types_SearchFieldEnum.SearchFieldPrinterName,
  types_SearchFieldEnum.SearchFieldRemovableMediaName,
  types_SearchFieldEnum.SearchFieldRemovableMediaSerialNo,
  types_SearchFieldEnum.SearchFieldRemovableMediaType,
  types_SearchFieldEnum.SearchFieldURL,
  types_SearchFieldEnum.SearchFieldWebsiteCategory,
  types_SearchFieldEnum.SearchFieldZone,
  types_SearchFieldEnum.SearchFieldListMatchers,
];

const supportedFields = ENV.FEATURES.CONSOLE_SUPPORTED_ENDPOINT_FIELDS || { allowed: [] };
const allowedFields = supportedFields.all_allowed
  ? new Set(Object.values(types_SearchFieldEnum))
  : new Set([...alwaysAllowedFields, ...(supportedFields.allowed || [])]);

export const isAllowedField = (
  field: types.SearchField,
  { minEndpointVersion }: { minEndpointVersion?: string } = {}
): boolean => {
  if (allowedFields.has(field)) {
    return true;
  }

  if (supportedFields.automatic && minEndpointVersion) {
    const requiredVersion = getFieldMinRequiredVersion(field);
    // String comparison will work for properly formatted versions like "23.05"
    return minEndpointVersion >= requiredVersion;
  }

  return false;
};

export const getFieldMinRequiredVersion = (field: types.SearchField): string => {
  const { Major, Minor } =
    SearchFieldToMinimumVersion[field as keyof typeof SearchFieldToMinimumVersion];
  const majorStr = Major < 10 ? `0${Major}` : `${Major}`;
  const minorStr = Minor < 10 ? `0${Minor}` : `${Minor}`;

  return `${majorStr}.${minorStr}`;
};
