import { ROUTES_PATHS } from "./routesPaths";

let currentVersion: string | null | undefined = null;
async function getNewVersion() {
  if (process.env.NODE_ENV === "test") {
    return;
  }

  const el = await fetch("/v1/web-config", {
    cache: "no-store",
    method: "GET",
  });
  const headers = new Map(el.headers);
  return headers.get("x-version");
}

// Polls every 30 seconds and checks if the backend version is the same. If the backend was updated then we should reload the page to prevent frontend from possible bugs
export async function setup(n = 30000) {
  currentVersion = await getNewVersion();
  const t = setInterval(async () => {
    const shouldRefresh = window.location.pathname.includes(ROUTES_PATHS.LOGIN);
    await checkVersion(shouldRefresh);
  }, n);
  return () => {
    clearInterval(t);
  };
}

const versionChangeSubscribers = new Map();
export function subscribeOnVersionChange(callback: () => any) {
  versionChangeSubscribers.set(callback, callback);

  return () => {
    versionChangeSubscribers.delete(callback);
  };
}

// This is required when we expect version update and do not want to trigger subscribers or refresh
export async function silentlyUpdateVersion() {
  currentVersion = await getNewVersion();
}

export const checkVersion = async (forceRefresh = false) => {
  const newVersion = await getNewVersion();
  if (newVersion !== currentVersion) {
    console.warn(
      "version changed from %s to %s %s",
      currentVersion,
      newVersion,
      forceRefresh ? ", refreshing page" : ""
    );

    // Update current version to new only if we actually reacted to it - refreshed page or subscribers received event
    // Otherwise we will trigger this event again next time we check version
    if (forceRefresh) {
      currentVersion = newVersion;
      window.location.reload();
      return;
    }

    if (versionChangeSubscribers.size) {
      currentVersion = newVersion;
      versionChangeSubscribers.forEach((callback) => callback());
    }
  }
};

setup();
