import React, { useCallback, useState, useEffect } from "react";
import formatDistance from "date-fns/formatDistance";
import useInterval from "libs/hooks/useInterval";

export interface IUpdateTimeLabelProps {
  time: number | string;
  formatLabel?: (time: string) => string;
  className?: string;
}

function format(time: any) {
  return (formatDistance(time, Date.now(), { includeSeconds: false }) + " ago").replace(
    "less than",
    ""
  );
}
export const UpdateTimeLabel = ({ time, formatLabel = (s) => s }: IUpdateTimeLabelProps) => {
  const [timeFromNow, setTimeFromNow] = useState("");
  const refresh = useCallback(() => {
    if (time) {
      setTimeFromNow(format(time));
    }
  }, [time, setTimeFromNow]);

  useEffect(() => {
    setTimeFromNow(format(time));
  }, [time]);

  useInterval(refresh, 60000);

  return <span>{formatLabel(timeFromNow)}</span>;
};
