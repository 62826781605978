import memoizeOne from "memoize-one";

export function LazyService<T = any>(cb: () => Promise<T>): Promise<T> {
  cb = memoizeOne(cb);
  return {
    then(next: any, rej: any) {
      return next(cb(), rej);
    },
  } as any;
}
