import React, { ChangeEvent, Component } from "react";
import { FormControlLabel } from "@mui/material";
import { notification } from "modules/notification";
import { messages } from "ui/messages";

interface IFileUploadProps {
  accept: string;
  multiple: boolean;
  type?: string; // Used for displaying simplififed type in error message
  onChange(files: File[]): any;
  className?: string;
  children?: React.ReactNode;
}

export class FileUpload extends Component<IFileUploadProps> {
  static defaultProps = {
    accept: "image/*",
    multiple: false,
  };

  $input = React.createRef<HTMLInputElement>();

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { accept, type } = this.props;
    const { files } = event.target;
    const fileList = Array.from(files || []);

    const isAccepted = fileList.every((file) => this.props.accept.includes(file.type));

    if (!isAccepted) {
      notification.error({
        message: messages.fileUpload.warningMessage(type || accept),
      });
      return;
    }

    this.props.onChange(fileList);

    this.$input.current!.value = "";
  };

  render() {
    const { accept, children, multiple, className } = this.props;

    return (
      <FormControlLabel
        className={className}
        style={{ marginRight: 0 }}
        control={
          <input
            multiple={multiple}
            style={{ display: "none" }}
            type="file"
            accept={accept}
            onChange={this.onChange}
            ref={this.$input}
          />
        }
        label={children}
      />
    );
  }
}
