export function compareBy<T>(keyFn: (a: T) => any, asc: boolean = false) {
  const compare = new Intl.Collator("en", { numeric: true }).compare;
  return (a: T, b: T) => compare(keyFn(b), keyFn(a)) * (asc ? -1 : 1);
}

export function sortBy<T>(items: T[], fn: (arg: T) => number) {
  return items.sort(compareBy(fn));
}

function isSortableProp<T>(a: T) {
  return typeof a === "string" || typeof a === "number";
}
export function compareByProp<T, Key extends keyof T>(
  key: Key,
  options: Intl.CollatorOptions = {}
) {
  options.sensitivity ??= "base";
  options.numeric ??= true;
  const compare = new Intl.Collator("en", options).compare;
  return (a: T, b: T) => {
    if (isSortableProp(a?.[key]) && isSortableProp(b?.[key])) {
      return compare(a?.[key] as any, b?.[key] as any);
    }
    return -1;
  };
}
