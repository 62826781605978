import React, { useLayoutEffect, useRef } from "react";
import { FormSpy, useForm } from "react-final-form";
import isString from "lodash/isString";
import { debounce, isEqual } from "lodash";
import diff from "object-diff";
import useMountedState from "react-use/lib/useMountedState";
import constate from "constate";
export interface IAutoSaveComponentProps {
  save(values: IAutoSaveComponentProps["values"]): Promise<any>;
  values: any;
  active?: string;
  cancel?(): void;
  render?(): any;
  fieldsToSkip?: string[];
  fieldsOnChange: string | string[];
  initialValues: any;
  dirty: any;
}

interface IState {
  submitting: boolean;
}

// TODO refactor or think about different approach this
export class AutoSaveComponent extends React.Component<IAutoSaveComponentProps, IState> {
  promise?: Promise<any>;
  prevValues: object;
  keysMap: any;

  constructor(props: IAutoSaveComponentProps) {
    super(props);
    this.keysMap = {};
    this.prevValues = props.values;
    this.state = { submitting: false };
  }

  static defaultProps: Partial<IAutoSaveComponentProps> = {
    fieldsToSkip: [],
  };

  componentDidMount() {
    document.addEventListener("keyup", this.handleSubmitKey);
    document.addEventListener("keydown", this.handleSubmitKey);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleSubmitKey);
    document.removeEventListener("keydown", this.handleSubmitKey);
  }

  handleSubmitKey = (e: KeyboardEvent) => {
    this.keysMap[e.keyCode] = e.type === "keydown";

    // 91 - cmd
    // 13 - enter
    // 17 - ctrl

    if (this.props.active && this.keysMap[13] && (this.keysMap[91] || this.keysMap[17])) {
      this.cancelRequest();
      this.save(this.props.values);
    }
  };

  componentWillReceiveProps(nextProps: any) {
    if (
      this.props.initialValues !== nextProps.initialValues || // update after request (setQuery)
      (this.props.active &&
        this.props.active === nextProps.active &&
        this.props.values === nextProps.values) // update on request or success
    ) {
      return;
    }

    if (this.props.active) {
      this.hasDiff(nextProps.values) && this.cancelRequest();

      // blur occurred
      if (this.props.active !== nextProps.active) {
        // when blur occurs new active always undefined (??)
        this.save(nextProps.values);
      }

      if (this.props.active === nextProps.active) {
        // change
        if (this.isOnChange(this.props.active)) {
          this.save(nextProps.values);
          return;
        }
      }
    }
  }

  cancelRequest(): void {
    this.props.cancel && this.props.cancel();
  }

  isOnChange(field: string): boolean {
    const { fieldsOnChange } = this.props;

    if (!fieldsOnChange) {
      return false;
    }

    if (isString(fieldsOnChange)) {
      return field.includes(fieldsOnChange as string);
    } else {
      return (fieldsOnChange as []).some((key) => field.includes(key));
    }
  }

  hasDiff(nextValues: object): boolean {
    if (this.prevValues === nextValues) {
      return false;
    }

    const difference = diff(this.prevValues, nextValues);
    const keys = Object.keys(difference);

    return !keys.every((key) => this.skipField(key));
  }

  skipField(field: string): boolean {
    const { fieldsToSkip = [] } = this.props;

    return fieldsToSkip.includes(field);
  }

  save = async (nextValues: any) => {
    if (this.promise) {
      await this.promise;
    }
    const { save } = this.props;
    const hasDiff = this.hasDiff(nextValues);

    // This diff step is totally optional
    if (hasDiff) {
      this.setState({ submitting: true });

      this.prevValues = nextValues;

      this.promise = save(nextValues);
      await this.promise;
      delete this.promise;
      this.setState({ submitting: false });
    }
  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return this.state.submitting && this.props.render ? this.props.render() : null;
  }
}

export const AutoSave = (props: any) => (
  <FormSpy
    {...props}
    component={AutoSaveComponent}
    subscription={{ values: true, active: true, initialValues: true }}
  />
);

type OnChangeDelayedProps = {
  onChange: (reason: string, values: any) => void;
};

export const [OnChangeDelayed, useAutosave] = constate(({ onChange }: OnChangeDelayedProps) => {
  const { subscribe } = useForm();
  const prevValue = useRef<string[] | undefined>(undefined);
  const onChangeRef = useRef<OnChangeDelayedProps["onChange"] | undefined>(undefined);
  onChangeRef.current = onChange;
  const mounted = useMountedState();
  useLayoutEffect(() => {
    const notTrottled = (v: any) => {
      if (!isEqual(v.values, prevValue.current?.values || v.initialValues) && mounted()) {
        onChangeRef.current?.("values", v.values);
        prevValue.current = v;
      }
    };
    const debounced = debounce(notTrottled, 1000);

    const unsub = subscribe(
      (v) => {
        if (v.active) {
          debounced(v);
        } else {
          debounced.cancel();
          notTrottled(v);
        }
      },
      {
        values: true,
        active: true,
        initialValues: true,
      }
    );
    return () => {
      unsub();
      debounced.cancel();
    };
  }, [subscribe]);
  return { onChange };
});
