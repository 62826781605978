import { useCallback } from "react";
import { resolveService } from "service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { checkVersion } from "app/setupVersionCheck";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { api } from "api";
import { auth } from "api/gen";
import { notification } from "modules/notification";
import { EUserRole } from "api/auth/interfaces";
import { getTokenExpirationTime } from "api/auth/storage";
import { preloadBetas } from "modules/preferences/state";
import { logEvent, eventsConstants } from "modules/analytics/Analytics";
import { sendGQLRequest } from "api/graphql/network";
import {
  GetAuthProvidersDocument,
  GlobalAuthSettingsDocument,
  UpdateAuthProviderAvailabilityDocument,
  UpdateAuthProviderAvailabilityMutationVariables,
  UpdateGlobalAuthSettingsDocument,
} from "api/graphql";
import { authEffects } from "./effects";
import { messages } from "./messages";
import { AuthService } from "./firebase";

type RegistrationError =
  | (Error & { type: "error" })
  | {
      type: "mfa-required";
      finishAuth(): void;
    }
  | {
      type: "mfa";
      sendCode(verifier: any): Promise<string>;
      verifyCode(code: any): Promise<void>;
    };

type RegistrationArgs = {
  type: string;
  cloud?: string;
  values?: { email: any; password: any };
  params?: { name: any };
};

export function useSignUp(email: any) {
  const navigate = useNavigate();
  const onUserVerified = useOnUserVerifiedCallback();

  return useMutation<void, RegistrationError, RegistrationArgs>({
    mutationFn: async ({ type, values, cloud, params }) => {
      const svc = await resolveService("authService");
      try {
        const currentUser = await svc.getCurrentUser();
        if (!currentUser) {
          await svc.signInWithEmailLink(email);
        }
      } catch (e) {
        handleLoginError(
          svc,
          e,
          async () => {
            await postSignUpSetup(svc, type, cloud, params, values);
          },
          onUserVerified
        );
        if ((e as any).code !== "auth/multi-factor-auth-required") {
          await svc.logout();
        }
        throw e;
      }
      return await postSignUpSetup(svc, type, cloud, params, values);
    },
    onSuccess() {
      notification.open({ message: messages.notifications.signUpSuccess });
      navigate("/login");
    },
  });

  async function postSignUpSetup(
    svc: AuthService,
    type: string,
    cloud: string | undefined,
    params: { name: any } | undefined,
    values: { email: any; password: any } | undefined
  ) {
    await svc.unlinkAll();
    const p =
      type === "cloud" ? svc.linkCloudProvider(cloud as any, params) : svc.linkCredentials(values!);
    return p
      .then(signup)
      .then(() => svc.refreshToken())
      .catch(async (el) => {
        if (el.code === "auth/provider-already-linked") {
          notification.open({
            message: el.message + " Please visit login page.",
          });
          navigate("/login");
        } else if (el?.response?.data?.detail?.Code === "invalid-email") {
          el.message = el.response.data.message;
          notification.error({
            props: {
              autoHideDuration: 10000,
            },
            message: el.response.data.message ?? el.message,
          });
          await svc.unlinkAll();
        } else if (el.response?.data?.detail?.Code === "mfa-required") {
          el.type = "mfa-required";
          el.finishAuth = () =>
            signup()
              .then(() => svc.refreshToken())
              .then(onAuthDone)
              .then(onUserVerified)
              .then(() => navigate("/"));
        }

        throw el;
      })
      .then(onAuthDone)
      .then(onUserVerified)
      .then(() => {
        navigate("/");
      });
  }
}

async function signup(v?: any) {
  await axios.post("/v2/user-management/signup");
  return v;
}
async function onAuthDone() {
  const s = await resolveService("authService");
  // wait for user to be resolved
  await s.getCurrentUser();
  const userInfo = await api.auth.AuthService.UserGetCurrent({});
  if (userInfo.roles?.includes(EUserRole.INACTIVE)) {
    throw new Error(messages.notifications.accountNotApproved);
  }
  const expirationTime = await getTokenExpirationTime();
  if ((expirationTime || 0) < Date.now()) {
    notification.warning({
      message: messages.notifications.systemClockOutOfSync,
      description: messages.notifications.systemClockOutOfSyncDescription,
      props: {
        autoHideDuration: 10000,
      },
    });
  }

  return userInfo;
}

function useOnUserVerifiedCallback() {
  const client = useQueryClient();

  return useCallback(async (user: any) => {
    localStorage.setItem("authenticated", "on");
    const { success } = authEffects.login;
    const permissions = await resolveService("permissions");
    permissions.setCurrentUser(user.id);
    preloadBetas(client);
    success(user);
    logEvent(eventsConstants.auth.login);
    // After login user feature flags can be different from anonymous.
    // In that case we can refresh as part of login process instead of showing popup.
    checkVersion(true);
  }, []);
}

function handleLoginError(
  svc: any,
  error: any,
  onVerify: () => Promise<any>,
  onAuthFinished: (user: any) => Promise<void>
) {
  if (error?.response?.data?.detail?.Code === "sign-up-incomplete") {
    error.message = messages.notifications.notInvitedAccount;
  }
  if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
    error.message = messages.loginError;
  }
  if (error.code === "auth/admin-restricted-operation") {
    error.message = "You are not invited. Contact admin to get the invite.";
  }
  if (error.code === "auth/multi-factor-auth-required") {
    error.type = "mfa";
    let verificationId: any;
    error.sendCode = async (verifier: any) => {
      verificationId = await svc.loginMFASendCode(verifier, error.resolver);
      return verificationId;
    };
    error.verifyCode = async (code: any) => {
      return svc
        .loginMFAAssert(verificationId, error.resolver, code)
        .then(onVerify)
        .catch((err: any) => {
          let message =
            err.response?.data?.error ||
            error?.message ||
            error.code ||
            err?.response?.data ||
            "Something went wrong. Please contact an admin";

          if (err.response?.status === 403) {
            message = messages.loginError;
          }

          if (err?.response?.data?.detail?.Code === "invalid-provider") {
            message = messages.notifications.notInvitedAccount;
          }

          notification.error({
            message,
            props: { autoHideDuration: 4000 },
          });
          throw err;
        });
    };
  }

  if (error.response?.data?.detail?.Code === "mfa-required") {
    error.type = "mfa-required";
    error.finishAuth = () => svc.refreshToken().then(onAuthDone).then(onAuthFinished);
  }

  throw error;
}

type LoginArgs = {
  type: string;
  cloud?: string;
  values?: any;
  params?: any;
};

type LoginError =
  | (Error & { type: "error" })
  | {
      type: "mfa";
      boolean: boolean;
      sendCode(verifier: any): Promise<any>;
      verifyCode(code: string): Promise<any>;
      setRecapcha(): void;
    }
  | {
      type: "mfa-required";
      finishAuth(): void;
    };

export function useLogin() {
  const onUserVerified = useOnUserVerifiedCallback();

  return useMutation<void, LoginError, LoginArgs>({
    mutationFn: async ({ type, cloud, values, params }: LoginArgs) => {
      const svc = await resolveService("authService");
      let res;
      if (type === "cloud") {
        res = svc
          .signInCloud(cloud as any, params)
          .then(onAuthDone)
          .then(onUserVerified);
      } else {
        res = svc.signInPassword(values).then(onAuthDone).then(onUserVerified);
      }

      return res.catch(async (error) =>
        handleLoginError(
          svc,
          error,
          async () => {
            await onUserVerified(await onAuthDone());
          },
          onUserVerified
        )
      );
    },
    onError(e) {
      if (e.type !== "mfa" && e.type !== "mfa-required") {
        notification.error({
          message: e.message,
          props: { autoHideDuration: 10000 },
        });
      }
    },
  });
}

type LoginWithRedirectArgs = {
  cloud: string;
  params?: any;
};
export function useLoginWithRedirect() {
  const onUserVerified = useOnUserVerifiedCallback();

  return useMutation<void, LoginError, LoginWithRedirectArgs>({
    mutationFn: async ({ cloud, params }: LoginWithRedirectArgs) => {
      const svc = await resolveService("authService");

      try {
        const result = await svc.getRedirectResult();

        if (!result.token) {
          await svc.signInCloudWithRedirect(cloud as any, params);
          return;
        }

        const userInfo = await onAuthDone();
        onUserVerified(userInfo);
      } catch (error: any) {
        handleLoginError(
          svc,
          error,
          async () => {
            await onUserVerified(await onAuthDone());
          },
          onUserVerified
        );
      }
    },
    onError(e) {
      if (e.type !== "mfa" && e.type !== "mfa-required") {
        notification.error({
          message: e.message,
          props: { autoHideDuration: 10000 },
        });
      }
    },
  });
}

const AuthProvidersKey = "auth-providers";
const AuthProviderListKey = "auth-providers-list";

export function useDefaultProviders() {
  return useQuery({
    queryKey: [AuthProvidersKey],
    queryFn: api.auth.AuthService.ListEnabledAuthProviders,
  });
}

export function useDefaultProvidersList() {
  return useQuery({
    queryKey: [AuthProviderListKey],
    queryFn: () => sendGQLRequest(GetAuthProvidersDocument, {}),
  });
}

export function useDefaultProviderAvailabilityUpdate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ name, enabled }: UpdateAuthProviderAvailabilityMutationVariables) =>
      sendGQLRequest(UpdateAuthProviderAvailabilityDocument, { name, enabled }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [AuthProviderListKey],
      });
    },
  });
}

export function usePasswordProviderMFAUpdate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ enforce }: { enforce: boolean }) =>
      api.auth.AuthService.UpdateEnforceMFA({
        provider: "password",
        enabled: enforce,
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [AuthProviderListKey],
      });
    },
    onError() {
      notification.error({
        message: "Error updating MFA",
      });
    },
  });
}

const SAMLProviderListKey = "saml-provider-list";

export function useSAMLProviders() {
  return useQuery({
    queryKey: ["saml-providers"],

    queryFn: () => {
      return api.auth.AuthService.SAMLProvidersListIDs({});
    },
  });
}

export function useSAMLProvidersList() {
  return useQuery({
    queryKey: [SAMLProviderListKey],
    queryFn: api.auth.AuthService.SAMLProvidersList,
  });
}

export function useSAMLProviderUpdate({ onDone }: { onDone?: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.auth.AuthService.SAMLProviderPatch,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [SAMLProviderListKey],
      });
      onDone?.();
    },
  });
}

export function useSAMLProviderCreate({ onDone }: { onDone?: () => void }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.auth.AuthService.SAMLProviderCreate,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [SAMLProviderListKey],
      });
      onDone?.();
    },
  });
}

export function useSAMLProviderDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: api.auth.AuthService.SAMLProviderDelete,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [SAMLProviderListKey],
      });
    },
  });
}

export function useForgotPassword(
  props: Partial<
    Omit<
      UseMutationOptions<
        auth.UserPasswordResetEmailResp,
        Error,
        {
          email: string;
        },
        unknown
      >,
      "mutationFn"
    >
  > = {}
) {
  return useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      return api.auth.AuthService.UserPasswordResetEmail({ email });
    },
    onSuccess() {
      notification.success({
        message: messages.resetAuthMethod,
      });
    },
    ...props,
  });
}

interface GlobalAuthSettings {
  inactivity_timeout_seconds?: number;
  inactivity_prompt_timeout_seconds?: number;
}
const AuthSettingsKey = "auth-settings";
export function useGlobalAuthSettings(
  config?:
    | Omit<
        UseQueryOptions<GlobalAuthSettings, unknown, GlobalAuthSettings, string[]>,
        "queryKey" | "queryFn"
      >
    | undefined
) {
  return useQuery({
    queryKey: [AuthSettingsKey],

    queryFn: () =>
      sendGQLRequest(GlobalAuthSettingsDocument, {}).then(
        (result) => result?.global_auth_settings?.[0]?.auth_data
      ),

    ...config,
  });
}

export function useGlobalAuthSettingsUpdate({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: GlobalAuthSettings) =>
      sendGQLRequest(UpdateGlobalAuthSettingsDocument, {
        auth_data: settings,
      }),
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [AuthSettingsKey],
      });
      onSuccess?.();
    },
    onError,
  });
}
